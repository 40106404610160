import { firstLetterUpperCase } from './firstLetterUpperCase';

const NAMES_OVERRIDE: { [key: string]: string } = {
  fetchai: 'FetchAI',
  eos: 'EOS',
  iost: 'IOST',
  lto: 'LTO',
  iotex: 'IoTeX',
  nkn: 'NKN',
  boinc: 'BOINC',
};

export const formatNodeName = (str: string) => {
  return NAMES_OVERRIDE[str] ?? firstLetterUpperCase(str);
};

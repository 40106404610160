/* eslint-disable @typescript-eslint/camelcase */
import { createAction } from 'common/utils/reduxUtils';
import {
  ChartRepo,
  IExtendedRequestAction,
  IFetchChartsParams,
  IRequestConfig,
} from 'common/types';
import { DEFAULT_CACHE_SIZE, DEFAULT_CACHE_TIME } from 'common/core/const';
import { IApiChartSaveAs } from '../types';
import { CHARTSAPI } from '../const';
import { mapToAPIEnterpriseLevel } from '../../teams/apiMappings/teamMappings';

const ChartsActionTypes = {
  CHART_CREATE: 'CHART_CREATE',
  CHART_CREATE_ERROR: 'CHART_CREATE_ERROR',
  CHART_CREATE_SUCCESS: 'CHART_CREATE_SUCCESS',

  CHARTS_FETCH: 'CHARTS_FETCH',
  CHARTS_FETCH_RESET: 'CHARTS_FETCH_RESET',

  CHART_FETCH_DETAILS: 'CHART_FETCH_DETAILS',

  CHART_UPDATE: 'CHART_UPDATE',
  CHART_UPDATE_SUCCESS: 'CHART_UPDATE_SUCCESS',
  CHART_UPDATE_ERROR: 'CHART_UPDATE_ERROR',

  CHART_DOWNLOAD_INIT: 'CHART_DOWNLOAD_INIT',
  CHART_DOWNLOAD: 'CHART_DOWNLOAD',
  CHART_DOWNLOAD_SUCCESS: 'CHART_DOWNLOAD_SUCCESS',
  CHART_DOWNLOAD_ERROR: 'CHART_DOWNLOAD_ERROR',

  CHART_SAVE_AS: 'CHART_SAVE_AS',
  CHART_SAVE_AS_SUCCESS: 'CHART_SAVE_AS_SUCCESS',
  CHART_SAVE_AS_ERROR: 'CHART_SAVE_AS_ERROR',
  CHART_SAVE_AS_RESET: 'CHART_SAVE_AS_RESET',

  //
  CHART_DEPLOY: 'CHART_DEPLOY',
};

function getChartDetailsAddressURL(
  repository: string,
  name: string,
  version: string,
  teamId?: string,
) {
  return `${CHARTSAPI}/${repository}/${name}/${version}${
    teamId ? '?team_id=' + teamId : ''
  }`;
}

function makeChartsRequest(
  repository: ChartRepo,
  teamId: string,
  isEnterpise?: boolean,
  level?: string,
): IRequestConfig {
  if (repository.length === 0) {
    throw new Error(
      'Numbers of repositories to fetch charts should be higher then 0',
    );
  }

  return {
    url: CHARTSAPI,
    method: 'GET',
    params: {
      repository,
      team_id: teamId,
      node_market: true,
      enterprise_level: mapToAPIEnterpriseLevel(level, isEnterpise),
    },
  };
}

const ChartsActions = {
  fetchCharts: ({
    teamId,
    repository,
    isEnterprise,
    level,
  }: IFetchChartsParams): IExtendedRequestAction => ({
    type: ChartsActionTypes.CHARTS_FETCH,
    request: makeChartsRequest(repository, teamId, isEnterprise, level),
    meta: {
      cacheKey: repository + teamId,
      cache: DEFAULT_CACHE_TIME,
      cacheSize: DEFAULT_CACHE_SIZE,
      repository,
      takeLatest: false,
    },
  }),

  resetFetchChartsStatus: () => ({
    type: ChartsActionTypes.CHARTS_FETCH_RESET,
  }),

  createChart: (
    repository: ChartRepo = 'user',
    name: string,
    version: string,
    chartFile: string,
    teamId?: string,
  ): IExtendedRequestAction => ({
    type: ChartsActionTypes.CHART_CREATE,
    request: {
      url: getChartDetailsAddressURL(repository, name, version),
      params: {
        ...(teamId ? { team_id: teamId } : {}),
      },
      method: 'POST',
      data: { chart_file: chartFile },
    },
  }),

  fetchChartDetails: (
    repository: string,
    name: string,
    version: string,
    teamId?: string,
  ): IExtendedRequestAction => ({
    type: ChartsActionTypes.CHART_FETCH_DETAILS,
    request: {
      url: getChartDetailsAddressURL(repository, name, version, teamId),
      method: 'GET',
    },
    meta: {
      cacheKey: repository + name + version + teamId,
      cache: DEFAULT_CACHE_TIME,
      cacheSize: DEFAULT_CACHE_SIZE,
    },
  }),

  downloadChart: (
    repository: string,
    name: string,
    version: string,
    teamId?: string,
  ): IExtendedRequestAction => ({
    type: ChartsActionTypes.CHART_DOWNLOAD,
    request: {
      url: getChartDetailsAddressURL(repository, name, version),
      params: {
        ...(teamId ? { team_id: teamId } : {}),
      },
      method: 'GET',
    },
  }),

  saveAsChart: ({
    sourceRepo,
    sourceVersion,
    sourceName,
    destinationRepo,
    destinationVersion,
    destinationName,
    values,
    teamId,
  }: {
    sourceRepo: string;
    sourceVersion: string;
    sourceName: string;
    destinationRepo: string;
    destinationVersion: string;
    destinationName: string;
    values: string;
    teamId?: string;
  }): IExtendedRequestAction => ({
    type: ChartsActionTypes.CHART_SAVE_AS,
    request: {
      url: CHARTSAPI,
      method: 'PUT',
      data: {
        src_repo: sourceRepo,
        src_ver: sourceVersion,
        src_name: sourceName,
        dst_repo: destinationRepo,
        dst_ver: destinationVersion,
        dst_name: destinationName,
        values_yaml: values,
        team_id: teamId,
      } as IApiChartSaveAs,
    },
  }),

  resetSaveAsChart: () => createAction(ChartsActionTypes.CHART_SAVE_AS_RESET),
};

export { ChartsActions, ChartsActionTypes, getChartDetailsAddressURL };

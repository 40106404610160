import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { requestInactive } from 'common/utils/requestStatus';
import { getAppDetails } from 'common/modules/apps/reducers/appsReducer';
import { IStoreState } from 'store/reducers';
import { AppsActions } from 'common/modules/apps/actions/AppsActions';
import { useParams } from 'react-router';
import { IApplicationDetailsRouterParams } from '../types';

const LOADING_STATE = requestInactive();

export const useAppParams = (): IApplicationDetailsRouterParams => {
  return useParams<IApplicationDetailsRouterParams>();
};

export const useAppDetails = (appIdOverride?: string) => {
  const appId = appIdOverride ?? useAppParams().appId;
  const { data, status, teamId } = useSelector((state: IStoreState) => {
    const appEntry = getAppDetails(
      state.apps.details,
      appId,
      state.team.currentTeamId,
    );

    return {
      appEntry,
      status: appEntry?.status || LOADING_STATE,
      data: appEntry?.data,
      teamId: state.team.currentTeamId,
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  const fetch = useMemo(
    () => () => {
      appId &&
        dispatch(AppsActions.fetchAppDetails({ appId, teamId: teamId || '' }));
    },
    [dispatch, appId, teamId],
  );

  return {
    fetch,
    data,
    status,
    appId,
  };
};

export const tronFullNodeName = 'tron-full';

export enum TronFullHooks {
  getProgress = 'apphook1=get-progress',
  getStatus = 'apphook2=get-status',
}

export const TRON_FULL_ENABLE_SNAPSHOT = 'ankrCustomValues___enable_snapshot';

export const TRON_FULL_DOCS_HREF =
  'https://docs.ankr.com/enteprise-solutions/tron';

import { put } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { TeamActions } from '../actions/TeamActions';
import { onTeamSelectSaga } from './teamSaga';
import { createAction } from '../../../utils/reduxUtils';

export function* onInvitationConfirm(action: AnyAction) {
  yield put(TeamActions.setCurrentTeam(action.meta.id));
  yield onTeamSelectSaga(createAction('', { teamId: action.meta.id }));
}

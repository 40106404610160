import React from 'react';

import { FormSpy } from 'react-final-form';

export const ReplicasContainer = ({
  children,
}: {
  children: React.ReactNode | (React.ReactNode | null)[];
}) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        return values?.replicas > 1 ? children : null;
      }}
    </FormSpy>
  );
};

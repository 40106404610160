import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { t } from 'common/utils/intl';
import { AppColor } from 'common/core/webColors';
import { fromCharCodes } from 'common/utils/fromCharCodes';
import Button from '@material-ui/core/Button';

interface IFileInputComponent {
  label?: string;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (data: string) => void;
  outputType?: 'base64' | 'string';
  backgroundColor?: string;
}

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  value: {
    marginLeft: 10,
    fontWeight: 'bold',
    color: '#4a4a4a',
  },
  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    margin: '8px 12px',
  },
  fileUploadButton: ({ backgroundColor = '#fff' }: IFileInputComponent) => ({
    boxShadow: '0 1px 4px 0 #d8d8d8',
    border: 'solid 1px #d0d0d0',
    color: AppColor.OFFICIAL,
    fontWeight: 'normal',
    background: backgroundColor,
    whiteSpace: 'nowrap',
  }),
}));

const FileInputComponent = (props: IFileInputComponent) => {
  const {
    label,
    value,
    onChange,
    error,
    helperText,
    outputType = 'base64',
    backgroundColor,
    name,
    ...rest
  } = props;
  const classes = useStyles(props);
  const [filename, setFilename] = React.useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files.item(0);

    if (file && onChange) {
      new Response(file).arrayBuffer().then(arrayBuffer => {
        let data = fromCharCodes(Array.from(new Uint8Array(arrayBuffer)));
        data = data.replace('ï»¿', '');
        if (outputType === 'base64') {
          const encryptedData = btoa(data);
          onChange(encryptedData);
        } else {
          onChange(data);
        }

        setFilename(file.name);
      });
    }

    return false;
  };
  const id = name || 'file-input';
  return (
    <>
      <input
        name={name}
        className={classes.input}
        id={id}
        type="file"
        onChange={handleChange}
        {...rest}
      />

      <label htmlFor={id}>
        <Button
          variant="contained"
          component="div"
          classes={{ root: classes.fileUploadButton }}
        >
          {t('buttons.choose-file')}
        </Button>
      </label>

      <span className={classes.value}>{filename || t('buttons.no-file')}</span>

      {error && <div className={classes.error}>{helperText}</div>}
    </>
  );
};

const FileInput = FileInputComponent;

export { FileInput };

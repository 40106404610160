import * as Sentry from '@sentry/browser';
import BigNumber from 'bignumber.js';

import {
  APIAuthenticationType,
  APIOrderStatus,
  APIType,
  IAPICluster,
  IAPIClusterResponse,
  IAPIMarketAPIResponse,
  IAPIMarketBlockInfo,
  IAPIMarketBlockInfoResponse,
  IAPIMarketInstance,
  IAPIMarketInstanceResponse,
  IAPIMarketItem,
  IAPIMarketPlan,
  IAPIMarketPlansResponse,
  IAPIMarketResponse,
  IAPIOrder,
  IAPIOrderResponse,
  IAPIRequestsHistoryItem,
  IAPIRequestsHistoryResponse,
} from './APIMarketTypes';

export const mapAPIMarketList = (
  response: IAPIMarketResponse,
): IAPIMarketItem[] => {
  try {
    return response.data.map(item => {
      return {
        id: item.id,
        code: item.code,
        name: item.name,
        network: item.network,
        nodeType: item.node_family,
        iconUrl: item.icon_url,
        iconOnlyUrl: item.icon_only_url,
        nodeName: item.name,
        price: new BigNumber(item.price),
        supportedClusterIds: item.supported_cluster_ids,
        shown: item.shown,
      };
    });
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const formatAPIMarketInstanceResponseToIAPIMarketInstance = (
  item: IAPIMarketInstanceResponse,
): IAPIMarketInstance => {
  return {
    id: item.id,
    code: item.node_code,
    network: item.network,
    nodeType: item.node_type,
    nodeName: item.node_name,
    projectName: item.project_name,
    totalRequests: item.total_requests,
    todaysRequests: item.today_request,
    maximumLimit: parseInt(item.maximum_limit, 10),
    endpoint: item.endpoint,
    wsEndpoint: item.ws_endpoint,
    iconUrl: item.iconUrl,
    iconOnlyUrl: item.iconOnlyUrl,
    nodeCode: item.node_code,
    plan: item.plan,
    authUsername: item.auth_username,
    authPassword: item.auth_password,
    isActive: item.status === 'api_status_active',
    orderId: item.order_id,
    subscribeStatus: item.subscribe_status,
    createTime: Number(item.create_time) * MILLISECONDS,
    activeUntil: Number(item.active_until) * MILLISECONDS,
    clusterId: item.cluster_name,
    supportedClusterIds: item.supportedClusterIds,
  };
};

export const mapAPIList = (
  response: IAPIMarketAPIResponse,
): IAPIMarketInstance[] => {
  try {
    return response.data
      .sort(
        (a, b) =>
          +new Date(Number(b.create_time) * MILLISECONDS) -
          +new Date(Number(a.create_time) * MILLISECONDS),
      )
      .map(formatAPIMarketInstanceResponseToIAPIMarketInstance);
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapAPIPlansList = (
  response: IAPIMarketPlansResponse,
): IAPIMarketPlan[] => {
  try {
    return response.data
      .filter(item => !item.type?.includes('test'))
      .map(item => ({
        id: item.id,
        type: item.type,
        limitDaily: new BigNumber(item.max_req_daily),
        limitMonthly: new BigNumber(item.max_req_mo),
        limitSecondly: new BigNumber(item.ratet_limit_sec),
        price: new BigNumber(item.price),
        nodeCodes: item.node_codes,
        dateCreated: new Date(item.create_time),
        support: item.support,
      }));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapAPIOrder = (item: IAPIOrderResponse): IAPIOrder | undefined => {
  try {
    return {
      id: item.order_id,
      apiType: item.api_type as APIType,
      planId: item.plan_id,
      duration: +item.plan_duration_month,
      price: new BigNumber(item.server_price),
      authType: item.auth_type as APIAuthenticationType,
      username: item.auth_username,
      password: item.auth_password,
      token: item.auth_token,
      dateCreated: new Date(item.create_time),
      status: item.order_status as APIOrderStatus,
    };
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

export const mapBlockInfo = (
  item: IAPIMarketBlockInfoResponse,
): IAPIMarketBlockInfo | undefined => {
  try {
    return {
      currentBlock: Number(item.current_block),
      highestBlock: Number(item.highest_block),
    };
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

const MILLISECONDS = 1000;

export const mapAPIRequestsHistoryList = (
  response: IAPIRequestsHistoryResponse,
): IAPIRequestsHistoryItem[] => {
  try {
    return response.api_log_res_list
      .sort((a, b) => +new Date(b.sent_time) - +new Date(a.sent_time))
      .map(item => {
        const {
          method,
          error_code,
          http_status,
          response_time,
          sent_time,
          raw_request,
          raw_response,
        } = item;

        const sentTime = Number(sent_time) * MILLISECONDS;

        const result: IAPIRequestsHistoryItem = {
          method,
          errorCode: error_code,
          httpStatus: http_status,
          responseTime: response_time,
          sentTime,
          rawRequest: raw_request,
          rawResponse: raw_response,
        };

        return result;
      });
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapDecodeBase64Array = (
  response: string[],
): string[] | undefined => {
  try {
    return response.map(i => atob(i));
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

export const mapAPIClusterList = (
  items: IAPIClusterResponse[],
): IAPICluster[] => {
  return items.map(item => {
    const { cluster_id, geo_location } = item;

    return {
      clusterId: cluster_id,
      geoLocation: geo_location,
    };
  });
};

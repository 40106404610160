import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getErrorText, hasError } from 'common/utils/form';
import { ChipInput } from './ChipInput';
import { FormHelperText } from '@material-ui/core';

export const ChipInputField = ({
  input: { onChange, value },
  onBeforeAdd,
  meta,
  className,
  dataSource,
}: FieldRenderProps<HTMLInputElement> & {
  className?: string;
  onBeforeAdd?: (chip: any) => boolean;
  dataSource?: string[];
}) => {
  const arrayValue: string[] = value && Array.isArray(value) ? value : [];

  const handleAdd = useCallback(
    (data: string) => {
      onChange([...arrayValue, data] as any);
    },
    [arrayValue, onChange],
  );

  const handleDelete = useCallback(
    (data: string) => {
      onChange(arrayValue.filter(item => item !== data) as any);
    },
    [arrayValue, onChange],
  );

  const onKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') e.preventDefault();
  }, []);

  return (
    <div className={className} onKeyPress={onKeyPress}>
      <ChipInput
        value={arrayValue}
        onAdd={handleAdd}
        onDelete={handleDelete}
        onBeforeAdd={onBeforeAdd}
        blurBehavior="add"
        allowDuplicates={false}
        dataSource={dataSource}
      />
      {hasError(meta) && (
        <FormHelperText error={true}>{getErrorText(meta)}</FormHelperText>
      )}
    </div>
  );
};

import { AvatarImage } from './AvatarImage';
import { connect } from 'react-redux';
import { IUserState } from 'auth/store/reducers/userReducer';

const mapStateToProps = (state: { user: IUserState }) => {
  return {
    avatar: state.user.avatar,
    username: state.user.name,
  };
};

const AvatarImageContainer = connect(mapStateToProps)(AvatarImage);

export { AvatarImageContainer };

import { getKDFKey } from './getKDFKey';
import { Counter, ModeOfOperation } from 'aes-js';
import keccak256 from 'keccak256';
import { IEncryptedPrivateKey } from 'common/types/walletTypes';

const SUPPORTED_CYPHER_NAME = 'aes-128-ctr';

export const decryptDataV3Errors = {
  CYPHER_IS_NOT_SUPPORTED: 'CYPHER_IS_NOT_SUPPORTED',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
};

export function decryptDataV3(
  encryptedPrivateKey: IEncryptedPrivateKey,
  auth: string,
) {
  if (encryptedPrivateKey.cipher !== SUPPORTED_CYPHER_NAME) {
    throw new Error(decryptDataV3Errors.CYPHER_IS_NOT_SUPPORTED);
  }

  const mac = Uint8Array.from(Buffer.from(encryptedPrivateKey.mac, 'hex'));
  const iv = Uint8Array.from(
    Buffer.from(encryptedPrivateKey.cipherparams.iv, 'hex'),
  );

  const cypherText = Uint8Array.from(
    Buffer.from(encryptedPrivateKey.ciphertext, 'hex'),
  );

  return getKDFKey(encryptedPrivateKey, auth).then(key => {
    const encryptKey = key.slice(0, 16);

    const counter = new Counter(5);
    counter.setBytes(iv);
    const cbc = new ModeOfOperation.ctr(encryptKey, counter);
    const privateKey = cbc.decrypt(cypherText);

    const calculatedMac = new Uint8Array(
      keccak256(
        Buffer.from([
          ...Array.from(key.slice(16, 32)),
          ...Array.from(cypherText),
        ]),
      ),
    );

    if (Buffer.compare(new Buffer(mac), new Buffer(calculatedMac)) !== 0) {
      throw new Error(decryptDataV3Errors.WRONG_PASSWORD);
    }

    return {
      privateKey,
    };
  });
}

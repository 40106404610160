import { createAction } from 'common/utils/reduxUtils';
import {
  ISnackbar,
  ISnackbarOptions,
  SnackbarKey,
  SnackbarMessage,
} from 'common/store/reducers/snackbarsReducer';

const SnackbarsActionsTypes = {
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',
  REMOVE_SNACKBAR: 'REMOVE_SNACKBAR',
  SHOW_SNACKBAR_INT: 'SHOW_SNACKBAR_INT',
  REMOVE_SNACKBAR_INT: 'REMOVE_SNACKBAR_INT',
  CLEAR_SNACKBAR_INT: 'CLEAR_SNACKBAR_INT',
};

const SnackbarsActions = {
  showSnackbar: (
    message: SnackbarMessage,
    options: ISnackbarOptions = { autoHideDuration: 5000 },
  ) =>
    createAction(SnackbarsActionsTypes.SHOW_SNACKBAR, {
      message,
      options,
    }),
  showSnackbarInternal: (snackbar: ISnackbar) =>
    createAction(SnackbarsActionsTypes.SHOW_SNACKBAR_INT, snackbar),
  removeSnackbar: (key: SnackbarKey) =>
    createAction(SnackbarsActionsTypes.REMOVE_SNACKBAR, key),
  removeSnackbarInternal: (key: SnackbarKey) =>
    createAction(SnackbarsActionsTypes.REMOVE_SNACKBAR_INT, key),
  clearSnackbarInternal: () =>
    createAction(SnackbarsActionsTypes.CLEAR_SNACKBAR_INT),
};

export { SnackbarsActionsTypes, SnackbarsActions };

import React, { useState, useCallback, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Popper, ClickAwayListener, Grow, IconButton } from '@material-ui/core';

import { IStoreState } from 'store/reducers';
import { isUserAuthenticated as isUserAuthenticatedSelector } from 'auth/store/reducers/tokensReducer';
import { NewsIcon } from 'common/components/Icons/NewsIcon';
import { useToolbarNotificationStyles } from './ToolbarNotificationStyles';
import { NodeActivity } from 'modules/DashboardUI/components/NodeActivity';
import { useIsXSDown } from 'common/utils/hooks/themeHooks';

interface IToolbarNotificationComponentProps {
  className?: string;
}

const isNewNotification = true; //hard-code

export const ToolbarNotification = ({
  className,
}: IToolbarNotificationComponentProps) => {
  const classes = useToolbarNotificationStyles();

  const isUserAuthenticated = useSelector((state: IStoreState) =>
    isUserAuthenticatedSelector(state.tokens),
  );

  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const toggle = useCallback(() => setIsOpened(isOpened => !isOpened), []);

  const handleClose = useCallback(() => {
    setTimeout(() => setIsOpened(false), 0);
  }, []);

  const isXSDown = useIsXSDown();

  useEffect(() => {
    if (isXSDown) {
      document.body.style.overflow = isOpened ? 'hidden' : '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isXSDown, isOpened]);

  useEffect(() => {
    window.addEventListener('resize', handleClose);

    return () => window.removeEventListener('resize', handleClose);
  }, [handleClose]);

  if (!isUserAuthenticated) return null;

  return (
    <>
      <div
        className={classNames(className, classes.component)}
        ref={elementRef}
      >
        <IconButton
          className={classNames(
            classes.toggle,
            isNewNotification && classes.toggleAlert,
          )}
          aria-label="notifications"
          onClick={toggle}
        >
          <NewsIcon />
        </IconButton>
      </div>

      <Popper
        open={isOpened}
        anchorEl={elementRef.current}
        transition={true}
        disablePortal={true}
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
              <div className={classes.paper}>
                <NodeActivity className={classes.nodeActivity} />
                {/* <NotificationDropdown className={classes.dropdown} /> */}
              </div>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
};

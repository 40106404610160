import { AUTH_PATH, LOGIN_PATH } from 'auth/src/const';

export { LOGIN_PATH };
export const ROOT_PATH = '/';
export const TERMS_PATH = 'https://www.ankr.com/terms';
export const MODE_PATH = `${AUTH_PATH}/mode`;
export const SIGN_UP_PATH = `${AUTH_PATH}/sign-up`;
export const SIGN_UP_SUCCESS_PATH = `${AUTH_PATH}/sign-up-success`;
export const USER_CONFIRM_REGISTRATION_PATH = '/confirm-register';
export const USER_REGISTRATION_SUCCESS_PATH = '/register-success';
export const CHANGE_EMAIL_PATH = '/change-email';
export const RESET_PASSWORD_PATH = '/reset-password';
export const RESET_PASSWORD_SUCCESS_PATH = `${AUTH_PATH}/reset-password-success`;
export const PASSWORD_RECOVERY_PATH = `${AUTH_PATH}/recover-password`;
export const PASSWORD_RECOVERY_SENT_PATH = `${PASSWORD_RECOVERY_PATH}/sent`;

export const CONTENT_MIN_HEIGHT = 434;
export const FORM_WIDTH = 340;

export const STAGE_API_BASE = 'https://hub-stage.ankr.com';
export const IS_STAGE_ENV = process.env.REACT_APP_API_BASE === STAGE_API_BASE;
export const ANY_RECAPTCHA_STRING_FOR_BACKEND = 'RECAPTCHA';
export const IS_RECAPTCHA_MOCKED = false;
export const IS_RECAPTCHA_ON_THE_STAGE_DISABLED =
  IS_STAGE_ENV && !IS_RECAPTCHA_MOCKED;

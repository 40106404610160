/* eslint-disable @typescript-eslint/camelcase */
import { FileInputField } from 'common/components/Form/FileInput';
import { PasswordInputField } from 'common/components/Form/PasswordInput';
import { RadioGroupField } from 'common/components/Form/RadioGroup';
import { ChipInputField } from 'common/components/Form/ChipInputField';

export const PAGE_MAX_WIDTH = 1600;

export const APP_DEPLOY_PATH = '/apps/deploy';

export const TEXT_FIELD = {
  attributes: { margin: 'none' },
};

export const PASSWORD_FIELD = {
  Component: PasswordInputField,
  attributes: { type: 'password', margin: 'none' },
};

export const CELO_DAPP_MNEMONIC_FIELD = {
  Component: ChipInputField,
};

export const CELO_VALIDATOR_RADIO_FIELD = {
  Component: RadioGroupField,
  attributes: {
    type: 'radio',
    margin: 'none',
    defaultValue: 'true',
    items: [
      {
        id: 'true',
        name:
          'app-deploy.default-custom-variables.overrides.celo-validator.use-ankr',
        description: '',
      },
      {
        id: 'false',
        name:
          'app-deploy.default-custom-variables.overrides.celo-validator.use-twillo',
        description: '',
      },
    ],
  },
};

export const CELO_ARCHIVE_RADIO_FIELD = {
  Component: RadioGroupField,
  attributes: {
    type: 'radio',
    margin: 'none',
    defaultValue: 'false',
    items: [
      {
        id: 'false',
        name: 'app-deploy.celo-deploy.non-archive',
        description: '',
      },
      {
        id: 'true',
        name: 'app-deploy.celo-deploy.archive',
        description: '',
      },
    ],
  },
};

export const ETHEREUM_ARCHIVE_RADIO_FIELD = {
  Component: RadioGroupField,
  attributes: {
    type: 'radio',
    margin: 'none',
    defaultValue: 'false',
    items: [
      {
        id: 'false',
        name: 'app-deploy.celo-deploy.non-archive',
        description: '',
      },
      {
        id: 'true',
        name: 'app-deploy.celo-deploy.archive',
        description: '',
      },
    ],
  },
};

export const FILE_FIELD = {
  Component: FileInputField,
  attributes: { outputType: 'string' },
};

export const MATIC_ETHERUEM_PRIVATE_KEY = 'ankrCustomValues___privateKey';
export const TRON_ADDRESS = 'ankrCustomValues___address';
export const MATIC_INFURA_KEY = 'ankrCustomValues___InfuraRPCAPIKey';
export const CELER_ETHERUEM_PRIVATE_KEY = 'ankrCustomValues___privateKey';
export const CELER_INFURA_KEY = 'ankrCustomValues___InfuraRPCAPIKey';
export const VITE_WALLET_ADDRESS = 'ankrCustomValues___address';
export const VITE_NODE_NAME = 'ankrCustomValues___nodename';
export const KUSAMA_ARCHIVE_MODE_KEY = 'ankrCustomValues___archiveMode';
export const MATIC_ARCHIVE_MODE_KEY = 'ankrCustomValues___archiveMode';
export const MATIC_ARCHIVE_NETWORK = 'ankrCustomValues___network';
export const EDGEWARE_NODE_NAME = 'ankrCustomValues___nodeName';
export const TOMO_X_EXCHANGE_ADDRESS = 'ankrCustomValues___exchangeAddress';
export const TOMO_X_NODE_NAME = 'ankrCustomValues___identity';
export const CELO_VALIDATOR_TWILO_ACCOUNT_SID =
  'ankrCustomValues___twilio_account_sid';
export const CELO_VALIDATOR_TWILO_AUTH_TOKEN =
  'ankrCustomValues___twilio_auth_token';
export const CELO_VALIDATOR_TWILO_SERVICE_SID =
  'ankrCustomValues___twilio_messaging_service_sid';
export const CELO_VALIDATOR_ANKR_SMS =
  'ankrCustomValues___use_ankr_sms_services';
export const CELO_ARCHIVE_MODE = 'ankrCustomValues___archiveMode';
export const CELO_DAPP_MNEMONIC = 'ankrCustomValues___mnemonic';
export const CELO_DAPP_PASSWORD = 'ankrCustomValues___password';
export const TOMOCHAIN_NODE_NAME = 'ankrCustomValues___identity';
export const TOMOCHAIN_PRIVATE_KEY = 'ankrCustomValues___private_key';
export const HARMONY_STAKING_NODENAME = 'ankrCustomValues___nodename';
export const HARMONY_STAKING_PASSWORD = 'ankrCustomValues___password';
export const ELROND_NAME = 'ankrCustomValues___name';
export const ELROND_KEYBASE = 'ankrCustomValues___keybase';
export const ELROND_NODE_NAME = 'ankrCustomValues___nodeName';
export const ELROND_KEY_FILE = 'ankrCustomValues___initialNodesSk';

export const REDDCOIN_RPC_USER = 'ankrCustomValues___rpcuser';
export const REDDCOIN_RPC_PASSWORD = 'ankrCustomValues___rpcpassword';

export enum FieldType {
  textArea = '**textarea**',
  optional = '**optional**',
  file = '**file**',
}

export const ARWEAVE_ADDRESS_LENGTH = 43;

export const CHAINLINK_VAL_WALLET_PASSWORD =
  'ankrCustomValues___wallet_password';
export const CHAINLINK_VAL_API_EMAIL = 'ankrCustomValues___api_email';
export const CHAINLINK_VAL_API_PASSWORD = 'ankrCustomValues___api_password';
export const CHAINLINK_VAL_NETWORK = 'ankrCustomValues___network';

export const NODE_NAME_MAX_LENGTH = 32;
export const NODE_NAME_INPUT_ATTRS = {
  inputProps: { maxLength: NODE_NAME_MAX_LENGTH },
};

export const CELO_VALIDATOR_DEFAULT_VALUES = {
  customValues: {
    [CELO_VALIDATOR_ANKR_SMS]: 'true',
  },
};

export const CELO_DEFAULT_VALUES = {
  customValues: {
    ankrCustomValues___archiveMode: 'false',
  },
};

export const ETHEREUM_STORAGE_OVERRIDE = {
  archive: 7000000,
  nonArchive: 700000,
};

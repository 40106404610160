import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const MenuTeamIcon = withSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 14.5C22 15.8807 20.8807 17 19.5 17C18.1193 17 17 15.8807 17 14.5C17 13.1193 18.1193 12 19.5 12C20.8807 12 22 13.1193 22 14.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4.5C15 5.88071 13.8807 7 12.5 7C11.1193 7 10 5.88071 10 4.5C10 3.11929 11.1193 2 12.5 2C13.8807 2 15 3.11929 15 4.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.5C8 15.8807 6.88071 17 5.5 17C4.11929 17 3 15.8807 3 14.5C3 13.1193 4.11929 12 5.5 12C6.88071 12 8 13.1193 8 14.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.406 5.36618L18.4884 6.55615C19.4231 7.09028 20 8.08431 20 9.16088V10.5309C19.8362 10.5105 19.6693 10.5 19.5 10.5C18.9696 10.5 18.4632 10.6033 18 10.7908V9.16088C18 8.80203 17.8077 8.47068 17.4961 8.29264L15.4964 7.14993C15.9382 6.65077 16.2571 6.04042 16.406 5.36618ZM16.0538 16.532C16.4118 17.1378 16.925 17.6411 17.5387 17.987L13.4884 20.3015C12.5661 20.8285 11.4339 20.8285 10.5116 20.3015L6.91104 18.244C7.61088 17.9801 8.21726 17.5256 8.66688 16.9438L11.5039 18.565C11.8113 18.7407 12.1887 18.7407 12.4961 18.565L16.0538 16.532ZM6 10.5309V9.16088C6 8.80203 6.19229 8.47068 6.50386 8.29264L9.19545 6.75459C8.81678 6.20064 8.57377 5.5466 8.51427 4.84033L5.51158 6.55615C4.57686 7.09028 4 8.08431 4 9.16088V10.7908C4.46322 10.6033 4.96955 10.5 5.5 10.5C5.66933 10.5 5.8362 10.5105 6 10.5309Z"
    />
  </>,
);

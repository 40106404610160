import { makeStyles, StyleRules } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (): StyleRules => ({
    root: {
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    large: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '40px',
    },
    fullPage: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '40px',
      height: '100vh',
      width: '100vw',
    },
  }),
);

export const binanceChain = 'binance-chain';

export enum BinanceChainHooks {
  GET_PROGRESS = 'apphook1=get-progress',
  GET_STATUS = 'apphook2=get-status',
  GET_CHAIN_INFO = 'apphook3=get-chain-info',
}

export const BINANCE_CHAIN_ENABLE_SNAPSHOT =
  'ankrCustomValues___enable_snapshot';
export const BINANCE_CHAIN_INDEXER = 'ankrCustomValues___indexer';
export const BINANCE_CHAIN_NETWORK = 'ankrCustomValues___network';
export const BINANCE_CHAIN_SYNCTYPE = 'ankrCustomValues___syncType';

import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';

export const parseQtumRpcCredentials = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const result =
      response?.data?.result?.replace(/\n/gi, '').replace(/\\/g, '') ?? '';

    return {
      ...response,
      data: safeParseJSON(result ?? '{}'),
    };
  } catch (err) {
    return {
      ...response,
    };
  }
};
const IS_INDEXING_TRUE = 'true';

export const parseQtumIndexingStatus = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const result = safeParseJSON(response.data.result ?? '{}');

    const isSyncing = result?.isIndexing === IS_INDEXING_TRUE;

    return {
      ...response,
      data: {
        ...result,
        isSyncing,
      },
    };
  } catch (err) {
    return {
      ...response,
    };
  }
};

import { BigNumber } from 'bignumber.js';

import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { APIMarketActionTypes } from './APIMarketActions';
import {
  requestFailed,
  requestInactive,
  RequestStatus,
} from 'common/utils/requestStatus';
import {
  IAPIMarketInstanceStatsResponse,
  IAPIMarketInstance,
  IAPIMarketAPIResponse,
  IAPIMarketItem,
  IAPIMarketResponse,
  IAPIMarketInstanceStats,
  IAPIMarketItemDocumentation,
  IAPIMarketPlansResponse,
  IAPIMarketPlan,
  IAPIOrderResponse,
  IAPIOrder,
  IAPIDetails,
  IAPIUpgradePlanAmountResponse,
  IArchiveModePaymentStatusResponse,
  IArchiveModePaymentStatus,
  ArchiveModePaymentStatus,
  IAPIProjectLogResponse,
  IAPIMarketBlockInfo,
  IAPIMarketBlockInfoResponse,
  IAPIRequestsHistoryItem,
  IAPIRequestsHistoryResponse,
  IAPIProjectAllowedDomainResponse,
  IAPIProjectWhiteResponse,
  IAPICluster,
  IAPIClusterListResponse,
  IAPIMarketInstanceResponse,
} from './APIMarketTypes';
import {
  mapAPIList,
  mapAPIMarketList,
  mapAPIPlansList,
  mapAPIOrder,
  mapBlockInfo,
  mapAPIRequestsHistoryList,
  mapAPIClusterList,
  formatAPIMarketInstanceResponseToIAPIMarketInstance,
} from './APIMarketMappings';
import { IExtendedAxiosResponse } from 'common/types';
import { t } from 'common/utils/intl';

type TimeStamp = string;
type Count = string;

export type ProjectLog = Record<TimeStamp, Count>;

export interface IAPIMarketState {
  list: IAPIMarketInstance[];
  listItem?: IAPIMarketInstance;
  market: IAPIMarketItem[];
  stats: IAPIMarketInstanceStats[];
  plans: IAPIMarketPlan[];
  currentPlan: IAPIMarketPlan | undefined;
  order: IAPIOrder | undefined;
  api: IAPIDetails | undefined;
  archiveModePaymentStatus: IArchiveModePaymentStatus | undefined;
  upgradePlanAmount: BigNumber | undefined;
  projectLog: ProjectLog | undefined;
  methodResponse: object | undefined;
  projectAllowedDomain: string[] | undefined;
  projectWhiteIP: string[] | undefined;
  apiDailyQuota: BigNumber | undefined;
  getUpgradePlanAmountStatus: RequestStatus;
  getMethodStatus: RequestStatus;
  getProjectLogStatus: RequestStatus;
  documentation: IAPIMarketItemDocumentation;
  blockInfo: IAPIMarketBlockInfo | undefined;
  fetchMarketListStatus: RequestStatus;
  fetchAPIListStatus: RequestStatus;
  fetchAPIListItemStatus: RequestStatus;
  fetchStatsStatus: RequestStatus;
  fetchPlansStatus: RequestStatus;
  getOrderStatus: RequestStatus;
  fetchNetworkListStatus: RequestStatus;
  fetchNetworkAccessStatus: RequestStatus;
  deleteStatus: RequestStatus;
  createStatus: RequestStatus;
  upgradePlanStatus: RequestStatus;
  getAPIStatus: RequestStatus;
  deleteAPIStatus: RequestStatus;
  subscribeAPIStatus: RequestStatus;
  getAPIDailyQuotaStatus: RequestStatus;
  getArchiveModePaymentStatusStatus: RequestStatus;
  createArchiveOrderStatus: RequestStatus;
  getBlockInfoStatus: RequestStatus;

  requestsHistoryList: IAPIRequestsHistoryItem[];
  fetchRequestsHistoryListStatus: RequestStatus;
  requestsHistoryItemsCount: number;
  getProjectAllowedDomainStatus: RequestStatus;
  createProjectAllowedDomainStatus: RequestStatus;
  deleteProjectAllowedDomainStatus: RequestStatus;
  getProjectWhiteIPStatus: RequestStatus;
  createProjectWhiteIPStatus: RequestStatus;
  deleteProjectWhiteIPStatus: RequestStatus;
  clusters: IAPICluster[];
  fetchClusterListStatus: RequestStatus;
}

const initialState: IAPIMarketState = {
  list: [],
  listItem: undefined,
  market: [],
  stats: [],
  plans: [],
  currentPlan: undefined,
  order: undefined,
  api: undefined,
  upgradePlanAmount: undefined,
  methodResponse: undefined,
  projectLog: undefined,
  projectAllowedDomain: undefined,
  projectWhiteIP: undefined,
  apiDailyQuota: undefined,
  documentation: {
    overview: '',
    availableNodes: [],
    explorerURL: '',
    documentationHTML: '',
  },
  blockInfo: undefined,
  archiveModePaymentStatus: undefined,
  fetchAPIListStatus: requestInactive(),
  fetchAPIListItemStatus: requestInactive(),
  fetchMarketListStatus: requestInactive(),
  fetchStatsStatus: requestInactive(),
  fetchPlansStatus: requestInactive(),
  getOrderStatus: requestInactive(),
  fetchNetworkListStatus: requestInactive(),
  fetchNetworkAccessStatus: requestInactive(),
  deleteStatus: requestInactive(),
  createStatus: requestInactive(),
  upgradePlanStatus: requestInactive(),
  getAPIStatus: requestInactive(),
  deleteAPIStatus: requestInactive(),
  subscribeAPIStatus: requestInactive(),
  getUpgradePlanAmountStatus: requestInactive(),
  getMethodStatus: requestInactive(),
  getProjectLogStatus: requestInactive(),
  getArchiveModePaymentStatusStatus: requestInactive(),
  createArchiveOrderStatus: requestInactive(),
  getBlockInfoStatus: requestInactive(),

  requestsHistoryList: [],
  fetchRequestsHistoryListStatus: requestInactive(),
  requestsHistoryItemsCount: 0,
  getProjectAllowedDomainStatus: requestInactive(),
  createProjectAllowedDomainStatus: requestInactive(),
  deleteProjectAllowedDomainStatus: requestInactive(),
  getProjectWhiteIPStatus: requestInactive(),
  createProjectWhiteIPStatus: requestInactive(),
  deleteProjectWhiteIPStatus: requestInactive(),

  clusters: [],
  fetchClusterListStatus: requestInactive(),
};

export const APIMarketReducer = createReducer(initialState, {
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketResponse>
  >(
    APIMarketActionTypes.FETCH_API_MARKET_MARKET_LIST,
    'fetchMarketListStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          market: action.response.data
            ? mapAPIMarketList(action.response.data)
            : [],
        };
      },
    },
  ),

  ...createAPIReducer<IAPIMarketState, IAPIMarketAPIResponse>(
    APIMarketActionTypes.FETCH_API_LIST,
    'fetchAPIListStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          list: action?.data ? mapAPIList(action) : [],
        };
      },
    },
  ),
  ...createAPIReducer<IAPIMarketState, { data: IAPIMarketInstanceResponse }>(
    APIMarketActionTypes.FETCH_API_LIST_ITEM,
    'fetchAPIListItemStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          listItem: action.data
            ? formatAPIMarketInstanceResponseToIAPIMarketInstance(action.data)
            : undefined,
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketPlansResponse>
  >(APIMarketActionTypes.FETCH_API_MARKET_PLANS_LIST, 'fetchPlansStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        plans: action.response.data
          ? mapAPIPlansList(action.response.data)
          : [],
      };
    },
  }),

  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIOrderResponse>
  >(APIMarketActionTypes.GET_API_MARKET_ORDER, 'getOrderStatus', {
    onSuccess: (state, action) => {
      const order = action.response.data
        ? mapAPIOrder(action.response.data)
        : undefined;

      return {
        ...state,
        order,
      };
    },
  }),

  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.DELETE_API_MARKET_API, 'deleteStatus', {
    onReset: state => {
      return {
        ...state,
        deleteStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.CREATE_API_PROJECT, 'createStatus', {
    onReset: state => {
      return {
        ...state,
        createStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.UPGRADE_API_MARKET_PLAN, 'upgradePlanStatus', {
    onReset: state => {
      return {
        ...state,
        upgradePlanStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.DELETE_API, 'deleteAPIStatus', {
    onReset: state => {
      return {
        ...state,
        deleteAPIStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<IAPIMarketState, IExtendedAxiosResponse<object>>(
    APIMarketActionTypes.SUBSCRIBE_API,
    'subscribeAPIStatus',
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketBlockInfoResponse>
  >(APIMarketActionTypes.GET_API_MARKET_BLOCK_INFO, 'getBlockInfoStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        blockInfo: action.response.data
          ? mapBlockInfo(action.response.data)
          : undefined,
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIUpgradePlanAmountResponse>
  >(
    APIMarketActionTypes.GET_API_MARKET_UPDATE_PLAN_AMOUNT,
    'getUpgradePlanAmountStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          upgradePlanAmount: new BigNumber(
            action.response.data?.amounts_all_payable,
          ),
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIUpgradePlanAmountResponse>
  >(APIMarketActionTypes.GET_API_MARKET_METHOD, 'getMethodStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        methodResponse: action.response.data,
      };
    },
    onReset: state => {
      return {
        ...state,
        methodResponse: undefined,
      };
    },
    onError: state => ({
      ...state,
      getMethodStatus: requestFailed(t('api-market.details.tabs.server-error')),
    }),
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIProjectLogResponse>
  >(APIMarketActionTypes.GET_API_MARKET_PROJECT_LOG, 'getProjectLogStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        projectLog: action.response.data?.total_calls,
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIProjectAllowedDomainResponse>
  >(
    APIMarketActionTypes.GET_API_MARKET_PROJECT_ALLOWED_DOMAIN,
    'getProjectAllowedDomainStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          projectAllowedDomain: action.response.data?.allowed_cross_domain_list,
        };
      },
    },
  ),
  ...createAPIReducer<IAPIMarketState, IExtendedAxiosResponse<object>>(
    APIMarketActionTypes.CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN,
    'createProjectAllowedDomainStatus',
    {
      onReset: state => {
        return {
          ...state,
          createProjectAllowedDomainStatus: requestInactive(),
        };
      },
    },
  ),
  ...createAPIReducer<IAPIMarketState, IExtendedAxiosResponse<object>>(
    APIMarketActionTypes.DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN,
    'deleteProjectAllowedDomainStatus',
    {
      onReset: state => {
        return {
          ...state,
          deleteProjectAllowedDomainStatus: requestInactive(),
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIProjectWhiteResponse>
  >(
    APIMarketActionTypes.GET_API_MARKET_PROJECT_WHITE_IP,
    'getProjectWhiteIPStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          projectWhiteIP: action.response.data?.ip_white_list,
        };
      },
    },
  ),
  ...createAPIReducer<IAPIMarketState, IExtendedAxiosResponse<object>>(
    APIMarketActionTypes.CREATE_API_MARKET_PROJECT_WHITE_IP,
    'createProjectWhiteIPStatus',
    {
      onReset: state => {
        return {
          ...state,
          createProjectWhiteIPStatus: requestInactive(),
        };
      },
    },
  ),
  ...createAPIReducer<IAPIMarketState, IExtendedAxiosResponse<object>>(
    APIMarketActionTypes.DELETE_API_MARKET_PROJECT_WHITE_IP,
    'deleteProjectWhiteIPStatus',
    {
      onReset: state => {
        return {
          ...state,
          deleteProjectWhiteIPStatus: requestInactive(),
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IArchiveModePaymentStatusResponse>
  >(
    APIMarketActionTypes.GET_ARCHIVE_MODE_PAYMENT_STATUS,
    'getArchiveModePaymentStatusStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          archiveModePaymentStatus: {
            status: action.response.data
              .payment_status as ArchiveModePaymentStatus,
          },
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.CREATE_ARCHIVE_ORDER, 'createArchiveOrderStatus', {}),

  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIRequestsHistoryResponse>
  >(
    APIMarketActionTypes.FETCH_REQUESTS_HISTORY_LIST,
    'fetchRequestsHistoryListStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          requestsHistoryList: action.response.data.api_log_res_list
            ? mapAPIRequestsHistoryList(action.response.data)
            : [],
          requestsHistoryItemsCount:
            parseInt(action?.response?.data?.item_count, 10) || 0,
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIClusterListResponse>
  >(APIMarketActionTypes.FETCH_API_CLUSTER_LIST, 'fetchClusterListStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        clusters: action.response.data?.clusters
          ? mapAPIClusterList(action.response.data.clusters)
          : [],
      };
    },
  }),
});

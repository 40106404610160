import {
  NUCYPHER_WORKER_IMPORT_KEYSTORE_FILE,
  NUCYPHER_WORKER_NETWORK,
  NucypherWorkerHooks,
  NucypherWorkerNodeName,
} from './nucypherWorkerConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/nucypherWorker.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableTabs = loadable(
  async () =>
    import('./manage/NucypherWorkerConfig').then(
      module => module.NucypherWorkerConfig,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/NucypherWorkerCustomValue').then(
      module => module.NucypherWorkerCustomValue,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const nucypherWorkerConfig: INodeConfig = {
  chartName: NucypherWorkerNodeName,
  deploy: {
    component: LoadableDeploy,
    title: `nodes.${NucypherWorkerNodeName}.deploy.title`,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      [NUCYPHER_WORKER_NETWORK]: 'mainnet',
      [NUCYPHER_WORKER_IMPORT_KEYSTORE_FILE]: 'false',
    },
  }),
  hideCustomVariableDivider: true,
  tabsComponent: LoadableTabs,
  interceptors: {
    'nucypher-node': {
      [NucypherWorkerHooks.getBindingStatus]: defaultParse,
      [NucypherWorkerHooks.getKeystoreFile]: defaultParse,
      [NucypherWorkerHooks.getNetwork]: defaultParse,
      [NucypherWorkerHooks.getAddress]: defaultParse,
      [NucypherWorkerHooks.getBalance]: defaultParse,
      [NucypherWorkerHooks.getBackupStatus]: defaultParse,
    },
  },
};

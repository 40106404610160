import { IconCitizenHooks, iconCitizenNodeName } from './IconCitizenConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/IconCitizen.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableManage = loadable(
  async () =>
    import('./manage/IconCitizenManage').then(
      module => module.IconCitizenManage,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/IconCitizenCustomValues').then(
      module => module.IconCitizenCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const iconCitizenConfig: INodeConfig = {
  chartName: iconCitizenNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'icon-citizen': {
      [IconCitizenHooks.nodeStatus]: defaultParse,
    },
  },
};

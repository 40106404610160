import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { StrollableContainer } from 'react-stroller';
import { Typography } from '@material-ui/core';

import { t } from 'common/utils/intl';
import { NodeActivityLog } from '../NodeActivityLog';
import { EmptyActivities } from '../EmptyActivities';

import { Select } from 'common/components/Form/Select';
import { INodeLog } from 'common/modules/nodeLog/nodeLogTypes';
import { RequestStatus } from 'common/utils/requestStatus';
import { RequestStatusDisplay } from 'common/components/RequestStatusDisplay';
import {
  ScrollBar,
  VerticalScrollIndicator,
} from 'components/StrollerComponents';
import { useStyles } from './NodeActivityViewStyles';
import { FilterValue } from './NodeActivityViewTypes';
import {
  useFilterSelect,
  getStartTimeAndEndTime,
} from './NodeActivityViewUtils';
import { MILLISECONDS } from 'common/core/const';

interface INodeActivityStoreProps {
  nodeLogs: INodeLog[];
  fetchNodeLogStatus: RequestStatus;
  className?: string;
  queryLog?: (startTime: number, endTime: number) => void;
}

export const NodeActivityView = ({
  fetchNodeLogStatus,
  nodeLogs,
  className,
  queryLog,
}: INodeActivityStoreProps) => {
  const classes = useStyles();
  const [filter, setFilter] = useState<FilterValue>(FilterValue.TODAY);

  const filterSelect = useFilterSelect();

  const handleChangeSelect = useCallback(
    (event: any) => {
      const value = event.target.value as FilterValue;
      setFilter(value);

      if (!queryLog) return;

      const { startTime, endTime } = getStartTimeAndEndTime(value);

      queryLog(
        Number.parseInt((startTime / MILLISECONDS).toString()),
        Number.parseInt((endTime / MILLISECONDS).toString()),
      );
    },
    [queryLog],
  );

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.header}>
        <Typography
          variant="h4"
          className={classes.title}
          color="textSecondary"
        >
          {t('dashboard.node-activity.title')}
        </Typography>
        <div className={classes.dropdown}>
          <Select
            className={classes.select}
            values={filterSelect}
            variant="outlined"
            value={filter}
            onChange={handleChangeSelect}
          />
        </div>
      </div>
      <div className={classes.logs}>
        <RequestStatusDisplay status={fetchNodeLogStatus}>
          <div className={classes.panel}>
            <StrollableContainer
              bar={ScrollBar}
              draggable={true}
              inBetween={<VerticalScrollIndicator />}
            >
              {nodeLogs.length === 0 ? (
                <EmptyActivities />
              ) : (
                nodeLogs.map((item: INodeLog, index) => (
                  <NodeActivityLog log={item} key={index} />
                ))
              )}
            </StrollableContainer>
          </div>
        </RequestStatusDisplay>
      </div>
    </div>
  );
};

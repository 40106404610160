import { makeStyles, Theme } from '@material-ui/core/styles';

export const useValueHookRowStyles = makeStyles<Theme>(theme => ({
  box: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%',
    },
  },
}));

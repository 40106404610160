import { ICluster, IExtendedAxiosResponse } from 'common/types';
import { RequestStatus, requestInactive } from '../../utils/requestStatus';
import { createReducer, createAPIReducer } from '../../utils/reduxUtils';
import {
  IClusterFetchResponse,
  mappingClusters,
} from 'common/store/apiMappings/clusterMappings';
import { ClusterActionTypes } from '../actions/ClusterActions';

export interface IClusterState {
  clusters: ICluster[];
  fetchClusterStatus: RequestStatus;
}

const initialState: IClusterState = {
  clusters: [],
  fetchClusterStatus: requestInactive(),
};

const clusterReducer = createReducer(initialState, {
  ...createAPIReducer<
    IClusterState,
    IExtendedAxiosResponse<IClusterFetchResponse>
  >(ClusterActionTypes.CLUSTER_FETCH, 'fetchClusterStatus', {
    onSuccess: (state, { response }) => {
      const clusters = response?.data?.clusters
        ? mappingClusters(response?.data?.clusters)
        : [];

      return {
        ...state,
        clusters,
      };
    },
  }),
});

export { clusterReducer };

import { MILLISECONDS } from '../../../core/const';
import { IApiNodeLogs, IApiNodeLog, INodeLog, Action } from '../nodeLogTypes';

const mapping = (log: IApiNodeLog): INodeLog => {
  const { action, level, timestamp, user_name, item } = log;

  const time = Number(timestamp) * MILLISECONDS;

  return {
    level,
    action: Action[action] || '',
    item,
    time: new Date(time),
    userName: user_name,
  };
};

export const mapNodeLog = (logs: IApiNodeLogs): INodeLog[] => {
  try {
    return logs.event_item.map(mapping);
  } catch (error) {
    return [];
  }
};

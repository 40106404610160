import React from 'react';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import { stafiValidatorNodeName } from './stafiValidatorConst';
import { HOOKS } from 'common/modules/apps/const';
import {
  parseKusamaKeystore,
  parseKusamaQueryAll,
} from 'common/modules/nodes/interceptors/KusamaInterceptors';
import { getInstanceDefaultName } from '../../../AppDeployUI/utils/getInstanceDefaultName';
import {
  ICreateInitialValueParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';

const LoadableConfig = loadable(
  async () =>
    import(
      'modules/NodesUI/screens/NodeDetails/Hooks/KusamaValidator/KusamaValidatorCustomConfigV2'
    ).then(module => module.KusamaValidatorCustomConfigV2),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/StafiValidatorCustomValue').then(
      module => module.StafiValidatorCustomValue,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const stafiValidatorConfig: INodeConfig = {
  chartName: stafiValidatorNodeName,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.matic-deploy.title',
  },
  getDefaultCustomValues: ({
    appList,
    chartName,
    userName,
  }: ICreateInitialValueParams) => ({
    customValues: {
      ankrCustomValues___nodeName: getInstanceDefaultName(
        appList,
        chartName,
        userName,
      ),
    },
  }),
  info: LoadableConfig,
  manage: false as false,
  details: false as false,
  interceptors: {
    'stafi-node': {
      [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
      [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
      [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
      [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
      [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
      [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
      [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
    },
  },
};

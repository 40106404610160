import React, { ReactNode } from 'react';
import { HookRow, IHookRowProps } from './HookRow';
import { HookCopyableValue } from './components/HookCopyableValue';
import { Box } from '@material-ui/core';
import { useValueHookRowStyles } from './useValueHookRowStyles';

interface IValueHookRowProps extends Omit<IHookRowProps, 'children'> {
  value?: string;
  showBackground?: boolean;
  defaultValue?: ReactNode;
}

export const ValueHookRow = ({
  value,
  showBackground,
  defaultValue,
  ...rest
}: IValueHookRowProps) => {
  const classes = useValueHookRowStyles();

  return (
    <HookRow {...rest}>
      <Box className={classes.box}>
        <HookCopyableValue
          value={value}
          showBackground={showBackground}
          defaultValue={defaultValue}
        />
      </Box>
    </HookRow>
  );
};

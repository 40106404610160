import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const ZendeskHelpCircleIcon = withSvgIcon(
  <>
    <circle cx="25" cy="25" r="25" fill="#2075E8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 9.5C16.4396 9.5 9.5 16.4396 9.5 25C9.5 33.5604 16.4396 40.5 25 40.5C33.5604 40.5 40.5 33.5604 40.5 25C40.5 16.4396 33.5604 9.5 25 9.5ZM8.5 25C8.5 15.8873 15.8873 8.5 25 8.5C34.1127 8.5 41.5 15.8873 41.5 25C41.5 34.1127 34.1127 41.5 25 41.5C15.8873 41.5 8.5 34.1127 8.5 25ZM25 15.5C19.7533 15.5 15.5 19.7533 15.5 25C15.5 30.2467 19.7533 34.5 25 34.5C30.2467 34.5 34.5 30.2467 34.5 25C34.5 19.7533 30.2467 15.5 25 15.5ZM14.5 25C14.5 19.201 19.201 14.5 25 14.5C30.799 14.5 35.5 19.201 35.5 25C35.5 30.799 30.799 35.5 25 35.5C19.201 35.5 14.5 30.799 14.5 25Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4961 10.847L33.8344 11.0619C35.5924 12.1784 37.1246 13.6183 38.3475 15.2979L38.5992 15.6435L33.513 20.7296L33.185 20.1744C32.199 18.5058 30.7171 17.1647 28.943 16.3543L28.2881 16.0551L33.4961 10.847ZM33.6328 12.1246L29.9952 15.7621C31.4858 16.5698 32.7586 17.7274 33.7036 19.1248L37.2833 15.5451C36.2601 14.2178 35.0266 13.061 33.6328 12.1246ZM16.0551 28.2881L16.3543 28.943C17.1647 30.7171 18.5058 32.199 20.1744 33.185L20.7296 33.513L15.6435 38.5992L15.2979 38.3475C13.6183 37.1246 12.1784 35.5924 11.0619 33.8344L10.847 33.4961L16.0551 28.2881ZM12.1246 33.6328C13.061 35.0266 14.2178 36.2601 15.5451 37.2833L19.1248 33.7036C17.7274 32.7586 16.5698 31.4858 15.7621 29.9952L12.1246 33.6328Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2471 11.0045L21.4146 16.172L20.7904 16.4811C19.0478 17.3439 17.6074 18.726 16.672 20.4249L16.3511 21.0079L11.2339 15.8907L11.4745 15.5471C12.6665 13.8447 14.1716 12.3778 15.9065 11.2298L16.2471 11.0045ZM12.5384 15.781L16.1323 19.3749C17.0376 17.9506 18.2765 16.7593 19.7394 15.9109L16.1215 12.2931C14.7473 13.2551 13.5363 14.4343 12.5384 15.781ZM33.828 28.5853L38.9955 33.7528L38.7701 34.0935C37.6222 35.8283 36.1553 37.3335 34.4529 38.5254L34.1092 38.7661L28.992 33.6489L29.575 33.3279C31.2739 32.3925 32.6561 30.9521 33.5189 29.2095L33.828 28.5853ZM34.089 30.2606C33.2406 31.7234 32.0493 32.9624 30.625 33.8676L34.219 37.4616C35.5656 36.4637 36.7449 35.2526 37.7069 33.8784L34.089 30.2606Z"
      fill="white"
    />
  </>,

  { viewBox: '0 0 50 50' },
);

import { safeParseJSON } from 'common/utils/string';
import { IHookResponse } from 'common/utils/convertResponseToError';

const ELROND_GET_STATUS_INIT_DATA = {
  isSyncing: true,
  chainId: '',
  shardId: '',
  currentBlockHash: '',
  consensusState: '',
  connectedNodes: 0,
  liveValidatorNodes: 0,
};

export const parseElrondGetStatus = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const { data } = safeParseJSON(response.data.result ?? '{}');

    const hasUpgrade =
      data.metrics.erd_app_version.indexOf(
        data.metrics.erd_latest_tag_software_version,
      ) < 0;

    return {
      ...response,
      data: {
        isSyncing: Boolean(data.metrics.erd_is_syncing),
        chainId: data.metrics.erd_chain_id,
        shardId: data.metrics.erd_shard_id.toString(),
        currentBlockHash: data.metrics.erd_current_block_hash,
        consensusState: data.metrics.erd_consensus_state,
        connectedNodes: data.metrics.erd_connected_nodes,
        liveValidatorNodes: data.metrics.erd_live_validator_nodes,
        currentNodeType: data.metrics.erd_node_type,
        nodeName: data.metrics.erd_node_display_name,
        nodeType: data.metrics.erd_node_type,
        connectedPeers: data.metrics.erd_num_connected_peers,
        validatorState: data.metrics.erd_peer_type,
        hasUpgrade,
        publicKeyBlockSign: data.metrics.erd_public_key_block_sign,
        currentNonce: data.metrics?.erd_nonce ?? 0,
        highestNonce: data.metrics?.erd_probable_highest_nonce ?? 0,
      },
    };
  } catch (err) {
    return {
      ...response,
      data: ELROND_GET_STATUS_INIT_DATA,
    };
  }
};
export const parseElrondPeerRating = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const data = safeParseJSON(response.data.result ?? '{}');

    return {
      ...response,
      data: {
        rating: data.rating ?? 0,
      },
    };
  } catch (err) {
    return {
      ...response,
      data: {
        rating: 0,
      },
    };
  }
};
export const parseElrondUpgradeNode = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const data = JSON.parse(response.data.result);

    return {
      ...response,
      data: {
        success: data,
      },
    };
  } catch (err) {
    return {
      ...response,
      data: {
        success: false,
      },
    };
  }
};
export const parseElrondGetVersion = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const data = JSON.parse(response.data.result);

    return {
      ...response,
      data,
    };
  } catch (err) {
    return {
      ...response,
    };
  }
};

import { IHookResponse } from 'common/utils/convertResponseToError';

export const CARDANO_MIN_BALANCE = 500;

const cleanCardanoResult = (string: string): any => {
  const removePrefix = string.replace('---\n', '');

  const correctJSONString = removePrefix
    .replace(/\n/gi, ',')
    .split(',')
    .map(item => item.split(': '))
    .reduce((a, [key, val]) => Object.assign(a, { [key]: val }), {});

  return correctJSONString;
};

const CARDANO_BALANCE_DIVIDED = 1000000;
export function parseCardanoBalance(response: IHookResponse): IHookResponse {
  const data = response.data?.result;

  try {
    const resultObj = cleanCardanoResult(data);

    const result = Number(resultObj?.value) || 0;

    return {
      ...response,
      data: {
        balance: result / CARDANO_BALANCE_DIVIDED,
        minBalance: CARDANO_MIN_BALANCE,
      },
    };
  } catch {
    return {
      ...response,
      data: { balance: 0, minBalance: CARDANO_MIN_BALANCE },
    };
  }
}
export const parseCardanoStatus = (response: IHookResponse): IHookResponse => {
  const data = response.data?.result;

  const resultObj = cleanCardanoResult(data);

  return {
    ...response,
    data: { status: resultObj?.state },
  };
};

const CARDANO_INIT_RESULT = {
  data: {
    privateKey: '',
    publicKey: '',
    address: '',
  },
};
export function parseCardanoKeys(response: IHookResponse): IHookResponse {
  const data = response.data?.result;

  try {
    const resultObj = cleanCardanoResult(data);

    return {
      ...response,
      data: {
        privateKey: resultObj?.PRIVATE_KEY_SK ?? '',
        publicKey: resultObj?.PUBLIC_KEY_PK ?? '',
        address: resultObj?.ADDRESS ?? '',
      },
    };
  } catch {
    return {
      ...response,
      ...CARDANO_INIT_RESULT,
    };
  }
}

export function parseCardanoNodeId(response: IHookResponse): IHookResponse {
  const data = response.data?.result;

  try {
    const isCreated = data.indexOf('No such file or directory') > -1;

    if (isCreated) {
      return {
        ...response,

        data: undefined,
      };
    }

    return {
      ...response,

      data: {
        nodeId: data,
      },
    };
  } catch {
    return {
      ...response,
    };
  }
}

import { safeParseJSON } from '../../../../utils/string';
import { checkRPCError } from 'common/utils/rpcError';

interface IApiBoincCheckStatusResultTask {
  percentage: number;
  state: string;
  active_task_state: string;
  estimate_remaining_time: number;
  name: string;
  ready_to_report: string;
}

export interface IBoincCheckStatusResultTask {
  percentage: number;
  taskState: string;
  activeTaskState: string;
  estimateRemainingTime: number;
  name: string;
  readyToReport: string;
}

export enum BoincErrorCodes {
  authError,
  rpcError,
}
export interface IBoincCheckStatusResult {
  message: string;
  data?: {
    username: string;
    recentAverageCredit: number;
    totalCredit: number;
    tasks: IBoincCheckStatusResultTask[];
  };
  errorCode?: BoincErrorCodes;
}

const normalizeTask = (
  task: IApiBoincCheckStatusResultTask,
): IBoincCheckStatusResultTask => {
  return {
    percentage: task.percentage ?? 0,
    taskState: task.state ?? 'EXECUTING',
    activeTaskState: task.active_task_state ?? '',
    estimateRemainingTime: task.estimate_remaining_time ?? 0,
    name: task.name ?? '',
    readyToReport: task.ready_to_report ?? '',
  };
};

export function parseBoincCheckStatus(data: string): IBoincCheckStatusResult {
  const resultObj = safeParseJSON(data ?? '{}');

  if (resultObj?.code === -1) {
    return {
      message: resultObj?.data ?? '',
      data: undefined,
      errorCode: BoincErrorCodes.authError,
    };
  }
  if (resultObj?.code === -2) {
    return {
      message: resultObj?.data ?? '',
      data: undefined,
      errorCode: BoincErrorCodes.rpcError,
    };
  }

  if (checkRPCError(data)) {
    return {
      message: data,
      data: undefined,
      errorCode: BoincErrorCodes.rpcError,
    };
  }

  const tasks = resultObj?.tasks ?? [];

  return {
    message: 'success',
    data: {
      username: resultObj?.username ?? '',
      recentAverageCredit: resultObj?.expavg_credit ?? 0,
      totalCredit: resultObj?.total_credit ?? 0,
      tasks: tasks.map((task: IApiBoincCheckStatusResultTask) =>
        normalizeTask(task),
      ),
    },
  };
}

import React from 'react';
import {
  BINANCE_SMARTCHAIN_ARCHIVE_MODE,
  BinanceSmartchainHooks,
  binanceSmartchainNodeName,
} from './binanceSmartchainConst';
import {
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/binanceSmartchain.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';

const LoadableConfig = loadable(
  async () =>
    import('./manage/SmartchainFullNodeInfo').then(
      module => module.SmartchainFullNodeInfo,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/SmartchainFullNode').then(
      module => module.SmartchainFullNode,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const binanceSmartchainConfig: INodeConfig = {
  chartName: binanceSmartchainNodeName,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.deploy.advanced-configuration',
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      ankrCustomValues___syncMode: 'false',
      ankrCustomValues___network: 'mainnet',
    },
  }),
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => {
    return {
      [BINANCE_SMARTCHAIN_ARCHIVE_MODE]: customValues?.[
        BINANCE_SMARTCHAIN_ARCHIVE_MODE
      ]
        ? 'true'
        : 'false',
    };
  },
  info: LoadableConfig,
  manage: false as false,
  details: false as false,
  interceptors: {
    [binanceSmartchainNodeName]: {
      [BinanceSmartchainHooks.getNetwork]: defaultParse,
      [BinanceSmartchainHooks.getStatus]: defaultParse,
      [BinanceSmartchainHooks.getSyncMode]: defaultParse,
    },
  },
};

import React, { ReactNode } from 'react';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { StyleRules, Theme } from '@material-ui/core/styles';
import { HintIcon } from '../Icons/HintIcon';
import classNames from 'classnames';
import { Typography, Box, Tooltip, TooltipProps } from '@material-ui/core';

const tooltipHintStyles = (theme: Theme): StyleRules => ({
  popper: {},
  tooltip: {
    boxShadow: `0px 4px 14px rgba(32, 117, 232, 0.15)`,
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    margin: 0,
    border: `1px solid ${theme.palette.grey['200']}`,
    fontSize: 12,
    position: 'relative',
    maxWidth: 320,

    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  tooltipLeft: {},
  tooltipRight: {},
  tooltipTop: {
    bottom: 16,
  },
  tooltipBottom: {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.2)',
  },
  hintIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  disabled: {},
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '130%',
  },
});

interface ITooltipHintComponentProps extends StyledComponentProps {
  disabled?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  placement?: TooltipProps['placement'];
  children?: ReactNode;
}

const MOBILE_LONG_PRESS_TIME_MS = 10;

const isEmptyString = (value: ReactNode) => {
  return typeof value === 'string' && value.trim() === '';
};

function TooltipHintComponent({
  classes = {},
  disabled = false,
  title = '',
  description = '',
  placement = 'bottom',
  children,
}: ITooltipHintComponentProps) {
  return (
    <Tooltip
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip,
        tooltipPlacementLeft: classes.tooltipLeft,
        tooltipPlacementRight: classes.tooltipRight,
        tooltipPlacementTop: classes.tooltipTop,
        tooltipPlacementBottom: classes.tooltipBottom,
      }}
      enterTouchDelay={MOBILE_LONG_PRESS_TIME_MS}
      title={
        <>
          {title && !isEmptyString(title) && (
            <Box mb={1}>
              <Typography variant="body1" className={classes.title}>
                {title}
              </Typography>
            </Box>
          )}
          <Box className={classes.subtitle}>{description}</Box>
        </>
      }
      arrow={true}
      placement={placement}
      interactive={true}
    >
      {children ? (
        children
      ) : (
        <HintIcon
          size="xs"
          className={classNames(classes.hintIcon, disabled && classes.disabled)}
        />
      )}
    </Tooltip>
  );
}

const TooltipHint = withStyles(tooltipHintStyles)(TooltipHintComponent);

export { TooltipHint };

import React from 'react';
import { t } from 'common/utils/intl';
import { DocsHookRow } from '../../../../NodesUI/screens/NodeDetails/Hooks/DocsHookRow';

const DOCS_HREF = 'https://docs.ankr.com/enteprise-solutions/zcash';

export const ZcashDocs = () => {
  return (
    <DocsHookRow
      title={t('nodes.zcash.manage.docs')}
      href={DOCS_HREF}
      showBorder={false}
      showBoldLabel={false}
    />
  );
};

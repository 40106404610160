import React from 'react';

import { useInitEffect } from 'common/utils/hooks';

import { getPercentage } from 'common/utils/get-percentage';

import { useInterval } from 'common/utils/useInterval';
import { NODE_STATUS_POLLING } from 'common/modules/nodes/const';
import { ProgressHookRow } from '../../../../NodesUI/screens/NodeDetails/Hooks/ProgressHookRow';
import { useTerraStatus } from '../hooks/TerraHooks';

const TerraStatusComponent = () => {
  const { call, result, status } = useTerraStatus();

  useInitEffect(() => {
    call();
  });

  const toHeight = Number(result?.latest_height ?? 0);
  const currentHeight = Number(result?.current_height ?? 0);

  const ratio = getPercentage(currentHeight, toHeight);
  const isSyncing = JSON.parse(result?.status ?? 'false') || ratio < 100;

  useInterval(() => {
    if (isSyncing) {
      call();
    }
  }, NODE_STATUS_POLLING);

  return (
    <ProgressHookRow
      status={status}
      height={toHeight}
      currentHeight={currentHeight}
      isSyncing={isSyncing}
    />
  );
};

const TerraStatus = TerraStatusComponent;

export { TerraStatus };

import React from 'react';
import { connect } from 'react-redux';

import { ISnackbar } from 'common/store/reducers/snackbarsReducer';
import { SnackbarsActions } from 'common/store/actions/SnackbarsActions';
import { omit } from 'common/utils/typeUtils';

interface ISnackbarPortalProps {
  open: boolean;
}

interface ISnackbarPortalActions extends ISnackbar {
  showSnackbar: typeof SnackbarsActions.showSnackbar;
  removeSnackbar: typeof SnackbarsActions.removeSnackbar;
}

type ISnackbarPortal = ISnackbarPortalActions & ISnackbarPortalProps;

class SnackbarPortalComponent extends React.Component<ISnackbarPortal> {
  public componentDidMount(): void {
    if (this.props.open) {
      this.showSnackbar();
    }
  }

  public componentDidUpdate(prevProps: Readonly<ISnackbarPortal>): void {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.showSnackbar();
      } else {
        this.props.removeSnackbar(this.props.key);
      }
    }
  }

  public render() {
    return null;
  }

  private showSnackbar() {
    this.props.showSnackbar(
      this.props.message,
      omit(
        this.props,
        'showSnackbar',
        'removeSnackbar',
        'children',
        'message',
        'open',
      ),
    );
  }
}

const mapDispatchToProps = {
  showSnackbar: SnackbarsActions.showSnackbar,
  removeSnackbar: SnackbarsActions.removeSnackbar,
};

const SnackbarPortal = connect(
  null,
  mapDispatchToProps,
)(SnackbarPortalComponent);

export { SnackbarPortal };

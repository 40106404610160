import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const MenuNodesIcon = withSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6 9V6H9V9H6ZM4 5C4 4.44772 4.44772 4 5 4H10C10.5523 4 11 4.44772 11 5V10C11 10.5523 10.5523 11 10 11H5C4.44772 11 4 10.5523 4 10V5ZM15 9V6H18V9H15ZM13 5C13 4.44772 13.4477 4 14 4H19C19.5523 4 20 4.44772 20 5V10C20 10.5523 19.5523 11 19 11H14C13.4477 11 13 10.5523 13 10V5ZM6 15V18H9V15H6ZM5 13C4.44772 13 4 13.4477 4 14V19C4 19.5523 4.44772 20 5 20H10C10.5523 20 11 19.5523 11 19V14C11 13.4477 10.5523 13 10 13H5ZM15 18V15H18V18H15ZM13 14C13 13.4477 13.4477 13 14 13H19C19.5523 13 20 13.4477 20 14V19C20 19.5523 19.5523 20 19 20H14C13.4477 20 13 19.5523 13 19V14Z"
  />,
);

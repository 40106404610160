export const NearFull = 'near-mainnet';
export const NearFullNode = 'near-node';

export enum NearFullHooks {
  getBlocksSyncStatus = 'apphook1=get-blocks-sync-status',
  getHeadersSyncStatus = 'apphook2=get-headers-sync-status',
}

export const NEAR_FULL_SNAPSHOT_MODE = 'ankrCustomValues___use_snapshot';

export const NEAR_FULL_DOCS_HREF =
  'https://docs.ankr.com/enteprise-solutions/near';

import React from 'react';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { t } from 'common/utils/intl';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';

const styles = (theme: Theme): StyleRules => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'inline-flex',
  },
  pageContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: 5,
  },
  center: {
    justifyContent: 'center',
    display: 'inline-flex',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  icon: {
    marginRight: 8,
    color: theme.palette.error.main,
  },
  text: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 'bold',
    display: 'inline-block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {},
});

interface IErrorProps extends StyledComponentProps {
  children?: React.ReactNode;
  center?: boolean;
}

const ErrorComponent = ({
  classes = {},
  children,
  center = true,
}: IErrorProps) => {
  return (
    <div className={classNames(classes.root, center && classes.center)}>
      <ErrorIcon className={classes.icon} />
      <div className={classes.text}>{children || t('errors.unknown')}</div>
    </div>
  );
};

const Error = withStyles(styles)(ErrorComponent);

export { Error };

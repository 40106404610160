import secp256k1 from '../components/ExtractedNodeModules/secp256k1';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const EthUtil = require('ethereumjs-util');
export const isValidEthPrivateKey = (privateKeyString: string) => {
  try {
    const withPrefix = privateKeyString.startsWith('0x')
      ? privateKeyString
      : `0x${privateKeyString}`;
    const privateKeyBuffer = EthUtil.toBuffer(withPrefix);

    const validation = secp256k1.privateKeyVerify(privateKeyBuffer);

    return validation;
  } catch (err) {
    return false;
  }
};

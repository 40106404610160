import { createCustomTheme } from './createCustomTheme';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { defaultTheme, mainTheme, PALETTE } from './mainTheme';
import { darken } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const authTheme = createCustomTheme({
  ...mainTheme,
  props: {
    ...mainTheme.props,
    MuiTypography: {
      paragraph: false,
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h6',
        subtitle2: 'h6',
        body1: 'div',
        body2: 'div',
      },
    },
    MuiFormHelperText: {
      component: Box,
    } as any,
  },
  overrides: {
    ...mainTheme.overrides,
    MuiContainer: {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        flexDirection: 'row',
        '& a': {
          textDecoration: 'none',
        },
        '&&': {
          padding: 0,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 16,
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 10,
      },
      inputAdornedStart: {
        paddingLeft: 8,
      },
      input: {
        padding: '26px 16px',
        fontSize: 16,
        fontWeight: 500,
        boxSizing: 'border-box',
        background: '#fff',
        '&[disabled]': {
          color: PALETTE.grey['800'],
          backgroundColor: PALETTE.grey['100'],
        },
      },
      root: {
        '&$focused $notchedOutline, &:hover $notchedOutline': {
          borderColor: PALETTE.primary.light,
          borderWidth: 1,
        },
        '&$disabled': {
          backgroundColor: PALETTE.grey['100'],
          borderColor: PALETTE.grey['200'],
        },
        '&$disabled:hover $notchedOutline, &$disabled $notchedOutline': {
          borderColor: PALETTE.grey['200'],
        },
      },
      notchedOutline: {
        borderColor: PALETTE.grey['200'],
      },
    },
    MuiButton: {
      root: {
        padding: '6px 16px',

        [defaultTheme.breakpoints.down('sm')]: {
          padding: '4px 12px',
        },
        '&&': {
          textTransform: 'none',
          fontSize: 16,
          fontWeight: 'bold',
        },
        '&&, &&:active': {
          boxShadow: 'none',
        },
        '&$sizeSmall': {
          fontSize: 14,
          lineHeight: '20px',
        },
        '&$sizeLarge': {
          padding: '12px 16px',
        },
      },
      contained: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '5px 16px',
        '&$sizeSmall': {
          paddingTop: 3,
          paddingBottom: 3,
        },
        '&$sizeLarge': {
          padding: '9px 16px',
        },
      },
      containedPrimary: {
        '&, a&': {
          color: '#fff',
        },

        '&$disabled': {
          backgroundColor: '#C7D0DD',
          color: '#fff',
        },
        '&:hover': {
          backgroundColor: PALETTE.primary.light,
        },
        '&:active': {
          backgroundColor: darken(PALETTE.primary.main, 0.1),
        },
        '& $label': {
          textShadow: '0px 1px 2px rgba(24, 101, 205, 0.4)',
        },
        '&$focusVisible': {
          boxShadow: 'none',
          backgroundColor: darken(PALETTE.primary.main, 0.1),
        },
      },
      outlinedPrimary: {
        borderColor: PALETTE.grey['200'],
        color: PALETTE.grey['700'],
        '&$disabled': {
          color: '#C7D0DD',
          borderColor: PALETTE.grey['200'],
        },
        '&:hover': {
          color: PALETTE.primary.main,
          backgroundColor: '#fff',
        },
        '&:active': {
          backgroundColor: PALETTE.grey['#EEF0F4'],
          color: PALETTE.primary.main,
        },
      },
    },
    MuiTypography: {
      ...mainTheme.overrides?.MuiTypography,
      body1: {
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '120%',
      },
      body2: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '120%',
      },
      h2: {
        [defaultTheme.breakpoints.down('xs')]: {
          fontWeight: 'normal',
        },
        ...mainTheme.overrides?.MuiTypography?.h2,
      },
    },
  },
} as ThemeOptions);

export { authTheme };

import React from 'react';
import { IStoreState } from 'store/reducers';
import { connect } from 'react-redux';

interface IUsernameProps {
  username: string;
}

const UsernameComponent = ({ username }: IUsernameProps) => <>{username}</>;

const mapStateToProps = (state: IStoreState) => {
  return {
    username: state.user.name,
  };
};

const Username = connect(mapStateToProps)(UsernameComponent);

export { Username };

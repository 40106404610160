import { AxiosRequestConfig } from 'axios';
import { APPAPI_APP, ChartName } from '../../apps/const';
import { safeParseJSON } from '../../../utils/string';
import { t } from '../../../utils/intl';
import {
  ETHERIUM_MEDALLA_KEYSTORE,
  ETHERIUM_MEDALLA_PASSWORD,
  PCHAIN_KEYSTORE,
  PCHAIN_PASSWORD,
  SMARTCHAIN_VALIDATOR_PRIVATE_KEY,
} from '../const';
import { getCustomizeVariableValue } from '../../apps/apiMappings/chartsMappings';

import { verifyKeystorePassword } from '../../../utils/billing/verifyKeystorePassword';
import { isValidEthPrivateKey } from '../../../utils/ethTokensValidation';

async function onFulfilled(request: AxiosRequestConfig) {
  if (APPAPI_APP === request.url && request.method === 'post') {
    const data = safeParseJSON(request.data);

    switch (data?.chart_name) {
      case ChartName.ethereumMedalla:
        const password = getCustomizeVariableValue(
          data.custom_values,
          ETHERIUM_MEDALLA_PASSWORD,
        );

        const keystore = getCustomizeVariableValue(
          data.custom_values,
          ETHERIUM_MEDALLA_KEYSTORE,
        );

        if (keystore && typeof password === 'string') {
          if (!(await verifyKeystorePassword(JSON.parse(keystore), password))) {
            (request as any).response = {
              data: {
                message: t(
                  'app-deploy.ethereum2-deploy.validation.keystore-error',
                ),
              },
            };
            throw request;
          }
        }

        break;
      case ChartName.smartchainValidator:
        const privateKey = getCustomizeVariableValue(
          data.custom_values,
          SMARTCHAIN_VALIDATOR_PRIVATE_KEY,
        );

        if (!privateKey || !isValidEthPrivateKey(privateKey)) {
          (request as any).response = {
            data: {
              message: t(
                'app-deploy.default-custom-variables.overrides.binance-val.validation.invalid-private-key',
              ),
            },
          };
          throw request;
        }
        break;
      case ChartName.pchain:
      case ChartName.pchainTest:
        {
          const password = getCustomizeVariableValue(
            data.custom_values,
            PCHAIN_PASSWORD,
          );

          const keystore = getCustomizeVariableValue(
            data.custom_values,
            PCHAIN_KEYSTORE,
          );

          if (keystore && typeof password === 'string') {
            if (
              !(await verifyKeystorePassword(JSON.parse(keystore), password))
            ) {
              (request as any).response = {
                data: {
                  message: t(
                    'app-deploy.pchain-custom-values.validation.keystore-error',
                  ),
                },
              };
              throw request;
            }
          }
        }
        break;

      default:
    }
  }

  return request;
}

export const deployInterceptor = { onFulfilled };

import { SyncStatusVariant } from 'common/modules/nodes/const';
import { Theme } from '@material-ui/core';

export function getStatusColor(status: SyncStatusVariant, theme: Theme) {
  switch (status) {
    case SyncStatusVariant.progress: {
      return theme.extra.palette.warning.main;
    }
    case SyncStatusVariant.progressBlue: {
      return theme.palette.primary.main;
    }
    case SyncStatusVariant.done: {
      return theme.extra.palette.success.main;
    }
    case SyncStatusVariant.paused: {
      return theme.palette.grey[700];
    }
    default: {
      return theme.palette.error.main;
    }
  }
}

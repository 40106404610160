import { IExtendedRequestAction } from 'common/types';

export const TwoFactorActionTypes = {
  GET_CODE: 'FETCH_API_TWO_FACTOR_CODE',
  GET_STATUS: 'FETCH_API_TWO_FACTOR_STATUS',
  GET_STATUS_RESET: 'FETCH_API_TWO_FACTOR_STATUS',
  ENABLE_TWO_FACTOR_STATUS: 'FETCH_API_ENABLE_TWO_FACTOR',
  ENABLE_TWO_FACTOR_STATUS_RESET: 'FETCH_API_ENABLE_TWO_FACTOR_RESET',
  DISABLE_TWO_FACTOR_STATUS: 'FETCH_API_DISABLE_TWO_FACTOR',
  DISABLE_TWO_FACTOR_STATUS_RESET: 'FETCH_API_DISABLE_TWO_FACTOR_RESET',
  CHECK_DIGIT_CODE: 'FETCH_API_CHECK_DIGIT_CODE',
  GENERATE_RECOVERY_CODES: 'GENERATE_RECOVERY_CODES',
  GET_RECOVERY_CODES: 'GET_RECOVERY_CODES',
  GET_RECOVERY_CODES_RESET: 'GET_RECOVERY_CODES_RESET',
  GET_TWOFA_REMINDER: 'GET_TWOFA_REMINDER',
  GET_TWOFA_REMINDER_RESET: 'GET_TWOFA_REMINDER_RESET',
};

export const TwoFactorActions = {
  getCode: (username: string): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.GET_CODE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/totp/key:generate`,
      method: 'POST',
      // eslint-disable-next-line @typescript-eslint/camelcase
      data: { user_name: username },
    },
  }),
  regenerateCode: (username: string): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.GET_CODE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/totp/key:regenerate`,
      method: 'POST',
      // eslint-disable-next-line @typescript-eslint/camelcase
      data: { user_name: username },
    },
  }),
  getStatus: (): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.GET_STATUS,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/totp/status`,
      method: 'GET',
    },
  }),
  resetStatus: () => ({
    type: TwoFactorActionTypes.GET_STATUS_RESET,
  }),
  activateTwoFactorAuth: (
    password: string,
    code: string,
  ): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.ENABLE_TWO_FACTOR_STATUS,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/totp:enable`,
      method: 'POST',
      data: { password, code },
    },
  }),
  activateTwoFactorAuthReset: () => ({
    type: TwoFactorActionTypes.ENABLE_TWO_FACTOR_STATUS_RESET,
  }),
  deactivateTwoFactorAuth: (
    password: string,
    code: string,
  ): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.DISABLE_TWO_FACTOR_STATUS,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/totp:disable`,
      method: 'POST',
      data: { password, code },
    },
  }),
  deactivateTwoFactorAuthReset: () => ({
    type: TwoFactorActionTypes.DISABLE_TWO_FACTOR_STATUS_RESET,
  }),
  checkDigitCode: (code: string): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.CHECK_DIGIT_CODE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/totp:check`,
      method: 'POST',
      data: { code },
    },
  }),
  generateRecoveryCodes: (
    password: string,
    code: string,
  ): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.GENERATE_RECOVERY_CODES,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/recovery_code/generate`,
      method: 'POST',
      data: { code, password },
    },
  }),
  getRecoveryCodes: (
    password: string,
    code: string,
  ): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.GET_RECOVERY_CODES,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/recovery_code/get`,
      method: 'POST',
      data: { code, password },
    },
  }),
  getRecoveryCodesReset: () => ({
    type: TwoFactorActionTypes.GET_RECOVERY_CODES_RESET,
  }),
  getReminder: (): IExtendedRequestAction => ({
    type: TwoFactorActionTypes.GET_TWOFA_REMINDER,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/totp/notice`,
      method: 'GET',
    },
  }),
  getReminderReset: () => ({
    type: TwoFactorActionTypes.GET_TWOFA_REMINDER_RESET,
  }),
};

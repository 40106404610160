import React from 'react';
import { StyledComponentProps } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import classNames from 'classnames';

import { INodeLog } from 'common/modules/nodeLog/nodeLogTypes';
import { useStyles } from './NodeActivityLogStyles';

const renderMessage = (action: string, item: string, userName?: string) => {
  const actionString = action ? `<span>${action}</span> ` : '';

  let result = `${actionString}${item}`;

  if (userName) {
    result = result.replace(userName, `<span>${userName}</span>`);
  }

  return result;
};

interface INodeActivityLogProps extends StyledComponentProps {
  log: INodeLog;
}

export const NodeActivityLog = ({ log }: INodeActivityLogProps) => {
  const classes = useStyles();

  const renderStatus = (status: string) => {
    return <div className={classNames(classes.status, classes[status])}></div>;
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.status}>{renderStatus(log.level)}</div>
        <p
          className={classes.message}
          dangerouslySetInnerHTML={{
            __html: renderMessage(log.action, log.item, log.userName),
          }}
        ></p>
      </div>
      <Typography className={classes.time}>
        {format(log.time, 'dd MMMM, HH:mm')}
      </Typography>
    </div>
  );
};

export const NucypherWorkerNodeName = 'nucypher';

export enum NucypherWorkerHooks {
  getBalance = 'apphook1=get-worker-balance',
  getAddress = 'apphook2=get-worker-address',
  getBindingStatus = 'apphook3=get-binding-status',
  getKeystoreFile = 'apphook4=get-keystore-file',
  getNetwork = 'apphook5=get-nucypher-network',
  getBackupStatus = 'apphook6=is-backed-up',
}

export const NUCYPHER_WORKER_NETWORK = 'ankrCustomValues___nucypher_network';
export const NUCYPHER_WORKER_PASSWORD =
  'ankrCustomValues___nucypher_worker_eth_password';
export const NUCYPHER_WORKER_IMPORT_KEYSTORE_FILE =
  'ankrCustomValues___import_keystore_file';
export const NUCYPHER_WORKER_KEYSTORE_FILE = 'ankrCustomValues___keystore_file';

import React from 'react';
import { t } from 'common/utils/intl';
import { Theme, withStyles, Typography } from '@material-ui/core';
import { StyleRules, StyledComponentProps } from '@material-ui/styles';
import { EnterCode } from '../EnterCode';
import { uid } from 'react-uid';

const styles = (theme: Theme): StyleRules => ({
  root: {},
  field: {
    marginTop: theme.spacing(1.5),
  },
});

interface ICodeFormProps extends StyledComponentProps {
  codeKey: string;
  handleCode: (code: string) => void;
}

const CodeFormComponent = ({
  classes = {},
  codeKey,
  handleCode,
}: ICodeFormProps) => {
  return (
    <div className={classes.root}>
      <Typography component="label" variant="h6" className={classes.label}>
        {t('two-factor-auth.fields.code')}
      </Typography>
      <EnterCode
        key={uid(codeKey)}
        className={classes.field}
        onReady={handleCode}
      />
    </div>
  );
};

const CodeForm = withStyles(styles)(CodeFormComponent);

export { CodeForm };

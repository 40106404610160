import React, { useMemo } from 'react';

import {
  TextField,
  InputAdornment,
  IconButton,
  Theme,
  BaseTextFieldProps,
} from '@material-ui/core';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { EyeHideIcon, EyeIcon } from '../../Icons/EyeIcon';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  icon: {
    '&&': {
      color: theme.palette.grey['700'],
    },
  },
}));

interface IPasswordInputComponent extends OutlinedTextFieldProps {
  label?: string;
  size: BaseTextFieldProps['size'];
}

const SHRINK = { shrink: true };
const PasswordInput = (props: IPasswordInputComponent) => {
  const [hidden, setHidden] = React.useState(true);
  const classes = useStyles(props);

  const handleHidden = React.useCallback(() => {
    setHidden(!hidden);
  }, [hidden]);

  return useMemo(() => {
    const passwordType = hidden ? 'password' : 'text';

    const InputProps = {
      ...props.InputProps,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={handleHidden}
          >
            {hidden ? (
              <EyeHideIcon classes={{ root: classes.icon }} />
            ) : (
              <EyeIcon classes={{ root: classes.icon }} />
            )}
          </IconButton>
        </InputAdornment>
      ),
    };

    return (
      <TextField
        margin="dense"
        InputLabelProps={SHRINK}
        {...props}
        type={passwordType}
        InputProps={InputProps}
      />
    );
  }, [hidden, props, handleHidden, classes.icon]);
};

PasswordInput.defaultProps = {
  fullWidth: true,
  size: 'medium',
};

export { PasswordInput };

import React, { ReactNode, useCallback } from 'react';

import { StyledComponentProps, Theme, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import {
  IRequestStatusDisplayProps,
  RequestStatusDisplay,
  RequestStatusDisplayErrorRender,
} from 'common/components/RequestStatusDisplay';
import {
  getRequestsError,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import { TooltipHint } from 'common/components/TooltipHint';
import classNames from 'classnames';
import { Error } from 'common/components/Error';
import { useIsXSDown } from 'common/utils/hooks/themeHooks';

const styles = (theme: Theme): StyleRules => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&$borders': {
      borderBottom: '1px solid #EEF0F4',
      paddingBottom: theme.spacing(2),
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
    '&$nested': {
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        paddingTop: theme.spacing(1),
        '&$nested': {
          paddingTop: 0,
        },
      },
    },
    '&$tile': {
      [theme.breakpoints.up('md')]: {
        border: '1px solid #EEF0F4',
        borderRadius: 4,
      },
    },
  },
  borders: {},
  tile: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '$tile &': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
      },
    },
  },
  titleApart: {
    flexDirection: 'column',
    justifyContent: 'unset',
    alignItems: 'flex-start',
    '& $label': {
      marginBottom: theme.spacing(1),
    },
  },
  label: {
    whiteSpace: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    marginRight: theme.spacing(1),
    alignSelf: 'flex-start',
    marginTop: 4,
    '&$bold': {
      fontWeight: 'bold',
    },
    '$tile &': {
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 0, 1.5),
        alignSelf: 'auto',
      },
    },
  },
  bold: {},
  wrapTitle: {
    wordBreak: 'break-all',
  },
  more: {
    marginTop: theme.spacing(1),
  },
  nested: {},
});

export const SHOW_LOADING_WHEN_INACTIVE_FALSE = {
  showLoadingWhenInactive: false,
};
export const HIDE_UPDATE_STATUS = {
  hideUpdateStatus: true,
};

export interface IHookRowProps {
  children?: React.ReactElement<any> | React.ReactElement[] | false | ReactNode;
  status?: RequestStatus | RequestStatus[];
  title?: ReactNode;
  hintTitle?: ReactNode;
  hint?: ReactNode;
  more?: ReactNode;
  wrapTitle?: boolean;
  showTitleApart?: boolean;
  nested?: boolean;
  requestStatusDisplayProps?: Omit<
    IRequestStatusDisplayProps,
    'children' | 'status'
  >;
  showBorder?: boolean;
  showBoldLabel?: boolean;
  errorText?: string;
  hideWhenLoading?: boolean;
  showAsTile?: boolean;
}

const HookRowComponent = ({
  classes = {},
  status,
  children,
  title,
  hintTitle,
  hint,
  more,
  requestStatusDisplayProps,
  wrapTitle,
  showTitleApart,
  nested,
  errorText,
  hideWhenLoading,
  showBorder = true,
  showBoldLabel = true,
  showAsTile = false,
}: IHookRowProps & StyledComponentProps) => {
  const renderError: RequestStatusDisplayErrorRender = useCallback(
    (statusArray: RequestStatus[], errorText?: string): ReactNode => {
      return (
        <Error center={false}>
          {errorText || getRequestsError(...statusArray)}
        </Error>
      );
    },
    [],
  );
  const isXSDown = useIsXSDown();
  return (
    <div
      className={classNames(
        classes.root,
        showBorder && classes.borders,
        nested && classes.nested,
        showAsTile && classes.tile,
      )}
    >
      <div
        className={classNames(
          classes.row,
          (showTitleApart ?? isXSDown) && classes.titleApart,
        )}
      >
        {title && (
          <div
            className={classNames(
              classes.label,
              showBoldLabel && classes.bold,
              wrapTitle && classes.wrapTitle,
            )}
          >
            {title}
            {hint && <TooltipHint description={hint} title={hintTitle} />}
          </div>
        )}

        <RequestStatusDisplay
          status={status || requestSuccessful()}
          size={24}
          errorText={errorText}
          {...requestStatusDisplayProps}
          renderError={renderError}
          hideWhenLoading={hideWhenLoading}
        >
          {children}
        </RequestStatusDisplay>
      </div>
      {more && (
        <RequestStatusDisplay
          status={status || requestSuccessful()}
          {...requestStatusDisplayProps}
          errorText={errorText}
          hideWhenLoading
        >
          <div className={classes.more}>{more}</div>
        </RequestStatusDisplay>
      )}
    </div>
  );
};

const HookRow = withStyles(styles)(HookRowComponent);

export { HookRow };

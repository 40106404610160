import { Route, Switch } from 'react-router';
import React from 'react';
import { withErrorHandler } from 'common/HOC/withErrorHandler';
import loadable from '@loadable/component';
import { LoadableScreen } from 'common/components/LoadableScreen';
import { withPaperLayout } from '../LayoutUI/components/Layout';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import { t } from 'common/utils/intl';

export const GUIDE_PATH = '/guide';
export const GUIDE_PATH_ICONS = '/guide/icons';

const LoadableGuideContainer = withPaperLayout(
  withErrorHandler()(
    loadable(
      async () => {
        const { GuideContainer } = await import('modules/Guide/screens/Guide');
        return (props: any) => <GuideContainer {...props} />;
      },
      {
        fallback: <LoadableScreen />,
      },
    ),
  ),
);

const LoadableIconsContainer = withPaperLayout(
  withErrorHandler()(
    loadable(
      async () => {
        const { Icons } = await import('modules/Guide/screens/Guide/Icons');
        return (props: any) => <Icons {...props} />;
      },
      {
        fallback: <LoadableScreen />,
      },
    ),
  ),
);

export const GuideRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={GUIDE_PATH}
        component={LoadableGuideContainer}
      />
      <Route
        exact={true}
        path={GUIDE_PATH_ICONS}
        component={LoadableIconsContainer}
      />
      <Route
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};

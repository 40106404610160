import React from 'react';
import { t } from 'common/utils/intl';
import { SHOW_LOADING_WHEN_INACTIVE_FALSE } from './HookRow';
import { ButtonHookRow } from './ButtonHookRow';

interface IDocsHookRowProps {
  href: string;
  title?: string;
  showBoldLabel?: boolean;
  showBorder?: boolean;
  nested?: boolean;
  buttonTitle?: string;
  buttonWidth?: number;
}

export const DocsHookRow = ({
  title,
  href,
  showBoldLabel,
  showBorder,
  nested,
  buttonTitle,
  buttonWidth = 90,
}: IDocsHookRowProps) => {
  return (
    <ButtonHookRow
      title={title ?? t('nodes.docs-hook-row.title')}
      buttonProps={{
        href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }}
      showBoldLabel={showBoldLabel}
      showBorder={showBorder}
      buttonText={buttonTitle ?? t(`buttons.view`)}
      requestStatusDisplayProps={SHOW_LOADING_WHEN_INACTIVE_FALSE}
      buttonWidth={buttonWidth}
      nested={nested}
    />
  );
};

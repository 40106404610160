import { ITokensState } from '../store/reducers/tokensReducer';

const getSignedInStatus = (state: { tokens: ITokensState }): boolean =>
  !!state.tokens.accessToken;

const parseJWTToken = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export { getSignedInStatus, parseJWTToken };

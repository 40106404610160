import { Megabytes } from 'common/types';

export enum NamespaceStatus {
  Available = 0,
  Deleted = 1,
  Unknown = 2,
}

export interface IApiNamespace {
  id: string;
  name: string;
  cluster_id: string;
  cpu_limit: number;
  cpu_used?: number;
  mem_limit: number;
  mem_used?: number;
  storage_limit: number;
  storage_used?: number;
  status: NamespaceStatus;
  created_time: string;
  updated_time: string;
}

export interface IApiNamespaceFetchResponse {
  namespaces: IApiNamespace[];
}

/**
 *  internal namespace representation for tables
 */
export interface INamespace {
  id: string;
  name: string;
  clusterId: string;
  cpuLimit: number;
  memLimit: number;
  storageLimit: number;
  cpuUsed?: number;
  memUsed?: number;
  storageUsed?: number;
  status?: NamespaceStatus;
  createdTime: Date;
  updatedTime: Date;
  prevStatus?: NamespaceStatus;
}

export interface INamespaceFormValues {
  id?: string;
  name?: string;
  clusterId?: string;
  cpuLimit?: number;
  memLimit?: Megabytes;
  storageLimit?: Megabytes;
  teamId?: string;
}

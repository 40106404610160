import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tHTML } from 'common/utils/intl';
import { IStoreState } from 'store/reducers';
import { PayActions } from 'common/modules/billing';
import { useInterval } from 'common/utils/hooks/useInterval';
import { Milliseconds } from 'common/types';
import { useEnterprise } from 'common/modules/teams/hooks/useEnterprise';
import { USD_PRICE_PRECISION } from 'common/core/const';
import { useStyles } from './ToolBarBalanceStyles';

const REFRESH_RATE: Milliseconds = 60_000 * 5; // 5 min

export const ToolBarBalance = () => {
  const classes = useStyles();
  const { amount, teamId } = useSelector((state: IStoreState) => {
    return {
      amount: state.pay.trialAmount,
      teamId: state.team.currentTeamId || '',
    };
  });
  const { isEnterprise } = useEnterprise();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEnterprise && teamId) {
      dispatch(PayActions.getTrialAmount({ teamId }));
    }
  }, [teamId, dispatch, isEnterprise]);

  useInterval(() => {
    if (!isEnterprise && teamId) {
      dispatch(PayActions.getTrialAmount({ teamId }));
    }
  }, REFRESH_RATE);

  return !isEnterprise && amount && amount.gt(0) ? (
    <div className={classes.root}>
      {tHTML('layout.balance', {
        balance: amount.toFormat(USD_PRICE_PRECISION),
      })}
    </div>
  ) : null;
};

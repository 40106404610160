import React from 'react';

import {
  ILoadingIndicatorProps,
  LoadingIndicator,
} from 'common/components/LoadingIndicator';

export const LoadableScreen = (props: ILoadingIndicatorProps) => {
  return <LoadingIndicator {...props} />;
};

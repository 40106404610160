import React, { ReactNode } from 'react';
import classNames from 'classnames';
import {
  StyledComponentProps,
  Theme,
  StyleRules,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Variant } from '@material-ui/core/styles/createTypography';

const styles = (theme: Theme): StyleRules => ({
  root: {},
  default: {
    color: theme.palette.grey['800'],
    width: '100%',
    border: `1px solid ${theme.palette.grey['100']}`,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    borderLeftWidth: 6,
    borderLeftStyle: 'solid',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    backgroundColor: theme.palette.common.white,
  },
  quote: {
    color: theme.palette.grey['800'],
    position: 'relative',
    paddingLeft: theme.spacing(2),
    '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      background: theme.palette.primary.main,
      width: 4,
      borderRadius: 3,
      height: '100%',
      margin: 'auto',
      top: 0,
      bottom: 0,
    },
  },
  success: {
    borderLeftColor: theme.palette.success.main,
    '&:before': {
      background: theme.palette.success.main,
    },
  },
  warning: {
    borderLeftColor: theme.palette.warning.main,
    '&:before': {
      background: theme.palette.warning.main,
    },
  },
  error: {
    borderLeftColor: theme.palette.error.main,
    '&:before': {
      background: theme.palette.error.main,
    },
  },
  info: {
    borderLeftColor: theme.palette.primary.main,
    '&:before': {
      background: theme.palette.primary.main,
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export enum MessageType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}
export enum MessageVariant {
  default = 'default',
  quote = 'quote',
}

interface IMessageProps extends StyledComponentProps {
  message?: ReactNode;
  content?: ReactNode;
  type: MessageType;
  variant?: MessageVariant;
  bodyVariant?: Variant;
}

const MessageComponent = ({
  classes = {},
  message,
  content,
  type,
  variant = MessageVariant.default,
  bodyVariant,
}: IMessageProps) => {
  return (
    <div className={classNames(classes.root, classes[variant], classes[type])}>
      {message && (
        <Typography
          variant={bodyVariant ?? 'body1'}
          className={classes.message}
          component="div"
        >
          {message}
        </Typography>
      )}
      {content && content}
    </div>
  );
};

const Message = withStyles(styles)(MessageComponent);

export { Message };

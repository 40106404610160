import { isProd } from 'common/utils/environment';

export const stkrNodeName = 'ankr-eth2';

export enum StkrHooks {
  getNodeStatus = 'apphook1=node-status',
  getPeerCount = 'apphook2=peers-count',
  getSidecarStatus = 'apphook3=sidecar-status',
  getSidecarId = 'apphook4=sidecar-id',
}

export const STKR_SIDECAR_ID = 'ankrCustomValues___id';
export const STKR_ACCESS_TOKEN = 'ankrCustomValues___accessToken';
export const STKR_NETWORK = 'ankrCustomValues___network';
export const STKR_NAME = 'ankrCustomValues___name';

const STAKING_ORIGIN = isProd()
  ? 'https://stakefi.ankr.com'
  : 'http://localhost:3001';

const STAKING_ORIGIN_GOERLI = isProd()
  ? 'https://goerli.stkr.io'
  : 'http://localhost:3001';

export const NETWORK_OPTION_GOERLI = 'goerli';
export const NETWORK_OPTION_MAINNET = 'mainnet';

export const STAKING_ORIGINS = {
  [NETWORK_OPTION_GOERLI]: STAKING_ORIGIN_GOERLI,
  [NETWORK_OPTION_MAINNET]: STAKING_ORIGIN,
};

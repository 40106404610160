import { useEffect, useRef } from 'react';

function useInterval(callback: () => void, delay: number | null = 1000) {
  const savedCallback = useRef<() => void>();
  const timerId = useRef<number>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (typeof delay !== 'number') {
        clearInterval(timerId.current);
        return;
      }

      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (typeof delay !== 'number') {
      clearInterval(timerId.current);
    } else {
      timerId.current = window.setInterval(tick, delay);
      return () => clearInterval(timerId.current);
    }

    return;
  }, [delay]);
}

export { useInterval };

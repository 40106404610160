import React, { useState } from 'react';
import classNames from 'classnames';
import { CircularProgress, Box } from '@material-ui/core';
import { useTimeout } from 'common/utils/hooks';
import { Milliseconds } from 'common/types/unitsTypes';
import { Pixels } from 'common/types/unitsTypes';
import { useStyles } from './LoadingIndicatorStyles';

const MIN_NOT_DISPLAY_TIME: Milliseconds = 300;
const DEFAULT_SIZE: Pixels = 40;

export interface ILoadingIndicatorProps {
  className?: string;
  size?: number;
  displaySince?: Milliseconds;
  fullPage?: boolean;
}

export const LoadingIndicator = ({
  className,
  size = DEFAULT_SIZE,
  displaySince = MIN_NOT_DISPLAY_TIME,
  fullPage = false,
}: ILoadingIndicatorProps) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  useTimeout(() => {
    setVisible(true);
  }, displaySince);
  if (!visible) {
    return null;
  }
  return (
    <Box
      className={classNames(
        classes.root,
        size >= DEFAULT_SIZE && classes.large,
        fullPage && classes.fullPage,
        className,
      )}
      fontSize={size}
    >
      <CircularProgress className={classNames(classes.progress)} size={size} />
    </Box>
  );
};

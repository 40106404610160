import localesUS from './locales/bluzelle.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import {
  BLUZELLE_MNEMONIC,
  BLUZELLE_MONIKER,
  BLUZELLE_HOOKS,
  BLUZELLE_NODE_NAME,
  BLUZELLE_PASSWORD,
  BLUZELLE_NETWORK,
} from './bluzelleConst';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableTabs = loadable(
  async () =>
    import('./manage/BluzelleTabs').then(module => module.BluzelleTabs),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/BluzelleCustomValue').then(
      module => module.BluzelleCustomValue,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseMnemonic(response: IHookResponse): IHookResponse {
  return {
    ...response,
    data: safeParseJSON(response.data.result ?? '{}'),
  };
}

export const bluzelleConfig: INodeConfig = {
  chartName: BLUZELLE_NODE_NAME,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.deploy.advanced-configuration',
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: () => ({
    customValues: {
      [BLUZELLE_MNEMONIC]: '',
      [BLUZELLE_MONIKER]: '',
      [BLUZELLE_PASSWORD]: '',
      [BLUZELLE_NETWORK]: 'testnet',
    },
  }),

  tabsComponent: LoadableTabs,
  manage: false as false,
  details: false as false,
  info: false as false,
  interceptors: {
    'bluzelle-validator': {
      [BLUZELLE_HOOKS.getMnemonic]: parseMnemonic,
      [BLUZELLE_HOOKS.publicKey]: defaultParse,
      [BLUZELLE_HOOKS.getBalance]: defaultParse,
      [BLUZELLE_HOOKS.applyAsValidator]: defaultParse,
      [BLUZELLE_HOOKS.checkValidator]: defaultParse,
      [BLUZELLE_HOOKS.staking]: defaultParse,
      [BLUZELLE_HOOKS.unbound]: defaultParse,
      [BLUZELLE_HOOKS.unjailValidator]: defaultParse,
      [BLUZELLE_HOOKS.deleteMnemonic]: defaultParse,
    },
    'bluzelle-sentry': {
      [BLUZELLE_HOOKS.currentHeight]: defaultParse,
    },
  },
};

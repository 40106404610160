import { put, select, putResolve, take } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { ITeamState } from 'common/modules/teams/reducers/teamReducer';
import { SendRequestResponse } from 'common/types';
import {
  APIMarketActions,
  IArchiveOrderPayload,
  ICreateAPIPayload,
  IAPIProjectAllowedAddressPayload,
  IUpgradePlanPayload,
  ISubscribePayload,
  APIMarketActionTypes,
} from '../APIMarketActions';
import {
  IAPIMarketInstanceResponse,
  IAPIMarketItemResponse,
  PayWay,
  PaymentMethod,
  IApiListResponse,
} from '../APIMarketTypes';
import { createAction } from 'common/utils/reduxUtils';
import { IStoreState } from 'store/reducers';
import { matchMarketInstanceResponseAndMarketItemResponse } from 'modules/APIMarketUI/APIMarketUtils';

export function* runCreateProjectSaga(action: Action<ICreateAPIPayload>) {
  const { payload } = action;

  const response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project`,
      method: 'POST',
      data: {
        service_id: payload.serviceId,
        project_name: payload.projectName,
        node_code: payload.nodeCode,
        team_id: payload.teamId,
        subscribe_status: payload.subscribeStatus,
        auth_info: {
          auth_type: payload.authType,
          auth_username: payload.authUsername,
          auth_password: payload.authPassword,
          auth_token: payload.authToken,
        },
        payment_info: {
          payment_methods: payload.paymentMethods,
          credit_card_id: payload.creditCardId,
        },
        api_cluster_name: payload.clusterId,
      },
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put(APIMarketActions.fetchInfuraAPIList(payload.teamId));

  yield put({ type: action.type + '_SUCCESS' });

  return response;
}

export function* runUpgradePlanSaga(action: Action<IUpgradePlanPayload>) {
  const { payload } = action;

  const response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `/apis/apiorder/v2alpha/api/order/upgrade/apiService`,
      method: 'PUT',
      data: {
        pay_way: payload.payment.payway,
        credit_card_id: payload.payment.cardId ?? '',
        plan_id: payload.planId,
        order_id: payload.orderId,
      },
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put({ type: action.type + '_SUCCESS' });

  return response;
}

export function* runDeleteAPISaga(action: Action<string>) {
  const { currentTeamId: teamId } = yield select(
    (state: { team: ITeamState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
      };
    },
  );

  let response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `/apis/apiorder/v2alpha/api/project/${action.payload}`,
      method: 'DELETE',
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  response = yield putResolve(
    APIMarketActions.fetchInfuraAPIList(teamId, { silent: true }),
  );

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put({
    type: action.type + '_SUCCESS',
  });
}

export function* runSubscribeAPISaga(action: Action<ISubscribePayload>): any {
  const { payload } = action;

  const response = yield putResolve({
    type: 'EMPTY',
    request: {
      url: '/apis/apiorder/v1alpha/api/order/subscribe',
      method: 'PUT',
      data: {
        order_id: payload.orderId,
        subscribe_status: payload.subscribeStatus,
      },
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put({ type: action.type + '_SUCCESS' });

  const apiId = yield select(
    (state: IStoreState) => state.apiMarket.listItem?.id,
  );

  if (apiId) {
    yield put(createAction(APIMarketActionTypes.FETCH_API_LIST_ITEM, apiId));
  }

  yield take(APIMarketActionTypes.FETCH_API_LIST + '_SUCCESS');
}

export function* runFetchAPIListSaga(action: Action<ICreateAPIPayload>) {
  const { payload: teamId }: any = action;

  const apisResponse: SendRequestResponse<IApiListResponse> = yield putResolve(
    APIMarketActions.fetchInfuraAPIList(teamId, { silent: true }),
  );

  const orderResponse: SendRequestResponse<{
    data: IAPIMarketItemResponse[];
  }> = yield putResolve(APIMarketActions.fetchInfuraMarketList());

  if ('error' in apisResponse || 'error' in orderResponse) {
    return yield put({ type: action.type + '_ERROR' });
  }

  const apis = apisResponse?.data?.project_list ?? [];
  const markets = orderResponse?.data?.data ?? [];

  const updatedApis = apis
    .map((api: IAPIMarketInstanceResponse) => {
      const matchingMarket = markets.find(
        (market: IAPIMarketItemResponse) => market.code === api.node_code,
      );
      if (!matchingMarket) return null;

      return matchMarketInstanceResponseAndMarketItemResponse(
        api,
        matchingMarket,
      );
    })
    .filter(Boolean);

  yield put({ type: action.type + '_SUCCESS', data: updatedApis });
}

export function* runFetchAPIListItemSaga(action: Action<string>): any {
  const { payload: apiId } = action;

  const apiResponse = yield putResolve(
    APIMarketActions.fetchInfuraAPIListItem(apiId, { silent: true }),
  );

  const orderResponse = yield putResolve(
    APIMarketActions.fetchInfuraMarketList(),
  );

  if ('error' in apiResponse || 'error' in orderResponse) {
    return yield put({ type: action.type + '_ERROR' });
  }

  const api: IAPIMarketInstanceResponse = apiResponse.data;
  const markets: IAPIMarketItemResponse[] = orderResponse.data?.data;

  const matchingMarket = markets?.find(
    (market: IAPIMarketItemResponse) => market.code === api?.node_code,
  );

  if (!matchingMarket) return;

  const updatedApi = matchMarketInstanceResponseAndMarketItemResponse(
    api,
    matchingMarket,
  );

  yield put({ type: action.type + '_SUCCESS', data: updatedApi });
}

export function* runCreateArchiveOrderSaga(
  action: Action<{ order: IArchiveOrderPayload; api: ICreateAPIPayload }>,
) {
  const {
    payload: { api, order },
  } = action;

  const response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project`,
      method: 'POST',
      data: {
        service_id: api.serviceId,
        project_name: api.projectName,
        node_code: api.nodeCode,
        team_id: api.teamId,
        subscribe_status: api.subscribeStatus,
        auth_info: {
          auth_type: api.authType,
          auth_username: api.authUsername,
          auth_password: api.authPassword,
          auth_token: api.authToken,
        },
        payment_info: {
          payment_methods:
            order.payment.payway === PayWay.AnkrCredit // TODO: temporary. Needs normalization
              ? PaymentMethod.AnkrCredit
              : PaymentMethod.CreditCard,
          credit_card_id: order.payment.cardId,
        },
        api_cluster_name: api.clusterId,
      },
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put(APIMarketActions.fetchInfuraAPIList(api.teamId));

  yield put({ type: action.type + '_SUCCESS' });

  return response;
}

export function* runCreateAPIProjectAllowedDomainSaga(
  action: Action<IAPIProjectAllowedAddressPayload>,
) {
  const {
    payload: { projectId, address },
  } = action;

  const response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project/security/cross`,
      method: 'POST',
      data: {
        project_id: projectId,
        allowed_domain: address,
      },
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put({ type: action.type + '_SUCCESS' });

  yield put(APIMarketActions.getAPIProjectAllowedDomain(projectId));

  return response;
}

export function* runDeleteAPIProjectAllowedDomainSaga(
  action: Action<IAPIProjectAllowedAddressPayload>,
) {
  const {
    payload: { projectId, address },
  } = action;

  const response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project/security/cross/delete`,
      method: 'POST',
      data: {
        project_id: projectId,
        allowed_domain: address,
      },
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put({ type: action.type + '_SUCCESS' });

  yield putResolve(APIMarketActions.getAPIProjectAllowedDomain(projectId));

  return response;
}

export function* runCreateAPIProjectWhiteIPSaga(
  action: Action<IAPIProjectAllowedAddressPayload>,
) {
  const {
    payload: { projectId, address },
  } = action;

  const response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project/security/ip_white_list`,
      method: 'POST',
      data: {
        project_id: projectId,
        ip_address: address,
      },
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put({ type: action.type + '_SUCCESS' });

  yield put(APIMarketActions.getAPIProjectWhiteIP(projectId));

  return response;
}

export function* runDeleteAPIProjectWhiteIPSaga(
  action: Action<IAPIProjectAllowedAddressPayload>,
) {
  const {
    payload: { projectId, address },
  } = action;

  const response: {} = yield putResolve({
    type: 'EMPTY',
    request: {
      url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project/security/ip_white_list/${projectId}/${address}`,
      method: 'DELETE',
    },
    meta: {
      silent: true,
    },
  });

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield put({ type: action.type + '_SUCCESS' });

  yield put(APIMarketActions.getAPIProjectWhiteIP(projectId));

  return response;
}

/* eslint-disable @typescript-eslint/camelcase */
import React, { useMemo } from 'react';
import { t } from 'common/utils/intl';
import {
  PASSWORD_FIELD,
  FILE_FIELD,
  CELO_VALIDATOR_RADIO_FIELD,
  CELO_ARCHIVE_RADIO_FIELD,
  CELO_ARCHIVE_MODE,
  TEXT_FIELD,
} from '../../const';
import { InputField } from 'common/components/Form/Input';
import { ICustomValue } from 'common/modules/apps/types';
import { FieldType } from '../../const';
import { DefaultCustomValue } from './DefaultCustomValue';
import { ChartName } from 'common/modules/apps/const';
import { validateArweaveNodeAddress } from './customVariablesValidations';
import { getCustomValueValidation } from 'common/utils/nodes/registerNode';

const FIELDS = {
  password: PASSWORD_FIELD,
  wallet: FILE_FIELD,
  blskey: FILE_FIELD,
  ankrCustomValues___use_ankr_sms_services: CELO_VALIDATOR_RADIO_FIELD,
  [CELO_ARCHIVE_MODE]: CELO_ARCHIVE_RADIO_FIELD,
};

const VALUE_FIELDS = {
  '**password**': PASSWORD_FIELD,
  '**re-password**': PASSWORD_FIELD,
  '**file**': FILE_FIELD,
};

const VALUE_VALIDATIONS = {
  [ChartName.arweave]: {
    ankrCustomValues___WALLET_ADDRESS: validateArweaveNodeAddress,
  },
};

interface IDefaultCustomValueProps {
  chartName?: string;
  customValues: ICustomValue[];
  placeholders?: string[];
  attributes?: object[];
  customValidations?: ((
    value: string,
    allValues: object,
  ) => string | undefined | Promise<string | undefined>)[];
  sortFields?: boolean;
}

const fieldRequiredValidation = (value: any) =>
  value ? undefined : t('validation.field-required-notice');

export const DefaultCustomValues = ({
  customValues,
  placeholders,
  attributes,
  customValidations,
  sortFields = true,
  chartName,
}: IDefaultCustomValueProps) => {
  const renderField = (customValue: ICustomValue, index: number) => {
    const {
      key,
      value,
      placeholder = placeholders ? placeholders[index] : '',
    } = customValue;

    const chosenAttributes = attributes?.[index]
      ? { ...attributes[index] }
      : FIELDS[key] || VALUE_FIELDS[value] || {};

    const {
      Component = InputField,
      attributes: defaultAttributes = TEXT_FIELD.attributes,
    } = chosenAttributes;

    const newCustomValidation = getCustomValueValidation(chartName ?? '', key);

    const customValidation =
      !!chartName &&
      !!VALUE_VALIDATIONS[chartName] &&
      !!VALUE_VALIDATIONS[chartName][key]
        ? VALUE_VALIDATIONS[chartName][key]
        : newCustomValidation
        ? newCustomValidation
        : customValidations
        ? customValidations[index]
        : value === FieldType.optional
        ? undefined
        : fieldRequiredValidation;

    return (
      <DefaultCustomValue
        key={key}
        customValue={customValue}
        Component={Component}
        customValidation={customValidation}
        placeholder={placeholder}
        attributes={{ ...defaultAttributes, ...attributes?.[index] }}
      />
    );
  };

  const sortedCustomValues = useMemo(() => {
    if (sortFields) {
      return customValues.sort((first: ICustomValue, second: ICustomValue) => {
        return first.label.toLocaleLowerCase() >
          second.label.toLocaleLowerCase()
          ? 1
          : -1;
      });
    }

    return customValues;
  }, [customValues, sortFields]);

  return <>{sortedCustomValues.map(renderField)}</>;
};

import React from 'react';
import { Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useProgressBarStyles } from './ProgressBarStyles';
import classNames from 'classnames';

interface IProgressBarProps {
  className?: string;
  current: number;
  count: number;
}

export const ProgressBar = ({
  className,
  current,
  count,
}: IProgressBarProps) => {
  const classes = useProgressBarStyles({ widthBar: (100 / count) * current });

  return (
    <div className={classNames(className, classes.component)}>
      <Typography className={classes.title} variant="h4" component="span">
        {t('two-factor-auth.nav.steps')} {`${current}/${count}`}
      </Typography>
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'components/App/App';
import { unregisterServiceWorker } from './registerServiceWorker';

import { initializeAnalytics } from 'common/utils/analytics/initializeAnalytics';

initializeAnalytics();

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

unregisterServiceWorker();

import BigNumber from 'bignumber.js';

export interface IApiDepositAmount {
  ankr_amount: string;
  save_usd: string;
  usd_amount: string;
  usdt_amount: string;
}

interface IDepositAmount {
  usdtAmount: BigNumber;
  ankrAmount: BigNumber;
  usdAmount: BigNumber;
  saveUsd: BigNumber;
}

export function mapDepositAmount(data: IApiDepositAmount): IDepositAmount {
  return {
    usdtAmount: new BigNumber(data.usdt_amount),
    ankrAmount: new BigNumber(data.ankr_amount),
    usdAmount: new BigNumber(data.usd_amount),
    saveUsd: new BigNumber(data.save_usd),
  };
}

import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const MenuApisIcon = withSvgIcon(
  <>
    <path d="M13.2793 14.7322C13.6518 15.0893 14.2556 15.0893 14.6281 14.7322L17.2469 12.2219C17.6576 11.8282 17.6576 11.1718 17.2469 10.7781L14.6281 8.26777C14.2556 7.91074 13.6518 7.91074 13.2793 8.26777C12.9069 8.62479 12.9069 9.20364 13.2793 9.56066L15.3025 11.5L13.2793 13.4393C12.9069 13.7964 12.9069 14.3752 13.2793 14.7322Z" />
    <path d="M9.3719 8.26777C9.74435 7.91074 10.3482 7.91074 10.7207 8.26777C11.0931 8.62479 11.0931 9.20364 10.7207 9.56066L8.69752 11.5L10.7207 13.4393C11.0931 13.7964 11.0931 14.3752 10.7207 14.7322C10.3482 15.0893 9.74435 15.0893 9.3719 14.7322L6.75309 12.2219C6.34242 11.8282 6.34242 11.1718 6.75309 10.7781L9.3719 8.26777Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.34315 4 3 5.34315 3 7V16C3 17.6569 4.34315 19 6 19H18C19.6569 19 21 17.6569 21 16V7C21 5.34315 19.6569 4 18 4H6ZM18 6H6C5.44771 6 5 6.44772 5 7V16C5 16.5523 5.44772 17 6 17H18C18.5523 17 19 16.5523 19 16V7C19 6.44772 18.5523 6 18 6Z"
    />
  </>,
);

import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const CopyIcon = withSvgIcon(
  <>
    <path d="M9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6H17.5C17.7761 6 18 6.22386 18 6.5V15C18 15.5523 18.4477 16 19 16C19.5523 16 20 15.5523 20 15V6.5C20 5.11929 18.8807 4 17.5 4H9Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9C4 7.89543 4.89543 7 6 7H15C16.1046 7 17 7.89543 17 9V18C17 19.1046 16.1046 20 15 20H6C4.89543 20 4 19.1046 4 18V9ZM6 9H15V18H6L6 9Z"
    />
  </>,
);

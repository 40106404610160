import { all, put, call, takeEvery } from 'redux-saga/effects';
import { IPromiseAction, IPromiseActionPayload } from '../../utils/reduxUtils';

function* runPromiseActionSaga(action: IPromiseAction<any>) {
  const { type, promises, hasSinglePayload } = action;

  const newMeta = { ...action.meta, requestAction: action };
  try {
    const data = yield all(
      promises.map((p: IPromiseActionPayload<any>) =>
        call(p.func, ...p.params),
      ),
    );
    if (hasSinglePayload) {
      yield put({ type: `${type}_SUCCESS`, data: data[0], meta: newMeta });
    } else {
      yield put({ type: `${type}_SUCCESS`, data, meta: newMeta });
    }
  } catch (error) {
    if (action.meta && action.meta.debug) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
    yield put({
      type: `${type}_ERROR`,
      error,
      errorText: error.toString(),
      meta: newMeta,
    });
  }
}

const promiseActionsPattern = (action: any) => !!action.promises;

/**
 * Process all actions having func in it.
 */
function* promiseActionSaga() {
  yield takeEvery(promiseActionsPattern, runPromiseActionSaga);
}

export { promiseActionSaga };

import { boxed } from 'kashe';
import {
  ICustomValuesLocale,
  INodeConfig,
  NodesHooksConfig,
} from './NodesTypes';
import { mergeDeep } from '../object';

interface ICustomValuesDescriptions {
  labels: { [key: string]: string };
  descriptions: { [key: string]: string };
  titles: { [key: string]: string };
  placeholders: { [key: string]: string };
}

const mapCustomVarLocales = (
  nodeName: string,
  customValues: ICustomValuesLocale,
) => {
  const mappedOverrides = Object.keys(customValues).reduce(
    // @ts-ignore
    (localeAccum: ICustomValuesDescriptions, cvName: string) => {
      return {
        labels: {
          ...localeAccum.labels,
          ...(customValues[cvName].label
            ? { [cvName]: customValues[cvName].label }
            : null),
        },
        descriptions: {
          ...localeAccum.descriptions,
          ...(customValues[cvName].description
            ? { [cvName]: customValues[cvName].description }
            : null),
        },
        titles: {
          ...localeAccum.titles,
          ...(customValues[cvName].title
            ? { [cvName]: customValues[cvName].title }
            : null),
        },
        placeholders: {
          ...localeAccum.placeholders,
          ...(customValues[cvName].placeholder
            ? { [cvName]: customValues[cvName].placeholder }
            : null),
        },
      };
    },
    {
      labels: {},
      descriptions: {},
      titles: {},
      placeholders: {},
    } as ICustomValuesDescriptions,
  );

  return {
    'default-custom-variables': {
      overrides: {
        [nodeName]: {
          label: {
            ankrCustomValues: mappedOverrides.labels,
          },
          description: {
            ankrCustomValues: mappedOverrides.descriptions,
          },
          title: {
            ankrCustomValues: mappedOverrides.titles,
          },
          placeholder: {
            ankrCustomValues: mappedOverrides.placeholders,
          },
        },
      },
    },
  };
};

const {
  registerNode,
  getInterceptors,
  getTabsConfig,
  getDeployConfig,
  getLaunchConfig,
  getDefaultCustomValues,
  getResetValues,
  getCustomValueValidation,
  getNodeConfig,
  getNodeLatestCustomValues,
  getNodesLocales,
} = (function() {
  let configs: { [nodeName: string]: INodeConfig } = {};

  const cachedGetInterceptors = boxed(() =>
    configs
      ? Object.keys(configs).reduce((accum, nodeName) => {
          return configs && configs[nodeName]?.interceptors
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              (mergeDeep(
                accum,
                configs[nodeName].interceptors as object,
              ) as NodesHooksConfig)
            : accum;
        }, {} as NodesHooksConfig)
      : undefined,
  );

  return {
    registerNode: (nodeConfig: INodeConfig) => {
      configs = { ...configs, [nodeConfig.chartName]: nodeConfig };
    },
    getInterceptors: () => {
      return cachedGetInterceptors(configs);
    },
    getNodesLocales: () => {
      return Object.keys(configs).reduce((accum, nodeName) => {
        if (configs[nodeName].locales) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const locales = configs[nodeName].locales!;

          const newLocales = Object.keys(locales).reduce(
            (localeAccum: object, localeName: string) => {
              const { customValues, ...locale } = locales[localeName];
              return {
                [localeName]: {
                  nodes: { [nodeName]: locale },
                  'app-deploy': customValues
                    ? mapCustomVarLocales(nodeName, customValues)
                    : {},
                },
              };
            },
            {},
          );
          return mergeDeep(accum, newLocales);
        } else {
          return accum;
        }
      }, {});
    },
    getNodeConfig: (chartName?: string) => {
      return chartName ? configs[chartName] : undefined;
    },
    getTabsConfig: (chartName: string) => {
      return {
        manage: configs[chartName]?.manage,
        details: configs[chartName]?.details,
        info: configs[chartName]?.info,
      };
    },
    getDeployConfig: (chartName?: string) => {
      return chartName ? configs[chartName]?.deploy : undefined;
    },
    getLaunchConfig: (chartName?: string) => {
      return chartName ? configs[chartName]?.launch : undefined;
    },
    getDefaultCustomValues: (chartName: string) => {
      return configs[chartName]?.getDefaultCustomValues;
    },
    getResetValues: (chartName: string) => {
      return configs[chartName]?.getResetValues;
    },
    getCustomValueValidation: (chartName: string, valueName: string) => {
      return configs[chartName]?.deployValidation
        ? configs[chartName]?.deployValidation[valueName]
        : undefined;
    },
    getNodeLatestCustomValues: (chartName: string) => {
      return configs[chartName]?.getNodeLatestCustomValues;
    },
  };
})();

export {
  registerNode,
  getInterceptors,
  getTabsConfig,
  getNodeConfig,
  getDeployConfig,
  getLaunchConfig,
  getDefaultCustomValues,
  getResetValues,
  getCustomValueValidation,
  getNodeLatestCustomValues,
  getNodesLocales,
};

import {
  ChartRepo,
  IApiChartDetails,
  IApiChartsFetchResponse,
  IApiErrorAction,
  IChart,
  IChartDetails,
  IExtendedAxiosResponse,
  IPaging,
  ISorting,
} from 'common/types';
import {
  extractRequestError,
  requestFailed,
  requestInactive,
  requestInProgress,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { APP_ROWS_PER_PAGE } from 'common/core/const';
import {
  normalizeChartDetails,
  normalizeChartsResponse,
} from '../apiMappings/chartsMappings';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { ChartsActionTypes } from '../actions/ChartsActions';

interface IChartEntry {
  data?: IChartDetails;
  status: RequestStatus;
}

interface IChartsEntries {
  [key: string]: IChartEntry;
}

export interface IChartsMap {
  [key: string]: IChart[];
}

export function getChartsFromMap(
  charts: IChartsMap,
  repository: ChartRepo,
): IChart[] | undefined {
  return charts[repository];
}

export function getChart(
  chartsMap: IChartsMap,
  {
    repository,
    name,
    version,
  }: {
    repository: ChartRepo;
    name: string;
    version: string;
  },
): IChart | undefined {
  const charts = getChartsFromMap(chartsMap, repository);
  return charts
    ? charts.find(
        item =>
          item.repository === repository &&
          item.name === name &&
          item.version === version,
      )
    : undefined;
}

export interface IChartsState {
  list: IChartsMap;
  createStatus: RequestStatus;
  updateStatus: RequestStatus;
  fetchStatus: RequestStatus;
  // TODO: to remove?
  sorting?: ISorting;
  paging: IPaging;
  fetchChartDetailsStatus: RequestStatus;
  chartDetails?: IChartDetails;
  saveAsStatus: RequestStatus;
  downloadStatus: RequestStatus;
}

const initialState: IChartsState = {
  list: {},
  createStatus: requestInactive(),
  updateStatus: requestInactive(),
  fetchStatus: requestInactive(),
  paging: { pageNumber: 0, rowsPerPage: APP_ROWS_PER_PAGE },
  sorting: undefined,
  fetchChartDetailsStatus: requestInactive(),
  saveAsStatus: requestInactive(),
  downloadStatus: requestInactive(),
};

const chartsReducer = createReducer(initialState, {
  ...createAPIReducer<
    IChartsState,
    IExtendedAxiosResponse<IApiChartsFetchResponse[], any>
  >(ChartsActionTypes.CHARTS_FETCH, 'fetchStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        list: {
          ...normalizeChartsResponse(action),
        },
      };
    },
  }),

  [ChartsActionTypes.CHART_CREATE]: (state: IChartsState): IChartsState => ({
    ...state,
    createStatus: requestInProgress(),
  }),
  [ChartsActionTypes.CHART_CREATE_SUCCESS]: (
    state: IChartsState,
  ): IChartsState => ({
    ...state,
    createStatus: requestSuccessful(),
  }),
  [ChartsActionTypes.CHART_CREATE_ERROR]: (
    state: IChartsState,
    action: IApiErrorAction,
  ): IChartsState => ({
    ...state,
    createStatus: requestFailed(extractRequestError(action)),
  }),

  [ChartsActionTypes.CHART_UPDATE]: (state: IChartsState): IChartsState => ({
    ...state,
    updateStatus: requestInProgress(),
  }),
  [ChartsActionTypes.CHART_UPDATE_SUCCESS]: (
    state: IChartsState,
  ): IChartsState => ({
    ...state,
    updateStatus: requestSuccessful(),
  }),
  [ChartsActionTypes.CHART_UPDATE_ERROR]: (
    state: IChartsState,
    action: IApiErrorAction,
  ): IChartsState => ({
    ...state,
    updateStatus: requestFailed(extractRequestError(action)),
  }),

  ...createAPIReducer<IChartsState, IExtendedAxiosResponse<IApiChartDetails>>(
    ChartsActionTypes.CHART_FETCH_DETAILS,
    'fetchChartDetailsStatus',
    {
      onSuccess: (state, action): IChartsState => {
        return {
          ...state,
          chartDetails: normalizeChartDetails(action.response.data),
        };
      },
    },
  ),

  [ChartsActionTypes.CHART_DOWNLOAD]: (state: IChartsState): IChartsState => ({
    ...state,
    downloadStatus: requestInProgress(),
  }),
  [ChartsActionTypes.CHART_DOWNLOAD_SUCCESS]: (
    state: IChartsState,
  ): IChartsState => ({
    ...state,
    downloadStatus: requestSuccessful(),
  }),
  [ChartsActionTypes.CHART_DOWNLOAD_ERROR]: (
    state: IChartsState,
    action: IApiErrorAction,
  ): IChartsState => ({
    ...state,
    downloadStatus: requestFailed(extractRequestError(action)),
  }),

  [ChartsActionTypes.CHART_SAVE_AS]: (state: IChartsState): IChartsState => ({
    ...state,
    saveAsStatus: requestInProgress(),
  }),
  [ChartsActionTypes.CHART_SAVE_AS_SUCCESS]: (
    state: IChartsState,
  ): IChartsState => ({
    ...state,
    saveAsStatus: requestSuccessful(),
  }),
  [ChartsActionTypes.CHART_SAVE_AS_ERROR]: (
    state: IChartsState,
    action: IApiErrorAction,
  ): IChartsState => ({
    ...state,
    saveAsStatus: requestFailed(extractRequestError(action)),
  }),
  [ChartsActionTypes.CHART_SAVE_AS_RESET]: (
    state: IChartsState,
  ): IChartsState => ({
    ...state,
    saveAsStatus: requestInactive(),
  }),

  // Eliminate all ...data from current reducer
  [UserActionTypes.SIGNOUT]: (): IChartsState => ({
    ...initialState,
  }),
});

export { chartsReducer };

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const LogoutIcon = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11L12 11L12 10.0806C12 9.24212 12.9699 8.77594 13.6247 9.29976L16.0239 11.2191C16.5243 11.6195 16.5243 12.3805 16.0239 12.7809L13.6247 14.7002C12.9699 15.2241 12 14.7579 12 13.9194L12 13L5 13ZM12 20C10.8954 20 10 19.1046 10 18L10 15.9375L12 15.9375L12 18L18 18L18 6L12 6L12 8.0625L10 8.0625L10 6C10 4.89543 10.8954 4 12 4L18 4C19.1046 4 20 4.89543 20 6L20 18C20 19.1046 19.1046 20 18 20L12 20Z"
    />
  </SvgIcon>
);

LogoutIcon.muiName = 'SvgIcon';

export { LogoutIcon };

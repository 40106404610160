import { IExtendedRequestAction } from 'common/types/requestTypes';
import { Region } from 'common/modules/cluster/types';
import { IApiPricePrameter } from '../../types';

const ClusterActionTypes = {
  CLUSTER_FETCH: 'CLUSTER_FETCH',
};

const ClusterActions = {
  fetchCluster: ({
    region,
    data,
  }: {
    region?: Region;
    data?: IApiPricePrameter;
  }): IExtendedRequestAction => ({
    type: ClusterActionTypes.CLUSTER_FETCH,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/cluster/v2/cluster`,
      method: 'GET',
      params: { continent: region, ...data },
    },
    meta: {
      takeLatest: false,
    },
  }),
  fetchEnterpriseCluster: ({
    region,
    data,
  }: {
    region?: Region;
    data?: IApiPricePrameter;
  }): IExtendedRequestAction => ({
    type: ClusterActionTypes.CLUSTER_FETCH,
    request: {
      url: `https://acms-stage.ankr.com/aci/market/cluster/info`,
      method: 'GET',
      params: {},
    },
  }),
};

export { ClusterActionTypes, ClusterActions };

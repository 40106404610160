/* eslint-disable @typescript-eslint/camelcase */
import { IExtendedRequestAction } from 'common/types/requestTypes';
import {
  ZENDESK_AUTHORIZATION_HEADER,
  ZENDESK_DOMAIN,
  ZENDESK_GROUP_IDS,
  ZendeskTicketFilters,
} from '../const';
import { ZendeskTicketStatus, ZendeskTicketType } from '../types/ticketTypes';
import { isProd } from '../../../utils/environment';
import { isBackendStatus } from '../utils/isBackendStatus';
import { isArray } from 'util';

const ZendeskActionTypes = {
  FETCH_ZENDESK_ORGANIZATION: 'FETCH_ZENDESK_ORGANIZATION',
  FETCH_ZENDESK_ORGANIZATION_SUCCESS: 'FETCH_ZENDESK_ORGANIZATION_SUCCESS',

  CREATE_ZENDESK_ORGANIZATION: 'CREATE_ZENDESK_ORGANIZATION',
  CREATE_ZENDESK_ORGANIZATION_SUCCESS: 'CREATE_ZENDESK_ORGANIZATION_SUCCESS',

  FETCH_ZENDESK_USER: 'FETCH_ZENDESK_USER',
  FETCH_ZENDESK_USER_SUCCESS: 'FETCH_ZENDESK_USER_SUCCESS',

  FETCH_ZENDESK_USERS: 'FETCH_ZENDESK_USERS',

  CREATE_ZENDESK_USER: 'CREATE_ZENDESK_USER',
  CREATE_ZENDESK_USER_SUCCESS: 'CREATE_ZENDESK_USER_SUCCESS',

  FETCH_ZENDESK_ORGANIZATION_MEMEBERSHIP:
    'FETCH_ZENDESK_ORGANIZATION_MEMEBERSHIP',

  CREATE_ZENDESK_ORGANIZATION_MEMEBERSHIP:
    'CREATE_ZENDESK_ORGANIZATION_MEMEBERSHIP',

  FETCH_ZENDESK_TICKETS: 'FETCH_ZENDESK_TICKETS',
  FETCH_ZENDESK_TICKETS_SEARCH: 'FETCH_ZENDESK_TICKETS_SEARCH',

  FETCH_ZENDESK_TICKETS_GET: 'FETCH_ZENDESK_TICKETS_GET',

  FETCH_ZENDESK_TICKET: 'FETCH_ZENDESK_TICKET',

  CREATE_ZENDESK_TICKET: 'CREATE_ZENDESK_TICKET',

  CREATE_ZENDESK_TICKET_COMMENT: 'CREATE_ZENDESK_TICKET_COMMENT',

  FETCH_ZENDESK_TICKET_COMMENTS: 'FETCH_ZENDESK_TICKET_COMMENTS',

  FETCH_ZENDESK_USER_BY_ID: 'FETCH_ZENDESK_USER_BY_ID',

  UPDATE_ZENDESK_TICKET: 'UPDATE_ZENDESK_TICKET',
};

const ZendeskActions = {
  fetchZendeskOrganization: ({
    teamId,
  }: {
    teamId: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_ORGANIZATION,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/organizations/search.json`,
      params: {
        external_id: teamId,
      },
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      noAuth: true,
    },
  }),
  createZendeskOrganization: ({
    teamId,
  }: {
    teamId: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.CREATE_ZENDESK_ORGANIZATION,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/organizations.json`,
      method: 'POST',
      headers: ZENDESK_AUTHORIZATION_HEADER,
      data: {
        organization: {
          name: teamId,
          external_id: teamId,
        },
      },
    },
    meta: {
      noAuth: true,
    },
  }),
  fetchZendeskUser: ({
    userId,
  }: {
    userId: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_USER,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/users/search.json`,
      params: {
        external_id: userId,
      },
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      noAuth: true,
    },
  }),
  fetchZendeskUserByID: (userId: number): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_USER_BY_ID,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/users/${userId}.json`,
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      userId,
      requestKey: userId,
      takeLatest: false,
      noAuth: true,
    },
  }),
  fetchZendeskUsers: ({
    zendeskOrganizationId,
  }: {
    zendeskOrganizationId: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_USERS,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/organizations/${zendeskOrganizationId}/users.json`,
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      noAuth: true,
    },
  }),
  createOrUpdateZendeskUser: ({
    userId,
    name,
    email,
  }: {
    userId: string;
    name: string;
    email: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.CREATE_ZENDESK_USER,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/users/create_or_update.json`,
      method: 'POST',
      headers: ZENDESK_AUTHORIZATION_HEADER,
      data: {
        user: {
          name,
          email,
          external_id: userId,
          verified: true,
        },
      },
    },
    meta: {
      noAuth: true,
    },
  }),
  fetchZendeskUserMemberships: ({
    zendeskUserId,
  }: {
    zendeskUserId: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_ORGANIZATION_MEMEBERSHIP,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/users/${zendeskUserId}/organization_memberships.json`,
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      noAuth: true,
    },
  }),
  createOrUpdateZendeskUserMembership: ({
    zendeskUserId,
    zendeskOrganizationId,
  }: {
    zendeskUserId: string;
    zendeskOrganizationId: number;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.CREATE_ZENDESK_ORGANIZATION_MEMEBERSHIP,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/users/${zendeskUserId}/organization_memberships.json`,
      method: 'POST',
      headers: ZENDESK_AUTHORIZATION_HEADER,
      data: {
        organization_membership: {
          user_id: zendeskUserId,
          organization_id: zendeskOrganizationId,
        },
      },
    },
    meta: {
      noAuth: true,
    },
  }),
  searchTickets: ({
    zendeskOrganizationId,
    search,
    page,
    perPage,
    status,
    statusComparer = ':',
    teamId,
    zendeskUserId,
  }: {
    zendeskOrganizationId?: number;
    search?: string;
    page?: number;
    perPage?: number;
    status?: ZendeskTicketFilters | ZendeskTicketFilters[];
    statusComparer?: ':' | '>' | '<';
    teamId?: string;
    zendeskUserId?: number;
  }): IExtendedRequestAction => {
    let query = '';
    let matched = false;

    if (zendeskOrganizationId) {
      query += `organization:${zendeskOrganizationId}`;
      matched = true;
    }

    const addStatus = (singleStatus: ZendeskTicketFilters) => {
      if (isBackendStatus(singleStatus)) {
        query += `${matched ? ' ' : ''}`;
        query += `status${statusComparer}${singleStatus}`;
        matched = true;
      }
    };

    if (status) {
      if (isArray(status)) {
        status.forEach(singleStatus => {
          addStatus(singleStatus);
        });
      } else {
        addStatus(status);
      }
    }

    if (teamId) {
      query += matched ? ' ' : '';
      query += `external_id:${teamId}`;
      matched = true;
    }

    if (zendeskUserId && search) {
      query += matched ? ' ' : '';
      query += `requester_id:${zendeskUserId}`;
      matched = true;
    }

    if (search) {
      query += matched ? ` "${search}"` : search;
    }

    return {
      type: ZendeskActionTypes.FETCH_ZENDESK_TICKETS_SEARCH,
      request: {
        url:
          zendeskUserId && !search
            ? `${ZENDESK_DOMAIN}/api/v2/users/${zendeskUserId}/tickets/requested.json`
            : `${ZENDESK_DOMAIN}/api/v2/search.json`,
        method: 'GET',
        headers: ZENDESK_AUTHORIZATION_HEADER,
        params: {
          ...(query ? { query } : {}),
          ...(typeof page === 'number' && typeof perPage === 'number'
            ? { page, per_page: perPage }
            : {}),
          sort_order: 'desc',
        },
      },
      meta: {
        noAuth: true,
      },
    };
  },
  fetchZendeskTicketsByApp: (appId: string): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_TICKETS,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/tickets.json`,
      params: {
        external_id: appId,
        sort_order: 'desc',
      },
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      noAuth: true,
    },
  }),
  fetchZendeskTicketsById: (ids: number[]): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_TICKETS_GET,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/tickets/show_many.json`,
      params: {
        ids: ids.join(','),
      },
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      noAuth: true,
    },
  }),
  fetchZendeskTicketsByUserAssigned: ({
    zendeskUserId,
    page,
    perPage,
    search,
  }: {
    zendeskUserId: number;
    page: number;
    perPage: number;
    search?: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_TICKETS,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/users/${zendeskUserId}/tickets/assigned.json`,
      params: {
        ...(search ? { query: `"${search}"` } : {}),
        ...(page && perPage ? { page, per_page: perPage } : {}),
      },
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      noAuth: true,
    },
  }),
  createZendeskTicket: ({
    type,
    subject,
    description,
    descriptionAttachments,
    collaborators,
    zendeskUserId,
    zendeskOrganizationId,
    appId,
  }: {
    type: ZendeskTicketType;
    subject: string;
    description: string;
    descriptionAttachments: string[];
    collaborators: string[];
    zendeskUserId: number;
    zendeskOrganizationId: number;
    appId?: string;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.CREATE_ZENDESK_TICKET,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/tickets.json`,
      method: 'POST',
      headers: ZENDESK_AUTHORIZATION_HEADER,
      data: {
        ticket: {
          subject,
          type,
          comment: { body: description, uploads: descriptionAttachments },
          collaborators,
          requester_id: zendeskUserId,
          submitter_id: zendeskUserId,
          organization_id: zendeskOrganizationId,
          external_id: appId,
          group_id: isProd()
            ? ZENDESK_GROUP_IDS.support
            : ZENDESK_GROUP_IDS.development,
        },
      },
    },
    meta: {
      noAuth: true,
    },
  }),
  fetchZendeskTicketComments: (ticketId: number): IExtendedRequestAction => ({
    type: ZendeskActionTypes.FETCH_ZENDESK_TICKET_COMMENTS,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/tickets/${ticketId}/comments.json`,
      method: 'GET',
      headers: ZENDESK_AUTHORIZATION_HEADER,
    },
    meta: {
      ticketId,
      requestKey: ticketId,
      takeLatest: false,
      noAuth: true,
    },
  }),
  createZendeskTicketComment: ({
    comment,
    attachments,
    ticketId,
    zendeskUserId,
  }: {
    comment: string;
    attachments: string[];
    ticketId: number;
    zendeskUserId: number;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.CREATE_ZENDESK_TICKET_COMMENT,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/tickets/${ticketId}.json`,
      method: 'PUT',
      headers: ZENDESK_AUTHORIZATION_HEADER,
      data: {
        ticket: {
          comment: {
            body: comment,
            author_id: zendeskUserId,
            uploads: attachments,
          },
        },
      },
    },
    meta: {
      ticketId,
      asPromise: true,
      noAuth: true,
    },
  }),
  updateZendeskTicketStatus: ({
    status,
    ticketId,
  }: {
    status: ZendeskTicketStatus;
    ticketId: number;
  }): IExtendedRequestAction => ({
    type: ZendeskActionTypes.UPDATE_ZENDESK_TICKET,
    request: {
      url: `${ZENDESK_DOMAIN}/api/v2/tickets/${ticketId}.json`,
      method: 'PUT',
      headers: ZENDESK_AUTHORIZATION_HEADER,
      data: {
        ticket: {
          status,
        },
      },
    },
    meta: {
      noAuth: true,
    },
  }),
};

export { ZendeskActionTypes, ZendeskActions };

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  root: {
    borderRadius: 4,
  },
  header: {
    marginBottom: 24,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {},
  select: {
    width: 132,
    '& .MuiInputBase-root': {
      background: 'transparent',
    },
    '& .MuiSelect-select': {
      height: 36,
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
    },
  },
  logs: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 70,
  },
  panel: {
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    maxHeight: 'calc(100vh - 280px)',

    '@media (orientation: landscape)': {
      maxHeight: 'calc(100vh - 220px)',
    },
  },
}));

import { IExtendedRequestAction } from '../../types/requestTypes';

const AnkrActionTypes = {
  ANKR_FETCH_SYMBOL_PRICE: 'ANKR_FETCH_SYMBOL_PRICE',
  ANKR_FETCH_SYMBOL_PRICE_SUCCESS: 'ANKR_FETCH_SYMBOL_PRICE_SUCCESS',
  ANKR_FETCH_SYMBOL_PRICE_ERROR: 'ANKR_FETCH_SYMBOL_PRICE_ERROR',
};

export type PriceHistoryRange = '1h' | '1d' | '7d' | '1m' | '3m';

export enum IFetchSymbolPrice {
  ANKR = 'ANKR',
  USDT = 'USDT',
}

const AnkrActions = {
  fetchSymbolPrice: (symbol: IFetchSymbolPrice): IExtendedRequestAction => ({
    type: AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/price/v1alpha/quotes/${symbol}`,
      method: 'GET',
    },
    meta: {
      symbol,
    },
  }),
};

export { AnkrActionTypes, AnkrActions };

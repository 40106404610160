import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const ThumbIcon = withSvgIcon(
  <>
    <rect
      x="0.5"
      y="0.5"
      width="37"
      height="27"
      rx="7.5"
      fill="#2075E8"
      stroke="white"
    />
    <path d="M11 14L16.4286 19L16.4286 9L11 14Z" fill="white" />
    <path d="M27.2866 14L21.858 19L21.8581 9L27.2866 14Z" fill="white" />
  </>,
  { viewBox: '0 0 38 28' },
);

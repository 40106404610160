import { BlockstackHooks } from './BlockstackConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/Blockstack.en-US.json';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/BlockstackManage').then(module => module.BlockstackManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  const status = data.status.replace("'", '');

  return {
    ...response,
    data: {
      ...data,

      current_burn_block_height:
        Number(data.current_burn_block_height ?? 0) ?? 0,
      current_stacks_tip_height:
        Number(data.current_stacks_tip_height ?? 0) ?? 0,
      highest_burn_block_height:
        Number(data.highest_burn_block_height ?? 0) ?? 0,
      highest_stacks_tip_height:
        Number(data.highest_stacks_tip_height ?? 0) ?? 0,
      status,
    },
  };
}

export const makeBlockstackConfig = (nodeName: string): INodeConfig => ({
  chartName: nodeName,
  locales: {
    'en-US': localesUS,
  },
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    blockstack: {
      [BlockstackHooks.getNodeStatus]: parseStatus,
    },
  },
});

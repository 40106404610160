export function convertSecondsToTimeTrack(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toFixed(0).padStart(2, '0')}`;
}

export function millisecondToHours(totalMs: number) {
  const hours = Math.floor(totalMs / 1000 / 3600);

  return hours;
}

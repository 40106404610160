export const graphNodeName = 'graph';

export enum GraphHooks {
  deploySubgraph = 'apphook1=deploy-subgraph',
  removeSubgraph = 'apphook2=remove-subgraph',
  deployedSubgraph = 'apphook3=deployed-subgraph',
}

export const GRAPH_INDEXER_ADDRESS = 'ankrCustomValues___indexer_address';
export const GRAPH_MNEMONIC = 'ankrCustomValues___mnemonic';
export const GRAPH_ETHEREUM_ENDPOINT = 'ankrCustomValues___ethereum_endpoint';

import React from 'react';
import { MainApp } from './MainApp';
import { LOCALES_DATA } from 'locales/locales';
import { store } from 'store/store';
import AppBase from 'common/components/App/AppBase';
import { historyInstance } from 'common/utils/historyInstance';
import { persistor } from 'store/store';
import 'common/fonts/LabGrotesque/stylesheet.css';
import { mainTheme } from 'themes/mainTheme';
import { registerNodes } from '../../modules/Nodes/nodes';
import { mergeDeep } from 'common/utils/object';
import { getNodesLocales } from 'common/utils/nodes/registerNode';

const App = () => {
  registerNodes();
  const locales = mergeDeep(LOCALES_DATA, getNodesLocales());
  return (
    <AppBase
      translations={locales}
      store={store}
      historyInstance={historyInstance}
      theme={mainTheme}
      persistor={persistor}
    >
      <MainApp />
    </AppBase>
  );
};

export { App };

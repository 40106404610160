import { IExtendedRequestAction } from 'common/types/requestTypes';
import { createAction } from 'common/utils/reduxUtils';
import { IApiNotificationSettingsItemResponse } from './SettingsTypes';

export const SettingsActionTypes = {
  SET_NOTIFICATION_SETTINGS_APP_ID: 'SET_NOTIFICATION_SETTINGS_APP_ID',
  FETCH_NOTIFICATION_SETTINGS: 'FETCH_NOTIFICATION_SETTINGS',
  UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS',
};

export const SettingsActions = {
  fetchNotificationSettings: (ids: string[]): IExtendedRequestAction => {
    return {
      type: SettingsActionTypes.FETCH_NOTIFICATION_SETTINGS,
      request: {
        url: '/apis/apiorder/v2alpha/api/notification/get_list',
        method: 'POST',
        data: {
          project_id_list: ids,
        },
      },
    };
  },
  updateNotificationSettings: (
    settings: IApiNotificationSettingsItemResponse[],
  ): IExtendedRequestAction => {
    return {
      type: SettingsActionTypes.UPDATE_NOTIFICATION_SETTINGS,
      request: {
        url: '/apis/apiorder/v2alpha/api/notification/update',
        method: 'POST',
        data: {
          notification_setting_list: settings,
        },
      },
    };
  },
  setNotificationSettingsApiId: (payload: string) =>
    createAction(SettingsActionTypes.SET_NOTIFICATION_SETTINGS_APP_ID, payload),
};

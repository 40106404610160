import { RequestAction, RequestsStore } from '@redux-requests/core';

import { timeout } from '../../utils/timeout';
import { UtilityActions } from '../actions/UtilityActions';
import { SUCCESS_REDIRECT_DELAY } from './utils';

export const onSuccess = async (
  response: any,
  action: RequestAction,
  store: RequestsStore,
): Promise<any> => {
  if (action.meta?.redirectOnSuccess) {
    await timeout(SUCCESS_REDIRECT_DELAY);

    store.dispatch(UtilityActions.redirect(action.meta.redirectOnSuccess));
  }

  return response;
};

import { StyleRules } from '@material-ui/core/styles';

const snackbarStyles = (): StyleRules => ({
  anchorOriginTopCenter: {
    top: 0,
    right: 0,
    left: 0,
    width: '100%',
    transform: 'translateX(0)',
  },
});

export { snackbarStyles };

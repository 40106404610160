import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '35px 8px',
  },
  svg: {
    width: '100%',
    height: '100%',
    maxWidth: 137,
    maxHeight: '25vh',

    '& svg': {
      width: '100%',
    },
  },
  message: {
    maxWidth: 230,
  },
}));

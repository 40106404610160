export function isProd() {
  return !process.env.DEBUG && process.env.NODE_ENV === 'production';
}

const ANKR_PROD_DOMAIN = 'https://api.ankr.com';

const REACT_APP_API_BASE = process.env.REACT_APP_API_BASE;

function isProdAPI() {
  return REACT_APP_API_BASE === ANKR_PROD_DOMAIN;
}

export const ETHERSCAN_TX_URL = isProdAPI()
  ? 'https://etherscan.io/tx/'
  : 'https://ropsten.etherscan.io/tx/';

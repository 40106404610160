import { withErrorHandler } from 'common/HOC/withErrorHandler';
import { LoadableScreen } from 'common/components/LoadableScreen';
import React from 'react';
import { withTransparentLayout } from '../modules/LayoutUI/components/Layout';
import loadable, { LoadableComponent } from '@loadable/component';

export const LoadableNotFoundPaper = withTransparentLayout(
  withErrorHandler()(
    loadable(
      async () => import('screens/NotFound').then(module => module.NotFound),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

import { NulsHooks, nulsNodeName } from './NulsConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/Nuls.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () => import('./manage/NulsManage').then(module => module.NulsManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      currentHeight: Number(data.currentHeight ?? 0) ?? 0,
      totalHeight: Number(data.totalHeight ?? 0) ?? 0,
    },
  };
}

export const nulsConfig: INodeConfig = {
  chartName: nulsNodeName,

  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'nuls-node': {
      [NulsHooks.getNodeStatus]: parseStatus,
      [NulsHooks.getIndexingStatus]: defaultParse,
    },
  },
};

import { IExtendedRequestAction } from 'common/types/requestTypes';
import { createAction } from '../../utils/reduxUtils';

const Ethereum2ActionTypes = {
  GET_ETHEREUM_KEY: 'GET_ETHEREUM_KEY',
  GET_ETHEREUM_KEY_SUCCESS: 'GET_ETHEREUM_KEY_SUCCESS',
  GET_ETHEREUM_KEY_ERROR: 'GET_ETHEREUM_KEY_ERROR',

  GET_ETHEREUM_PHRASE: 'GET_ETHEREUM_PHRASE',
  GET_ETHEREUM_PHRASE_SUCCESS: 'GET_ETHEREUM_PHRASE_SUCCESS',
  GET_ETHEREUM_PHRASE_ERROR: 'GET_ETHEREUM_PHRASE_ERROR',

  CLEAR_ETHEREUM_STATE: 'CLEAR_ETHEREUM_STATE',
};

const Ethereum2Actions = {
  fetchEthereum2Key: (uid: string): IExtendedRequestAction => ({
    type: Ethereum2ActionTypes.GET_ETHEREUM_KEY,
    request: {
      url: `https://acms-stage.ankr.com/aci-keyservice/eth2/v1/key`,
      method: 'POST',
      data: {
        uid,
      },
    },
    meta: {
      noAuth: true,
    },
  }),
  fetchEthereum2Phrase: (
    uid: string,
    passwd: string,
  ): IExtendedRequestAction => ({
    type: Ethereum2ActionTypes.GET_ETHEREUM_PHRASE,
    request: {
      url: `https://acms-stage.ankr.com/aci-keyservice/eth2/v1/phrase`,
      method: 'POST',
      data: {
        uid,
        passwd,
      },
    },

    meta: {
      noAuth: true,
    },
  }),
  clearEthereum2State: () =>
    createAction(Ethereum2ActionTypes.CLEAR_ETHEREUM_STATE),
};

export { Ethereum2Actions, Ethereum2ActionTypes };

import { Theme } from '@material-ui/core/styles';

import { MAIN_LAYOUT_SIDEBAR_WIDTH } from 'common/core/const';
import { makeStyles } from '@material-ui/styles';

export const useNavBarSMStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'fixed',

    width: '100vw',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.15)',
  },

  nav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  item: {
    width: MAIN_LAYOUT_SIDEBAR_WIDTH,
    height: 62,
    backgroundColor: theme.palette.common.white,
    borderBottom: '2px solid transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0',
    '@media (max-width: 450px)': {
      flex: 1,
      width: 'auto',
    },
    '&:hover': {
      backgroundColor: '#f4f8fe',
      borderColor: theme.palette.primary.main,
    },
    '&:hover svg, &:hover $text': {
      color: theme.palette.primary.main,
    },
  },
  current: {
    backgroundColor: '#f4f8fe',
    borderColor: theme.palette.primary.main,
    '& svg, & $text': {
      color: theme.palette.primary.main,
    },
  },
  text: {
    marginTop: 6,
    color: theme.palette.grey['700'],
    textSizeAdjust: 'none',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '12px',
  },
}));

import React from 'react';

export const CheckboxActive = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="22" height="22" rx="4" fill="#2075E8" />
    <g filter="url(#filter_checkbox)">
      <path
        d="M7.62447 10.9345C7.19321 10.5895 6.56392 10.6594 6.21891 11.0907C5.8739 11.522 5.94382 12.1512 6.37508 12.4963L7.62447 10.9345ZM10.1632 14.2461L9.53852 15.027C9.76028 15.2044 10.047 15.2792 10.3271 15.2326C10.6073 15.1861 10.8544 15.0226 11.0069 14.783L10.1632 14.2461ZM15.4357 7.82344C15.7322 7.3575 15.5948 6.73941 15.1289 6.44291C14.663 6.1464 14.0449 6.28375 13.7484 6.74969L15.4357 7.82344ZM6.37508 12.4963L9.53852 15.027L10.7879 13.4653L7.62447 10.9345L6.37508 12.4963ZM11.0069 14.783L15.4357 7.82344L13.7484 6.74969L9.31956 13.7093L11.0069 14.783Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter_checkbox"
        x="1"
        y="2"
        width="20"
        height="20"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0938194 0 0 0 0 0.396652 0 0 0 0 0.804167 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  AppBar,
  Container,
  StyledComponentProps,
  Toolbar as MuiToolbar,
  withStyles,
} from '@material-ui/core';
import { useIsSMDown, useIsXSDown } from 'common/utils/hooks/themeHooks';
import classNames from 'classnames';
import { NavBar } from '../NavBar';
import { layoutStyles } from './LayoutStyles';
import Slide from '@material-ui/core/Slide';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Logo } from './assets/textLogo.svg';
import { PageTitle } from './PageTitle';
import { ToolBar } from '../Toolbar/ToolBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { EnableTwoFactorAuth } from '../../../TwoFactorAuth/screens/EnableTwoFactorAuth';

const SLIDE_TIMEOUT = 500;

interface ILayoutStoreProps {
  isUserAuthenticated: boolean;
  showTwoFAReminder: boolean;
  showNavBar?: boolean;
  hideNavBarSM?: boolean;
  hideNavBarXS?: boolean;
}

interface ILayoutProps extends ILayoutStoreProps, StyledComponentProps {
  children: ReactNode;
  className?: string;
  whiteXSBg?: boolean;
  resetTwoFAReminder: () => void;
}

export const Layout = withStyles(layoutStyles)(
  ({
    children,
    classes = {},
    isUserAuthenticated,
    showTwoFAReminder,
    resetTwoFAReminder,
    showNavBar = true,
    hideNavBarSM = false,
    hideNavBarXS = false,
    className,
    whiteXSBg,
  }: ILayoutProps) => {
    const isSMDown = useIsSMDown();
    const isXSDown = useIsXSDown();

    const [showNavBarFinal, setShowNavBarFinal] = useState(showNavBar);

    const handleCloseReminder = useCallback(() => {
      resetTwoFAReminder();
    }, [resetTwoFAReminder]);

    useEffect(() => {
      if ((hideNavBarSM && isSMDown) || (hideNavBarXS && isXSDown)) {
        setShowNavBarFinal(false);
      } else {
        setShowNavBarFinal(true);
      }
    }, [hideNavBarSM, hideNavBarXS, isSMDown, isXSDown]);

    const triggerDetached = useScrollTrigger({
      threshold: 10,
      disableHysteresis: true,
    });

    const triggerHide = useScrollTrigger({ threshold: 150 });

    return (
      <div
        className={classNames(
          classes.root,
          whiteXSBg && classes.whiteXSBg,
          showNavBarFinal && isUserAuthenticated && classes.authenticated,
        )}
      >
        <Slide
          appear={false}
          direction="down"
          in={!triggerHide || isSMDown}
          timeout={isSMDown ? 0 : SLIDE_TIMEOUT}
        >
          <AppBar
            position="fixed"
            classes={{
              root: classNames(
                !isSMDown && triggerDetached && classes.detached,
              ),
            }}
          >
            <MuiToolbar
              className={classNames(
                classes.toolbar,
                showNavBarFinal && isUserAuthenticated && classes.authenticated,
                showNavBarFinal && classes.withNavBar,
              )}
              disableGutters={true}
            >
              <Container maxWidth="xl" className={classes.container}>
                {(!isUserAuthenticated || isSMDown) && (
                  <RouterLink
                    to="/"
                    className={classNames(isSMDown && classes.iconContainer)}
                  >
                    <Logo className={isSMDown ? classes.icon : undefined} />
                  </RouterLink>
                )}
                {!isSMDown && <PageTitle />}
                <ToolBar />
              </Container>
            </MuiToolbar>
          </AppBar>
        </Slide>
        {showNavBarFinal && isUserAuthenticated && (
          <NavBar className={classes.navBar} />
        )}
        <div className={classes.content}>
          <div className={classNames(classes.paper, className)}>{children}</div>
        </div>
        {showTwoFAReminder && (
          <EnableTwoFactorAuth
            open={showTwoFAReminder}
            onClose={handleCloseReminder}
          />
        )}
      </div>
    );
  },
);

import { select, put, putResolve } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { AxiosResponse } from 'axios';

import { AppsActions, IFetchAppHook } from '../actions/AppsActions';
import {
  getAppDetails,
  IAppEntries,
  IAppsState,
} from '../reducers/appsReducer';
import { getCurrentTeam, ITeamState } from '../../teams/reducers/teamReducer';
import { IAppHook, IAppEnteriseHook } from '../types';
import { safeParseJSON } from 'common/utils/string';

const hookIdList: IAppEnteriseHook = {};
let enterpriseHookId = '';

export function* fetchHookDataSaga({
  payload,
}: {
  payload: IFetchAppHook;
}): unknown {
  const {
    hook,
    currentTeamId,
    details,
    isEnterprise,
  }: {
    hook: IAppHook;
    currentTeamId?: string;
    details: IAppEntries;
    isEnterprise: boolean | undefined;
  } = yield select((state: { apps: IAppsState; team: ITeamState }) => {
    const appDetails = getAppDetails(
      state.apps.details,
      payload.appId,
      state.team.currentTeamId,
    );

    return {
      hook: appDetails?.data?.appHooks?.find(
        hook => hook.label === payload.name,
      ),
      details: state.apps.details,
      currentTeamId: state.team.currentTeamId,
      isEnterprise: getCurrentTeam(state.team)?.isEnterprise,
    };
  });

  if (hook) {
    if (isEnterprise) {
      const hookAction = hook.label + payload.appId;
      if (!hookIdList[hookAction]) {
        const {
          response,
        }: { response: AxiosResponse<string> } = yield putResolve(
          AppsActions.fetchAppEnterpriseHookId(
            hook,
            payload.appId,
            currentTeamId,
          ),
        );

        const data = response?.data;

        enterpriseHookId =
          safeParseJSON(data)?.result || safeParseJSON(data)?.esResult || data;
        hookIdList[hookAction] = enterpriseHookId;
      }
      if (enterpriseHookId !== '') {
        return yield put(
          AppsActions.appEnterpriseHookCall(
            hook,
            hookIdList[hookAction],
            payload.appId,
            currentTeamId,
          ),
        );
      }
    } else {
      return yield put(
        AppsActions.appHookCall(
          { ...hook, args: payload.args ?? {} },
          payload.appId,
          currentTeamId,
        ),
      );
    }
  } else {
    Sentry.configureScope(scope => {
      scope.setExtra('payload', payload);
      scope.setExtra('details', details);
      Sentry.captureException("Hook hasn't been found");
    });
  }
}

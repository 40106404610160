import { delay, all, put, select, putResolve } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { ITeamState } from '../../teams/reducers/teamReducer';
import { AppStatus } from '../../apps/types';
import { PaymentMethod } from '../const';
import { IApiEthPaymentStatus } from '../apiMapping/getEthPaymentStatus';
import { PayActions, PayActionTypes } from '../actions/PayActions';
import {
  IExtendedRequestActionMeta,
  SendRequestSuccessResponse,
} from '../../../types';
import { getAppDetails, IAppsState } from '../../apps/reducers/appsReducer';

const WAIT_SERVER_DELAY = 300;

function* getDepositAddresses(id: string, forceCheck?: boolean): any {
  try {
    const { currentTeamId: teamId, app } = yield select(
      (state: { team: ITeamState; apps: IAppsState }) => {
        const appEntry = getAppDetails(
          state.apps.details,
          id,
          state.team.currentTeamId,
        );

        return {
          currentTeamId: state.team.currentTeamId ?? '',
          app: appEntry?.data,
        };
      },
    );

    yield put({
      type: PayActionTypes.GET_ETH_PAYMENT_STATUS,
    });

    if (app.status === AppStatus.App_Transaction_In_Progress || forceCheck) {
      // TODO Call by payment method once
      const response: SendRequestSuccessResponse<
        IApiEthPaymentStatus
      >[] = yield all([
        putResolve({
          type: 'EMPTY1',
          request: {
            url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${teamId}/eth_order/${app.id}?pay_method=${PaymentMethod.USDT_ERC20}`,
            method: 'GET',
            data: {},
          },
          meta: {
            id: PaymentMethod.USDT_ERC20,
            silent: true,
          },
        }),
        putResolve({
          type: 'EMPTY2',
          request: {
            url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${teamId}/eth_order/${app.id}?pay_method=${PaymentMethod.ANKR_ERC20}`,
            method: 'GET',
            data: {},
          },
          meta: {
            id: PaymentMethod.USDT_ERC20,
            silent: true,
          },
        }),
      ]);

      const data = response.find(
        (item: SendRequestSuccessResponse<IApiEthPaymentStatus>) => item?.data,
      )?.data;

      const paymentMethod = (() => {
        if (response[0].data) {
          return PaymentMethod.USDT_ERC20;
        }

        if (response[1].data) {
          return PaymentMethod.ANKR_ERC20;
        }
      })();

      if (data) {
        return yield put({
          type: PayActionTypes.GET_ETH_PAYMENT_STATUS_SUCCESS,
          data: { ...data, paymentMethod },
        });
      }
    }

    yield put({
      type: PayActionTypes.GET_ETH_PAYMENT_STATUS_SUCCESS,
      data: undefined,
    });
  } catch (error) {
    yield put({
      type: PayActionTypes.GET_ETH_PAYMENT_STATUS_ERROR,
      data: { error: error.toString() },
    });
  }
}

export function* onAppDetailsSuccess(
  action: {
    meta: Required<Pick<IExtendedRequestActionMeta, 'id'>>;
  } & Action<void>,
) {
  const {
    meta: { id },
  } = action;

  yield delay(WAIT_SERVER_DELAY);

  yield getDepositAddresses(id);
}

export function* onExtendAppSuccess(
  action: {
    meta: Required<Pick<IExtendedRequestActionMeta, 'id'>>;
  } & Action<void>,
) {
  const {
    meta: { id },
  } = action;

  const { currentTeamId: teamId } = yield select(
    (state: { team: ITeamState }) => {
      return {
        currentTeamId: state.team.currentTeamId ?? '',
      };
    },
  );

  yield put(PayActions.getDepositList({ teamId }));
  yield getDepositAddresses(id, true);
}

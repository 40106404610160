import React from 'react';
import {
  StyledComponentProps,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { t } from 'common/utils/intl';
import { CopyToClipboard } from 'common/components/CopyToClipboard';
import IconButton from '@material-ui/core/IconButton';
import { CopyIcon } from 'common/components/Icons/CopyIcon';
import { SnackbarsActions } from 'common/store/actions/SnackbarsActions';
import { connect } from 'react-redux';
import classNames from 'classnames';

const styles = (theme: Theme): StyleRules => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingRight: 0,
    minHeight: 24,
    position: 'relative',
    '&&:hover $icon': {
      color: theme.palette.grey['800'],
    },
    '&&:hover $addressText': {
      color: theme.palette.grey['800'],
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% + 12px)',
      justifyContent: 'flex-start',
      right: 0,
    },
  },
  defaultValue: {
    right: 0,
  },
  addressText: {
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.grey['700'],
    flex: 1,
    fontWeight: 'bold',
    cursor: 'pointer',
    wordBreak: 'break-all',
  },
  inProgress: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.grey['700'],
    // marginLeft: 14,
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.grey['700'],
  },
  background: {
    height: 44,
    width: '100%',
    background: theme.palette.grey['300'],
    borderRadius: '4px',
    padding: 14,
    paddingRight: 0,
    right: 0,
    position: 'unset',
    cursor: 'pointer',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});

interface IHookAddressProps extends StyledComponentProps {
  value?: string;
  showSnackbar: typeof SnackbarsActions.showSnackbar;
  showBackground?: boolean;
  defaultValue?: React.ReactNode;
}

const HookCopyableValueComponent = ({
  classes = {},
  value,
  showSnackbar,
  showBackground = false,
  defaultValue,
}: IHookAddressProps) => {
  const handleCopyToClipboard = () => {
    showSnackbar(t('nodes.details.copied'), {
      key: 'NodeAddressComponent.copied',
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  return (
    <div
      className={classNames(
        classes.root,
        showBackground && classes.background,
        !value && defaultValue,
      )}
    >
      {value ? (
        <CopyToClipboard text={value} onCopy={handleCopyToClipboard}>
          <div className={classes.inner}>
            <Typography variant="body1" className={classes.addressText} noWrap>
              {value}
            </Typography>
            <IconButton className={classes.iconButton}>
              <CopyIcon className={classes.icon} />
            </IconButton>
          </div>
        </CopyToClipboard>
      ) : (
        <Typography variant="body1" className={classes.inProgress}>
          {defaultValue ?? t('nodes.details.in-progress')}
        </Typography>
      )}
    </div>
  );
};

const HookCopyableValue = connect(null, {
  showSnackbar: SnackbarsActions.showSnackbar,
})(withStyles(styles)(HookCopyableValueComponent));

export { HookCopyableValue };

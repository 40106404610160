import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useChipsInputStyles = makeStyles<Theme>(theme => ({
  component: {
    width: '100%',

    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,

    backgroundColor: theme.palette.common.white,
  },

  wrapper: {
    padding: theme.spacing(1.5, 1.5),

    minHeight: 104,
    marginBottom: 0,

    '&::before, &::after': {
      display: 'none',
    },
  },

  container: {
    alignItems: 'flex-start',
  },

  input: {
    minHeight: 32,
    padding: 0,

    borderWidth: 0,
  },

  chip: {
    marginRight: theme.spacing(1.5),

    fontWeight: 500,
    color: theme.palette.common.white,

    borderRadius: 4,

    backgroundColor: theme.palette.primary.main,

    '& svg': {
      width: 16,
      height: 16,

      fontSize: 12,
      color: theme.palette.common.white,
    },
  },
}));

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useScrollBarStyles = makeStyles<Theme, { offset?: number }>(
  theme => ({
    bar: {
      position: 'relative',
      right: props => `-${props.offset}px`,
      width: 3,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 3,
    },
    barTransparent: {
      backgroundColor: 'transparent',
    },
  }),
);

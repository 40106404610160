import {
  AppStatus,
  IApiApp,
  IApiFetchAppsResponse,
  IRealtimeOverview,
  IApiRealtimeOverview,
  IApp,
  IClusterPrice,
  IPriceResult,
  IAPIUptimeResponse,
  IUptimeResponse,
} from '../types';
import { t } from 'common/utils/intl';
import * as Sentry from '@sentry/browser';
import { normalizeNamespaceItem } from '../../namespaces/apiMappings/namespaceMappings';
import { normalizeChart, normalizeNumberFormat } from './chartsMappings';
import { ICluster } from 'common/types/apiTypes';
import { ICheckPriceResponse } from '../../../store/apiMappings/clusterMappings';

export const mapAppStatus = (status: string): AppStatus =>
  // @ts-ignore I'm doing it right!
  AppStatus[status] || AppStatus.APP_UNKNOWN;

const normalizeApp = (app: IApiApp): IApp => {
  const status = mapAppStatus(app.status);
  return {
    id: app.id,
    name: app.name,
    creator: app.creator,
    teamId: app.team_id,
    status,
    realtimeOverview: app.realtimeOverview,
    statusName: t(`apps.AppStatus.${status}`),
    createdTime: new Date(app.created_time),
    updatedTime: new Date(app.updated_time),
    endpoint: app.endpoint,
    chart: normalizeChart(app.chart),
    namespace: normalizeNamespaceItem(app.namespace),
    resource: app.resource,
    note: app.note,
    nodePorts: app.node_ports,
    appHooks: app.chart?.app_hooks,
    payWay: app.payWay,
    customValue: app.custom_value,
    replicas: app.replicas_number,
  };
};

const STATUSES_ORDER: Array<AppStatus> = [
  AppStatus.APP_FAILED,
  AppStatus.APP_DEPLOYING,
  AppStatus.APP_PENDING,
  AppStatus.APP_RUNNING,
  AppStatus.App_Stopped,
  AppStatus.App_Resetted,
  AppStatus.APP_DELETED,
  AppStatus.APP_UNKNOWN,
];

const normalizeApps = (data: IApiFetchAppsResponse) => {
  try {
    return data?.apps?.length > 0
      ? data.apps.map(normalizeApp).sort((a, b) => {
          const statusOrderA = STATUSES_ORDER.findIndex(
            val => val === a.status,
          );
          const statusOrderB = STATUSES_ORDER.findIndex(
            val => val === b.status,
          );
          if (statusOrderA < statusOrderB) {
            return -1;
          } else if (statusOrderA > statusOrderB) {
            return 1;
          }

          return b.createdTime.getTime() - a.createdTime.getTime();
        })
      : [];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

const mapMetricsOverview = (data: IApiRealtimeOverview): IRealtimeOverview => {
  return {
    cpuUsed: normalizeNumberFormat(data.cpu.cur, 2),
    cpuLimit: normalizeNumberFormat(data.cpu.cap, 2),
    cpuUnit: data.cpu.unit,
    memUsed: normalizeNumberFormat(data.mem.cur, 1),
    memLimit: normalizeNumberFormat(data.mem.cap, 1),
    memUnit: data.mem.unit,
    storageUsed: normalizeNumberFormat(data.storage.cur, 1),
    storageLimit: normalizeNumberFormat(data.storage.cap, 1),
    storageUnit: data.storage.unit,
  };
};

const normalizeRpcData = (data: IAPIUptimeResponse): IUptimeResponse => {
  return {
    startTime: data.start_time,
    successRate: data.success_rate,
    uptimeBlocks: data.uptime_blocks,
  };
};

export const CLUSTER_PRICE_ACCURACY = Math.pow(10, 5);

const normalizePrice = (clusters: ICluster[]): IClusterPrice[] => {
  try {
    return clusters.map((item: ICluster) => ({
      id: item.id,
      hourlyPrice: item.hourlyPrice
        .dividedBy(CLUSTER_PRICE_ACCURACY)
        .toFixed(4),
      dailyPrice: item.dailyPrice.toFixed(2),
      monthlyPrice: item.monthlyPrice
        .dividedBy(CLUSTER_PRICE_ACCURACY)
        .toFixed(2),
    }));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

const normalizePriceResult = (
  data: ICheckPriceResponse,
): IPriceResult | undefined => {
  try {
    return {
      ok: data.ok,
      demand: data.demand,
      total: data.total,
      balance: data.balance,
      payMethodName: data.pay_method_name,
      failPhase: data.fail_phase,
    };
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

export {
  normalizeApp,
  normalizeApps,
  normalizePrice,
  mapMetricsOverview,
  normalizePriceResult,
  normalizeRpcData,
};

import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';
import BigNumber from 'bignumber.js';

export enum PchainNodeType {
  main = 'main',
  child = 'child',
}

export enum PchainStatus {
  'syncing' = 'syncing',
  'synced' = 'synced',
  'notRunning' = 'not running',
  'checking' = 'Check',
}

export interface IPchainStatusResult {
  main: PchainStatus;
  child: PchainStatus;

  mainCurrent: number;
  mainHighest: number;
  childCurrent: number;
  childHighest: number;
}

export enum PchainEpoch {
  ApplyCandidate = 'Delegate/Candidate',
  ValidatorVoting = 'Vote',
  RevealVote = 'Reveal Vote',
  CheckNextEpoch = 'Check',
  NotRunning = 'not running',
}

export interface IPchainEpochResult {
  main: PchainEpoch;
  child: PchainEpoch;
}

const PCHAIN_TOKEN_DIVIDER = Math.pow(10, 18);

export interface IPchainBalance {
  main: {
    balance?: BigNumber;
    proxied?: BigNumber;
    refund?: BigNumber;
    depositProxied?: BigNumber;
  };
  child: {
    balance?: BigNumber;
    proxied?: BigNumber;
    refund?: BigNumber;
    depositProxied?: BigNumber;
  };
}

export function pchainBalanceParse(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');

  return {
    ...response,
    data: {
      main: {
        balance: data.main?.result?.balance
          ? new BigNumber(data.main.result.balance).div(PCHAIN_TOKEN_DIVIDER)
          : new BigNumber(0),
        proxied: data.main?.result?.total_proxiedBalance
          ? new BigNumber(data.main.result.total_proxiedBalance).div(
              PCHAIN_TOKEN_DIVIDER,
            )
          : new BigNumber(0),
        depositProxied: data.main?.result?.total_depositProxiedBalance
          ? new BigNumber(data.main.result.total_depositProxiedBalance).div(
              PCHAIN_TOKEN_DIVIDER,
            )
          : new BigNumber(0),
        refund: data.main?.result?.total_pendingRefundBalance
          ? new BigNumber(data.main.result.total_pendingRefundBalance).div(
              PCHAIN_TOKEN_DIVIDER,
            )
          : new BigNumber(0),
      },
      child: {
        balance: data.child?.result?.balance
          ? new BigNumber(data.child.result.balance).div(PCHAIN_TOKEN_DIVIDER)
          : new BigNumber(0),
        proxied: data.child?.result?.total_proxiedBalance
          ? new BigNumber(data.child.result.total_proxiedBalance).div(
              PCHAIN_TOKEN_DIVIDER,
            )
          : new BigNumber(0),
        depositProxied: data.child?.result?.total_depositProxiedBalance
          ? new BigNumber(data.child.result.total_depositProxiedBalance).div(
              PCHAIN_TOKEN_DIVIDER,
            )
          : new BigNumber(0),
        refund: data.child?.result?.total_pendingRefundBalance
          ? new BigNumber(data.child.result.total_pendingRefundBalance).div(
              PCHAIN_TOKEN_DIVIDER,
            )
          : new BigNumber(0),
      },
    } as IPchainBalance,
  };
}

export interface IPchainKeystoreValidationResult {
  imported: boolean;
  valid: boolean;
}

export interface IPchainCandidateStatus {
  main: boolean;
  child: boolean;
}

export function parsePchainCandidateStatus(
  response: IHookResponse,
): IHookResponse {
  const result = safeParseJSON(response.data?.result ?? '{}');

  return {
    ...response,
    data: {
      main: result?.main === 'true',
      child: result?.child === 'true',
    } as IPchainCandidateStatus,
  };
}

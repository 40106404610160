import { LtoNetworkHooks, ltoNetworkNodeName } from './LtoNetworkConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/LtoNetwork.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/LtoNetworkManage').then(module => module.LtoNetworkManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/LtoNetworkCustomValues').then(
      module => module.LtoNetworkCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      currentHeight: Number(data?.currentBlock?.height ?? 0) ?? 0,
      latestHeight: Number(data?.highestBlock?.height ?? 0) ?? 0,
      syncing: data.syncing,
    },
  };
}

export const ltoNetworkConfig: INodeConfig = {
  chartName: ltoNetworkNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  manage: false as false,
  info: LoadableManage,
  details: false as false,
  interceptors: {
    'lto-network': {
      [LtoNetworkHooks.getAddress]: defaultParse,
      [LtoNetworkHooks.nodeStatus]: parseStatus,
      [LtoNetworkHooks.getBalance]: defaultParse,
      [LtoNetworkHooks.stakeStatus]: defaultParse,
    },
  },
};

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useEnterCodeStyles = makeStyles<Theme>(theme => ({
  code: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  field: {
    flexGrow: 1,
    marginRight: theme.spacing(1.75),

    '&:last-child': {
      marginRight: 0,
    },
  },

  input: {
    boxSizing: 'border-box',
    width: '100%',
    height: 44,
    margin: 0,
    padding: 3,

    color: theme.palette.text.primary,
    fontSize: 22,
    fontWeight: 400,
    textAlign: 'center',

    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,

    transitionTimingFunction: 'linear',
    transitionDuration: '200ms',
    transitionProperty: 'border-color',

    outline: 'none',

    '-moz-appearance': 'textfield',

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '&:hover': {
      borderColor: theme.palette.primary.light,
    },

    '&:focus': {
      borderColor: theme.palette.primary.light,
    },

    '&:-moz-ui-invalid': {
      boxShadow: 'none',
    },
  },

  inputLarge: {
    height: 52,
  },
}));

import { CosmosHooks, cosmosNodeName } from './CosmosConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/Cosmos.en-US.json';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/CosmosManage').then(module => module.CosmosManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/CosmosCustomValues').then(
      module => module.CosmosCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      current_height: Number(data.current_height ?? 0) ?? 0,
      latest_height: Number(data.latest_height ?? 0) ?? 0,
      isSynced: data.status === 'true',
    },
  };
}

export const cosmosConfig: INodeConfig = {
  chartName: cosmosNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'cosmos-node': {
      [CosmosHooks.nodeStatus]: parseStatus,
    },
  },
};

export const hederaHashgraphNodeName = 'hedera-hashgraph';

export enum HederaHashgraphHooks {
  getNodeStatus = 'apphook1=get-node-status',
}

export const HEDERA_HASHGRAPH_ACCESSKEY = 'ankrCustomValues___accessKey';
export const HEDERA_HASHGRAPH_GCPPROJECTID = 'ankrCustomValues___gcpProjectId';
export const HEDERA_HASHGRAPH_GOOGLE_CREDENTIALS_FILE =
  'ankrCustomValues___google_credentials_file';
export const HEDERA_HASHGRAPH_SECRETKEY = 'ankrCustomValues___secretKey';

export const HEDERA_HASHGRAPH_DOCS_HREF =
  'https://docs.ankr.com/enteprise-solutions/hedera-hashgraph';

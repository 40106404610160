import { OntologySyncHooks, ontologySyncNodeName } from './OntologySyncConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/OntologySync.en-US.json';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/OntologySyncManage').then(
      module => module.OntologySyncManage,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  const currentHeight = Number(data.currentBlocks ?? 0) ?? 0;
  const latestHeight = Number(data.totalBlocks ?? 0) ?? 0;
  return {
    ...response,
    data: {
      ...data,
      currentHeight,
      latestHeight,
      syncing: currentHeight < latestHeight,
    },
  };
}

export const OntologySyncConfig: INodeConfig = {
  chartName: ontologySyncNodeName,
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'ontology-node': {
      [OntologySyncHooks.getNodeStatus]: parseStatus,
    },
  },
};

function downloadFile(blob: Blob, filename: string) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');
    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['data/plain', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }
}

export function downloadBinaryFile(binary: string, filename: string) {
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([array], { type: 'octet/stream' });

  downloadFile(blob, filename);
}

export function downloadTextFile(data: string, filename: string) {
  const blob = new Blob([data], { type: 'data/plain' });
  downloadFile(blob, filename);
}

export function downloadJSONFile(data: {}, filename: string) {
  const blob = new Blob([JSON.stringify(data)], { type: 'data/plain' });
  downloadFile(blob, filename);
}

export function exportStringAsTxt(text: string, fileName: string) {
  const blob = new Blob([text], { type: 'txt' });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = ['txt', a.download, a.href].join(':');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
}

import { fork, takeEvery } from 'redux-saga/effects';
import { setRedirects } from './redirectSagas';
import {
  appRequestsSaga,
  fetchAddressInfoSaga,
  notifyModifyInfoSuccessSaga,
  updateTeamNotificationSaga,
} from 'common/modules/apps/effects/appsSagas';
import { snackbarsSaga } from 'common/store/effects/snackbarsSaga';
import { startOfflineNotifications } from 'common/store/effects/offlineNotificationSaga';
import { downloadChart, updateCharts } from 'store/effects/chartSaga';
import {
  notifyDeleteNotificationErrorSaga,
  updateNamespacesSaga,
} from 'common/modules/namespaces/effects/namespacesSagas';
import { userSaga } from 'auth/store/effects/userEffects';
import { ankrPricePollerRunner } from 'common/store/effects/pricePollerSaga';
import { promiseActionSaga } from 'common/store/effects/promisesSaga';
import { teamSaga } from 'common/modules/teams/effects/teamSaga';
import { paySaga } from 'common/modules/billing/effects/paySaga';
import { cardPaySaga } from 'common/modules/billing/effects/cardPaySaga';
import { ankrNativePaySaga } from 'common/modules/billing/effects/ankrNativePaySaga';
import { InvoiceActionTypes } from 'common/modules/billing/actions/InvoiceActions';
import { fetchOverviewSaga } from 'common/modules/billing/effects/fetchOverviewSaga';
import { runExternalTokenSaga } from 'auth/store/effects/externalTokenSaga';
import { twoFactorSaga } from '../../modules/TwoFactorAuth/store/TwoFactorSaga';
import { zendeskSaga } from 'common/modules/zendesk/effects/zendeskSaga';
import {
  onAppDetailsSuccess,
  onExtendAppSuccess,
} from 'common/modules/billing/effects/depositSaga';
import { PayActionTypes } from 'common/modules/billing/actions/PayActions';
import { AppsActionTypes } from 'common/modules/apps/actions/AppsActions';
import { onExtendAppSaga } from 'common/modules/billing/effects/onExtendAppSaga';
import { ankrCreditPaySaga } from 'common/modules/billing/effects/ankrCreditPaySaga';
import {
  runCreateProjectSaga,
  runCreateArchiveOrderSaga,
  runDeleteAPISaga,
  runFetchAPIListSaga,
  runUpgradePlanSaga,
  runCreateAPIProjectAllowedDomainSaga,
  runCreateAPIProjectWhiteIPSaga,
  runDeleteAPIProjectAllowedDomainSaga,
  runDeleteAPIProjectWhiteIPSaga,
  runSubscribeAPISaga,
  runFetchAPIListItemSaga,
} from 'modules/APIMarketUI/store/effects/apiMarketSaga';
import { APIMarketActionTypes } from 'modules/APIMarketUI/store/APIMarketActions';
import {
  createSidecar,
  nodesUISaga,
} from '../../modules/NodesUI/effects/NodesUISaga';
import { fetchApisInvoiceListSaga } from '../../modules/FinanceUI/effects/fetchApisInvoiceListSaga';

function* subscribeSaga() {
  yield fork(zendeskSaga);

  yield fork(setRedirects);
  yield fork(ankrPricePollerRunner);
  yield fork(downloadChart);
  yield fork(updateCharts);
  yield fork(notifyDeleteNotificationErrorSaga);
  yield fork(updateNamespacesSaga);
  yield fork(userSaga);
  yield fork(runExternalTokenSaga);
  yield fork(twoFactorSaga);
  yield fork(teamSaga);
  yield fork(paySaga);
  yield fork(cardPaySaga);
  yield fork(ankrNativePaySaga);
  yield fork(ankrCreditPaySaga);

  yield fork(promiseActionSaga);
  yield fork(promiseActionSaga);
  yield fork(appRequestsSaga);
  yield fork(fetchAddressInfoSaga);

  yield takeEvery(InvoiceActionTypes.FETCH_OVERVIEW, fetchOverviewSaga);
  yield takeEvery(
    InvoiceActionTypes.FETCH_APIS_INVOICE_LIST,
    fetchApisInvoiceListSaga,
  );

  yield takeEvery(AppsActionTypes.APP_DETAILS_SUCCESS, onAppDetailsSuccess);
  yield takeEvery(PayActionTypes.EXTEND_APP, onExtendAppSaga);
  yield takeEvery(PayActionTypes.EXTEND_APP_SUCCESS, onExtendAppSuccess);
  yield takeEvery(
    APIMarketActionTypes.CREATE_API_PROJECT,
    runCreateProjectSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.CREATE_ARCHIVE_ORDER,
    runCreateArchiveOrderSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.UPGRADE_API_MARKET_PLAN,
    runUpgradePlanSaga,
  );
  yield takeEvery(APIMarketActionTypes.DELETE_API, runDeleteAPISaga);
  yield takeEvery(APIMarketActionTypes.SUBSCRIBE_API, runSubscribeAPISaga);
  yield takeEvery(APIMarketActionTypes.FETCH_API_LIST, runFetchAPIListSaga);
  yield takeEvery(
    APIMarketActionTypes.FETCH_API_LIST_ITEM,
    runFetchAPIListItemSaga,
  );

  yield takeEvery(
    APIMarketActionTypes.CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN,
    runCreateAPIProjectAllowedDomainSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN,
    runDeleteAPIProjectAllowedDomainSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.CREATE_API_MARKET_PROJECT_WHITE_IP,
    runCreateAPIProjectWhiteIPSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.DELETE_API_MARKET_PROJECT_WHITE_IP,
    runDeleteAPIProjectWhiteIPSaga,
  );
  yield takeEvery(AppsActionTypes.APP_CREATE_WITH_OPTIONS, createSidecar);
}

/**
 * Main saga
 */
function* rootSaga() {
  yield fork(subscribeSaga);
  yield fork(notifyModifyInfoSuccessSaga);
  yield fork(updateTeamNotificationSaga);
  yield fork(snackbarsSaga);
  yield fork(startOfflineNotifications);
  yield fork(nodesUISaga);
}

export { rootSaga };

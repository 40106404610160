import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from 'common/nav/PrivateRoute';
import {
  NODES_PATH,
  SETTINGS_PREFIX,
  TEAM_CONFIRM_MEMBER,
  TEAM_PREFIX,
  WELCOME_PATH,
} from 'common/nav/navUtils';
import { LoadableNotFoundPaper } from './CommonLoadables';
import { t } from 'common/utils/intl';
import { DashboardRoutes } from 'modules/DashboardUI/DashboardRoutes';
import { WelcomeRoutes } from 'modules/WelcomeUI/WelcomeRoutes';
import { GUIDE_PATH, GuideRoutes } from '../modules/Guide/GuideRoutes';
import { AuthRoutes } from 'modules/AuthUI/AuthRoutes';
import { APPS_PATH } from '../modules/NodesUI/const';
import { NodesRoutes } from '../modules/NodesUI/NodesRoutes';
import { SettingsRoutes } from '../modules/SettingsUI/SettingsRoutes';
import { AppDeployRoutes } from '../modules/AppDeployUI/AppDeployRoutes';
import { AppInfoRoutes } from '../modules/AppInfoUI/AppInfoRoutes';
import { TeamRoutes } from 'modules/TeamUI/TeamRoutes';
import {
  CHANGE_EMAIL_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  SIGN_UP_PATH,
  USER_CONFIRM_REGISTRATION_PATH,
  USER_REGISTRATION_SUCCESS_PATH,
} from '../modules/AuthUI/const';
import { AUTH_PATH } from 'auth/src/const';
import { APP_INFO_PATH } from '../modules/AppInfoUI/const';
import { APP_DEPLOY_PATH } from '../modules/AppDeployUI/const';
import { BILLING_PATH } from '../modules/FinanceUI/const';
import { FEATURE_NEW_NODE_INFORMATION_PAGE_ENABLED } from '../const';
import { API_MARKET_LIST_PATH } from '../modules/APIMarketUI/APIMarketConsts';
import { APIMarketRoutes } from '../modules/APIMarketUI/APIMarketRoutes';
import { NodeInfoRoutes } from '../modules/NodeInfoUI/NodeInfoRoutes';
import { TICKETS_PATH } from '../modules/TicketsUI/TicketsConsts';
import { TicketsRoutes } from '../modules/TicketsUI/TicketsRoutes';
import { FinanceRoutes } from '../modules/FinanceUI/FinanceRoutes';

interface IMainNavigationProps {
  isSignedIn: boolean;
}

function MainRoutes(props: IMainNavigationProps) {
  const { isSignedIn } = props;

  return (
    <>
      <Switch>
        <Route
          exact={true}
          path={'/'}
          render={() => <AuthRoutes isSignedIn={isSignedIn} />}
        />

        {/* TODO - Redirect old login paths */}
        <Route
          path={'/login'}
          render={props => (
            <Redirect to={`${LOGIN_PATH}${props.location.search}`} />
          )}
        />
        <Route
          path={['/sign-up', '/signup']}
          render={() => <Redirect to={SIGN_UP_PATH} />}
        />

        <Route
          path={AUTH_PATH}
          render={() => <AuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          exact={true}
          path={RESET_PASSWORD_PATH}
          render={() => <AuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          exact={true}
          path={USER_CONFIRM_REGISTRATION_PATH}
          render={() => <AuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          exact={true}
          path={USER_REGISTRATION_SUCCESS_PATH}
          render={() => <AuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          path={API_MARKET_LIST_PATH}
          render={() => <APIMarketRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          path={TICKETS_PATH}
          render={() => <TicketsRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          isSignedIn={isSignedIn}
          path={WELCOME_PATH}
          render={() => <WelcomeRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          isSignedIn={isSignedIn}
          path={NODES_PATH}
          render={() => <DashboardRoutes isSignedIn={isSignedIn} />}
        />

        <PrivateRoute
          isSignedIn={isSignedIn}
          path={[TEAM_PREFIX, TEAM_CONFIRM_MEMBER]}
          render={() => <TeamRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          isSignedIn={isSignedIn}
          path={GUIDE_PATH}
          render={() => <GuideRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          isSignedIn={isSignedIn}
          path={APP_DEPLOY_PATH}
          render={() => <AppDeployRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          isSignedIn={isSignedIn}
          path={APP_INFO_PATH}
          render={() =>
            FEATURE_NEW_NODE_INFORMATION_PAGE_ENABLED ? (
              <NodeInfoRoutes isSignedIn={isSignedIn} />
            ) : (
              <AppInfoRoutes isSignedIn={isSignedIn} />
            )
          }
        />

        <Route
          isSignedIn={isSignedIn}
          path={APPS_PATH}
          render={() => <NodesRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          isSignedIn={isSignedIn}
          path={[SETTINGS_PREFIX, CHANGE_EMAIL_PATH]}
          render={() => <SettingsRoutes isSignedIn={isSignedIn} />}
        />

        <PrivateRoute
          isSignedIn={isSignedIn}
          path={BILLING_PATH}
          render={() => <FinanceRoutes isSignedIn={isSignedIn} />}
        />

        <PrivateRoute
          isSignedIn={isSignedIn}
          component={LoadableNotFoundPaper}
          breadcrumbs={[t('navigation.404')]}
        />
      </Switch>
    </>
  );
}

export { MainRoutes };

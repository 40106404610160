import { APPAPI_APP, APPAPI_ENTERPRISE_APP } from '../const';
import { IExtendedRequestAction } from 'common/types/requestTypes';
import { getAppDetailsURL } from '../utils';
import {
  IApiPricePrameter,
  IAppAddressInfoParams,
  IAppHook,
  IAppListParams,
  IChangeAppNameParams,
  IFetchAppParams,
  IMetricsRealtimeParams,
  IPurgeAppParams,
  IWalletInfo,
} from '../types';
import { PaymentMethodID } from '../types/deployTypes';
import { createAction } from '../../../utils/reduxUtils';
import {
  CPU_DIVIDER,
  DEFAULT_CACHE_SIZE,
  MEM_DIVIDER,
  STORAGE_DIVIDER,
} from 'common/core/const';
import { revertCustomVariableKeyMapping } from '../apiMappings/chartsMappings';
import { Month, Seconds } from '../../../types';
import {
  DEPOSIT_PERIOD_FIELD_NAME,
  PAYMENT_METHOD_FIELD_NAME,
  PaymentMethod,
} from '../../billing/const';

interface IPaymentMethod {
  paymentMethod: PaymentMethod;
  id?: string;
}

export interface IDepositPaymentMethod extends IPaymentMethod {}
export interface IDailyPaymentMethod extends IPaymentMethod {}

export interface ICreateAppInputPayload {
  advancedMode: boolean;
  name: string;
  paymentMethod: PaymentMethodID;
  cpu: number;
  memory: number;
  storage: number;
  cluster: {
    id: string;
  };
  customValues?: { [key: string]: string };
  [PAYMENT_METHOD_FIELD_NAME]: IDepositPaymentMethod;
  period: Month;
  replicas?: number;
}

export interface ICreateAppPayload
  extends Omit<ICreateAppInputPayload, 'advancedMode'> {
  teamId: string;
  isEnterprise: boolean;
  repository: string;
  version: string;
  chartName: string;
  chartDescription: string;
  chartIconUrl: string;
  appVersion: string;
  depositPaymentMethod: IDepositPaymentMethod;
  period: Month;
}

export interface IFetchAppHook {
  args?: {};
  name: string;
  appId: string;
}

const AppsActionTypes = {
  APP_CREATE: 'APP_CREATE',
  APP_CREATE_WITH_OPTIONS: 'APP_CREATE_WITH_OPTIONS',
  APP_CREATE_SUCCESS: 'APP_CREATE_SUCCESS',
  APP_CREATE_ERROR: 'APP_CREATE_ERROR',
  APP_CREATE_RESET: 'APP_CREATE_RESET',

  APPS_FETCH: 'APPS_FETCH',
  APPS_FETCH_SUCCESS: 'APPS_FETCH_SUCCESS',
  APPS_FETCH_ERROR: 'APPS_FETCH_ERROR',

  APP_LAUNCH: 'APP_LAUNCH',
  APP_LAUNCH_SUCCESS: 'APP_LAUNCH_SUCCESS',
  APP_LAUNCH_ERROR: 'APP_LAUNCH_ERROR',
  APP_LAUNCH_RESET: 'APP_LAUNCH_RESET',

  APP_RESET: 'APP_RESET',
  APP_RESET_SUCCESS: 'APP_RESET_SUCCESS',
  APP_RESET_ERROR: 'APP_RESET_ERROR',
  APP_RESET_RESET: 'APP_RESET_RESET',

  APPS_FETCH_ALL_TEAMS: 'APPS_FETCH_ALL_TEAMS',

  CALCULATE_APP_PRICE: 'CALCULATE_APP_PRICE',
  CALCULATE_APP_PRICE_SUCCESS: 'CALCULATE_APP_PRICE_SUCCESS',
  CALCULATE_APP_PRICE_ABORT: 'CALCULATE_APP_PRICE_ABORT',

  APP_DELETE: 'APP_DELETE',
  APP_DELETE_RESET: 'APP_DELETE_RESET',

  APP_UPDATE: 'APP_UPDATE',

  APP_REALTIME: 'APP_REALTIME',
  APP_REALTIME_SUCCESS: 'APP_REALTIME_SUCCESS',
  APP_REALTIME_ERROR: 'APP_REALTIME_ERROR',

  APP_DETAILS: 'APP_DETAILS',
  APP_DETAILS_SUCCESS: 'APP_DETAILS_SUCCESS',
  APP_DETAILS_ERROR: 'APP_DETAILS_ERROR',

  APP_ADDRESS_INFO: 'APP_ADDRESS_INFO',
  APP_ADDRESS_INFO_RESET: 'APP_ADDRESS_INFO_RESET',
  APP_CANCEL_EXTEND: 'APP_CANCEL_EXTEND',
  APP_CANCEL_EXTEND_SUCCESS: 'APP_CANCEL_EXTEND_SUCCESS',

  APP_HOOK_DATA_FETCH: 'APP_HOOK_DATA_FETCH',
  APP_HOOK: 'APP_HOOK',

  APP_ENTERPRISE_HOOK: 'APP_ENTERPRISE_HOOK',
  APP_ENTERPRISE_HOOK_SUCCESS: 'APP_ENTERPRISE_HOOK_SUCCESS',

  RESET_APP_HOOK: 'RESET_APP_HOOK',

  APP_HOOKS_RESET: 'APP_HOOKS_RESET',
  APP_HOOK_RESET: 'APP_HOOK_RESET',
  APP_HOOK_ABORT: 'APP_HOOK_ABORT',

  APP_FETCH_RENCENT_ALERTS: 'APP_FETCH_RENCENT_ALERTS',
  APP_FETCH_RENCENT_ALERTS_SUCCESS: 'APP_FETCH_RENCENT_ALERTS_SUCCESS',
  APP_FETCH_RENCENT_ALERTS_ERROR: 'APP_FETCH_RENCENT_ALERTS_ERROR',

  APP_FETCH_RPC_UPTIME: 'APP_FETCH_RPC_UPTIME',
  APP_FETCH_RPC_UPTIME_SUCCESS: 'APP_FETCH_RPC_UPTIME_SUCCESS',
  APP_FETCH_RPC_UPTIME_ERROR: 'APP_FETCH_RPC_UPTIME_ERROR',

  APP_CHANGE_NAME: 'APP_CHANGE_NAME',
  APP_CHANGE_NAME_SUCCESS: 'APP_CHANGE_NAME_SUCCESS',
  APP_CHANGE_NAME_ERROR: 'APP_CHANGE_NAME_ERROR',
  APP_CHANGE_NAME_RESET: 'APP_CHANGE_NAME_RESET',

  WALLET_INFO: 'WALLET_INFO',
  WALLET_INFO_RESET: 'WALLET_INFO_RESET',
};

interface IAppHookCall extends IAppHook {
  teamId?: string;
}

interface IAppHookRPCCallParams {
  payload?: object;
  appId: string;
  baseURL: string;
  container: string;
  label: string;
  endpoint: string;
}

const APP_CACHE_TIME: Seconds = 10;

const AppsActions = {
  fetchApps: (
    { teamId: team_id }: IAppListParams,
    meta = {},
  ): IExtendedRequestAction => ({
    type: AppsActionTypes.APPS_FETCH,
    request: {
      url: APPAPI_APP,
      method: 'GET',
      params: {
        team_id,
      },
    },
    meta: {
      cacheKey: team_id,
      cache: APP_CACHE_TIME,
      cacheSize: DEFAULT_CACHE_SIZE,
      ...meta,
    },
  }),
  launchApp: (
    id: string,
    team_id: string,
    customValues?: { [key: string]: string }[],
  ): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_LAUNCH,
    request: {
      url: `${APPAPI_APP}/${id}/launch`,
      method: 'POST',
      data: {
        team_id,
        ...(customValues && { custom_values: customValues }),
      },
    },
  }),
  resetLaunch: () => createAction(AppsActionTypes.APP_LAUNCH_RESET),
  resetApp: ({
    id,
    teamId,
    customValues,
    replicas,
  }: {
    id: string;
    teamId: string;
    customValues: any;
    replicas: number;
  }): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_RESET,
    request: {
      url: `${APPAPI_APP}/${id}/reset`,
      method: 'POST',
      data: {
        team_id: teamId,
        replicas_number: replicas,
        custom_values: customValues
          ? Object.keys(customValues).map(key => {
              return {
                key: revertCustomVariableKeyMapping(key),
                value: (customValues && customValues[key as any]) || '',
              };
            })
          : [],
      },
    },
    meta: {
      appId: id,
    },
  }),
  resetAppReset: () => createAction(AppsActionTypes.APP_RESET_RESET),
  createEnterpriseApp: (
    payload: ICreateAppPayload,
    redirectOnSuccess?: string,
  ): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_CREATE,
    request: {
      url: APPAPI_ENTERPRISE_APP,
      method: 'POST',
      data: {
        name: payload.name,
        cluster_id: payload.cluster.id,
        namespace_name: '',
        cpu_limit: Number(payload.cpu) * CPU_DIVIDER,
        mem_limit: Number(payload.memory) * MEM_DIVIDER,
        storage_limit: Number(payload.storage) * STORAGE_DIVIDER,
        team_id: payload.teamId,
        chart_name: payload.chartName,
        chart_repo: payload.repository,
        chart_ver: payload.version,
        chart_desc: payload.chartDescription,
        chart_icon_url: payload.chartIconUrl,
        app_version: payload.appVersion,
        instance_type: '',
        enterprise: payload.isEnterprise,
        custom_values: payload.customValues
          ? Object.keys(payload.customValues).map(key => {
              return {
                key: revertCustomVariableKeyMapping(key),
                value:
                  (payload.customValues && payload.customValues[key as any]) ||
                  '',
              };
            })
          : [],
      },
    },
    meta: {
      redirectOnSuccess,
    },
  }),
  createAppWithOptions: (
    payload: ICreateAppPayload,
    redirectOnSuccess?: string,
  ) =>
    createAction(AppsActionTypes.APP_CREATE_WITH_OPTIONS, {
      payload,
      redirectOnSuccess,
    }),
  createApp: (
    payload: ICreateAppPayload,
    redirectOnSuccess?: string,
    meta = {},
  ): IExtendedRequestAction => {
    const pre_pay_info = (() => {
      if (
        payload.depositPaymentMethod.paymentMethod === PaymentMethod.CREDIT_CARD
      ) {
        return undefined;
      }

      return {
        pay_method:
          payload.depositPaymentMethod.paymentMethod ===
          PaymentMethod.DEPOSIT_CREDIT_CARD
            ? PaymentMethod.CREDIT_CARD
            : payload.depositPaymentMethod.paymentMethod,
        ...(payload.depositPaymentMethod.paymentMethod ===
        PaymentMethod.DEPOSIT_CREDIT_CARD
          ? {}
          : { period: payload[DEPOSIT_PERIOD_FIELD_NAME] }),
        ...(payload.depositPaymentMethod.id
          ? { pre_pay_id: payload.depositPaymentMethod.id }
          : {}),
      };
    })();

    return {
      type: AppsActionTypes.APP_CREATE,
      request: {
        url: APPAPI_APP,
        method: 'POST',
        data: {
          pre_pay_info,
          name: payload.name,
          cluster_id: payload.cluster.id,
          namespace_name: '',
          cpu_limit: Number(payload.cpu) * CPU_DIVIDER,
          mem_limit: Number(payload.memory) * MEM_DIVIDER,
          storage_limit: Number(payload.storage) * STORAGE_DIVIDER,
          team_id: payload.teamId,
          chart_name: payload.chartName,
          chart_repo: payload.repository,
          chart_ver: payload.version,
          chart_desc: payload.chartDescription,
          chart_icon_url: payload.chartIconUrl,
          app_version: payload.appVersion,
          instance_type: '',
          enterprise: payload.isEnterprise,
          replicas_number: payload.replicas,
          custom_values: payload.customValues
            ? Object.keys(payload.customValues).map(key => {
                return {
                  key: revertCustomVariableKeyMapping(key),
                  value:
                    (payload.customValues &&
                      payload.customValues[key as any]) ||
                    '',
                };
              })
            : [],
        },
      },
      meta: {
        redirectOnSuccess,
        ...meta,
      },
    };
  },
  createAppWithAnkrDailyPayment: (
    // TODO: remove after ANKR native deprication
    payload: ICreateAppPayload,
    redirectOnSuccess?: string,
  ): IExtendedRequestAction => {
    return {
      type: AppsActionTypes.APP_CREATE,
      request: {
        url: APPAPI_APP,
        method: 'POST',
        data: {
          name: payload.name,
          cluster_id: payload.cluster.id,
          namespace_name: '',
          cpu_limit: Number(payload.cpu) * CPU_DIVIDER,
          mem_limit: Number(payload.memory) * MEM_DIVIDER,
          storage_limit: Number(payload.storage) * STORAGE_DIVIDER,
          team_id: payload.teamId,
          chart_name: payload.chartName,
          chart_repo: payload.repository,
          chart_ver: payload.version,
          chart_desc: payload.chartDescription,
          chart_icon_url: payload.chartIconUrl,
          app_version: payload.appVersion,
          instance_type: '',
          enterprise: payload.isEnterprise,
          custom_values: payload.customValues
            ? Object.keys(payload.customValues).map(key => {
                return {
                  key: revertCustomVariableKeyMapping(key),
                  value:
                    (payload.customValues &&
                      payload.customValues[key as any]) ||
                    '',
                };
              })
            : [],
        },
      },
      meta: {
        redirectOnSuccess,
      },
    };
  },
  calculatePrice: (
    clusterId: string,
    data: IApiPricePrameter,
    abort = false,
    meta = {},
  ): IExtendedRequestAction => ({
    type: abort
      ? AppsActionTypes.CALCULATE_APP_PRICE_ABORT
      : AppsActionTypes.CALCULATE_APP_PRICE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/cluster/v2/cluster`,
      method: 'GET',
      params: data,
      // @ts-ignore
      clusterId: clusterId,
    },
    meta: {
      cacheKey: `${data.node_kind}+${data['resource.memory']}+${data['resource.cpu']}+${data['resource.storage']}+${clusterId}`,
      cache: APP_CACHE_TIME,
      cacheSize: DEFAULT_CACHE_SIZE,
      ...meta,
    },
  }),
  resetCreateApp: () => createAction(AppsActionTypes.APP_CREATE_RESET),
  fetchAppDetails: ({
    appId,
    teamId,
  }: IFetchAppParams): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_DETAILS,
    request: {
      url: getAppDetailsURL(appId, teamId),
      method: 'GET',
    },
    meta: { id: appId },
  }),

  fetchAppAddressInfo: ({
    teamId,
    appId,
  }: IAppAddressInfoParams): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_ADDRESS_INFO,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${teamId}/app_addr_infos/${appId}`,
      method: 'GET',
    },
    meta: { id: appId },
  }),

  fetchAppAddressInfoReset: () =>
    createAction(AppsActionTypes.APP_ADDRESS_INFO_RESET),

  cancelAppExtend: ({
    teamId,
    appId,
  }: IAppAddressInfoParams): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_CANCEL_EXTEND,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${teamId}/cancel_extend_order`,
      method: 'POST',
      data: { appId: appId },
    },
    meta: { id: appId, teamId },
  }),

  fetchRealtimeMetrics: ({
    clusterId,
    namespaceId,
    appId,
  }: IMetricsRealtimeParams): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_REALTIME,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/dccn-metrics/metrics/v1/realtime`,
      method: 'POST',
      data: { appId: appId, clusterId: clusterId, namespaceId: namespaceId },
      params: {},
    },
  }),

  changeAppName: ({
    appId,
    name,
    teamId,
  }: IChangeAppNameParams): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_CHANGE_NAME,
    request: {
      url: `/app/v2/app/${appId}/change_app_name`,
      method: 'POST',
      data: { name, team_id: teamId },
    },
  }),
  changeAppNameReset: () => createAction(AppsActionTypes.APP_CHANGE_NAME_RESET),

  deleteApp: ({ appId, teamId }: IPurgeAppParams): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_DELETE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/app/v2/app/${appId}?team_id=${teamId}`,
      method: 'DELETE',
    },
    meta: {
      id: appId,
    },
  }),
  deleteAppReset: () => createAction(AppsActionTypes.APP_DELETE_RESET),

  fetchAppHookData: (params: IFetchAppHook) =>
    createAction(AppsActionTypes.APP_HOOK_DATA_FETCH, params),
  appHookCall: (
    payload: IAppHook<any>,
    appId: string,
    teamId?: string,
    abort = false,
  ): IExtendedRequestAction<IAppHookCall> => {
    return {
      type: abort ? AppsActionTypes.APP_HOOK_ABORT : AppsActionTypes.APP_HOOK,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/app/v2/app/${appId}/hook`,
        method: 'POST',
        data: {
          teamId,
          ...payload,
        },
        // @ts-ignore
        meta: {
          appId,
          label: payload.label,
          container: payload.container,
        },
      },
      meta: {
        appId,

        label: payload.label,
        container: payload.container,
      },
    };
  },
  fetchAppEnterpriseHookId: (
    payload: IAppHook<any>,
    appId: string,
    teamId?: string,
  ): IExtendedRequestAction => {
    return {
      type: AppsActionTypes.APP_ENTERPRISE_HOOK,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/app/v2/app/${appId}/hook`,
        method: 'POST',
        data: {
          teamId,
          ...payload,
        },
        // @ts-ignore
        meta: {
          appId,
          label: payload.label,
          container: payload.container,
        },
      },
      meta: {
        appId,

        label: payload.label,
        container: payload.container,
      },
    };
  },
  appEnterpriseHookCall: (
    payload: IAppHook<any>,
    hookId: string,
    appId: string,
    teamId?: string,
    abort = false,
  ): IExtendedRequestAction => {
    return {
      type: abort ? AppsActionTypes.APP_HOOK_ABORT : AppsActionTypes.APP_HOOK,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/app/v2/enterprise-app/${appId}/hook/${hookId}/result`,
        method: 'GET',
        params: {
          team_id: teamId,
        },
        data: {
          teamId,
          ...payload,
        },
        // @ts-ignore
        meta: {
          appId,
          label: payload.label,
          container: payload.container,
        },
      },
      meta: {
        appId,

        label: payload.label,
        container: payload.container,
      },
    };
  },

  fetchRecentAlerts: (appId: string): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_FETCH_RENCENT_ALERTS,
    request: {
      url: `${process.env.REACT_APP_API_ACMS}/aci-alerts/v1alpha/alarm/${appId}`,
      params: {},
      method: 'GET',
    },
  }),

  fetchRPCUptime: (appId: string, day: string): IExtendedRequestAction => ({
    type: AppsActionTypes.APP_FETCH_RPC_UPTIME,
    request: {
      url: `${process.env.REACT_APP_API_ACMS}/aci-alerts/v1alpha/RPC_uptime/${appId}/${day}`,
      params: {},
      method: 'GET',
    },
  }),

  resetAppHook: (hookName: string) =>
    createAction(AppsActionTypes.APP_HOOK_RESET, hookName),

  resetAppHooks: () => createAction(AppsActionTypes.APP_HOOKS_RESET),
  setWalletInfo: (walletInfo: IWalletInfo) =>
    createAction(AppsActionTypes.WALLET_INFO, walletInfo),
  resetWalletInfo: () => createAction(AppsActionTypes.WALLET_INFO_RESET),
};

export { AppsActions, AppsActionTypes, getAppDetailsURL };

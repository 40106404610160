/* eslint-disable @typescript-eslint/no-empty-function */
import { AnyAction } from 'redux';
import { END, eventChannel } from 'redux-saga';
import { put, race, take, takeEvery } from 'redux-saga/effects';
import {
  SnackbarsActions,
  SnackbarsActionsTypes,
} from 'common/store/actions/SnackbarsActions';
import { ISnackbar } from 'common/store/reducers/snackbarsReducer';

import { SNACKBAR_AUTO_HIDE_DURATION } from 'common/core/const';

let lastNotificationId = 0;

function* showSnackbar(action: AnyAction) {
  const { message, options: optionsOrigin } = action.payload;
  const options = { ...optionsOrigin };

  const originalOnClose = options.onClose;

  const channel = eventChannel(emitter => {
    const onClose = () => {
      if (originalOnClose) {
        originalOnClose();
      }
      emitter(true);
      emitter(END);
    };
    setImmediate(() => emitter(onClose));
    // tslint:disable-next-line
    return () => {};
  });

  const handleClose = yield take(channel);
  const snackbar: ISnackbar = {
    ...options,
    message,
    key: options.key || (++lastNotificationId).toString(),
    autoHideDuration:
      options.autoHideDuration || options.persist
        ? undefined
        : SNACKBAR_AUTO_HIDE_DURATION,
    onClose: handleClose,
  };

  yield put(SnackbarsActions.showSnackbarInternal(snackbar));

  try {
    yield race([
      take(channel),
      take(
        (filterAction: any) =>
          filterAction.type === SnackbarsActionsTypes.REMOVE_SNACKBAR &&
          snackbar.key === filterAction.payload,
      ),
    ]);
  } finally {
    if (!snackbar.persist) {
      yield put(SnackbarsActions.removeSnackbarInternal(snackbar.key));
    }
  }
}

function* removeSnackbar(action: AnyAction) {
  yield put(SnackbarsActions.removeSnackbarInternal(action.payload));
}

function* snackbarsSaga() {
  yield takeEvery(SnackbarsActionsTypes.SHOW_SNACKBAR, showSnackbar);
  yield takeEvery(SnackbarsActionsTypes.REMOVE_SNACKBAR, removeSnackbar);
}

export { snackbarsSaga };

import * as Sentry from '@sentry/browser';
import {
  ChartsActions,
  ChartsActionTypes,
} from 'common/modules/apps/actions/ChartsActions';
import { putResolve, fork, put, take, select } from 'redux-saga/effects';
import { SnackbarsActions } from 'common/store/actions/SnackbarsActions';
import { AxiosResponse } from 'axios';
import { t } from 'common/utils/intl';
import { downloadBinaryFile } from 'common/utils/files';
import { IStoreState } from 'store/reducers';
import {
  IApiDownloadChart,
  IChartID,
} from 'common/modules/apps/types/chartsTypes';
import { getCurrentTeam } from 'common/modules/teams/reducers/teamReducer';

function showError() {
  return put(
    SnackbarsActions.showSnackbar(t('download-chart-link.file-not-found'), {
      key: 'CHART_DOWNLOAD_ERROR',
      variant: 'error',
    }),
  );
}

function* downloadChartRequest(payload: IChartID) {
  try {
    const teamId: string = yield select((state: IStoreState) => {
      return state.team.currentTeamId;
    });

    const response: AxiosResponse<IApiDownloadChart> = yield putResolve(
      ChartsActions.downloadChart(
        payload.repository,
        payload.name,
        payload.version,
        teamId,
      ),
    );

    const chart_file = response?.data?.chart_file;

    if (chart_file) {
      downloadBinaryFile(
        atob(chart_file),
        `${payload.name}_${payload.version}.tgz`,
      );
    } else {
      yield showError();
    }
  } catch (error) {
    Sentry.configureScope(scope => {
      scope.setTag('scope', 'API');
      scope.setExtra('download_request', payload);
      Sentry.captureException(error);
    });
    yield showError();
  }
}

export function* downloadChart() {
  while (true) {
    try {
      const { payload }: { payload: IChartID } = yield take(
        ChartsActionTypes.CHART_DOWNLOAD_INIT,
      );

      yield fork(downloadChartRequest, payload);
    } catch (error) {
      Sentry.captureException(error);
      yield showError();
    }
  }
}

export function* updateCharts() {
  while (true) {
    yield take([ChartsActionTypes.CHART_CREATE_SUCCESS]);
    const teamId: string = yield select((state: IStoreState) => {
      return state.team.currentTeamId;
    });
    const { isEnterprise, level } = yield select((state: IStoreState) => {
      return getCurrentTeam(state.team);
    });
    yield put(
      ChartsActions.fetchCharts({
        repository: 'user',
        teamId,
        isEnterprise,
        level,
      }),
    );
  }
}

import React from 'react';
import {
  AVALANCHE_V_IMPORT_STAKER,
  AVALANCHE_V_NETWORK_ID,
  AVALANCHE_V_STAKER_CRT,
  AVALANCHE_V_STAKER_KEY,
  AvalancheVHooks,
  avalancheVNodeName,
} from './AvalancheVConst';
import {
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/AvalancheV.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableTabs = loadable(
  async () =>
    import('./manage/AvalancheVTabs').then(module => module.AvalancheVTabs),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/AvalancheVCustomValues').then(
      module => module.AvalancheVCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseDownload(response: IHookResponse): IHookResponse {
  return {
    ...response,
    data: safeParseJSON(response.data.result ?? '{}'),
  };
}

export const avalancheVConfig: INodeConfig = {
  chartName: avalancheVNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => {
    return {
      [AVALANCHE_V_STAKER_CRT]:
        customValues && customValues[AVALANCHE_V_STAKER_CRT]
          ? customValues[AVALANCHE_V_STAKER_CRT].replace('\n', '\\n')
          : '',
      [AVALANCHE_V_STAKER_KEY]:
        customValues && customValues[AVALANCHE_V_STAKER_KEY]
          ? customValues[AVALANCHE_V_STAKER_KEY].replace('\n', '\\n')
          : '',
    };
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      [AVALANCHE_V_NETWORK_ID]: 'mainnet',
      [AVALANCHE_V_IMPORT_STAKER]: 'false',
    },
  }),

  hideCustomVariableDivider: true,
  tabsComponent: LoadableTabs,
  interceptors: {
    avalanche: {
      [AvalancheVHooks.isBootstrapped]: defaultParse,
      [AvalancheVHooks.isValidatorActive]: defaultParse,
      [AvalancheVHooks.getValidatorName]: defaultParse,
      [AvalancheVHooks.getNetworkName]: defaultParse,
      [AvalancheVHooks.getStakerBackup]: parseDownload,
      [AvalancheVHooks.isBackedUp]: defaultParse,
    },
  },
};

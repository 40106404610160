import { put, select, takeLatest } from 'redux-saga/effects';
import { TwoFactorActions } from './TwoFactorActions';
import {
  isUserAuthenticated,
  ITokensState,
} from 'auth/store/reducers/tokensReducer';
import { REHYDRATE } from 'redux-persist';
import { UserActionTypes } from 'auth/store/actions/UserActions';

function* fetchTwoFactorSaga() {
  const isAuthenticated = yield select((state: { tokens: ITokensState }) => {
    return isUserAuthenticated(state.tokens);
  });

  if (isAuthenticated) {
    yield put(TwoFactorActions.getStatus());
    yield put(TwoFactorActions.getReminder());
  }
}

export function* twoFactorSaga() {
  yield takeLatest(
    [REHYDRATE, UserActionTypes.SIGNIN_SUCCESS],
    fetchTwoFactorSaga,
  );
}

import React from 'react';
import {
  BINANCE_CHAIN_ENABLE_SNAPSHOT,
  BINANCE_CHAIN_INDEXER,
  BINANCE_CHAIN_NETWORK,
  BINANCE_CHAIN_SYNCTYPE,
  binanceChain,
  BinanceChainHooks,
} from './binanceChainConst';
import {
  ICreateInitialValueParams,
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import localesUS from './locales/binance-chain.en-US.json';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';

const LoadableManage = loadable(
  async () =>
    import('./manage/BinanceChainInfo').then(module => module.BinanceChainInfo),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/BinanceCustomValues').then(
      module => module.BinanceCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      currentHeight: Number(data.currentHeight ?? 0) ?? 0,
      latestHeight: Number(data.latestHeight ?? 0) ?? 0,
      status: data.syncing === 'true',
    },
  };
}

export const binanceChainConfig: INodeConfig = {
  chartName: binanceChain,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  progressHook: BinanceChainHooks.GET_PROGRESS,
  progressHookEstimate: 12,
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => ({
    [BINANCE_CHAIN_INDEXER]: Boolean(
      customValues && (customValues[BINANCE_CHAIN_INDEXER] ?? true),
    ).toString(),
    [BINANCE_CHAIN_ENABLE_SNAPSHOT]: Boolean(
      customValues && (customValues[BINANCE_CHAIN_ENABLE_SNAPSHOT] ?? true),
    ).toString(),
  }),
  getDefaultCustomValues: ({
    appList,
    chartName,
    userName,
  }: ICreateInitialValueParams) => ({
    customValues: {
      [BINANCE_CHAIN_NETWORK]: 'mainnet',
      [BINANCE_CHAIN_SYNCTYPE]: 'fast',
    },
  }),

  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'init-binance': {
      [BinanceChainHooks.GET_PROGRESS]: defaultParse,
    },
    'binance-node': {
      [BinanceChainHooks.GET_CHAIN_INFO]: defaultParse,
      [BinanceChainHooks.GET_STATUS]: parseStatus,
    },
  },
};

import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { requestInactive, RequestStatus } from 'common/utils/requestStatus';
import { IExtendedAxiosResponse } from 'common/types';
import { BigNumber } from 'bignumber.js';
import { StkrActionTypes } from './StkrActions';
import { IProviderRewards, IProviderRewardsResponse } from './StkrTypes';

export interface IAnkrStakingState {
  rewards: IProviderRewards | undefined;
  getRewardsStatus: RequestStatus;
}

const initialState: IAnkrStakingState = {
  rewards: undefined,
  getRewardsStatus: requestInactive(),
};

export const StkrReducer = createReducer(initialState, {
  ...createAPIReducer<
    IAnkrStakingState,
    IExtendedAxiosResponse<IProviderRewardsResponse>
  >(StkrActionTypes.GET_REWARDS, 'getRewardsStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        rewards: action.data
          ? {
              globalRatio: action.data.globalRatio,
              rewards: new BigNumber(action.data.rewards),
            }
          : undefined,
      };
    },
  }),
});

import { delay, put, select, takeEvery, putResolve } from 'redux-saga/effects';
import {
  AppsActions,
  AppsActionTypes,
  ICreateAppPayload,
} from 'common/modules/apps/actions/AppsActions';
import { PayActions } from 'common/modules/billing/actions/PayActions';
import { PaymentMethod } from 'common/modules/billing/const';
import { SendRequestResponse } from 'common/types';
import { throwIfContainsError } from 'common/utils/throwIfContainsError';
import { ITeamState } from 'common/modules/teams/reducers/teamReducer';
import { IExtendedAxiosResponse } from 'common/types';
import { StkrActions } from '../../Nodes/nodes/Stkr/store/StkrActions';
import { Action } from 'redux-actions';

const REFRESH_DELAY = 200;
const ANKR_STAKING_CHART_NAME = 'ankr-eth2';
const CUSTOM_VALUES_PREFIX = 'ankrCustomValues___';

const sidecarIdField = `${CUSTOM_VALUES_PREFIX}id`;
const networkField = `${CUSTOM_VALUES_PREFIX}network`;
const accessTokenFeld = `${CUSTOM_VALUES_PREFIX}accessToken`;

function* appResetSaga(action: IExtendedAxiosResponse<any>) {
  const appId = action.meta.appId;

  const { currentTeamId: teamId } = yield select(
    (state: { team: ITeamState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
      };
    },
  );
  yield delay(REFRESH_DELAY);
  yield put(AppsActions.fetchAppDetails({ appId, teamId }));
}

export function* createSidecar(
  action: Action<{
    payload: ICreateAppPayload;
    redirectOnSuccess?: string;
  }>,
) {
  const {
    payload: { payload },
  } = action;
  const chartName = payload.chartName;

  if (payload.customValues && chartName === ANKR_STAKING_CHART_NAME) {
    let sidecarId = payload.customValues[sidecarIdField];

    if (!sidecarId) {
      const network = payload.customValues[networkField];
      const accessToken = payload.customValues[accessTokenFeld];

      const createSidecarResponse: SendRequestResponse<{
        id: string;
      }> = throwIfContainsError(
        yield putResolve(
          StkrActions.createSidecar({
            appName: payload.name,
            network,
            accessToken,
          }),
        ),
      );

      sidecarId = createSidecarResponse.data.id;
      payload.customValues[sidecarIdField] = sidecarId;

      const getCertsResponse: SendRequestResponse<any> = throwIfContainsError(
        yield putResolve(
          StkrActions.getCerts({
            sidecarId,
            network,
            accessToken,
          }),
        ),
      );

      const certs = getCertsResponse.data;
      payload.customValues = {
        ...payload.customValues,
        ...certs,
      };
    }
  }

  if (payload.isEnterprise) {
    throwIfContainsError(yield put(AppsActions.createEnterpriseApp(payload)));
  } else if (
    payload.depositPaymentMethod.paymentMethod ===
    PaymentMethod.DEPOSIT_CREDIT_CARD
  ) {
    yield put(PayActions.createAppByDepositCard(payload));
  } else if (
    payload.depositPaymentMethod.paymentMethod === PaymentMethod.CREDIT_CARD
  ) {
    yield put(PayActions.createAppByDailyCard(payload));
  } else if (
    payload.depositPaymentMethod.paymentMethod === PaymentMethod.ANKR
  ) {
    yield put(PayActions.createAppByDailyAnkrNative(payload));
  } else if (
    payload.depositPaymentMethod.paymentMethod === PaymentMethod.ANKR_CREDIT
  ) {
    yield put(PayActions.createAppByAnkrCredit(payload));
  } else {
    throwIfContainsError(yield put(AppsActions.createApp(payload)));
  }
}

export function* nodesUISaga() {
  yield takeEvery(AppsActionTypes.APP_RESET_SUCCESS, appResetSaga);
}

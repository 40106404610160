export const avalancheNodeName = 'avalanche';

export enum AvalancheHooks {
  getApiStatus = 'apphook1=get-api-status',
  getBootstrapStatus = 'apphook2=get-bootstrap-status',
}

export const AVALANCHE_API_ADMIN_ENABLED =
  'ankrCustomValues___api_admin_enabled';
export const AVALANCHE_API_IPCS_ENABLED = 'ankrCustomValues___api_ipcs_enabled';
export const AVALANCHE_API_KEYSTORE_ENABLED =
  'ankrCustomValues___api_keystore_enabled';
export const AVALANCHE_API_METRICS_ENABLED =
  'ankrCustomValues___api_metrics_enabled';
export const AVALANCHE_SNOW_QUORUM_SIZE = 'ankrCustomValues___snow_quorum_size';
export const AVALANCHE_SNOW_SAMPLE_SIZE = 'ankrCustomValues___snow_sample_size';

export const AVALANCHE_DOCS_HREF =
  'https://docs.ankr.com/enteprise-solutions/avalanche';

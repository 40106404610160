import { safeParseJSON } from 'common/utils/string';

import { convertSecondsToTimeTrack } from 'common/utils/time';
import { IHookResponse } from 'common/utils/convertResponseToError';

enum RewardDestination {
  staked = 'staked',
  stash = 'stash',
  controller = 'controller',
}

const BLOCK_PER_SECOND = 6;

const KUSAMA_QUERY_ALL_DEFAULT_VALUE = {
  commission: 0,
  nodeStatus: 'syncing',
  totalStake: 0,
  unbondingAmount: 0,
  remainingTimeSeconds: convertSecondsToTimeTrack(0),

  nodeName: '',
  balances: {
    stash: {
      balance: 0,
      minDeposit: 0.1,
    },
    controller: {
      balance: 0,
      minDeposit: 0.1,
    },
  },
  rewardDestination: RewardDestination.staked,
};

export function parseKusamaQueryAll(response: IHookResponse): IHookResponse {
  const data = response.data?.result;
  const resultObj = safeParseJSON(data ?? '{}');

  try {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const reward_destination = Object.keys(
      resultObj?.data?.reward_destination,
    )?.[0];
    return {
      ...response,
      data: {
        commission: resultObj?.data?.commission
          ? Number(resultObj.data.commission)
          : 0,
        nodeStatus: resultObj?.data?.status || 'syncing',
        totalStake: Number(resultObj?.data?.bond_info?.bonded ?? 0),
        unbondingAmount: Number(
          resultObj?.data?.bond_info?.unbonding?.value ?? 0,
        ),
        remainingTimeSeconds: convertSecondsToTimeTrack(
          Number(resultObj?.data?.bond_info.unbonding?.remaining_blocks ?? 0) *
            BLOCK_PER_SECOND,
        ),

        nodeName: resultObj?.data?.node_name || '',
        balances: {
          stash: {
            balance: Number(
              resultObj?.data?.balances?.stash?.transferrable ?? 0,
            ),
            minDeposit: 0.1,
          },
          controller: {
            balance: Number(
              resultObj?.data?.balances?.controller?.transferrable ?? 0,
            ),
            minDeposit: 0.1,
          },
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        rewardDestination: reward_destination ?? RewardDestination.staked,
      },
    };
  } catch (err) {
    return { ...response, data: KUSAMA_QUERY_ALL_DEFAULT_VALUE };
  }
}
export function parseKusamaKeystore(response: IHookResponse): IHookResponse {
  const data = response.data?.result;

  const resultObj = safeParseJSON(data ?? '{}');

  return {
    ...response,

    data: {
      accounts: {
        stash: {
          seed: resultObj?.data?.accounts?.stash?.encoded ?? '',
          address: resultObj?.data?.accounts?.stash?.address ?? '',
          type: resultObj?.data?.accounts?.stash?.encoding?.type ?? '',
          download: resultObj?.data?.accounts?.stash ?? {},
        },
        controller: {
          seed: resultObj?.data?.accounts?.controller?.encoded ?? '',
          address: resultObj?.data?.accounts?.controller?.address ?? '',
          type: resultObj?.data?.accounts?.controller?.encoding?.type ?? '',
          download: resultObj?.data?.accounts?.controller ?? {},
        },
      },
    },
  };
}

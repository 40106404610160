import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { requestInactive, RequestStatus } from 'common/utils/requestStatus';
import {
  mapTeams,
  mapTeamMembers,
  mapTeamRoles,
  mapTeam,
} from '../apiMappings/teamMappings';
import {
  IApiTeam,
  IApiTeamMemberResponse,
  IApiTeamResponse,
  ITeam,
  ITeamMember,
  ITeamRole,
} from '../teamTypes';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { TeamActionTypes } from '../actions/TeamActions';
import { IExtendedAxiosResponse } from '../../../types';

export function getCurrentTeam(state: ITeamState) {
  return state.teams.find(team => team.id === state.currentTeamId);
}

export function isEnterpriseModeAvailable(state: ITeamState) {
  return state.teams.some(team => team.isEnterprise);
}

export function isPersonalModeAvailable(state: ITeamState) {
  return state.teams.some(team => !team.isEnterprise);
}

export function getTeamMembers(state: ITeamState) {
  const teamMembers =
    state.currentTeamId && state.teamMembers[state.currentTeamId];
  return teamMembers || [];
}

export interface ITeamState {
  teams: ITeam[];
  currentTeamId?: string;
  teamMembers: { [keys: string]: ITeamMember[] };
  teamRoles: ITeamRole[];
  fetchTeamsStatus: RequestStatus;
  assignTeamStatus: RequestStatus;
  fetchTeamRolesStatus: RequestStatus;
  createTeamStatus: RequestStatus;
  fetchTeamDetailStatus: RequestStatus;
  inviteTeamMemberStatus: RequestStatus;
  changeTeamNameStatus: RequestStatus;
  updateTeamMemberStatus: RequestStatus;
  deleteTeamMemberStatus: RequestStatus;
  confirmTeamMemberStatus: RequestStatus;
  resendInvitationEmailStatus: RequestStatus;
}

const teamReducerInitialStateUnstored: Omit<
  ITeamState,
  'teams' | 'teamRoles' | 'teamMembers'
> = {
  createTeamStatus: requestInactive(),
  fetchTeamsStatus: requestInactive(),
  assignTeamStatus: requestInactive(),
  fetchTeamRolesStatus: requestInactive(),
  fetchTeamDetailStatus: requestInactive(),
  inviteTeamMemberStatus: requestInactive(),
  changeTeamNameStatus: requestInactive(),
  updateTeamMemberStatus: requestInactive(),
  deleteTeamMemberStatus: requestInactive(),
  confirmTeamMemberStatus: requestInactive(),
  resendInvitationEmailStatus: requestInactive(),
};
const initialState: ITeamState = {
  ...teamReducerInitialStateUnstored,
  teams: [],
  teamMembers: {},
  teamRoles: [],
};

interface ITeamDetailsMeta {
  request: { meta: { teamId: string } };
}

export const teamReducerStorageBlacklist = Object.keys(
  teamReducerInitialStateUnstored,
);

const resetRoles = (state: ITeamState): ITeamState => {
  return {
    ...state,
    teamRoles: [],
  };
};

const teamReducer = createReducer(initialState, {
  ...createAPIReducer<ITeamState, IExtendedAxiosResponse<IApiTeamResponse>>(
    TeamActionTypes.FETCH_TEAMS,
    'fetchTeamsStatus',
    {
      onRequest: resetRoles,
      onSuccess: (state, action) => {
        const teams = mapTeams(action.response.data);

        return {
          ...state,
          teams,
          currentTeamId:
            state.currentTeamId &&
            teams.find(team => team.id === state.currentTeamId)
              ? state.currentTeamId
              : teams[0] && teams[0].id,
        };
      },
    },
  ),
  ...createAPIReducer<ITeamState, { data: IApiTeamResponse }>(
    TeamActionTypes.ASSIGN_TEAM,
    'assignTeamStatus',
    {
      onRequest: resetRoles,
    },
  ),
  ...createAPIReducer<ITeamState, IExtendedAxiosResponse<IApiTeam>>(
    TeamActionTypes.TEAM_CREATE,
    'createTeamStatus',
    {
      onRequest: resetRoles,
      onSuccess: (state, action) => {
        const teams = [...state.teams, mapTeam(action.response.data)];
        return {
          ...state,
          teams,
          currentTeamId: action.response.data.id,
        };
      },
    },
  ),
  ...createAPIReducer<
    ITeamState,
    IExtendedAxiosResponse<IApiTeamMemberResponse, ITeamDetailsMeta>
  >(TeamActionTypes.TEAM_DETAIL_FETCH, 'fetchTeamDetailStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          [action.meta.requestAction.request.meta.teamId]: mapTeamMembers(
            action.response.data.members,
          ),
        },
      };
    },
  }),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.INVITE_TEAM_MEMBER,
    'inviteTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.CHANGE_TEAM_NAME,
    'changeTeamNameStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.UPDATE_TEAM_MEMBER,
    'updateTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.DELETE_TEAM_MEMBER,
    'deleteTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.CONFIRM_TEAM_MEMBER,
    'confirmTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.TEAM_ROLES_FETCH,
    'fetchTeamRolesStatus',
    {
      onSuccess: (state, action) => {
        const teamRoles = mapTeamRoles(action.response.data);
        return {
          ...state,
          teamRoles,
        };
      },
    },
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.RESEND_INVITATION_EMAIL,
    'resendInvitationEmailStatus',
  ),

  [TeamActionTypes.SET_CURRENT_TEAM]: (
    state: ITeamState,
    action: { payload: { teamId: string } },
  ) => {
    return {
      ...state,
      teamMembers: [],
      teamRoles: {},
      currentTeamId: action.payload.teamId,
    };
  },

  [UserActionTypes.SIGNOUT]: (): ITeamState => {
    return initialState;
  },
});

export { teamReducer };

import * as Sentry from '@sentry/browser';
import { IAPIZendeskTicket, IZendeskTicket } from '../types/ticketTypes';

export const normalizeZendeskTicket = (
  ticket: IAPIZendeskTicket,
): IZendeskTicket => {
  return {
    id: ticket.id,
    url: ticket.url,
    appId: ticket.external_id,
    createdAt: new Date(ticket.created_at),
    updatedAt: new Date(ticket.updated_at),
    type: ticket.type,
    subject: ticket.subject,
    rawSubject: ticket.raw_subject,
    description: ticket.description,
    attachments: [],
    priority: ticket.priority,
    status: ticket.status,
    recipient: ticket.recipient,
    requesterId: ticket.requester_id,
    submitterId: ticket.submitter_id,
    assigneeId: ticket.assignee_id,
    organizationId: ticket.organization_id,
    groupId: ticket.group_id,
    collaboratorIds: ticket.collaborator_ids,
    followIds: ticket.follower_ids,
    problemId: ticket.problem_id,
    hasIncidents: ticket.has_incidents,
    dueAt: ticket.due_at,
    tags: ticket.tags,
    via: ticket.via,
    customFields: ticket.custom_fields,
    satisfactionRatings: ticket.satisfaction_rating,
    sharingAgreements: ticket.sharing_agreement_ids,
  };
};

export const normalizeZendeskTickets = (tickets: IAPIZendeskTicket[]) => {
  try {
    return tickets.map(normalizeZendeskTicket);
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

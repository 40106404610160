import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useStep1Styles } from './Step1Styles';
import { useIsXXSDown } from 'common/utils/hooks/themeHooks';
import classNames from 'classnames';
import { Android, IOS } from './Icon';
import { IStepProps } from '../types';
import { defineFlowStep } from '../../../components/Flow/definition';

interface IStep1Props {
  className?: string;
}

const LINK_OPTIONS = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const GOOGLE_APP_IOS_LINK =
  'https://apps.apple.com/us/app/google-authenticator/id388497605';
const GOOGLE_APP_ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';

export const Step1Component = ({ className }: IStep1Props) => {
  const classes = useStep1Styles();

  const isXXSDown = useIsXXSDown();

  return (
    <div className={classNames(className, classes.component)}>
      <Typography
        className={classes.title}
        variant={isXXSDown ? 'h3' : 'h2'}
        component="p"
      >
        {t('two-factor-auth.captions.enable.second-screen')}
      </Typography>
      <Button
        className={classNames(classes.link, classes.ios)}
        href={GOOGLE_APP_IOS_LINK}
        size="large"
        component="a"
        variant="outlined"
        {...LINK_OPTIONS}
      >
        <IOS />
      </Button>
      <Button
        className={classNames(classes.link, classes.android)}
        href={GOOGLE_APP_ANDROID_LINK}
        size="large"
        component="a"
        variant="outlined"
        {...LINK_OPTIONS}
      >
        <Android />
      </Button>
    </div>
  );
};

const Step1Wrapper = ({ innerClassName }: IStepProps) => {
  return <Step1Component className={innerClassName} />;
};

export const Step1 = defineFlowStep<{}, {}, IStepProps>({
  Body: Step1Wrapper,
});

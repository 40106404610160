import { AnyAction } from 'redux';
import { UserActions, UserActionTypes } from '../actions/UserActions';
import { TokenActions, TokenActionTypes } from '../actions/TokensActions';
import { NODES_PATH } from 'common/nav/navUtils';
import { put, takeEvery, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { TeamActions } from 'common/modules/teams/actions/TeamActions';

function* setExternalToken(action: AnyAction) {
  yield put(UserActions.signOut());
  yield put(TokenActions.setTokens(action.payload));
  yield put(UserActions.refresh());
  yield put(TeamActions.fetchTeams());
  yield take([
    UserActionTypes.USER_REFRESH_ERROR,
    UserActionTypes.USER_REFRESH_SUCCESS,
  ]);
  yield put(push(NODES_PATH));
}

export function* runExternalTokenSaga() {
  yield takeEvery(TokenActionTypes.SET_EXTERNAL_TOKEN, setExternalToken);
}

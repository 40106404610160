import { Theme, makeStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      color: theme.palette.grey[700],
      fontSize: 12,
      fontWeight: 'normal',
      paddingRight: 20,
      paddingLeft: 4,
      height: 34,
      borderRight: '1px solid #E1E4EB',
      textAlign: 'right',

      '& .balance': {
        paddingLeft: 3,
        color: theme.palette.grey[800],
        fontWeight: 'bold',
        fontSize: 14,
      },
    },
  }),
);

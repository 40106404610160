import {
  TRON_FULL_ENABLE_SNAPSHOT,
  TronFullHooks,
  tronFullNodeName,
} from './TronFullConst';
import {
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/TronFull.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableManage = loadable(
  async () =>
    import('./manage/TronFullManage').then(module => module.TronFullManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/TronFullCustomValues').then(
      module => module.TronFullCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const tronFullConfig: INodeConfig = {
  chartName: tronFullNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => {
    return {
      [TRON_FULL_ENABLE_SNAPSHOT]: customValues
        ? Boolean(customValues[TRON_FULL_ENABLE_SNAPSHOT]).toString()
        : 'false',
    };
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'init-tron': {
      [TronFullHooks.getProgress]: defaultParse,
    },
    'tron-node': {
      [TronFullHooks.getStatus]: defaultParse,
    },
  },
};

import { requestInactive, RequestStatus } from 'common/utils/requestStatus';
import { createReducer, createAPIReducer } from 'common/utils/reduxUtils';
import { Ethereum2ActionTypes } from '../actions/EthereumMedallaAction';
import { LOCATION_CHANGE } from 'connected-react-router';

import { AnyAction } from 'redux';

export interface IEthereumState {
  getEthereumKeyStatus: RequestStatus;
  getEthereumPhraseStatus: RequestStatus;
  phrase: string;
  keyStore: string;
  depositData: string;
}

const initialState: IEthereumState = {
  getEthereumKeyStatus: requestInactive(),
  getEthereumPhraseStatus: requestInactive(),
  phrase: '',
  keyStore: '',
  depositData: '',
};

export const EthereumReducer = createReducer(initialState, {
  ...createAPIReducer<IEthereumState, AnyAction>(
    Ethereum2ActionTypes.GET_ETHEREUM_KEY,
    'getEthereumKeyStatus',
    {
      onSuccess: (state: IEthereumState, action: AnyAction): IEthereumState => {
        return {
          ...state,
          keyStore: action.response.data?.keyStore,
          depositData: action.response.data?.depositData,
        };
      },
    },
  ),

  ...createAPIReducer<IEthereumState, AnyAction>(
    Ethereum2ActionTypes.GET_ETHEREUM_PHRASE,
    'getEthereumPhraseStatus',
    {
      onSuccess: (state: IEthereumState, action: AnyAction): IEthereumState => {
        return {
          ...state,
          phrase: action.response.data?.phrase,
        };
      },
    },
  ),

  [Ethereum2ActionTypes.CLEAR_ETHEREUM_STATE]: (
    state: IEthereumState,
  ): IEthereumState => ({
    ...state,
    ...initialState,
  }),

  [LOCATION_CHANGE]: (state: IEthereumState): IEthereumState => ({
    ...state,

    ...initialState,
  }),
});

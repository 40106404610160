import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';

export const parseBitcoinNodeStatus = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const result =
      response?.data?.result
        ?.replace(/\n/gi, '')
        .replace(/\\/g, '')
        .replace('RpcClient Initialized', '') ?? '';

    return {
      ...response,
      data: safeParseJSON(result ?? '{}'),
    };
  } catch (err) {
    return {
      ...response,
    };
  }
};

import { Action } from 'redux-actions';
import { createReducer } from 'common/utils/reduxUtils';
import { RedirectActionTypes } from '../actions/RedirectActions';

export interface IRedirectState {
  resource?: string;
}

const initialState: IRedirectState = {};

const redirectReducer = createReducer(initialState, {
  [RedirectActionTypes.SET_REDIRECT]: (
    state: IRedirectState,
    { payload: { resource } }: Action<{ resource: string }>,
  ): IRedirectState => ({
    ...state,
    resource,
  }),
  [RedirectActionTypes.RESET_REDIRECT]: (
    state: IRedirectState,
  ): IRedirectState => ({
    ...state,
    resource: undefined,
  }),
});

export { redirectReducer };

import { SyncStatusVariant } from '../const';
import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';

function getSyncStatus(data: any) {
  if (data?.result?.indexOf('{message: false}') === 0) {
    return SyncStatusVariant.done;
  }

  if (data?.result?.indexOf('{message: true}') === 0) {
    return SyncStatusVariant.progress;
  }

  return SyncStatusVariant.error;
}

export interface ISyncStatusResult {
  syncStatus: SyncStatusVariant;
}

export function parseSyncStatus(response: IHookResponse): IHookResponse {
  return {
    ...response,
    data: {
      syncStatus: getSyncStatus(safeParseJSON(response.data ?? '{}')),
    } as ISyncStatusResult,
  };
}

import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const snackbarContentStyles = (theme: Theme): StyleRules => ({
  success: {
    backgroundColor: theme.palette.success.main,
  },
  default: {
    backgroundColor: theme.palette.error.dark,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export { snackbarContentStyles };

import React from 'react';
import {
  Box,
  Grid,
  StyledComponentProps,
  StyleRules,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  Message,
  MessageType,
  MessageVariant,
} from 'common/components/Message';
import { t } from 'common/utils/intl';
import { ICustomValueComponentProps } from 'common/types';
import { findCustomValue } from '../../../../AppDeployUI/utils/hookUtils';
import { DefaultCustomValues } from '../../../../AppDeployUI/components/CustomizeVariables/DefaultCustomValues';
import { ZCASH_USE_PASSWORD, ZCASH_USE_USERNAME } from '../ZcashConst';
import { PASSWORD_FIELD } from '../../../../AppDeployUI/const';

const styles = (theme: Theme): StyleRules => ({
  root: {
    '& input': {
      margin: 0,
      marginRight: theme.spacing(1),
    },
    width: '100%',
  },
});

const MAX_LENGTH = 64;

const StafiCustomValueComponent = ({
  classes = {},
  customValues = [],
  chartName,
}: ICustomValueComponentProps & StyledComponentProps) => {
  const passwordField = findCustomValue(customValues, ZCASH_USE_PASSWORD);
  const userField = findCustomValue(customValues, ZCASH_USE_USERNAME);

  const validate = (value?: string) => {
    if (!value || value.trim() === '')
      return t('validation.field-required-notice');

    if (value.length > MAX_LENGTH) {
      return t('validation.field-length', { size: MAX_LENGTH });
    }
    return undefined;
  };

  return (
    <div className={classes.root}>
      <Box ml={2}>
        <Message
          message={t(`nodes.${chartName}.deploy.memo`)}
          type={MessageType.info}
          variant={MessageVariant.quote}
        />
      </Box>

      <Grid container>
        <Grid item sm={6} xs={12}>
          {userField && (
            <DefaultCustomValues
              customValues={[userField]}
              customValidations={[validate]}
            />
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          {passwordField && (
            <DefaultCustomValues
              customValues={[passwordField]}
              attributes={[PASSWORD_FIELD]}
              customValidations={[validate]}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export const ZcashCustomValue = withStyles(styles)(StafiCustomValueComponent);

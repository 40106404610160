import { RequestAction, RequestsStore } from '@redux-requests/core';

import { accessTokenSelector } from '../selectors/tokenSelectors';
import { IRequestConfig } from '../../types';
import { makeRequestsWithAuthHeader } from './utils';

export const onRequest = (
  request: IRequestConfig<any> | IRequestConfig<any>[],
  action: RequestAction,
  store: RequestsStore,
) => {
  const accessToken = accessTokenSelector(store.getState());

  if (!action?.meta?.noAuth && accessToken) {
    return makeRequestsWithAuthHeader(request, accessToken);
  }

  return request;
};

import { createReducer } from 'common/utils/reduxUtils';
import { OfflineActionTypes } from 'common/store/actions/OfflineActions';

export interface IOfflineState {
  isOffline: boolean;
}

const initialState: IOfflineState = {
  isOffline: false,
};

const offlineReducer = createReducer(initialState, {
  [OfflineActionTypes.SET_OFFLINE]: (
    state: IOfflineState,
    action: { payload: boolean },
  ): IOfflineState => {
    return {
      ...state,
      isOffline: action.payload,
    };
  },
});

export { offlineReducer };

import { ZcashCustomValue } from './deploy/ZcashCustomValue';
import { ZcashConfig } from './manage/ZcashConfig';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/Zcash.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import { ZcashHooks, zcashName } from './ZcashConst';

export const zcashConfig: INodeConfig = {
  chartName: zcashName,
  deploy: {
    component: ZcashCustomValue,
  },
  locales: {
    'en-US': localesUS,
  },
  hideCustomVariableDivider: true,
  info: ZcashConfig,
  manage: false as false,
  details: false as false,
  interceptors: {
    'zcash-node': {
      [ZcashHooks.getStatus]: defaultParse,
      [ZcashHooks.getCredentials]: defaultParse,
    },
    'init-zcash': { [ZcashHooks.getProgress]: defaultParse },
  },
};

import { historyInstance } from 'common/utils/historyInstance';
import {
  IApiErrorResponse,
  IRequestConfig,
  Milliseconds,
  IExtendedRequestAction,
} from '../../types';
import { EXPIRED_PATH, LOGIN_PATH } from 'auth/src/const';
import { UserActions } from 'auth/store/actions/UserActions';

export const SUCCESS_REDIRECT_DELAY: Milliseconds = 100;

export const makeRequestWithAuthHeader = (
  request: any,
  accessToken: string,
) => {
  return {
    ...request,
    headers: {
      ...(request?.headers ?? {}),
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export function makeRequestsWithAuthHeader(
  request: IRequestConfig<any> | IRequestConfig<any>[],
  accessToken: string,
) {
  return request instanceof Array
    ? request.map(item => makeRequestWithAuthHeader(item, accessToken))
    : makeRequestWithAuthHeader(request, accessToken);
}

export function getLocationResource(): string {
  return `${historyInstance.location.pathname}${
    historyInstance.location.search ? historyInstance.location.search : ''
  }`;
}

/**
 * Check error is auth error
 * @param error
 */
export const isAuthError = (error: IApiErrorResponse) => {
  return (
    error?.response?.status === 401 ||
    // Workaround for the wrong status code.
    (error?.response?.status === 400 &&
      error?.response?.data?.Code === 'AuthError')
  );
};

/**
 * Returns true if we don't need to refresh token.
 * @param action
 */
export function shouldSkipAuth(action: IExtendedRequestAction) {
  return Boolean(action?.meta?.noAuth || action?.meta?.noRefresh);
}

export const sendRequestWithToken = (
  action: IExtendedRequestAction,
  token: string,
  store: any,
): IExtendedRequestAction => {
  const newAction = {
    ...action,
    request: makeRequestsWithAuthHeader(action.request, token),
  };

  return store.dispatch(newAction);
};

export const signoutToExpiredPath = (store: any) => {
  store.dispatch(
    UserActions.signOut({
      tokenFailed: true,
      resource: getLocationResource(),
    }),
  );
};

export const signoutToLogin = (store: any) => {
  store.dispatch(UserActions.redirectToLogin());
};

export const makeRedirectWithoutRefreshToken = (store: any) => {
  const isExpiredTokenPath = historyInstance.location.pathname === EXPIRED_PATH;
  const isLoginPath = historyInstance.location.pathname === LOGIN_PATH;

  if (isLoginPath) return;

  if (isExpiredTokenPath) {
    signoutToLogin(store);
  } else {
    signoutToExpiredPath(store);
  }
};

import { StyleRules } from '@material-ui/styles';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    root: {
      borderBottom: `1px solid ${theme.palette.grey['200']}`,
      backgroundColor: theme.palette.common.white,
      padding: '24px 32px 16px 50px',
      color: theme.palette.grey['800'],
      fontSize: 14,
      lineHeight: '130%',
      position: 'relative',
    },
    row: {
      display: 'flex',
    },
    status: {
      position: 'absolute',
      width: theme.spacing(2),
      height: theme.spacing(2),
      top: 12,
      left: 10,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
    SUCCESS: {
      backgroundImage: `url('${require('../assets/suc.svg')}')`,
    },
    DANGER: {
      backgroundImage: `url('${require('../assets/error.svg')}')`,
    },
    INFO: {
      backgroundImage: `url('${require('../assets/info.svg')}')`,
    },
    WARNING: {
      backgroundImage: `url('${require('../assets/warn.svg')}')`,
    },
    message: {
      fontSize: 14,
      lineHeight: '130%',
      margin: 0,
      marginBottom: 6,
      wordBreak: 'break-word',

      '&:first-letter': {
        textTransform: 'capitalize',
      },

      '& span': {
        fontWeight: 500,
        fontStyle: 'normal',
      },
    },
    time: {
      fontSize: 12,
      color: theme.palette.grey['700'],
    },
  }),
);

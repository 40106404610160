import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FileInput } from './FileInput';

const FileInputField = ({
  input: { name, onChange, value },
  meta,
  ...rest
}: FieldRenderProps<HTMLInputElement>) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FileInput
      {...rest}
      name={name}
      helperText={!!showError ? meta.error || meta.submitError : undefined}
      error={!!showError}
      onChange={onChange as any}
      value={value}
    />
  );
};

export { FileInputField };

import { fade, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useScrollIndicatorStyles = makeStyles<Theme, { offset: number }>(
  theme => ({
    indicator: {
      position: 'absolute',

      display: 'inline-block',

      opacity: 1,

      transitionTimingFunction: 'linear',
      transitionDuration: '200ms',

      transitionProperty: 'opacity',

      pointerEvents: 'none',
    },

    indicatorVertical: {
      right: 0,
      left: 0,

      height: 60,
    },

    indicatorHidden: {
      zIndex: -1,

      opacity: 0,
    },

    indicatorTop: {
      top: props => (props.offset ? props.offset : 0),

      backgroundImage: `linear-gradient(to bottom, ${
        theme.palette.common.white
      }, ${fade(theme.palette.common.white, 0)})`,
    },

    indicatorBottom: {
      bottom: props => (props.offset ? props.offset : 0),

      backgroundImage: `linear-gradient(to top, ${
        theme.palette.common.white
      }, ${fade(theme.palette.common.white, 0)})`,
    },
  }),
);

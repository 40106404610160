import { SendRequestResponse } from '../types';
import * as Sentry from '@sentry/browser';

export function throwIfContainsError(
  response: SendRequestResponse,
  log = false,
) {
  if ('error' in response) {
    if (log) {
      Sentry.captureException(response);
    }

    throw response?.error || response;
  }

  return response;
}

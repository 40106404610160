export const hathorNodeName = 'hathor';

export enum HathorHooks {
  getNodeStatus = 'apphook1=get-node-status',
  getPeerId = 'apphook2=get-peer-id',
  getIdentityBackup = 'apphook3=get-identity-backup',
  getIsBackedUp = 'apphook4=is-backed-up',
}

export const HATHOR_IMPORT_IDENTITY = 'ankrCustomValues___import_identity';
export const HATHOR_IDENTITY_FILE = 'ankrCustomValues___identity_file';

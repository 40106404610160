import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';

export enum IEthereum2ValidatorStatusEnum {
  UNKNOWN_STATUS = 'UNKNOWN_STATUS',
  PARTIALLY_DEPOSITED = 'PARTIALLY_DEPOSITED',
  DEPOSITED = 'DEPOSITED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXITING = 'EXITING',
  SLASHING = 'SLASHING',
  EXITED = 'EXITED',
}

const ETHEREUM2_BACKEND_SYNCED = 'synced';

export function parseEthereum2NodeStatus(
  response: IHookResponse,
): IHookResponse {
  try {
    const result = response.data?.result ?? '';

    const resultObj = safeParseJSON(result ?? '{}');

    const nodeStatus = resultObj?.data?.status ?? '';

    const isSynced = nodeStatus.indexOf(ETHEREUM2_BACKEND_SYNCED) > -1;
    return {
      ...response,
      data: {
        isSynced,
        ...resultObj?.data,
      },
    };
  } catch (err) {
    return {
      ...response,
    };
  }
}

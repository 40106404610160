import { useState, useEffect } from 'react';

import { useDebouncedValue } from 'common/utils/hooks';

export const useDebouncedOnChange = (
  onChange: (value?: string) => void,
  value?: string,
) => {
  const [search, setSearch] = useState<string | undefined>(value);

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleClear = () => {
    setSearch('');
  };

  const debounceFilter = useDebouncedValue(search, 300);

  useEffect(() => {
    onChange(debounceFilter);
  }, [onChange, debounceFilter]);

  return { search, handleSearchChange, handleClear };
};

import { ChartName, HOOKS } from 'common/modules/apps/const';

export const APPS_PATH = '/apps';
export const NODES_MARKET_PATH = '/apps/nodes';
export const VALIDATORS_MARKET_PATH = '/apps/validators';
export const API_MARKET_PATH = '/apps/api';

export const NODES_DETAILS_PATH = '/apps/details';
export const AUTO_REFRESH_DELAY_TIME = 30000;

export const PROGRESS_HOOKS: { [key: string]: string } = {
  [ChartName.tomochain]: HOOKS.TOMOCHAIN_GET_PROGRESS,
  [ChartName.horizen]: HOOKS.HORIZEN_GET_PROGRESS,
  [ChartName.horizenSuper]: HOOKS.HORIZEN_GET_PROGRESS,
  [ChartName.iost]: HOOKS.IOST_GET_PROGRESS,
  [ChartName.vite]: HOOKS.VITE_FULL_GET_PROGRESS,
  [ChartName.viteSupernode]: HOOKS.VITE_FULL_GET_PROGRESS,
  [ChartName.tomoX]: HOOKS.TOMOX_GET_PROGRESS,
  [ChartName.tron]: HOOKS.TRON_GET_PROGRESS,
  [ChartName.arweave]: HOOKS.ARWEAVE_GET_PROGRESS,
};
export const PROGRESS_HOOKS_TIME_ESTIMATION_HOURS: { [key: string]: number } = {
  [ChartName.tomochain]: 12,
  // [ChartName.horizen]: undefined,
  // [ChartName.horizenSuper]: undefined,
  [ChartName.iost]: 1,
  [ChartName.vite]: 4,
  [ChartName.viteSupernode]: 4,
  [ChartName.tomoX]: 12,
  [ChartName.tron]: 18,
  [ChartName.arweave]: 20,
};

export const AUTO_UPDATE_HOOKS: { [key: string]: string } = {
  [ChartName.elrond]: HOOKS.ELROND_AUTO_UPDATE,
};
export const GET_AUTO_UPDATE_HOOKS: { [key: string]: string } = {
  [ChartName.elrond]: HOOKS.ELROND_AUTO_UPDATE_STATUS,
};

export const OASIS_GAS = 0.000002;
export const OASIS_PAUSE_RECLAIM_BALANCE = 200;
export const OASIS_RESTAKE_GAS = 1;
export const OASIS_RESTAKE_MIN_OPERATION_AMOUNT = 10;

export const CHARTS_SORT_ORDER = {
  harmony: ['harmony-staking', 'harmony', 'harmony-sync'],
};

export const CARD_WIDTH = 253;

export const DELAY_IN = 200;
export const DELAY_OUT = 50;

export const CARD_ANIMATION_DURATION = 100;
export const CARD_MAX_ANIMATION_DURATION = 2000;

export const SKELETON_ANIMATION_DURATION = 70;
export const SKELETON_MAX_ANIMATION_DURATION = 1000;

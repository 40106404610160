import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import { handleRequests } from '@redux-requests/core';

import { historyInstance } from 'common/utils/historyInstance';
import { rootSaga } from './effects';
import { createRootReducer } from './reducers';
import { sendMixpanelStats } from './mixpanelSender';
import { HANDLE_REQUEST_CONFIG } from './handleRequest';
import { PERSIST_CONFIG } from './persistConfig';
import { composeEnhancers } from './composeEnhancers';

const sagaMiddleware = createSagaMiddleware();

const { requestsReducer, requestsMiddleware } = handleRequests(
  HANDLE_REQUEST_CONFIG,
);

const enhancer = composeEnhancers(
  applyMiddleware(
    routerMiddleware(historyInstance),
    ...requestsMiddleware,
    sagaMiddleware,
  ),
);

const persistedReducer = persistReducer(
  PERSIST_CONFIG,
  createRootReducer(historyInstance, requestsReducer),
);

const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  const state = store.getState();

  const {
    user: { id: userId },
    router: {
      location: { pathname },
    },
  } = state;

  sendMixpanelStats(userId, pathname);
});

export { store, persistor };

export function addSeconds(dirtyDate: Date, amount: number): Date {
  const timestamp = new Date(dirtyDate).getTime();
  return new Date(timestamp + amount * 1000);
}

export function getXMonthsAgoDate(x: number) {
  const date = new Date();
  date.setMonth(date.getMonth() - x);
  return date;
}

export function formatDate(date: Date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  } // this can be also done with day.padStart(2,'0')

  return `${year}-${month}-${day}`;
}

export function convertUTCDateToLocalDate(date: Date) {
  const newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return newDate;
}

const SECONDS_PER_HOUR = 3600;
const SECONDS_PER_MINUTE = 60;

export function formatDateToHHSS(seconds: number) {
  const minutes = ((seconds % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE)
    .toFixed(0)
    .padStart(2, '0');
  const hours = Math.floor(seconds / SECONDS_PER_HOUR)
    .toFixed(0)
    .padStart(2, '0');
  return `${hours}:${minutes}`;
}
export function formatDateMonth(date: Date) {
  try {
    const dateTimeFormatter = new Intl.DateTimeFormat('en', {
      month: 'short',
      day: 'numeric',
    });
    return dateTimeFormatter.format(date);
  } catch (err) {
    return date.toString();
  }
}

export function formatDateMonthYear(date: Date) {
  try {
    const dateTimeFormatter = new Intl.DateTimeFormat('en', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    return dateTimeFormatter.format(date);
  } catch (err) {
    return date.toString();
  }
}

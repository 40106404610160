/* eslint-disable @typescript-eslint/camelcase */
import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';
import BigNumber from 'bignumber.js';
import { convertUTCDateToLocalDate } from '../../../utils/date';

export const CELO_PRECISION = 3;
export const CELO_DIVIDER = new BigNumber(1000000000000000000);
const MSEC = 1000;

function makeBigNumbers(balance: any) {
  return {
    gold: new BigNumber(balance.gold).dividedBy(CELO_DIVIDER),
    usd: new BigNumber(balance.usd).dividedBy(CELO_DIVIDER),
    locked_gold: new BigNumber(balance.locked_gold).dividedBy(CELO_DIVIDER),
    pending: new BigNumber(balance.pending).dividedBy(CELO_DIVIDER),
  };
}
export function parseCeloBalances(response: IHookResponse): IHookResponse {
  const resultObj = safeParseJSON(response.data?.result ?? '{}');
  const mappedData = {
    validator_balance: makeBigNumbers(resultObj?.data?.validator_balance ?? {}),
    validator_group_balance: makeBigNumbers(
      resultObj?.data?.validator_group_balance ?? {},
    ),
    validator_group_rg_balance: makeBigNumbers(
      resultObj?.data?.validator_group_rg_balance ?? {},
    ),
    validator_rg_balance: makeBigNumbers(
      resultObj?.data?.validator_rg_balance ?? {},
    ),
    validator_signer_balance: makeBigNumbers(
      resultObj?.data?.validator_signer_balance ?? {},
    ),
  };

  return {
    ...response,
    data: {
      ...resultObj,
      data: mappedData,
    },
  };
}

export function parseCeloDAppBalance(response: IHookResponse): IHookResponse {
  const resultObj = safeParseJSON(response.data?.result ?? '{}');
  const mappedData = {
    gold: new BigNumber(resultObj.data.gold).dividedBy(CELO_DIVIDER),
    lockedGold: new BigNumber(resultObj.data.lockedGold).dividedBy(
      CELO_DIVIDER,
    ),
    usd: new BigNumber(resultObj.data.usd).dividedBy(CELO_DIVIDER),
    total: new BigNumber(resultObj.data.total).dividedBy(CELO_DIVIDER),
    pending: new BigNumber(resultObj.data.pending).dividedBy(CELO_DIVIDER),
  };
  return {
    ...response,
    data: {
      ...resultObj,
      data: mappedData,
    },
  };
}

export function parseCeloDAppLockedGold(
  response: IHookResponse,
): IHookResponse {
  const resultObj = safeParseJSON(response.data?.result ?? '{}');
  const mappedData = {
    lockedGold: {
      total: new BigNumber(resultObj.data.lockedGold.total),
      nonvoting: new BigNumber(resultObj.data.lockedGold.nonvoting),
      required: new BigNumber(resultObj.data.lockedGold.required),
    },
    pendingWithdrawals: resultObj.data.pendingWithdrawals.map(
      (item: { time: string; value: string }) => ({
        time: convertUTCDateToLocalDate(new Date(parseInt(item.time) * MSEC)),
        value: new BigNumber(item.value).dividedBy(CELO_DIVIDER),
      }),
    ),
  };
  return {
    ...response,
    data: {
      ...resultObj,
      data: mappedData,
    },
  };
}

export function parseCeloDAppElectionList(
  response: IHookResponse,
): IHookResponse {
  const resultObj = safeParseJSON(response.data?.result ?? '{}');
  const mappedData = Array.isArray(resultObj.data)
    ? resultObj.data.map(
        (item: {
          address: string;
          name: string;
          votes: string;
          capacity: string;
          eligible: boolean;
        }) => ({
          address: item.address,
          name: item.name,
          votes: new BigNumber(item.votes).dividedBy(CELO_DIVIDER),
          capacity: new BigNumber(item.capacity),
          eligible: item.eligible,
        }),
      )
    : [];
  return {
    ...response,
    data: {
      ...resultObj,
      data: mappedData,
    },
  };
}

export function parseCeloDAppVoteHistory(
  response: IHookResponse,
): IHookResponse {
  const resultObj = safeParseJSON(response.data?.result ?? '{}');
  let mappedData: {
    date: Date;
    group: string;
    votes: BigNumber;
    elected: boolean;
  }[];
  try {
    mappedData = Array.isArray(resultObj.data.items)
      ? resultObj.data.items.map(
          (item: {
            Date: string;
            Group: string;
            Votes: string;
            Elected: string;
          }) => ({
            date: convertUTCDateToLocalDate(new Date(parseInt(item.Date))),
            group: item.Group,
            votes: new BigNumber(item.Votes)
              .dividedBy(CELO_DIVIDER)
              .integerValue(BigNumber.ROUND_HALF_EVEN),
            elected: item.Elected === 'true',
          }),
        )
      : // Should be filtered on backend
        // .filter((item: { votes: BigNumber }) => item.votes.gt(0))
        [];
  } catch (e) {
    mappedData = [];
  }
  return {
    ...response,
    data: {
      ...resultObj,
      total: resultObj.data.total,
      data: mappedData,
    },
  };
}

import React from 'react';
import localesUS from './locales/enecuum.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import {
  ENECUUM_NODE_NAME,
  ENECUUM_POS_ID,
  ENECUUM_HOOKS,
  ENECUUM_NETWORK,
  ENECUUM_NETWORK_OPTION_TESTNET,
} from './enecuumConst';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';

const LoadableInfo = loadable(
  async () =>
    import('./info/EnecuumInformation').then(
      module => module.EnecuumInformation,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/EnecuumCustomValue').then(
      module => module.EnecuumCustomValue,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const enecuumConfig: INodeConfig = {
  chartName: ENECUUM_NODE_NAME,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.deploy.advanced-configuration',
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: () => ({
    customValues: {
      [ENECUUM_POS_ID]: '',
      [ENECUUM_NETWORK]: ENECUUM_NETWORK_OPTION_TESTNET,
    },
  }),

  info: LoadableInfo,
  manage: false,
  details: false,
  interceptors: {
    'enecuum-node': {
      [ENECUUM_HOOKS.getNodeStatus]: defaultParse,
      [ENECUUM_HOOKS.getValidatorStatus]: defaultParse,
      [ENECUUM_HOOKS.getAddress]: defaultParse,
      [ENECUUM_HOOKS.getNetwork]: defaultParse,
    },
  },
};

import { PaymentMethod } from '../../billing/const';

enum EscrowType {
  ANKR = 'ANKR',
  ETH = 'ETH',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum PayCurrency {
  ANKR = 'ANKR',
  USDT = 'USDT',
  USD = 'USD',
}

export enum PayStatus {
  PENDING = 'PENDING',
  READY = 'READY', //READY means ready for pay or deposite or withdraw.
  ENABLED = 'ENABLED', //ENABLED means current active pay method
}

export interface IApiPayMethod {
  name: PaymentMethod;
  type: EscrowType;
  currency: PayCurrency;
  selected: boolean; //pay method is selected. selected means current active pay method for next metering recycle. selected pay method will be ENABLEDin next metering recycle
  create_time: Date;
  update_time: Date;
  status: PayStatus;
}

export interface IPaymentMethod {
  name: PaymentMethod;
  type: EscrowType;
  currency: PayCurrency;
  selected: boolean;
  createTime: Date;
  updateTime: Date;
  status: PayStatus;
  needRetry: boolean;
}

export interface IApiMoney {
  unit: PayCurrency;
  precision: string;
  scale: number;
  text: string;
}

export type IMoney = IApiMoney;

enum CardStatus {
  VERIFYING = 'VERIFYING',
  READY = 'READY',
}

export enum CardBrand {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTER = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}

export interface IApiCard {
  id: string;
  brand: CardBrand;
  last4: string;
  exp_year: number;
  exp_month: number;
  enabled: boolean;
  status: CardStatus;
  create_time: Date;
  update_time: Date;
}

export interface IApiCardList {
  cards: IApiCard[];
}

export interface ICard {
  id: string;
  brand: CardBrand;
  last4: string;
  expYear: number;
  expMonth: number;
  enabled: boolean;
  status: CardStatus;
  createTime: Date;
  updateTime: Date;
}

export interface IApiStripeClientSecret {
  stripe_client_secret: string;
}

export interface IApiCreateCardResponse {
  id: string;
  brand: CardBrand;
  last4: string;
  exp_year: number;
  exp_month: number;
  enabled: boolean;
  status: 'VERIFYING' | 'READY';
  create_time: string;
  update_time: string;
}

export interface IApiPayMethodListResponse {
  methods: IApiPayMethod[];
}

export interface IApiCheckSelectableParams {
  cluster_id: string;
  cpu: string;
  memory: string;
  storage: string;
  chart_name: string;
}

export interface IApiCheckPayMethodSelectableResponse {
  selectable: boolean;
  demand: IApiMoney;
  total: IApiMoney;
  available_balance: IApiMoney;
}

export interface IPrePaymentPeriod {
  months: number;
}

import { t } from './intl';

const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 32;
const MIN_USERNAME_LENGTH = 3;

export const MIN_NO_NEGATIVE_WITH_DECIMAL = 0.1;

/**
 * Returns true if email is valid and not empty
 * @param email
 */
function validateEmail(email?: string): boolean {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email && re.test(String(email).toLowerCase());
}

export function isNodeNameValid(value: string) {
  return /^[a-zA-Z0-9-_]+$/.test(value);
}

export function isInteger(value: string) {
  return /^[0-9]+$/i.test(value);
}

export function isHex(value: string) {
  return /^0x[0-9A-Fa-f]+$/i.test(value);
}

export function isAlphanumeric(value: string) {
  return /^[a-z0-9]+$/i.test(value);
}

export function validateIPAddress(str?: string) {
  if (!str) {
    return false;
  }
  return /^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$/.test(
    str,
  );
}

export function isLatinUrl(str?: string) {
  if (!str) {
    return false;
  }
  const urlRegex =
    '^(?:(?:https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  const url = new RegExp(urlRegex, 'i');
  return (
    str.length < 2083 &&
    url.test(str) &&
    !/[^\u0020-\u007F\u00A0-\u024F\u1E00-\u1EFF]/.test(str)
  );
}

export function isURL(str: string) {
  const urlRegex =
    '^(?:(?:http|https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  const url = new RegExp(urlRegex, 'i');
  return str.length < 2083 && url.test(str);
}

function validatePasswordLength(password: string) {
  return (
    password.length >= MIN_PASSWORD_LENGTH &&
    password.length <= MAX_PASSWORD_LENGTH
  );
}

function validatePasswordAtLeastOneUppercase(password: string) {
  const uppercaseRegExp = /[A-Z]/;
  return uppercaseRegExp.test(password);
}

function validatePasswordAtLeastOneLowercase(password: string) {
  const lowercaseRegExp = /[a-z]/;
  return lowercaseRegExp.test(password);
}

function validatePasswordAtLeastOneSpecialCharacterOrNumber(password: string) {
  // eslint-disable-next-line no-useless-escape
  const lowercaseRegExp = /[0-9~!@#$%^&*()_+-=\[\]|;':",./<>?]/;
  return lowercaseRegExp.test(password);
}

function validatePassword(password?: string): boolean {
  if (!password) {
    return false;
  }

  return (
    validatePasswordLength(password) &&
    validatePasswordAtLeastOneUppercase(password) &&
    validatePasswordAtLeastOneLowercase(password) &&
    validatePasswordAtLeastOneSpecialCharacterOrNumber(password)
  );
}
function validatePasswordLevel(password?: string): number {
  let score = 0;
  if (!password) {
    return score;
  }

  if (validatePasswordLength(password)) {
    score += 1;
  }
  if (validatePasswordAtLeastOneUppercase(password)) {
    score += 1;
  }
  if (validatePasswordAtLeastOneLowercase(password)) {
    score += 1;
  }
  if (validatePasswordAtLeastOneSpecialCharacterOrNumber(password)) {
    score += 1;
  }

  return score;
}

function validatePasswordsEquality(
  password?: string,
  repeatPassword?: string,
): boolean {
  return !!password && password === repeatPassword;
}

function isEmpty(value?: string) {
  return !value;
}

function validateName(username?: string) {
  return !!username && username.trim().length >= MIN_USERNAME_LENGTH;
}

function isMnemonic(str: string) {
  return (
    /^([a-z]+\s+){11}[a-z]+$/.test(str) || /^([a-z]+\s+){23}[a-z]+$/.test(str)
  );
}

export const validateRequired = (value?: string) => {
  if (!value) {
    return t('validation.field-required-notice');
  }

  return undefined;
};

export {
  validateEmail,
  validatePasswordLength,
  validatePasswordLevel,
  validatePassword,
  validatePasswordsEquality,
  isEmpty,
  validateName,
  MIN_PASSWORD_LENGTH,
  MIN_USERNAME_LENGTH,
  validatePasswordAtLeastOneUppercase,
  validatePasswordAtLeastOneLowercase,
  validatePasswordAtLeastOneSpecialCharacterOrNumber,
  isMnemonic,
};

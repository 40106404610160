import { t } from 'common/utils/intl';
import { ARWEAVE_ADDRESS_LENGTH } from '../../const';

export const validateArweaveNodeAddress = (value?: string) => {
  if (typeof value !== 'string') {
    return t('validation.field-required-notice');
  }

  if (value && value.length !== ARWEAVE_ADDRESS_LENGTH) {
    return t('validation.field-length-equal', { size: ARWEAVE_ADDRESS_LENGTH });
  }

  if (!/^[a-zA-Z0-9-_]+$/.test(value)) {
    return t('app-deploy.arweave-custom-value.validation.address-format');
  }

  return undefined;
};

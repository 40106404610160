import { IApiErrorResponse } from 'common/types';
import { ErrorHandleConfiguration } from 'common/utils/convertResponseToError';

const EMPTY_ARRAY: any[] = [];
const EMPTY_VALUE = null;

export const MissingOrderEmptyAPIList: ErrorHandleConfiguration = {
  criteria: (response: IApiErrorResponse) =>
    response.response?.data?.message === 'no successful order found',
  provideValue: { data: { project_list: EMPTY_ARRAY } },
};

export const MissingOrderEmptyValue: ErrorHandleConfiguration = {
  criteria: (response: IApiErrorResponse) =>
    response.response?.data?.message === 'no successful order found',
  provideValue: { data: EMPTY_VALUE },
};

import { decryptDataV3 } from './decryptDataV3';
import { IEncryptedPrivateKey } from 'common/types/walletTypes';
import { verifyPassword, IKeystore } from '@chainsafe/bls-keystore';

export async function verifyKeystorePassword(
  encryptedPrivateKey: IKeystore | any,
  auth: string,
) {
  if (encryptedPrivateKey.version === 3) {
    try {
      return !!(await decryptDataV3(
        encryptedPrivateKey.crypto as IEncryptedPrivateKey,
        auth,
      ));
    } catch (error) {
      return false;
    }
  }

  if (encryptedPrivateKey.version === 4) {
    return await verifyPassword(encryptedPrivateKey, auth);
  }

  return undefined;
}

import BigNumber from 'bignumber.js';

import { createReducer } from 'common/utils/reduxUtils';
import {
  AnkrActionTypes,
  IFetchSymbolPrice,
  PriceHistoryRange,
} from 'common/store/actions/AnkrActions';
import {
  extractRequestError,
  requestFailed,
  requestInactive,
  requestInProgress,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import { IApiErrorAction, IExtendedAxiosResponse } from '../../types';
import { ZERO } from 'common/core/const';

export interface IAnkrState {
  price: BigNumber;
  usdtPrice: BigNumber;
  oldPrice: BigNumber;
  priceChartDataRequestStatus: RequestStatus;
  priceHistoryRange: PriceHistoryRange;
  priceStatus: RequestStatus;
}

const initialState: IAnkrState = {
  price: ZERO,
  usdtPrice: ZERO,
  oldPrice: ZERO,
  priceChartDataRequestStatus: requestInactive(),
  priceHistoryRange: '1h',
  priceStatus: requestInactive(),
};

const ankrReducer = createReducer(initialState, {
  /**
   * Fetch price v2
   */
  [AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE]: (
    state: IAnkrState,
  ): IAnkrState => ({
    ...state,
    priceStatus: requestInProgress(),
  }),
  [AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE_SUCCESS]: (
    state: IAnkrState,
    action: IExtendedAxiosResponse<{ price: string }>,
  ): IAnkrState => {
    const symbol = action.meta.symbol;

    const priceObject =
      symbol === IFetchSymbolPrice.ANKR
        ? {
            price: new BigNumber(action.response.data.price),
            oldPrice: new BigNumber(action.response.data.price),
          }
        : {
            usdtPrice: new BigNumber(action.response.data.price),
          };

    return {
      ...state,
      priceStatus: requestSuccessful(),
      ...priceObject,
    };
  },
  [AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE_ERROR]: (
    state: IAnkrState,
    action: IApiErrorAction,
  ): IAnkrState => ({
    ...state,
    priceStatus: requestFailed(extractRequestError(action)),
  }),
});

export { ankrReducer };

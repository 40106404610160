import { OmiseGoHooks, omiseGoNodeName } from './omiseGoConst';
import { getInstanceDefaultName } from '../../../AppDeployUI/utils/getInstanceDefaultName';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/omiseGo.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableConfig = loadable(
  async () =>
    import('./manage/OmiseGoConfig').then(module => module.OmiseGoConfig),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/OmiseGoCustomValue').then(
      module => module.OmiseGoCustomValue,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const omiseGoConfig: INodeConfig = {
  chartName: omiseGoNodeName,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.matic-deploy.title',
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      ankrCustomValues___nodeName: getInstanceDefaultName(
        appList,
        chartName,
        userName,
      ),
      ankrCustomValues___use_ankr_endpoint: 'true',
    },
  }),
  hideCustomVariableDivider: true,
  info: LoadableConfig,
  manage: false as false,
  details: false as false,
  interceptors: {
    'watcher-info': {
      [OmiseGoHooks.getStatusInfo]: defaultParse,
    },
  },
};

import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { TokenActionTypes } from '../actions/TokensActions';
import { createReducer, createAPIReducer } from 'common/utils/reduxUtils';
import { TOKENS_PERSIST_KEY } from 'common/core/const';
import {
  RequestStatus,
  requestInactive,
  requestInProgress,
  requestFailed,
  extractRequestError,
} from 'common/utils/requestStatus';
import { IApiLoginResponse } from 'auth/store/mappings/userMappings';
import { IApiErrorAction } from 'common/types';
import { IExtendedAxiosResponse } from 'common/types';
import { ISigninCodeInvalidResponse, ISignInResponse } from './userReducer';

export const isUserAuthenticated = (state: ITokensState) => {
  return !!state.accessToken;
};

export interface ITokensState {
  refreshToken: null | string;
  accessToken: null | string;
  persistTokens: boolean;
  tokenCheckStatus: RequestStatus;
  getTokenAfterChangePasswordStatus: RequestStatus;
}

/**
 * User reducer state
 */
const initialState: ITokensState = {
  accessToken: null,
  refreshToken: null,
  persistTokens: false,
  tokenCheckStatus: requestInactive(),
  getTokenAfterChangePasswordStatus: requestInactive(),
};

const tokensReducer = createReducer(initialState, {
  [TokenActionTypes.SSO_CHECK]: (state: ITokensState): ITokensState => ({
    ...state,
    tokenCheckStatus: requestInProgress(),
  }),
  [TokenActionTypes.SSO_CHECK_SUCCESS]: (state: ITokensState): ITokensState => {
    return {
      ...state,
      tokenCheckStatus: requestInactive(),
    };
  },
  [TokenActionTypes.SSO_CHECK_ERROR]: (
    state: ITokensState,
    action: IApiErrorAction,
  ): ITokensState => ({
    ...state,
    tokenCheckStatus: requestFailed(extractRequestError(action)),
    accessToken: null,
  }),

  [UserActionTypes.SIGNIN]: (state: ITokensState): ITokensState => ({
    ...state,
    refreshToken: null,
    accessToken: null,
  }),
  [TokenActionTypes.SET_TOKENS]: (
    state: ITokensState,
    action: {
      type: typeof TokenActionTypes.SET_TOKENS;
      payload: {
        accessToken: string;
        refreshToken?: string;
      };
    },
  ): ITokensState => {
    return {
      ...state,
      accessToken: action.payload.accessToken,
      refreshToken:
        state.persistTokens && action.payload.refreshToken
          ? action.payload.refreshToken
          : '',
    };
  },
  [UserActionTypes.SIGNIN_SUCCESS]: (
    state: ITokensState,
    action: IExtendedAxiosResponse<
      ISigninCodeInvalidResponse | ISignInResponse
    >,
  ): ITokensState => {
    if (
      action.response.status === 202 ||
      (action.response.data as ISigninCodeInvalidResponse).error
    ) {
      return state;
    }

    return {
      ...state,
      accessToken: (action.response.data as ISignInResponse).access_token,
      refreshToken: (action.response.data as ISignInResponse).refresh_token,
    };
  },
  [UserActionTypes.SIGNIN_ERROR]: (state: ITokensState): ITokensState => ({
    ...state,
    refreshToken: null,
    accessToken: null,
  }),
  ...createAPIReducer<ITokensState, IExtendedAxiosResponse<IApiLoginResponse>>(
    UserActionTypes.GET_TOKEN_AFTER_CHANGE_PASSWORD,
    'getTokenAfterChangePasswordStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          accessToken: action.response.data.access_token,
          refreshToken: action.response.data.refresh_token,
        };
      },
    },
  ),
  [TokenActionTypes.REFRESH_TOKEN_SUCCESS]: (
    state: ITokensState,
    action: IExtendedAxiosResponse<IApiLoginResponse>,
  ): ITokensState => {
    return {
      ...state,
      accessToken: action.response.data.access_token,
      refreshToken: action.response.data.refresh_token,
      persistTokens: true,
    };
  },

  [REHYDRATE]: (state: ITokensState, action: AnyAction): ITokensState => {
    if (!action.payload || action.key !== TOKENS_PERSIST_KEY) {
      return state;
    }
    return {
      ...state,
      refreshToken: action.payload.refreshToken,
      accessToken: action.payload.accessToken,
      persistTokens: action.payload.persistTokens,
    };
  },
  // Eliminate all data from current reducer
  [UserActionTypes.SIGNOUT]: (): ITokensState => ({
    ...initialState,
  }),
});

export { tokensReducer };

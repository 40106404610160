import { IExtendedRequestAction } from '../../../types';
import { IFetchNodeLogPayload } from '../nodeLogTypes';

const NodeLogActionType = {
  FETCH_LOGS: 'FETCH_LOGS',
};

const NodeLogActions = {
  fetchLogs: (payload: IFetchNodeLogPayload): IExtendedRequestAction => ({
    type: NodeLogActionType.FETCH_LOGS,
    request: {
      url: `/user-activity/v2alpha/api-events?team_id=${payload.teamId}&start_time=${payload.startTime}&end_time=${payload.endTime}`,
      method: 'GET',
    },
  }),
};

export { NodeLogActionType, NodeLogActions };

import React from 'react';
import loadable, { LoadableComponent } from '@loadable/component';

import localesUS from './locales/ankrTest.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import {
  ANKR_TEST_ADDRESS,
  ANKR_TEST_CHART_NAME,
  ANKR_TEST_CONTAINER,
  ANKR_TEST_HOOKS,
  ANKR_TEST_NAME,
  ANKR_TEST_NETWORK,
} from './ankrTestConst';

const LoadableInfo = loadable(
  async () =>
    import('./info/AnkrTestInformation').then(
      module => module.AnkrTestInformation,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/AnkrTestCustomValue').then(
      module => module.AnkrTestCustomValue,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const ankrTestConfig: INodeConfig = {
  chartName: ANKR_TEST_CHART_NAME,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.deploy.advanced-configuration',
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: () => ({
    customValues: {
      [ANKR_TEST_NAME]: '',
      [ANKR_TEST_ADDRESS]: '',
      [ANKR_TEST_NETWORK]: '',
    },
  }),

  info: LoadableInfo,
  manage: false,
  details: false,
  interceptors: {
    [ANKR_TEST_CONTAINER]: {
      [ANKR_TEST_HOOKS.getNodeStatus]: defaultParse,
    },
  },
};

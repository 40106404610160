export const nearNode = 'near-node';

export enum NearHooks {
  NEAR_GET_BALANCE = 'apphook1=get-balance',
  NEAR_GET_STAKE_BALANCE = 'apphook2=get-stake-balance',
  NEAR_GET_NODE_PUBLIC_KEY = 'apphook3=get-node-public-key',
  NEAR_GET_NODE_VALIDATOR_KEY = 'apphook4=get-node-validator-key',
  NEAR_GET_STATUS = 'apphook5=get-status',
  NEAR_GET_NODE_VALIDATOR_STATUS = 'apphook6=get-validator-status',
  NEAR_GET_LOGIN_LINK = 'apphook7=get-login-link',
  NEAR_PASS_LOGIN = 'apphook8=pass-login',
  NEAR_STAKE = 'apphook9=stake',
  NEAR_CHECK_LOGIN = 'apphook10=check-login',
}

export const NEAR_VAL_ACCOUNT_ID = 'ankrCustomValues___accountID';

export const NEAR_EXPLORER_LINK = 'https://explorer.near.org/';
export const NEAR_EXPLORER_BETANET_LINK = 'https://explorer.betanet.near.org/';

export const NEAR_BETANET = `.betanet`;

export const NEAR_STAKE_AMOUNT_DIVIDE_BY = 1000000000000000000000000;

export interface IApiNotificationSettingsItemResponse {
  api_id: string;
  option_browser_event_api_removed?: boolean;
  option_email_event_exceeded_number_of_day: boolean;
  option_browser_event_exceeded_number_of_day?: boolean;
  option_email_event_exceeded_number_of_second: boolean;
  option_browser_event_exceeded_number_of_second?: boolean;
}

export interface IApiNotificationSettingsItem {
  apiId: string;
  optionBrowserEventApiRemoved: boolean;

  optionEmailEventExceededNumberOfDay: boolean;
  optionBrowserEventExceededNumberOfDay: boolean;
  optionEmailEventExceededNumberOfSecond: boolean;
  optionBrowserEventExceededNumberOfSecond: boolean;
}

export interface IApiNotificationSettingsListResponse {
  notification_setting_list: IApiNotificationSettingsItemResponse[];
}

export enum ApiIds {
  'ALL' = 'ALL',
}

export type ApiIdsType = keyof typeof ApiIds;

import {
  IApiCardList,
  IApiPayMethod,
  IApiPayMethodListResponse,
  ICard,
  IPaymentMethod,
  IPrePaymentPeriod,
  PayStatus,
} from '../../apps/types/payTypes';
import { PayActionTypes } from '../actions/PayActions';
import {
  isRequestSuccessful,
  requestInactive,
  RequestStatus,
} from '../../../utils/requestStatus';
import { createAPIReducer, createReducer } from '../../../utils/reduxUtils';
import {
  normalizeCards,
  normalizePayMethod,
  normalizePayMethodList,
} from '../../apps/apiMappings/payMappings';
import {
  DeployStep,
  PaymentConfigurationStep,
  PaymentMethod,
  PaymentMode,
  PaymentStep,
} from '../const';
import { Action } from 'redux-actions';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { IInvoiceDetails } from '../../apps/types/invoiceType';
import { IPriceResult } from 'common/modules/apps/types';
import { ICheckPriceResponse } from 'common/store/apiMappings/clusterMappings';
import { normalizePriceResult } from 'common/modules/apps/apiMappings/appMappings';
import {
  Days,
  IExtendedAxiosResponse,
  IExtendedRequestAction,
} from '../../../types';
import { IOverviewItem } from './invoiceReducerTypes';
import BigNumber from 'bignumber.js';
import {
  IApiDepositAmount,
  mapDepositAmount,
} from '../apiMapping/getDepositAmount';
import {
  IApiEthPaymentStatus,
  mapEthPaymentStatus,
} from '../apiMapping/getEthPaymentStatus';
import {
  IApiDepositList,
  IDepositItem,
  mapDepositList,
} from '../apiMapping/getDepositList';
import {
  IApiPrePaymentResponse,
  IPrePaymentItem,
  mapPaymentList,
} from '../apiMapping/getPaymentList';
import {
  IApiPrepareExtendOrderData,
  mapExtendPlan,
} from '../apiMapping/extendApp';
import {
  IApiAppPaymentData,
  IAppPaymentData,
  mapAppPaymentData,
} from '../apiMapping/appPaymentData';

export interface IEnableNewBankCardData {
  cardId?: string;
}

function getDeposit(state: IPayState, appId: string) {
  return state.depositList?.find(item => item.appId === appId);
}

export function getAppRemainingDays(state: IPayState, appId: string) {
  const deposit = getDeposit(state, appId);
  if (
    typeof deposit?.appTotalTime === 'number' &&
    typeof deposit?.appRunTime === 'number'
  ) {
    return (deposit?.appTotalTime - deposit.appRunTime) as Days;
  }
  return undefined;
}

export const getCurrentCard = (cards: ICard[]): ICard | undefined => {
  return cards.find((item: ICard) => item.enabled);
};

export function getDepositMode(state: IPayState): PaymentMode | undefined {
  if (state.paymentDeployStep === DeployStep.INIT) {
    return undefined;
  }

  if (
    state.paymentDeployStep === DeployStep.DEPOSIT_CHOOSE_METHOD ||
    state.paymentDeployStep === DeployStep.DEPOSIT
  ) {
    return 'deposit';
  }

  return 'daily';
}

function getDailyPrice(monthlyPrice: BigNumber): BigNumber {
  // TODO Change to a more accurate way
  return monthlyPrice.div(30);
}

const updatePaymentMethods = (
  methods: IPaymentMethod[],
  newMethod: IPaymentMethod,
) => {
  const fetchedMethod = methods.findIndex(
    method => method.name === newMethod.name,
  );

  const payMethodList: IPaymentMethod[] = [...methods];

  if (fetchedMethod !== -1) {
    payMethodList[fetchedMethod] = newMethod;
  } else {
    payMethodList.push(newMethod);
  }

  return payMethodList;
};

export const getPaymentMethod = (
  paymentMethods: IPaymentMethod[],
  paymentMethodName?: PaymentMethod,
) => paymentMethods.find(item => item.name === paymentMethodName);

function isPaymentConfigurationDone(paymentMethod?: IPaymentMethod) {
  return (
    paymentMethod?.status === PayStatus.READY ||
    paymentMethod?.status === PayStatus.ENABLED
  );
}

const MAX_CARDS_COUNT = 2;

export function isAddCardDisabled(state: IPayState) {
  return !(
    isRequestSuccessful(state.fetchPaymentMethodsStatus) &&
    state.cards.length < MAX_CARDS_COUNT
  );
}

export interface IPayState {
  paymentMethods: IPaymentMethod[];
  fetchPaymentMethodsStatus: RequestStatus;

  stripePublicKey?: string;
  getStripePublicKeyStatus: RequestStatus;

  enableNewBankCardStatus: RequestStatus;

  currentPaymentMethod?: PaymentMethod;
  fetchPaymentMethodStatus: RequestStatus;

  setupPaymentMethodStatus: RequestStatus;

  listCardsStatus: RequestStatus;
  cards: ICard[];
  currentCard: ICard | undefined;

  deleteBankCardStatus: RequestStatus;

  selectPaymentMethodStatus: RequestStatus;

  paymentStep: PaymentStep;
  prePaymentPeriod?: IPrePaymentPeriod;
  justCreatedCardId?: string;
  paymentDeployStep: DeployStep;

  candidatePaymentMethod?: PaymentMethod;

  paymentConfigurationStep: PaymentConfigurationStep;

  fetchOverviewStatus: RequestStatus;
  overview: IOverviewItem[];

  invoiceDetail?: IInvoiceDetails;
  fetchInvoiceDetailStatus: RequestStatus;

  checkPriceResult?: IPriceResult;
  checkPriceResultStatus: RequestStatus;

  getDailyPriceStatus: RequestStatus;
  dailyPrice?: BigNumber;
  depositUsdMonthlyAmount?: BigNumber;

  getDepositAmountStatus: RequestStatus;
  depositUsdtAmount?: BigNumber;
  depositAnkrAmount?: BigNumber;
  depositUsdAmount?: BigNumber;
  depositSaveUsdAmount?: BigNumber;
  depositAmountMethod?: PaymentMethod;

  ethPaymentStatusStatus: RequestStatus;
  ethPaymentAddress?: string;
  ethPaymentAmount?: BigNumber;
  ethPaymentUsdAmount?: BigNumber;
  ethPaymentMethod?: PaymentMethod;

  getDepositListStatus: RequestStatus;
  depositList?: IDepositItem[];

  extendAppStatus: RequestStatus;
  extendAmount?: BigNumber;
  extendPaymentMethod?: PaymentMethod;

  getPrePaymentDepositListStatus: RequestStatus;
  prePaymentDepositList?: IPrePaymentItem[];

  getAppPaymentInfoStatus: RequestStatus;
  appPaymentInfo?: IAppPaymentData;

  getTrialAmountStatus: RequestStatus;
  trialAmount: BigNumber;
}

const initialState: IPayState = {
  extendAppStatus: requestInactive(),

  currentPaymentMethod: undefined,

  getStripePublicKeyStatus: requestInactive(),

  enableNewBankCardStatus: requestInactive(),

  fetchPaymentMethodsStatus: requestInactive(),
  paymentMethods: [],

  fetchPaymentMethodStatus: requestInactive(),

  setupPaymentMethodStatus: requestInactive(),

  getDepositListStatus: requestInactive(),
  getPrePaymentDepositListStatus: requestInactive(),

  listCardsStatus: requestInactive(),
  cards: [],
  currentCard: undefined,

  deleteBankCardStatus: requestInactive(),

  selectPaymentMethodStatus: requestInactive(),

  paymentStep: PaymentStep.NONE,
  // TODO Merge `paymentDeployStep` to form data
  paymentDeployStep: DeployStep.DEPOSIT,

  candidatePaymentMethod: PaymentMethod.ANKR,

  paymentConfigurationStep: PaymentConfigurationStep.INIT,

  fetchOverviewStatus: requestInactive(),
  overview: [],

  invoiceDetail: undefined,
  fetchInvoiceDetailStatus: requestInactive(),

  checkPriceResultStatus: requestInactive(),

  getDailyPriceStatus: requestInactive(),

  getDepositAmountStatus: requestInactive(),

  ethPaymentStatusStatus: requestInactive(),

  getAppPaymentInfoStatus: requestInactive(),

  getTrialAmountStatus: requestInactive(),
  trialAmount: new BigNumber(0),
};

const payReducer = createReducer(initialState, {
  ...createAPIReducer<IPayState, IExtendedAxiosResponse<{ key: string }>>(
    PayActionTypes.GET_STRIPE_PUBLIC_KEY,
    'getStripePublicKeyStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          stripePublicKey: action.response.data.key,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: IEnableNewBankCardData }>(
    PayActionTypes.CREATE_NEW_BANK_CARD,
    'enableNewBankCardStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          justCreatedCardId: action.data.cardId,
          paymentStep: PaymentStep.NONE,
        };
      },
    },
  ),
  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiPayMethodListResponse>
  >(PayActionTypes.FETCH_PAYMENT_METHODS, 'fetchPaymentMethodsStatus', {
    onSuccess: (state, { response }) => {
      const paymentMethods: IPaymentMethod[] = normalizePayMethodList(
        response.data,
      );
      const selectedPaymentMethod = paymentMethods.find(item => item.selected);

      return {
        ...state,
        paymentMethods,
        currentPaymentMethod: selectedPaymentMethod?.name,
        paymentConfigurationStep: isPaymentConfigurationDone(
          selectedPaymentMethod,
        )
          ? PaymentConfigurationStep.DONE
          : state.paymentConfigurationStep,
      };
    },
  }),
  ...createAPIReducer<IPayState, IExtendedAxiosResponse<IApiPayMethod>>(
    PayActionTypes.FETCH_PAYMENT_METHOD,
    'fetchPaymentMethodStatus',
    {
      onSuccess: (state, { response }) => {
        const paymentMethod = normalizePayMethod(response.data);
        return {
          ...state,
          paymentMethods: updatePaymentMethods(
            state.paymentMethods,
            normalizePayMethod(response.data),
          ),
          paymentConfigurationStep: isPaymentConfigurationDone(paymentMethod)
            ? PaymentConfigurationStep.DONE
            : state.paymentConfigurationStep,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, IExtendedAxiosResponse<IApiPayMethod>>(
    PayActionTypes.SETUP_PAYMENT_METHOD,
    'setupPaymentMethodStatus',
    {
      onSuccess: (state, { response }) => {
        const paymentMethod: IPaymentMethod = normalizePayMethod(response.data);
        return {
          ...state,
          paymentMethods: updatePaymentMethods(
            state.paymentMethods,
            paymentMethod,
          ),
          currentPaymentMethod:
            paymentMethod.name === PaymentMethod.CREDIT_CARD
              ? paymentMethod.name
              : state.currentPaymentMethod,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, IExtendedAxiosResponse<IApiCardList>>(
    PayActionTypes.FETCH_BANK_CARDS,
    'listCardsStatus',
    {
      onSuccess: (state, { response }) => {
        const cards = normalizeCards(response.data.cards);
        return {
          ...state,
          cards: cards,
          currentCard: getCurrentCard(cards),
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: { cards: ICard[] } }>(
    PayActionTypes.DELETE_BANK_CARD,
    'deleteBankCardStatus',
    {
      onSuccess: (state, { data: { cards: removedCards } }) => {
        const cards = state.cards.filter(card =>
          removedCards.find(removedCard => removedCard.id === card.id),
        );

        return {
          ...state,
          cards,
          paymentStep: PaymentStep.SELECT_PAYMENT_METHOD,
        };
      },
    },
  ),
  [PayActionTypes.SETUP_PAYMENT_METHOD_RESET]: (
    state: IPayState,
  ): IPayState => {
    return {
      ...state,
      setupPaymentMethodStatus: requestInactive(),
    };
  },
  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiPayMethodListResponse>
  >(PayActionTypes.SELECT_PAYMENT_METHOD, 'selectPaymentMethodStatus', {
    onSuccess: (state, { response }) => {
      const paymentMethods: IPaymentMethod[] = normalizePayMethodList(
        response.data,
      );
      const selectedPaymentMethod = paymentMethods.find(item => item.selected);

      return {
        ...state,
        currentPaymentMethod: selectedPaymentMethod?.name,
        paymentMethods,
        paymentStep: PaymentStep.NONE,
        paymentConfigurationStep: isPaymentConfigurationDone(
          selectedPaymentMethod,
        )
          ? PaymentConfigurationStep.DONE
          : state.paymentConfigurationStep,
      };
    },
  }),
  [PayActionTypes.SELECT_PAYMENT_METHOD_RESET]: (
    state: IPayState,
  ): IPayState => {
    return {
      ...state,
      selectPaymentMethodStatus: requestInactive(),
    };
  },
  [PayActionTypes.SET_PRE_PAYMENT_PERIOD]: (
    state: IPayState,
    { payload },
  ): IPayState => {
    return {
      ...state,
      prePaymentPeriod: payload,
    };
  },
  ...createAPIReducer<IPayState, IExtendedAxiosResponse<ICheckPriceResponse>>(
    PayActionTypes.CHECK_PRICE,
    'checkPriceResultStatus',
    {
      onSuccess: (state: IPayState, action) => {
        const { data } = action.response;

        return {
          ...state,
          checkPriceResult: normalizePriceResult(data),
        };
      },
    },
  ),

  [PayActionTypes.SET_STEP]: (
    state: IPayState,
    { payload: { step } }: Action<{ step: PaymentStep }>,
  ): IPayState => {
    return {
      ...state,
      paymentStep: step,
      candidatePaymentMethod:
        step === PaymentStep.SELECT_PAYMENT_METHOD
          ? state.currentPaymentMethod
          : state.candidatePaymentMethod,
    };
  },

  [PayActionTypes.SET_PAYMENT_DEPLOY_STEP]: (
    state: IPayState,
    { payload: { step } }: Action<{ step: DeployStep }>,
  ): IPayState => {
    return {
      ...state,
      paymentDeployStep: step,
    };
  },

  [PayActionTypes.SET_CANDIDATE_PAYMENT_METHOD]: (
    state: IPayState,
    { payload: { paymentMethod } }: Action<{ paymentMethod: PaymentMethod }>,
  ): IPayState => {
    return {
      ...state,
      candidatePaymentMethod: paymentMethod,
    };
  },

  [PayActionTypes.SET_PAYMENT_CONFIGURATION_STEP]: (
    state: IPayState,
    { payload: { step } }: Action<{ step: PaymentConfigurationStep }>,
  ): IPayState => {
    return {
      ...state,
      paymentConfigurationStep: step,
    };
  },

  [UserActionTypes.SIGNOUT]: (): IPayState => ({
    ...initialState,
  }),
  [UserActionTypes.SIGNIN]: (): IPayState => ({
    ...initialState,
  }),

  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiDepositAmount & IExtendedRequestAction>
  >(PayActionTypes.GET_DAILY_AMOUNT, 'getDailyPriceStatus', {
    onSuccess: (state, action) => {
      const data = mapDepositAmount(action.response.data);
      return {
        ...state,
        dailyPrice: getDailyPrice(data.usdAmount),
        depositUsdMonthlyAmount: data.usdAmount,
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiDepositAmount & IExtendedRequestAction>
  >(PayActionTypes.GET_DEPOSIT_AMOUNT, 'getDepositAmountStatus', {
    onSuccess: (state, action) => {
      const data = mapDepositAmount(action.response.data);

      return {
        ...state,
        depositUsdtAmount: data.usdtAmount,
        depositAnkrAmount: data.ankrAmount,
        depositUsdAmount: data.usdAmount,
        depositSaveUsdAmount: data.saveUsd,
        depositAmountMethod: action.meta?.id as PaymentMethod,
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    { data: IApiEthPaymentStatus | undefined } & IExtendedRequestAction
  >(PayActionTypes.GET_ETH_PAYMENT_STATUS, 'ethPaymentStatusStatus', {
    onSuccess: (state, action) => {
      const data = action.data ? mapEthPaymentStatus(action.data) : undefined;

      return {
        ...state,
        ethPaymentAddress: data?.address,
        ethPaymentAmount: data?.amount,
        ethPaymentUsdAmount: data?.usd,
        ethPaymentMethod: data?.paymentMethod,
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiDepositList & IExtendedRequestAction>
  >(PayActionTypes.GET_DEPOSIT_LIST, 'getDepositListStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        depositList: mapDepositList(action.response.data),
      };
    },
  }),

  ...createAPIReducer<IPayState, { data: IApiPrepareExtendOrderData }>(
    PayActionTypes.EXTEND_APP,
    'extendAppStatus',
    {
      onSuccess: (state, action) => {
        const { amount: extendAmount, paymentMethod } = mapExtendPlan(
          action.data,
        );

        return {
          ...state,
          extendAmount,
          extendPaymentMethod: paymentMethod,
        };
      },
    },
  ),

  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiPrePaymentResponse>
  >(PayActionTypes.GET_PRE_PAYMENT_LIST, 'getPrePaymentDepositListStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        prePaymentDepositList: mapPaymentList(action.response.data),
      };
    },
  }),

  ...createAPIReducer<IPayState, IExtendedAxiosResponse<IApiAppPaymentData>>(
    PayActionTypes.GET_APP_PAYMENT_INFO,
    'getAppPaymentInfoStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          appPaymentInfo: mapAppPaymentData(action.response.data),
        };
      },
    },
  ),

  ...createAPIReducer<IPayState, IExtendedAxiosResponse<{ balance: string }>>(
    PayActionTypes.GET_TRIAL_AMOUNT,
    'getTrialAmountStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          trialAmount: new BigNumber(action.response.data.balance),
        };
      },
    },
  ),
  [PayActionTypes.RESET_TRIAL_AMOUNT]: (state: IPayState) => ({
    ...state,
    trialAmount: new BigNumber(0),
  }),
});

export { payReducer };

import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 50%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 'inherit',
    },
  },
  label: {
    marginBottom: 14,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,
    },
  },
}));

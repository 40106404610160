import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';
import { REGEX_BETWEEN_BRACKETS } from '../../../utils/markdown';

export function parseCompound(response: IHookResponse): IHookResponse {
  try {
    const result = response.data?.result ?? '';

    const regexMatch = result.match(REGEX_BETWEEN_BRACKETS);

    const resultBracket = regexMatch?.[0];
    const resultObj = safeParseJSON(resultBracket ?? '{}');

    return {
      ...response,
      data: {
        result: resultObj,
      },
    };
  } catch (err) {
    return {
      ...response,
    };
  }
}

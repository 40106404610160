import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

export const GATrackPageView = async (pathname: string) => {
  try {
    ReactGA.pageview(pathname);
  } catch (error) {
    Sentry.captureException(error);
  }
};

import React from 'react';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { Milliseconds } from '../../types';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (_: Theme): StyleRules => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});

const APP_MIN_LOADING_TIME: Milliseconds = 500;

function AppLoadingComponent({ classes = {} }: StyledComponentProps) {
  return (
    <div className={classes.root}>
      <LoadingIndicator displaySince={APP_MIN_LOADING_TIME} />
    </div>
  );
}

const AppLoading = withStyles(styles)(AppLoadingComponent);
export { AppLoading };

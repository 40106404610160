import { withStyles } from '@material-ui/core';
import { NothingFoundStyles } from './NothingFoundStyles';
import NothingFoundIcon from './assets/nothing-found.svg';
import { t } from 'common/utils/intl';
import React from 'react';
import { Classes } from 'jss';

interface INothingFoundComponent {
  classes?: Classes;
  title?: string;
  icon?: string;
}

const NothingFoundComponent = ({
  classes = {},
  title,
  icon,
}: INothingFoundComponent) => {
  return (
    <div className={classes.root}>
      <img
        src={icon || NothingFoundIcon}
        alt={title || t('nothing-found.warning')}
      />
      <div className={classes.label}>{title || t('nothing-found.warning')}</div>
    </div>
  );
};

const NothingFound = withStyles(NothingFoundStyles)(NothingFoundComponent);

export { NothingFound };

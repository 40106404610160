import BigNumber from 'bignumber.js';
import { PaymentMethod } from '../const';

export interface IApiEthPaymentStatus {
  address: string;
  amount: string;
  usd: string;
  paymentMethod?: PaymentMethod;
}

interface IEthPaymentStatus {
  address: string;
  amount: BigNumber;
  usd: BigNumber;
  paymentMethod?: PaymentMethod;
}

export function mapEthPaymentStatus(
  data: IApiEthPaymentStatus,
): IEthPaymentStatus {
  return {
    address: data.address,
    amount: new BigNumber(data.amount),
    usd: new BigNumber(data.usd),
    paymentMethod: data.paymentMethod,
  };
}

import React, { ReactNode } from 'react';
import { t } from 'common/utils/intl';
import { StatusHookRow } from './StatusHookRow';
import { SyncStatusVariant } from 'common/modules/nodes/const';
import { HooksGroup } from './HooksGroup';
import { HookRow, IHookRowProps } from './HookRow';
import { getPercentage } from 'common/utils/get-percentage';

interface IProgressHookRowProps extends IHookRowProps {
  currentHeight?: number;
  height?: number;
  nodeStatusTitleText?: string;
  nodeStatusTitleHint?: string;
  currentHeightTitleText?: string;
  currentHeightTitleHint?: string;
  hooksGroupTitle?: string;
  altTextStatus?: string;
  withHooksGroup?: boolean;
  isSyncing?: boolean;
  ignoreZeroHeight?: boolean;
  prefix?: React.ReactElement<any> | React.ReactElement[] | false | ReactNode;
}

export const ProgressHookRow = ({
  currentHeight = 0,
  height = 0,
  nodeStatusTitleText,
  currentHeightTitleText,
  currentHeightTitleHint,
  nodeStatusTitleHint,
  hooksGroupTitle,
  altTextStatus,
  withHooksGroup = true,
  ignoreZeroHeight,
  isSyncing,
  children,
  prefix,
  ...rest
}: IProgressHookRowProps) => {
  const ratio = getPercentage(currentHeight, height);
  const isSyncingInternal =
    isSyncing ?? (!ignoreZeroHeight && (height === 0 || ratio < 100));
  const data = `${currentHeight.toLocaleString()}/${height.toLocaleString()}`;

  const renderStatus = () => {
    return (
      <>
        {prefix}
        <StatusHookRow
          {...rest}
          nested={true}
          showBorder={false}
          showBoldLabel={false}
          title={
            nodeStatusTitleText ?? t('nodes.progress-hook-row.node-status')
          }
          hint={
            nodeStatusTitleHint ?? t('nodes.progress-hook-row.node-status-hint')
          }
          syncStatus={
            isSyncingInternal
              ? SyncStatusVariant.progressBlue
              : SyncStatusVariant.done
          }
          altTextStatus={altTextStatus}
          percentage={ratio}
        />

        {isSyncingInternal && (
          <HookRow
            {...rest}
            showBoldLabel={false}
            showBorder={false}
            title={
              currentHeightTitleText ??
              t('nodes.progress-hook-row.current-height')
            }
            hint={
              currentHeightTitleHint ??
              t('nodes.progress-hook-row.current-height-hint')
            }
            children={data}
            nested={true}
          />
        )}
        {children}
      </>
    );
  };

  if (withHooksGroup) {
    return (
      <HooksGroup
        {...rest}
        title={hooksGroupTitle ?? t('nodes.progress-hook-row.title')}
      >
        {renderStatus()}
      </HooksGroup>
    );
  }

  return renderStatus();
};

import { TerraHooks, terraNode } from './terraConst';
import { getPrefixedInstanceDefaultName } from '../../../AppDeployUI/utils/getInstanceDefaultName';
import {
  ICreateInitialValueParams,
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import { validateTerraNodeName } from './utils/terraValidations';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import { TerraCustomValue } from './deploy/TerraCustomValue';
import { TerraInformationTab } from './manage/TerraInformationTab';

export const makeTerraConfig = (nodeName: string): INodeConfig => ({
  chartName: nodeName,
  deploy: {
    component: TerraCustomValue,
  },
  deployValidation: {
    ankrCustomValues___moniker: validateTerraNodeName,
  },
  getDefaultCustomValues: ({
    appList,
    chartName,
    userName,
  }: ICreateInitialValueParams) => ({
    replicas: 1,
    customValues: {
      ankrCustomValues___moniker: getPrefixedInstanceDefaultName(
        appList,
        chartName,
        userName,
      ),
      ankrCustomValues___node_type: 'default',
    },
  }),
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => {
    return {
      ankrCustomValues___enable_snapshot: customValues
        ? Boolean(customValues['ankrCustomValues___enable_snapshot']).toString()
        : 'false',
    };
  },
  hideCustomVariableDivider: true,
  info: TerraInformationTab,
  manage: false as false,
  details: false as false,
  interceptors: {
    [terraNode]: {
      [TerraHooks.TERRA_GET_STATUS]: defaultParse,
    },
  },
});

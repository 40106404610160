export const tezosValidatorNodeName = 'tezos-validator';

export enum TezosValidatorHooks {
  getNodeStatus = 'apphook1=get-node-status',
  getBakingBalance = 'apphook2=get-baking-balance',
  getBakingAddr = 'apphook3=get-baking-addr',
  isImported = 'apphook4=is-imported',
  importPublicKey = 'apphook5=import-public-key',
  registerDelegate = 'apphook6=register-delegate',
  is_delegate = 'apphook7=is_delegate',
  get_auth_key = 'apphook8=get_auth_key',
}

export const TEZOS_VALIDATOR_TEZOS_NETWORK = 'ankrCustomValues___tezos_network';

import BigNumber from 'bignumber.js';

export interface IAPIMarketItemResponse {
  id: string;
  code: string;
  node_family: string;
  name: string;
  network: string;
  icon_url: string;
  icon_only_url: string;
  price: string;

  has_ws: boolean;
  path_express: string;
  service_name: string;
  status: string;
  supported_cluster_ids: string[];
  shown: boolean;
}

export interface IAPIMarketItem
  extends Omit<
    IAPIMarketItemResponse,
    | 'node_family'
    | 'icon_url'
    | 'icon_only_url'
    | 'price'
    | 'supported_cluster_ids'
    | 'has_ws'
    | 'path_express'
    | 'service_name'
    | 'status'
  > {
  nodeType: string;
  nodeName: string;
  iconUrl: string;
  iconOnlyUrl: string;
  price: BigNumber;
  supportedClusterIds: string[];
  shown: boolean;
}

export interface IAPIMarketNodePreview {
  name: string;
  disableReason?: string;
}

export interface IAPIMarketItemPreview {
  nodeFamily: string;
  displayName: string;
  iconUrl: string;
  nodes: IAPIMarketNodePreview[];
}

export interface IAPIMarketInstanceResponse {
  id: string;
  node_code: string;
  project_name: string;
  network: string;
  total_requests: number;
  today_request: number;
  create_time: string;
  active_until: string;
  status: string;
  endpoint: string;
  iconUrl: string;
  iconOnlyUrl: string;
  node_type: string;
  node_name: string;
  icon_url: string;
  icon_only_url: string;
  auth_username: string;
  auth_password: string;
  plan: string;
  order_id: string;
  ws_endpoint: string;
  maximum_limit: string;
  subscribe_status: SubscribeStatus;
  supportedClusterIds: string[];
  cluster_name: string;
}

interface IAPIMarketPlanResponse {
  id: string;
  type: string;
  type_desc: string;
  ratet_limit_sec: number;
  max_req_daily: number;
  max_req_mo: number;
  price: number;
  node_codes: string[];
  create_time: string;
  status: string;
  support: number;
}

export interface IAPIOrderResponse {
  order_id: string;
  original_order_id: string;
  api_type: string;
  api_id: string;
  plan_id: string;
  plan_duration_month: number;
  server_price: string;
  auth_type: string;
  auth_username: string;
  auth_password: string;
  auth_token: string;
  create_time: string;
  delete_time: string;
  order_status: string;
  api_level_type: string;
}

export interface IAPIDetailsResponse {
  id: string;
  node_code: string;
  project_name: string;
  network: string;
  total_requests: number;
  today_request: number;
  status: string;
  endpoint: string;
  ws_endpoint: string;
  auth_username: string;
  auth_password: string;
  plan: string;
  order_id: string;
}

export interface IArchiveModePaymentStatusResponse {
  payment_status: string;
}

export interface IAPIMarketInstance
  extends Pick<
      IAPIMarketInstanceResponse,
      'id' | 'network' | 'endpoint' | 'iconUrl' | 'iconOnlyUrl'
    >,
    Omit<IAPIMarketItem, 'name' | 'price'> {
  projectName: string;
  nodeName: string;
  totalRequests: number;
  todaysRequests: number;
  maximumLimit: number;
  nodeCode: string;
  plan: string;
  authUsername: string;
  authPassword: string;
  wsEndpoint: string;
  isActive: boolean;
  orderId: string;
  subscribeStatus: SubscribeStatus;
  createTime: number;
  activeUntil: number;
  clusterId: string;
}

export interface IAPIMarketPlan {
  id: string;
  type: string;
  limitDaily: BigNumber;
  limitMonthly: BigNumber;
  limitSecondly: BigNumber;
  price: BigNumber;
  nodeCodes: string[];
  dateCreated: Date;
  support: number;
}

export interface IAPIDetails {
  id: string;
  projectName: string;
  username: string;
  password: string;
  network: string;
  endpoint: string;
  wsEndpoint: string;
  nodeCode: string;
  isActive: boolean;
  plan: string;
  orderId: string;
}

export interface IAPIOrder {
  id: string;
  apiType: APIType;
  planId: string;
  duration: number;
  price: BigNumber;
  authType: APIAuthenticationType;
  username: string;
  password: string;
  token: string;
  dateCreated: Date;
  status: APIOrderStatus;
}

export interface IAPIMarketAPIResponse {
  data: IAPIMarketInstanceResponse[];
}

export interface IAPIMarketResponse {
  data: IAPIMarketItemResponse[];
}

export interface IAPIProjectAllowedDomainResponse {
  allowed_cross_domain_list: string[];
}

export interface IAPIProjectWhiteResponse {
  ip_white_list: string[];
}

export interface IAPIMarketPlansResponse {
  data: IAPIMarketPlanResponse[];
}

export interface IAPIMarketInstanceStats {
  key: string;
  date: Date;
  value: number;
}
interface IAPIAPIMarketInstanceStats {
  key: string;
  date: string;
  value: number;
}
export interface IAPIMarketInstanceStatsResponse {
  data: IAPIAPIMarketInstanceStats[];
}

export interface IAPIUpgradePlanAmountResponse {
  amounts_payable: string;
  special_api_amounts_payable: string;
  amounts_all_payable: string;
}

type CallTimeMs = number;
type CallCount = string;

export interface IAPIProjectLogResponse {
  total_calls: Record<CallTimeMs, CallCount>;
}

export interface IAPIMarketItemDocumentation {
  overview: string;
  availableNodes: string[];
  explorerURL: string;
  documentationHTML: string;
}

export interface IArchiveModePaymentStatus {
  status: ArchiveModePaymentStatus;
}

export interface IAPIMarketBlockInfoResponse {
  current_block: string;
  highest_block: string;
}

export interface IAPIMarketBlockInfo {
  currentBlock: number;
  highestBlock: number;
}

export interface IAPIPayment {
  payway: PayWay;
  cardId?: string;
}

export enum APICreateStep {
  INIT = 'INIT',
  AUTHENTICATION = 'AUTHENTICATION',
  PAYMENT = 'PAYMENT',
  ADD_CARD = 'ADD_CARD',
}

export enum APIPlanUpgradeStep {
  INIT = 'INIT',
  SELECT_CARD = 'SELECT_CARD',
  ADD_CARD = 'ADD_CARD',
  UPGRADING = 'UPGRADING',
}

export enum PayWay {
  CreditCard = 'pay_way_credit_card',
  AnkrCredit = 'pay_way_ankr_credit',
}

export enum PaymentMethod {
  CreditCard = 'payment_credit_card',
  AnkrCredit = 'payment_ankr_credit',
}

export enum APIType {
  Unknown = 'api_type_unknown',
  Unshared = 'api_type_unshared',
  Shared = 'api_type_shared',
}

export enum APIAuthenticationType {
  Basic = 'api_auth_type_basic_auth',
  Token = 'api_auth_type_token',
}

export enum APIOrderStatus {
  Success = 'order_status_pay_success',
}

export enum ArchiveModePaymentStatus {
  Unknown = 'special_api_status_unknown',
  Unpaid = 'special_api_status_unpaid',
  Paid = 'special_api_status_paid',
}

export enum SubscribeStatus {
  Enable = 'subscribe_enable',
  Disable = 'subscribe_disable',
}

type ErrorCode = string;
type HttpStatus = string;

export enum TimeRangePreset {
  Last5Minutes = 'last5Minutes',
  LastHour = 'lastHour',
  LastDay = 'lastDay',
  Last7Days = 'last7Days',
}

export const JSON_RPC_METHODS = [
  'web3_sha3',
  'web3_clientVersion',
  'net_version',
  'net_listening',
  'net_peerCount',
  'eth_syncing',
  'eth_mining',
  'eth_gasPrice',
  'eth_accounts',
  'eth_blockNumber',
  'eth_getBalance',
  'eth_getStorageAt',
  'eth_getTransactionCount',
  'eth_getBlockTransactionCountByHash',
  'eth_getBlockTransactionCountByNumber',
  'eth_getCode',
  'eth_call',
  'eth_estimateGas',
  'eth_getBlockByHash',
  'eth_getBlockByNumber',
  'eth_getTransactionByHash',
  'eth_getTransactionByBlockHashAndIndex',
  'eth_getTransactionByBlockNumberAndIndex',
  'eth_getTransactionReceipt',
  'eth_getUncleByBlockHashAndIndex',
  'eth_getUncleByBlockNumberAndIndex',
  'eth_newFilter',
  'eth_newBlockFilter',
  'eth_uninstallFilter',
  'eth_getFilterChanges',
  'eth_getLogs',
  'eth_getUncleCountByBlockHash',
  'eth_getUncleCountByBlockNumber',
] as const;

type JsonRpcMethod = typeof JSON_RPC_METHODS[number];

export interface IAPIRequestsHistoryListParams {
  pageRow: number;
  pageIndex: number;
  timeMin?: number;
  timeMax?: number;
  timeRangePreset?: TimeRangePreset;
  httpStatusCodeMin?: string;
  httpStatusCodeMax?: string;
  httpFailStatus?: boolean;
  jsonRpcErrorCodeMin?: string;
  jsonRpcErrorCodeMax?: string;
  jsonRpcMethodList?: JsonRpcMethod[];
  jsonRpcId?: string;
}

export interface IAPIRequestsHistoryListServerParams {
  page_rows: number;
  page_index: number;

  time_filters?: {
    time_min?: number;
    time_max?: number;
    time_range_preset?: TimeRangePreset;
  };
  http_status_filters?: {
    http_status_code_min?: string;
    http_status_code_max?: string;
    http_fail_status?: boolean;
  };
  json_rpc_error_code_filters?: {
    json_rpc_error_code_min?: string;
    json_rpc_error_code_max?: string;
  };
  json_rpc_method_list?: JsonRpcMethod[];
  json_rpc_id?: string;
}

export interface IAPIRequestsHistoryResponse {
  method: JsonRpcMethod;
  error_code: ErrorCode;
  http_status: HttpStatus;
  response_time: string;
  sent_time: string; // utc timestamp / 1000
  raw_request: string;
  raw_response: string;
}

export interface IAPIRequestsHistoryItem {
  method: JsonRpcMethod;
  errorCode: ErrorCode;
  httpStatus: HttpStatus;
  responseTime: string;
  sentTime: number;
  rawRequest: string;
  rawResponse: string;
}

export interface IAPIRequestsHistoryResponse {
  api_log_res_list: IAPIRequestsHistoryResponse[];
  item_count: string;
}

export interface IApiListResponse {
  project_list: IAPIMarketInstanceResponse[];
}
export interface IAPIClusterResponse {
  cluster_id: string;
  geo_location: {
    city: string;
    continent: string;
    country: string;
  };
}

export interface IAPICluster {
  clusterId: string;
  geoLocation: {
    city: string;
    continent: string;
    country: string;
  };
}

export interface IAPIClusterListResponse {
  clusters: IAPIClusterResponse[];
}

import React from 'react';
import { Box } from '@material-ui/core';

import { ProfileButtonContainer } from 'layouts/MainLayout/Toolbar/ProfileButton';
import { ToolbarNotification } from 'layouts/MainLayout/Toolbar/ToolbarNotification';
import { useStyles } from './ToolbarStyles';
import { ToolBarBalance } from './ToolBarBalance';

export const ToolBar = () => {
  const classes = useStyles();

  return (
    <Box
      m={-2}
      ml="auto"
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <ToolBarBalance />
      <ToolbarNotification className={classes.button} />
      <ProfileButtonContainer />
    </Box>
  );
};

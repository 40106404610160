import { put } from 'redux-saga/effects';
import { RedirectActions } from '../actions/RedirectActions';
import { historyInstance } from 'common/utils/historyInstance';
import { parse } from 'query-string';
import { PERSIST_KEY } from '../const';

export function* setPathEffect() {
  const search = parse(historyInstance.location.search);

  if (
    search[PERSIST_KEY] &&
    search.resource &&
    typeof search.resource === 'string'
  ) {
    yield put(RedirectActions.setRedirect(search.resource));
  }
}

import React, { useRef } from 'react';
import {
  Box,
  StyledComponentProps,
  StyleRules,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  ICustomValue,
  ICustomValueComponentProps,
} from 'common/modules/apps/types';
import { findCustomValue } from 'modules/AppDeployUI/utils/hookUtils';
import { t } from 'common/utils/intl';
import { Field, FormSpy } from 'react-final-form';
import { CheckboxField } from 'common/components/Form/Checkbox/CheckboxField';

import { TooltipHint } from 'common/components/TooltipHint';
import { DefaultCustomValues } from '../../../../AppDeployUI/components/CustomizeVariables/DefaultCustomValues';
import { RadioGroupField } from 'common/components/Form/RadioGroup';
import {
  Message,
  MessageType,
  MessageVariant,
} from 'common/components/Message';
import { ReplicasContainer } from '../../../components/ReplicasContainer';
import { ReplicasInput } from '../../../components/ReplicasInput';

const styles = (theme: Theme): StyleRules => ({
  root: {
    '& input': {
      margin: 0,
      marginRight: theme.spacing(1),
    },
    width: '100%',
  },
});

interface ITerraCustomValueProps
  extends ICustomValueComponentProps,
    StyledComponentProps {}

const TerraCustomValueComponent = ({
  classes = {},
  customValues,
  values,
  disableReplicas,
}: ITerraCustomValueProps) => {
  const state = useRef<{
    replicasEnabled: boolean;
    replicas: number;
  }>({
    replicasEnabled: false,
    replicas: 2,
  });
  const enableReplicas =
    (values?.customValues?.enable_replicas || false) && !disableReplicas;

  const enableSnapshot = findCustomValue(
    customValues,
    'ankrCustomValues___enable_snapshot',
  );

  const archiveMode = {
    ...findCustomValue(customValues, 'ankrCustomValues___node_type'),
    value: 'false',
  } as ICustomValue;

  return (
    <div className={classes.root}>
      <Box m={2}>
        <Message
          message={t(
            'app-deploy.default-custom-variables.overrides.terra.message',
          )}
          type={MessageType.info}
          variant={MessageVariant.quote}
        />
      </Box>
      <div>
        {archiveMode && (
          <DefaultCustomValues
            customValues={[archiveMode]}
            attributes={[
              {
                Component: RadioGroupField,
                attributes: {
                  type: 'radio',
                  margin: 'none',
                  defaultValue: 'default',
                  items: [
                    {
                      id: 'default',
                      name:
                        'app-deploy.default-custom-variables.overrides.terra.label.ankrCustomValues.non-archive',
                      description: '',
                    },
                    {
                      id: 'archive',
                      name:
                        'app-deploy.default-custom-variables.overrides.terra.label.ankrCustomValues.archive',
                      description: '',
                    },
                  ],
                },
              },
            ]}
          />
        )}
      </div>
      {enableSnapshot && (
        <Box m={2} mt={1} display="flex" alignItems="center">
          <Field
            name={`customValues.${enableSnapshot.key}`}
            component={CheckboxField}
            label={t(
              `app-deploy.default-custom-variables.overrides.terra.label.ankrCustomValues.enable_snapshot`,
            )}
            type="checkbox"
          />
          <TooltipHint
            description={t(
              `app-deploy.default-custom-variables.overrides.terra.description.ankrCustomValues.enable_snapshot`,
            )}
          />
        </Box>
      )}
      {!disableReplicas && (
        <Box m={2} display="flex" alignItems="center">
          <Field
            name={`customValues.enable_replicas`}
            component={CheckboxField}
            label={t(
              'app-deploy.default-custom-variables.overrides.terra.label.ankrCustomValues.enable_replicas',
            )}
            type="checkbox"
          />
          <TooltipHint
            description={t(
              `app-deploy.default-custom-variables.overrides.terra.description.ankrCustomValues.enable_replicas`,
            )}
          />
        </Box>
      )}
      {/*TODO: Move into ReplicasInput*/}
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          if (!values?.customValues?.enable_replicas) {
            if (
              values?.customValues?.enable_replicas !==
              state.current.replicasEnabled
            ) {
              form.change('replicas', 1);
              state.current.replicasEnabled = false;
            }
          } else {
            if (
              state.current.replicasEnabled !==
              values?.customValues?.enable_replicas
            ) {
              form.change('replicas', state.current.replicas);
              state.current.replicasEnabled = true;
            } else if (values?.replicas > 1) {
              state.current.replicasEnabled = true;
              state.current.replicas = values?.replicas ?? 2;
            }
          }
          return null;
        }}
      </FormSpy>

      <ReplicasInput
        hidden={!enableReplicas}
        label={t(
          `app-deploy.default-custom-variables.overrides.terra.label.ankrCustomValues.replicas`,
        )}
      />
      <ReplicasContainer>
        <Box m={1}>
          <Box m={2}>
            <Message
              message={t(
                'app-deploy.default-custom-variables.overrides.terra.multiple-instances',
              )}
              type={MessageType.info}
              variant={MessageVariant.quote}
            />
          </Box>
        </Box>
      </ReplicasContainer>
    </div>
  );
};

const TerraCustomValue = withStyles(styles)(TerraCustomValueComponent);

export { TerraCustomValue };

import { StyleRules } from '@material-ui/core/styles';

export const requestStatusDisplayStyles = (): StyleRules => ({
  loadingIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nothingFound: {},
  error: {},
});

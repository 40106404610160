import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const BillingIcon = withSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20 17C20 18.1046 19.1046 19 18 19H6C4.89543 19 4 18.1046 4 17V7C4 5.89543 4.89543 5 6 5H18C19.1046 5 20 5.89543 20 7V17ZM6 7H18V9H14C13.4477 9 13 9.44772 13 10V14C13 14.5523 13.4477 15 14 15H18V17H6V7ZM18 13V11H15V13H18Z"
    fill="currentColor"
  />,
);

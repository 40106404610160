import {
  IAPIMarketInstance,
  IAPIMarketInstanceResponse,
  IAPIMarketItem,
  IAPIMarketItemPreview,
  IAPIMarketItemResponse,
  IAPIMarketNodePreview,
  IAPIRequestsHistoryListParams,
  IAPIRequestsHistoryListServerParams,
} from './store/APIMarketTypes';
import { API_NAME_MAX_LENGTH } from './APIMarketConsts';

import binanceIcon from './assets/binance.svg';
import ethereumIcon from './assets/ethereum.svg';
import polkadotIcon from './assets/polkadot.svg';
import eosIcon from './assets/eos.svg';
import okexchainIcon from './assets/okchain.svg';
import kusamaIcon from './assets/kusama.svg';
import offchainIcon from './assets/offchain.svg';
import cubeIcon from './assets/cube.png';
import BigNumber from 'bignumber.js';
import { EMPTY_VALUE } from 'common/core/const';

const NUM_REGEXP = /-[0-9]+$/;

const findMaxInstance = (list: IAPIMarketInstance[], chainName: string) => {
  const filteredAPIs = list.filter(
    api => chainName.toLowerCase() === api.nodeName.toLowerCase(),
  );
  let max = 0;

  if (filteredAPIs) {
    max = filteredAPIs.length;
    filteredAPIs.forEach(app => {
      const res = app.projectName.match(NUM_REGEXP);
      if (!res) return;
      const cur = +res[0].replace('-', '');
      max = cur > max ? cur : max;
    });
  }
  return new BigNumber(max).plus(1);
};
export const getDefaultAPIName = (
  appList: IAPIMarketInstance[],
  node: string,
) => {
  let result: string;
  try {
    const newInstanceNum = findMaxInstance(appList, node).toFixed();

    result = `${node}-API`
      .substring(0, API_NAME_MAX_LENGTH - newInstanceNum.length - 1)
      .concat(`-${newInstanceNum}`)
      .replace(/ /g, '-');
  } catch {
    result = `${node}-API-1`;
  }

  return result;
};

export const renderIcon = (family: string) => {
  switch (family) {
    case 'binance':
      return binanceIcon;
    case 'ethereum':
      return ethereumIcon;
    case 'polkadot':
      return polkadotIcon;
    case 'eos':
      return eosIcon;
    case 'okexchain':
      return okexchainIcon;
    case 'kusama':
      return kusamaIcon;
    case 'offchain':
      return offchainIcon;
    default:
      return cubeIcon;
  }
};

export const MILLION = 1000000;
export const THOUSAND = 1000;

export const withSeparators = (input: string) =>
  input.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const byThousand = (limit: BigNumber): string => {
  if (limit.lt(THOUSAND)) {
    return limit.toFixed();
  }

  return `${withSeparators(limit.dividedBy(THOUSAND).toFixed())}K`;
};

export const getNodeName = (nodeType: string): string => {
  if (!nodeType) return '';
  switch (nodeType) {
    case 'ethereum':
      return 'Ethereum';
    case 'binance':
      return 'Binance Smart Chain';
    default:
      return nodeType[0].toUpperCase() + nodeType.slice(1);
  }
};

const emptyValues = [null, undefined, EMPTY_VALUE, ''];
export const isEmpty = (value: any) => {
  return emptyValues.includes(value);
};

function getMarketItems(market: IAPIMarketItem[]): IAPIMarketItemPreview[] {
  return Object.values(
    market.reduce((result, item) => {
      if (!result[item.nodeType]) {
        result[item.nodeType] = {
          nodeFamily: item.nodeType,
          displayName: getNodeName(item.nodeType),
          iconUrl: item.iconOnlyUrl,
          nodes: [],
        };
      }
      const nodes = result[item.nodeType].nodes;
      if (
        !nodes.find((node: IAPIMarketNodePreview) => node.name === item.name)
      ) {
        nodes.push({
          name: item.name,
        });
      }
      return result;
    }, {}),
  );
}

function flatMarketItems(
  items: IAPIMarketItemPreview[],
): IAPIMarketItemPreview[] {
  return items.reduce((acc, item) => {
    return [
      ...acc,
      ...item.nodes.map(node => ({
        nodeFamily: item.nodeFamily,
        displayName: node.name,
        iconUrl: item.iconUrl,
        nodes: [node],
      })),
    ];
  }, [] as IAPIMarketItemPreview[]);
}

export function getFlattenMarketItems(
  market: IAPIMarketItem[],
): IAPIMarketItemPreview[] {
  const marketItems = getMarketItems(market);
  return flatMarketItems(marketItems);
}

export const DEFAULT_ITEMS_LIMIT = 20;

export const prepareAPIRequestsHistoryListParamsForServer = (
  params: IAPIRequestsHistoryListParams,
): IAPIRequestsHistoryListServerParams => {
  const {
    pageRow,
    pageIndex,
    timeMin,
    timeMax,
    timeRangePreset,
    httpStatusCodeMin,
    httpStatusCodeMax,
    httpFailStatus,
    jsonRpcErrorCodeMin,
    jsonRpcErrorCodeMax,
    jsonRpcMethodList,
    jsonRpcId,
  } = params;

  const serverParams: IAPIRequestsHistoryListServerParams = {
    page_rows: pageRow,
    page_index: pageIndex,
  };

  if (timeMin || timeMax || timeRangePreset) {
    const time_filters: IAPIRequestsHistoryListServerParams['time_filters'] = {};

    if (timeMax) {
      time_filters.time_max = timeMax;
    }
    if (timeMin) {
      time_filters.time_min = timeMin;
    }
    if (timeRangePreset) {
      time_filters.time_range_preset = timeRangePreset;
    }

    serverParams.time_filters = time_filters;
  }

  if (httpStatusCodeMin || httpStatusCodeMax || httpFailStatus) {
    const http_status_filters: IAPIRequestsHistoryListServerParams['http_status_filters'] = {};

    if (httpStatusCodeMin) {
      http_status_filters.http_status_code_min = httpStatusCodeMin;
    }
    if (httpStatusCodeMax) {
      http_status_filters.http_status_code_max = httpStatusCodeMax;
    }
    if (httpFailStatus) {
      http_status_filters.http_fail_status = httpFailStatus;
    }

    serverParams.http_status_filters = http_status_filters;
  }

  if (jsonRpcErrorCodeMin || jsonRpcErrorCodeMax) {
    const json_rpc_error_code_filters: IAPIRequestsHistoryListServerParams['json_rpc_error_code_filters'] = {};

    if (jsonRpcErrorCodeMin) {
      json_rpc_error_code_filters.json_rpc_error_code_min = jsonRpcErrorCodeMin;
    }
    if (jsonRpcErrorCodeMax) {
      json_rpc_error_code_filters.json_rpc_error_code_max = jsonRpcErrorCodeMax;
    }

    serverParams.json_rpc_error_code_filters = json_rpc_error_code_filters;
  }

  if (jsonRpcMethodList) {
    serverParams.json_rpc_method_list = jsonRpcMethodList;
  }

  if (jsonRpcId) {
    serverParams.json_rpc_id = jsonRpcId;
  }

  return serverParams;
};

export const matchMarketInstanceResponseAndMarketItemResponse = (
  api: IAPIMarketInstanceResponse,
  market: IAPIMarketItemResponse,
): IAPIMarketInstanceResponse => {
  const {
    node_family,
    name,
    icon_url,
    icon_only_url,
    network,
    supported_cluster_ids,
  } = market;

  return {
    ...api,
    node_type: node_family,
    node_name: name,
    iconUrl: icon_url,
    iconOnlyUrl: icon_only_url,
    network,
    supportedClusterIds: supported_cluster_ids,
  };
};

export const zcashName = 'zcash';

export enum ZcashHooks {
  getStatus = 'apphook1=get-node-status',
  getCredentials = 'apphook2=get-rpc-credentials',
  getProgress = 'apphook3=get-progress',
}

export const ZCASH_USE_PASSWORD = 'ankrCustomValues___rpcpassword';
export const ZCASH_USE_USERNAME = 'ankrCustomValues___rpcuser';

import { SifchainHooks, sifchainNodeName } from './SifchainConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/sifchain.en-US.json';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/SifchainManage').then(module => module.SifchainManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/SifchainCustomValues').then(
      module => module.SifchainCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      current_height: Number(data.current_height ?? 0) ?? 0,
      latest_height: Number(data.latest_height ?? 0) ?? 0,
      status: data.status === 'true',
    },
  };
}

export const sifchainConfig: INodeConfig = {
  chartName: sifchainNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'sifnode-node': {
      [SifchainHooks.nodeStatus]: parseStatus,
    },
  },
};

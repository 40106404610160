import { requestInactive, RequestStatus } from '../../../utils/requestStatus';
import { createAPIReducer, createReducer } from '../../../utils/reduxUtils';
import { InvoiceActionTypes } from '../actions/InvoiceActions';
import { IOverview, IOverviewItem } from './invoiceReducerTypes';
import BigNumber from 'bignumber.js';
import { IApisInvoice } from '../../apps/types/invoiceType';
import { ZERO } from 'common/core/const';
import { UserActionTypes } from 'auth/store/actions/UserActions';

export interface IInvoiceState {
  fetchOverviewStatus: RequestStatus;
  overview: IOverviewItem[];
  total: BigNumber;
  apisInvoiceList: IApisInvoice[];
  fetchApisInvoiceListStatus: RequestStatus;
}

const initialState: IInvoiceState = {
  fetchOverviewStatus: requestInactive(),
  overview: [],
  total: ZERO,
  apisInvoiceList: [],
  fetchApisInvoiceListStatus: requestInactive(),
};

export const invoiceReducer = createReducer(initialState, {
  ...createAPIReducer<IInvoiceState, { data: IOverview }>(
    InvoiceActionTypes.FETCH_OVERVIEW,
    'fetchOverviewStatus',
    {
      onSuccess: (state, { data: { apps, total } }) => {
        return {
          ...state,
          overview: apps,
          total,
        };
      },
    },
  ),
  [UserActionTypes.SIGNOUT]: (): IInvoiceState => ({
    ...initialState,
  }),
  [UserActionTypes.SIGNIN]: (): IInvoiceState => ({
    ...initialState,
  }),

  ...createAPIReducer<
    IInvoiceState,
    {
      data: {
        apisInvoiceList: IApisInvoice[];
      };
    }
  >(InvoiceActionTypes.FETCH_APIS_INVOICE_LIST, 'fetchApisInvoiceListStatus', {
    onSuccess: (state, { data: { apisInvoiceList } }) => {
      return {
        ...state,
        apisInvoiceList,
      };
    },
  }),
});

import { Route, Switch } from 'react-router';
import React from 'react';
import { withErrorHandler } from 'common/HOC/withErrorHandler';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { withNoToolbarSMLayout } from '../LayoutUI/components/Layout';
import { PrivateRoute } from 'common/nav/PrivateRoute';
import { APP_DEPLOY_PATH } from './const';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import { t } from 'common/utils/intl';
import loadable, { LoadableComponent } from '@loadable/component';

const LoadableDeployContainer = withNoToolbarSMLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AppDeployUI/screens/Deploy/DeployContainer').then(
          module => module.DeployContainer,
        ),
      {
        fallback: <LoadingIndicator />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const AppDeployRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <PrivateRoute
        isSignedIn={isSignedIn}
        path={APP_DEPLOY_PATH}
        component={LoadableDeployContainer}
      />
      <Route
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};

import React from 'react';
import { Box } from '@material-ui/core';
import { Field } from 'react-final-form';
import { SelectField } from 'common/components/Form/Select';

const INSTANCE_COUNT_ITEMS = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

export const ReplicasInput = ({
  label,
  hidden,
}: {
  label: string;
  hidden: boolean;
}) => {
  return (
    <Box m={2} maxWidth={300} display={hidden ? 'none' : 'block'}>
      <Field
        name={'replicas'}
        component={SelectField}
        values={INSTANCE_COUNT_ITEMS}
        margin="none"
        label={label}
      />
    </Box>
  );
};

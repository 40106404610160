import { withStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Classes } from 'jss';
import { StyleRules } from '@material-ui/core/styles';
import { withLayout } from 'common/HOC/withLayout';

const layoutStyles = (): StyleRules => ({
  paper: {
    flex: 1,
    width: '100%',
    margin: '0 auto',
    borderRadius: 4,
  },
});

interface ILayoutProps {
  classes?: Classes;
  children: ReactNode;
}

const LayoutComponent = withStyles(layoutStyles)(
  ({ children, classes = {} }: ILayoutProps) => {
    return (
      <div>
        <div className={classes.paper}>{children}</div>
      </div>
    );
  },
);

const EmptyLayout = (props: Pick<ILayoutProps, 'children'>) => (
  <LayoutComponent {...props} />
);

export const withPlainLayout = withLayout()(EmptyLayout);

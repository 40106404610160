import { IHookResponse } from '../../../utils/convertResponseToError';
const NEAR_DEFAULT_SUCCESS_URL = 'http%3A%2F%2F127.0.0.1%3A5000';

export function parseNearLink(response: IHookResponse): IHookResponse {
  try {
    const result = response.data?.result ?? '';
    const redirectLink = result?.replace(
      NEAR_DEFAULT_SUCCESS_URL,
      window.location.href,
    );

    return {
      ...response,
      data: {
        result: redirectLink,
      },
    };
  } catch (err) {
    return {
      ...response,
    };
  }
}

import React from 'react';
import { Breadcrumbs, Box, IconButton } from '@material-ui/core';
import { PageContext } from 'common/nav/PrivateRoute';
import { Link } from 'react-router-dom';
import { IBreadcrumb } from 'common/types/commonTypes';
import { ArrowLeftIcon } from 'common/components/Icons/ArrowLeftIcon';
import { Helmet } from 'react-helmet';
import { useIsSMDown } from 'common/utils/hooks/themeHooks';
import { useStyles } from './PageTitleStyles';
import { useCallback } from 'react';

const BREADCRUMBS_SEPARATOR = '/';

export const PageTitle = () => {
  const classes = useStyles();
  const isSMDown = useIsSMDown();
  const { breadcrumbsState } = React.useContext(PageContext);

  const renderHeaderTitle = useCallback(() => {
    const headerTitle = breadcrumbsState.reduce(
      (prev: string, breadcrumb: IBreadcrumb | string) => {
        return (
          prev +
          ' - ' +
          (typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.title)
        );
      },
      '',
    );
    return (
      <Helmet>
        <title>Ankr {headerTitle}</title>
      </Helmet>
    );
  }, [breadcrumbsState]);

  const renderTitle = useCallback(
    ({ title, isLink }: { title: string; isLink: boolean }) => {
      return (
        <div key={title} className={isLink ? classes.link : classes.text}>
          {title}
        </div>
      );
    },
    [classes],
  );

  const backLink = breadcrumbsState
    .slice()
    .reverse()
    .find(item => item.hasOwnProperty('link'));

  const renderBackLink = useCallback(
    () => (
      <>
        {backLink && backLink.link && typeof backLink.link === 'string' && (
          <div className={classes.backButton}>
            <Link to={backLink.link}>
              <IconButton className={classes.icon}>
                <ArrowLeftIcon />
              </IconButton>
            </Link>
          </div>
        )}
      </>
    ),
    [backLink, classes],
  );

  if (breadcrumbsState.length < 1) {
    // if no breadcrumb props, return null
    return null;
  }

  if (isSMDown) {
    const lastBreadcrumb = breadcrumbsState[breadcrumbsState.length - 1];

    const title =
      typeof lastBreadcrumb === 'string'
        ? lastBreadcrumb
        : lastBreadcrumb.title;
    return (
      <>
        {renderTitle({ title, isLink: false })}
        {renderHeaderTitle()}
      </>
    );
  } else {
    return (
      <Box className={classes.root}>
        {renderHeaderTitle()}
        {renderBackLink()}
        <Box>
          <Breadcrumbs separator={BREADCRUMBS_SEPARATOR}>
            {breadcrumbsState.map((breadcrumb: IBreadcrumb | string) => {
              const title =
                typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.title;
              const link =
                typeof breadcrumb === 'string' ? undefined : breadcrumb.link;

              if (title.length < 1) {
                return null;
              }

              if (typeof link === 'string') {
                return (
                  <Link to={`${link}`} className={classes.link} key={title}>
                    {renderTitle({ title, isLink: true })}
                  </Link>
                );
              } else {
                return (
                  <div className={classes.title} key={title}>
                    {renderTitle({ title, isLink: false })}
                  </div>
                );
              }
            })}
          </Breadcrumbs>
        </Box>
      </Box>
    );
  }
};

export const ZENDESK_DOMAIN = 'https://ankrhelp.zendesk.com';

export const ZENDESK_AUTHORIZATION_HEADER = {
  authorization:
    'Bearer e9239d65aedb2453c3bd14901ac3dc82f21b335cb7ca95de49ee37ab4e473cf1',
};

export const ZENDESK_GROUP_IDS = {
  development: 360012143013,
  support: 360010082093,
};

export enum ZendeskTicketFilters {
  new = 'new',
  open = 'open',
  pending = 'pending',
  hold = 'hold',
  solved = 'solved',
  closed = 'closed',

  // Clientside filters
  any = 'any',
  created = 'created',
  assigned = 'assigned',
  node = 'node',
}

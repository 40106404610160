import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';
import BigNumber from 'bignumber.js';

export interface ISmartchainValidatorGetBalance {
  balance: BigNumber;
  feeBalance: BigNumber;
}

export function parseSmartchainValidatorBalance(
  response: IHookResponse,
): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');

  return {
    ...response,
    data: {
      balance: new BigNumber(data.balance),
      feeBalance: new BigNumber(data.feeBalance),
    } as ISmartchainValidatorGetBalance,
  };
}

export interface ISmartchainValidatorGetStaked {
  balance: BigNumber;
  selfStaked: BigNumber;
  delegated: BigNumber;
  totalStaked: BigNumber;
}

export function parseSmartchainValidatorGetStaked(
  response: IHookResponse,
): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');

  return {
    ...response,
    data: {
      balance: new BigNumber(data.balance),
      selfStaked: new BigNumber(data.selfStaked),
      delegated: new BigNumber(data.delegated),
      totalStaked: new BigNumber(data.totalStaked),
    } as ISmartchainValidatorGetStaked,
  };
}

export interface ISmartchainValidatorGetNodeAddressResponse {
  feeAddress: string;
  consensusAddress: string;
}

export interface ISmartchainValidatorGetBinanceAddressResponse {
  bnbAddress: string;
}

export interface IAmountOperation {
  amount: string;
}

import { Classes } from 'jss';
import React from 'react';
import { snackbarContentStyles } from './SnackbarContentStyles';
import { withStyles } from '@material-ui/core';
import MuiSnackbarContent, {
  SnackbarContentProps,
} from '@material-ui/core/SnackbarContent/SnackbarContent';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';

import { t } from 'common/utils/intl';
import { omit } from 'common/utils/typeUtils';
import {
  SnackbarAction,
  SnackbarVariantType,
} from 'common/store/reducers/snackbarsReducer';

// @ts-ignore
interface ISnackbarProps extends SnackbarContentProps {
  classes?: Classes;
  variant?: SnackbarVariantType;
  message?: React.ReactNode;
  intlMessage?: string;
  actions: SnackbarAction[];
  onActionClick?: (action: SnackbarAction) => void;
}

const SnackbarContentComponent = (props: ISnackbarProps) => {
  const {
    classes = {},
    variant,
    className,
    message,
    intlMessage,
    actions,
    onActionClick,
  } = props;

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onActionClick) {
      onActionClick(event.currentTarget.dataset.action as SnackbarAction);
    }
  };

  return (
    <MuiSnackbarContent
      className={classNames(variant && classes[variant], className)}
      message={
        <span className={classes.message}>
          {intlMessage ? t(intlMessage) : message}
        </span>
      }
      action={actions.map(action => {
        switch (action) {
          case 'close':
            return (
              <IconButton
                key="close"
                data-action="close"
                aria-label={t('button-close')}
                color="inherit"
                onClick={handleActionClick}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            );
          case 'ok':
            return (
              <IconButton
                key="ok"
                data-action="ok"
                aria-label={t('button-ok')}
                color="inherit"
                onClick={handleActionClick}
              >
                <CheckIcon className={classes.icon} />
              </IconButton>
            );
          case 'cancel':
            return (
              <IconButton
                key="cancel"
                data-action="cancel"
                aria-label={t('button-cancel')}
                color="inherit"
                onClick={handleActionClick}
              >
                <CancelIcon className={classes.icon} />
              </IconButton>
            );
          default:
            return null;
        }
      })}
      {...omit(
        props,
        'classes',
        'variant',
        'onActionClick',
        'intlMessage',
        'message',
      )}
    />
  );
};

const SnackbarContent = withStyles(snackbarContentStyles)(
  SnackbarContentComponent,
);

export { SnackbarContent };

import { INodeLog } from 'common/modules/nodeLog/nodeLogTypes';
import { RequestStatus } from 'common/utils/requestStatus';

export enum FilterValue {
  ALL,
  TODAY,
  LAST7DAY,
  LAST30DAY,
}

export interface INodeActivityStoreProps {
  nodeLogs: INodeLog[];
  fetchNodeLogStatus: RequestStatus;
  className?: string;
  queryLog?: (startTime: number, endTime: number) => void;
}

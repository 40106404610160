import { StyleRules, Theme } from '@material-ui/core/styles';

const avatarImageStyles = (theme: Theme): StyleRules => ({
  root: {
    position: 'relative',
    borderRadius: '50%',
  },
  xxsmall: {
    width: 20,
    height: 20,
    '& img': {
      width: 20,
      height: 20,
    },
    '& $initials': {
      fontSize: 10,
      lineHeight: 1,
      fontWeight: 'normal',
    },
  },
  tiny: {
    minWidth: 32,
    width: 32,
    height: 32,
    '& img': {
      width: 32,
      height: 32,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 24,
      width: 24,
      height: 24,
      '& $initials': {
        fontSize: 12,
        fontWeight: 'normal',
      },
      '& img': {
        width: 24,
        height: 24,
      },
    },
  },
  xsmall: {
    width: 40,
    height: 40,
    '& img': {
      width: 40,
      height: 40,
    },
  },
  small: {
    width: 60,
    height: 60,
    '& img': {
      width: 60,
      height: 60,
    },
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      '& img': {
        width: 40,
        height: 40,
      },
    },
  },
  medium: {
    width: 75,
    height: 75,
    '& img': {
      width: 75,
      height: 75,
    },
  },
  big: {
    width: 125,
    height: 125,
    '& img': {
      width: 125,
      height: 125,
    },
  },
  initials: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    fontSize: 16,
    textTransform: 'capitalize',
  },
});

export { avatarImageStyles };

export const BLUZELLE_NODE_NAME = 'bluzelle';

export const BLUZELLE_PASSWORD = 'ankrCustomValues___password';
export const BLUZELLE_MNEMONIC = 'ankrCustomValues___mnemonic';
export const BLUZELLE_MONIKER = 'ankrCustomValues___moniker';
export const BLUZELLE_NETWORK = 'ankrCustomValues___network';

export const BLUZELLE_NETWORK_OPTION_TESTNET = 'testnet';
export const BLUZELLE_NETWORK_OPTION_MAINNET = 'mainnet';

export const BLUZELLE_CREATE_VALIDATOR_BALANCE_REQUIRED = 1000;

export enum BLUZELLE_HOOKS {
  getMnemonic = 'apphook1=get-mnemonic',
  currentHeight = 'apphook2=current-height',
  publicKey = 'apphook3=public-key',
  getBalance = 'apphook4=get-balance',
  applyAsValidator = 'apphook5=apply-as-validator',
  checkValidator = 'apphook6=check-validator',
  staking = 'apphook7=staking',
  unbound = 'apphook8=unbound',
  unjailValidator = 'apphook9=unjail-validator',
  deleteMnemonic = 'apphook10=delete-mnemonic',
}

import * as Sentry from '@sentry/browser';

import {
  IApiNotificationSettingsItem,
  IApiNotificationSettingsListResponse,
} from './SettingsTypes';

export const mapNotificationSettingsList = (
  response: IApiNotificationSettingsListResponse,
): IApiNotificationSettingsItem[] => {
  try {
    return response.notification_setting_list.map(item => {
      return {
        apiId: item.api_id,
        optionBrowserEventApiRemoved: item.option_browser_event_api_removed,
        optionEmailEventExceededNumberOfDay:
          item.option_email_event_exceeded_number_of_day,
        optionBrowserEventExceededNumberOfDay:
          item.option_browser_event_exceeded_number_of_day,
        optionEmailEventExceededNumberOfSecond:
          item.option_email_event_exceeded_number_of_second,
        optionBrowserEventExceededNumberOfSecond:
          item.option_browser_event_exceeded_number_of_second,
      };
    });
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

import { ICustomValue } from 'common/modules/apps/types';

export const findCustomValue = (
  customValues: ICustomValue[],
  customName: string,
) => {
  return customValues.find((customValue: ICustomValue) => {
    return customValue.key === customName;
  });
};

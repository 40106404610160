export const NERVOS_NODE_NAME = 'nervos';

export const NERVOS_DOCUMENTATION_LINK = 'https://docs.nervos.org/';

export const NERVOS_NETWORK = 'ankrCustomValues___network';

export const NERVOS_NETWORK_OPTION_TESTNET = 'testnet';
export const NERVOS_NETWORK_OPTION_MAINNET = 'mainnet';

export enum NERVOS_HOOKS {
  getNodeStatus = 'apphook1=node-status',
  getNetwork = 'apphook2=get-network',
}

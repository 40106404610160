import BigNumber from 'bignumber.js';
import { PaymentMethod } from '../const';

export interface IApiPrepareExtendOrderData {
  amount: string;
  paymentMethod: PaymentMethod;
}

interface IExtendPlanData {
  amount: BigNumber;
  paymentMethod: PaymentMethod;
}

export function mapExtendPlan({
  amount,
  paymentMethod,
}: IApiPrepareExtendOrderData): IExtendPlanData {
  return { amount: new BigNumber(amount), paymentMethod };
}

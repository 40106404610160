const IGNORE_INTL_ERROR =
  'https://github.com/alibaba/react-intl-universal/releases/tag/1.12.0';
const SUPPRESS_LOCALIZATION_WARNING = [/.+\.overrides\..+/];

function parseKey(message: string) {
  return message.match(/".+"/)?.[0];
}

export function isSkipLocalizationWarning(message?: string) {
  if (!message) {
    return false;
  }

  if (message.indexOf(IGNORE_INTL_ERROR) !== -1) {
    return true;
  }

  const key = parseKey(message);

  if (!key) {
    return false;
  }

  return SUPPRESS_LOCALIZATION_WARNING.find(exp => exp.test(key));
}

/**
 * All UI related redux stuff should be here
 */

import { createReducer } from 'common/utils/reduxUtils';
import { LocaleName } from '../../locales/locales';

export interface IUIState {
  locale: LocaleName;
}

const initialState: IUIState = {
  locale: 'en-US',
};

const uiReducer = createReducer(initialState, {});

export { uiReducer };

import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const NothingFoundStyles = (theme: Theme): StyleRules => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    justifyContent: 'center',
    padding: 32,
    flex: 1,
    '& img': {
      marginLeft: -21,
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: '32px',
  },
});

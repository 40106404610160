import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import intl from 'react-intl-universal';
import * as Sentry from '@sentry/browser';
import { Provider, ReactReduxContext } from 'react-redux';
import { CssBaseline, Theme } from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';
import { AppLoading } from 'common/components/AppLoading';
import { AppError } from 'common/components/AppError';
import { isProd } from 'common/utils/environment';
import { AppContext } from 'common/components/App/AppContext';
import { Store } from 'redux';
import { History } from 'history';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Persistor } from 'redux-persist';
import IntlPolyfill from 'intl';
// eslint-disable-next-line
import './en.js';
import { isSkipLocalizationWarning } from './isSkipLocalizationWarning';

Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;

if (process.env.REACT_APP_SENTRY_DSN) {
  const DEBUG = !isProd();
  Sentry.init({
    debug: DEBUG,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_VERSION}`,
    environment: process.env.NODE_ENV,
    beforeSend(event) {
      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.error(event.message, event);
        return null;
      }

      return event;
    },
  });
}

interface IAppProps {
  translations: {};
  store: Store;
  historyInstance: History;
  theme: Theme;
  persistor: Persistor;
}

interface IAppState {
  initDone: boolean;
  locale: 'en-US';
  error?: Error;
}

class AppBase extends React.Component<IAppProps, IAppState> {
  constructor(props: any) {
    super(props);
    this.state = { initDone: false, locale: 'en-US' };
  }

  public componentDidMount(): void {
    this.loadLocales();
  }

  public componentDidCatch(error: Error, errorInfo: object) {
    this.setState({ error });
    if (isProd()) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          // @ts-ignore
          scope.setExtra(key, errorInfo[key] as any);
        });
        Sentry.captureException(error);
      });
    }
  }

  public render() {
    if (this.state.error) {
      return <AppError />;
    }
    return (
      <AppContext.Provider value={{ locale: this.state.locale }}>
        <Provider store={this.props.store} context={ReactReduxContext}>
          <MuiThemeProvider theme={this.props.theme}>
            <CssBaseline />
            <PersistGate
              loading={<AppLoading />}
              persistor={this.props.persistor}
            >
              <ConnectedRouter
                history={this.props.historyInstance}
                context={ReactReduxContext}
              >
                {!this.state.initDone ? <AppLoading /> : this.props.children}
              </ConnectedRouter>
            </PersistGate>
          </MuiThemeProvider>
        </Provider>
      </AppContext.Provider>
    );
  }

  protected loadLocales = () => {
    intl
      .init({
        currentLocale: this.state.locale,
        locales: this.props.translations,
        warningHandler: message => {
          if (isSkipLocalizationWarning(message)) {
            return;
          }

          Sentry.configureScope(scope => {
            scope.setTag('page_locale', this.state.locale);
            Sentry.captureException(message);
          });
        },
        commonLocaleDataUrls: {},
      })
      .then(() => {
        this.setState({ initDone: true });
      });
  };
}

export default AppBase;

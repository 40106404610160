import * as Sentry from '@sentry/browser';

import {
  IAPIZendeskMembership,
  IZendeskMembership,
} from '../types/membershipTypes';

export const normalizeZendeskMembership = (
  membership: IAPIZendeskMembership,
): IZendeskMembership | undefined => {
  try {
    return {
      id: membership.id,
      zendeskUserId: membership.user_id,
      zendeskOrganizationId: membership.organization_id,
      default: membership.default,
      createdAt: new Date(membership.created_at),
      updatedAt: new Date(membership.updated_at),
    };
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

const normalizeZendeskMemberships = (data: {
  organization_memberships: IAPIZendeskMembership[];
}) => {
  try {
    return data?.organization_memberships?.length > 0
      ? data.organization_memberships.map(normalizeZendeskMembership)
      : [];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const normalizeSingleZendeskMembership = (data: {
  organization_memberships: IAPIZendeskMembership[];
}): IZendeskMembership | undefined => {
  try {
    const response = normalizeZendeskMemberships(data);

    const firstResponse = response?.[0];
    return firstResponse;
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

/* eslint-disable @typescript-eslint/camelcase */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { IStoreState } from 'store/reducers';
import { RouteComponentProps, withRouter } from 'react-router';
import { getSignedInStatus } from 'auth/utils';
import { SnackbarsDisplay } from 'common/components/SnackbarsDisplay';
import { NoSsr } from '@material-ui/core';
import { MainRoutes } from 'nav/MainRoutes';

import { ZendeskMounter } from 'components/ZendeskMounter';

interface IMainAppProps {
  isSignedIn: boolean;
}

const MainAppComponent = ({
  isSignedIn,
}: IMainAppProps & RouteComponentProps<any>) => {
  return (
    <NoSsr>
      <ZendeskMounter />
      <SnackbarsDisplay />
      <MainRoutes isSignedIn={isSignedIn} />
    </NoSsr>
  );
};

const mapStateToProps = (state: IStoreState /*, ownProps*/) => {
  return {
    isSignedIn: getSignedInStatus(state),
  };
};

const MainApp = withRouter(
  connect(mapStateToProps)(
    memo(MainAppComponent, (prev, next) => {
      return prev.isSignedIn === next.isSignedIn;
    }),
  ),
);

export { MainApp };

import { ZendeskTicketFilters } from '../const';

const BACKEND_STATUSES = [
  ZendeskTicketFilters.new,
  ZendeskTicketFilters.open,
  ZendeskTicketFilters.pending,
  ZendeskTicketFilters.hold,
  ZendeskTicketFilters.solved,
  ZendeskTicketFilters.closed,
];

export function isBackendStatus(status: ZendeskTicketFilters) {
  return BACKEND_STATUSES.includes(status);
}

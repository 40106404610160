import React, { BaseSyntheticEvent } from 'react';
import * as Sentry from '@sentry/browser';
import {
  createStyles,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { t } from 'common/utils/intl';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    link: {
      cursor: 'pointer',
      color: 'red',
    },
  });

const AppErrorComponent = ({ classes = {} }: StyledComponentProps) => {
  const showErrorDialog = (event: BaseSyntheticEvent) => {
    Sentry.showReportDialog();
    event.preventDefault();
  };
  return (
    <div className={classes.root}>
      <div>
        <h1>{t('app-error.title')}</h1>
      </div>
      <div>
        <h3>{t('app-error.text')}</h3>
      </div>
      <div>
        <a href="#report" className={classes.link} onClick={showErrorDialog}>
          {t('app-error.report')}
        </a>
      </div>
    </div>
  );
};

const AppError = withStyles(styles)(AppErrorComponent);

export { AppError };

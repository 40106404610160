import BigNumber from 'bignumber.js';
import { PaymentMethod } from '../const';

export interface IPrePaymentAppItem {
  appId: string;
  appName: string;
  chartName: string;
  payMethod: PaymentMethod;
  usd: BigNumber;
  amount: BigNumber;
  billingTime: Date;
  iconUrl: string;
}

interface IApiPrePaymentAppItem {
  app_id: string;
  app_name: string;
  chart_name: string;
  pay_method: PaymentMethod;
  usd: string;
  amount: string;
  billing_time: string;
  icon_url: string;
}

interface IApiPrePaymentItem {
  period: string;
  total_usd: string;
  app_list: IApiPrePaymentAppItem[];
}

export interface IPrePaymentItem {
  period: string;
  totalUsd: BigNumber;
  appList: IPrePaymentAppItem[];
}

export interface IApiPrePaymentResponse {
  payment_list: IApiPrePaymentItem[];
}

function mapPrePaymentAppItem(item: IApiPrePaymentAppItem): IPrePaymentAppItem {
  return {
    appId: item.app_id,
    appName: item.app_name,
    chartName: item.chart_name,
    payMethod: item.pay_method,
    usd: new BigNumber(item.usd),
    amount: new BigNumber(item.amount),
    billingTime: new Date(parseInt(item.billing_time) * 1000),
    iconUrl: item.icon_url,
  };
}

function mapPrePaymentItem(item: IApiPrePaymentItem): IPrePaymentItem {
  return {
    period: item.period,
    totalUsd: new BigNumber(item.total_usd),
    appList: item.app_list.map(mapPrePaymentAppItem),
  };
}

export function mapPaymentList(
  data: IApiPrePaymentResponse,
): IPrePaymentItem[] {
  return data.payment_list.map(mapPrePaymentItem);
}

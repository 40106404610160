import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const CancelIcon = withSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.6343 17.7657C16.9467 18.0781 17.4533 18.0781 17.7657 17.7657C18.0781 17.4533 18.0781 16.9467 17.7657 16.6343L13.1314 12L17.7657 7.36569C18.0781 7.05327 18.0781 6.54674 17.7657 6.23432C17.4533 5.9219 16.9467 5.9219 16.6343 6.23432L12 10.8686L7.36569 6.23431C7.05327 5.9219 6.54673 5.9219 6.23432 6.23431C5.9219 6.54673 5.9219 7.05327 6.23432 7.36569L10.8686 12L6.23431 16.6343C5.9219 16.9467 5.9219 17.4533 6.23431 17.7657C6.54673 18.0781 7.05327 18.0781 7.36569 17.7657L12 13.1314L16.6343 17.7657Z"
  />,
);

import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { UserActions } from 'auth/store/actions/UserActions';
import { IProfileButtonStoreProps, ProfileButton } from './ProfileButton';
import { getCurrentTeam } from 'common/modules/teams/reducers/teamReducer';
import { IStoreState } from 'store/reducers';
import { isUserAuthenticated } from 'auth/store/reducers/tokensReducer';
import { SETTINGS_PREFIX } from 'common/nav/navUtils';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignOutClick: () => dispatch(UserActions.signOut()),
  onProfileClick: () => dispatch(push(SETTINGS_PREFIX)),
});

const ProfileButtonContainer = connect(
  (state: IStoreState): IProfileButtonStoreProps => {
    const teams = state.team.teams;
    return {
      currentTeam: getCurrentTeam(state.team),
      teams,
      isUserAuthenticated: isUserAuthenticated(state.tokens),
    };
  },
  mapDispatchToProps,
)(ProfileButton);

export { ProfileButtonContainer };

import { ElastosElaHooks, elastosElaNodeName } from './ElastosElaConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/ElastosEla.en-US.json';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/ElastosElaManage').then(module => module.ElastosElaManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      current: Number(data.current ?? 0) ?? 0,
      last: Number(data.last ?? 0) ?? 0,
      isSyncing: data.isSyncing === 'true',
    },
  };
}

export const elastosElaConfig: INodeConfig = {
  chartName: elastosElaNodeName,

  locales: {
    'en-US': localesUS,
  },

  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'elastos-node': {
      [ElastosElaHooks.getNodeStatus]: parseStatus,
    },
  },
};

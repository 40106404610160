import mixpanel, { Dict } from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';

interface IMixpanelTrack {
  event: string;
  properties?: Dict;
}

export const mixpanelTrack = async ({ event, properties }: IMixpanelTrack) => {
  try {
    mixpanel.track(event, properties);
  } catch (error) {
    Sentry.captureException(error);
  }
};

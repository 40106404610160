import { put, select, takeEvery, putResolve } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { PayActionTypes } from '../actions/PayActions';
import { SendRequestResponse } from '../../../types';
import {
  AppsActions,
  AppsActionTypes,
  ICreateAppPayload,
} from '../../apps/actions/AppsActions';
import { ITeamState } from '../../teams/reducers/teamReducer';
import { IPayState } from '../reducers/payReducer';
import { throwIfContainsError } from '../../../utils/throwIfContainsError';
import { CPU_DIVIDER, MEM_DIVIDER, STORAGE_DIVIDER } from '../../../core/const';
import { PaymentMethod } from '../const';
import { ICard } from '../../apps/types/payTypes';

function* createAppByAnkrCredit(action: Action<ICreateAppPayload>) {
  try {
    yield put({
      type: AppsActionTypes.APP_CREATE,
    });

    const {
      currentTeamId,
    }: {
      currentTeamId: string;
    } = yield select((state: { team: ITeamState; pay: IPayState }) => ({
      currentTeamId: state.team.currentTeamId ?? '',
    }));

    const createDepositCardResponse: SendRequestResponse<{
      code: 0;
      message: 'success';
      pre_pay_id: string;
    }> = throwIfContainsError(
      yield putResolve({
        type: 'EMPTY',
        request: {
          url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${currentTeamId}/cards/ankr`,
          method: 'POST',
          data: {
            team_id: currentTeamId,
            card_id: action.payload.depositPaymentMethod.id,
            period: action.payload.period,
            auto_renewal: 0,
            cluster_id: action.payload.cluster.id,
            chart_name: action.payload.chartName,
            cpu_limit: action.payload.cpu * CPU_DIVIDER,
            mem_limit: action.payload.memory * MEM_DIVIDER,
            storage_limit: action.payload.storage * STORAGE_DIVIDER,
            icon_url: action.payload.chartIconUrl,
            project_name: action.payload.name,
            replicas_number: action.payload.replicas,
            pay_type: 'ANKR_CREDIT',
          },
        },
        meta: { silent: true },
      }),
    );

    const payload = {
      ...action.payload,
      depositPaymentMethod: {
        id: createDepositCardResponse.data.pre_pay_id,
        paymentMethod: PaymentMethod.ANKR_CREDIT,
      },
    };

    const response: SendRequestResponse<{
      cards: ICard[];
    }> = throwIfContainsError(yield putResolve(AppsActions.createApp(payload)));

    return response;
  } catch (error) {
    yield put({
      type: AppsActionTypes.APP_CREATE_ERROR,
      data: { error },
    });
  }
}

export function* ankrCreditPaySaga() {
  yield takeEvery(
    PayActionTypes.CREATE_APP_BY_ANKR_CREDIT,
    createAppByAnkrCredit,
  );
}

import { createAction } from 'common/utils/reduxUtils';

export const RedirectActionTypes = {
  SET_REDIRECT: 'SET_REDIRECT',
  RESET_REDIRECT: 'RESET_REDIRECT',
};

export const RedirectActions = {
  setRedirect: (resource: string) =>
    createAction(RedirectActionTypes.SET_REDIRECT, { resource }),
  resetRedirect: () => createAction(RedirectActionTypes.RESET_REDIRECT),
};

import { registerNode } from 'common/utils/nodes/registerNode';
import { stafiConfig } from './nodes/Stafi/stafi';
import { stafiValidatorConfig } from './nodes/StafiValidator/stafiValidator';
import { makeTerraConfig } from './nodes/Terra/terra';
import {
  terra,
  terraColumbus3,
  terraColumbus4,
} from './nodes/Terra/terraConst';
import { omiseGoConfig } from './nodes/OmiseGo/omiseGo';
import { nucypherWorkerConfig } from './nodes/NucypherWorker/nucypherWorker';
import { zcashConfig } from './nodes/zcash/Zcash';
import { binanceSmartchainConfig } from './nodes/BinanceSmartchain/binanceSmartchain';
import { graphConfig } from './nodes/graph/Graph';
import { binanceChainConfig } from './nodes/binance-chain/binanceChain';
import { hederaHashgraphConfig } from './nodes/hedera-hashgraph/HederaHashgraph';
import { nearFullConfig } from './nodes/NearFull/nearFull';
import { nearConfig } from './nodes/Near/near';
import { avalancheVConfig } from './nodes/avalanche-v/AvalancheV';
import { avalancheOConfig } from './nodes/avalanche-o/AvalancheO';
import { avalancheConfig } from './nodes/avalanche/Avalanche';
import { rippleFullNodeConfig } from './nodes/ripple-full-node/RippleFullNode';
import { nulsConfig } from './nodes/nuls/Nuls';
import { algorandRelayConfig } from './nodes/algorand-relay/AlgorandRelay';
import { tezosConfig } from './nodes/tezos/Tezos';
import { sifchainConfig } from './nodes/sifchain/Sifchain';
import { makeBlockstackConfig } from './nodes/blockstack/Blockstack';
import {
  blockstackApiNodeName,
  blockstackNodeName,
} from './nodes/blockstack/BlockstackConst';
import { elastosElaConfig } from './nodes/elastos-ela/ElastosEla';
import { ltoNetworkConfig } from './nodes/lto-network/LtoNetwork';
import { OntologySyncConfig } from './nodes/ontology-sync/OntologySync';
import { iconCitizenConfig } from './nodes/icon-citizen/IconCitizen';
import { tezosValidatorConfig } from './nodes/tezos-validator/TezosValidator';
import { tronFullConfig } from './nodes/tron-full/TronFull';
import { plasmTestnetConfig } from './nodes/plasm-testnet/PlasmTestnet';
import { stkrConfig } from './nodes/Stkr/Stkr';
import { cosmosConfig } from './nodes/cosmos/Cosmos';
import { vitaeConfig } from './nodes/vitae/Vitae';
import { moonbeamConfig } from './nodes/moonbeam/Moonbeam';
import { cereValidatorConfig } from './nodes/cere-validator/CereValidator';
import { hathorConfig } from './nodes/Hathor/Hathor';
import { bluzelleConfig } from './nodes/Bluzelle/Bluzelle';
import { fantomVConfig } from './nodes/fantom-v/fantomV';
import { enecuumConfig } from './nodes/enecuum/enecuum';
import { nervosConfig } from './nodes/nervos/nervos';
import { nervosL2Config } from './nodes/nervos-l2/nervos';
import { ankrTestConfig } from './nodes/AnkrTest/ankrTest';

export function registerNodes() {
  registerNode(algorandRelayConfig);
  registerNode(stafiConfig);
  registerNode(stafiValidatorConfig);

  registerNode(iconCitizenConfig);

  registerNode(makeTerraConfig(terra));
  registerNode(makeTerraConfig(terraColumbus3));
  registerNode(makeTerraConfig(terraColumbus4));
  registerNode(tronFullConfig);
  registerNode(elastosElaConfig);
  registerNode(hederaHashgraphConfig);

  registerNode(omiseGoConfig);
  registerNode(tezosValidatorConfig);

  registerNode(binanceSmartchainConfig);
  registerNode(sifchainConfig);
  registerNode(nucypherWorkerConfig);

  registerNode(nearFullConfig);
  registerNode(nearConfig);

  registerNode(zcashConfig);

  registerNode(graphConfig);
  registerNode(cosmosConfig);
  registerNode(binanceChainConfig);
  registerNode(rippleFullNodeConfig);
  registerNode(nulsConfig);
  registerNode(tezosConfig);
  registerNode(OntologySyncConfig);
  registerNode(avalancheVConfig);
  registerNode(avalancheOConfig);
  registerNode(avalancheConfig);
  registerNode(ltoNetworkConfig);
  registerNode(plasmTestnetConfig);
  registerNode(makeBlockstackConfig(blockstackNodeName));
  registerNode(makeBlockstackConfig(blockstackApiNodeName));
  registerNode(stkrConfig);
  registerNode(vitaeConfig);
  registerNode(moonbeamConfig);
  registerNode(cereValidatorConfig);
  registerNode(hathorConfig);
  registerNode(bluzelleConfig);
  registerNode(fantomVConfig);
  registerNode(enecuumConfig);
  registerNode(nervosConfig);
  registerNode(nervosL2Config);
  registerNode(ankrTestConfig);
}

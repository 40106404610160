export const BILLING_PATH = '/billing';
export const BILLING_DEPOSIT_PATH = `${BILLING_PATH}/deposit`;
export const BILLING_DAILY_BILLING_PATH = `${BILLING_PATH}/daily`;
export const BILLING_PAYMENTS_PATH = `${BILLING_PATH}/payments`;
export const BILLING_APIS_PATH = `${BILLING_PATH}/apis`;

export const BILLING_TABLE_MIN_WIDTH = 808;
export const BILLING_HEAD_HEIGHT = 45;

export const DAYS_LEFT_THRESHOLD = 5;

export const BILLING_MAX_VISIBLE_LOGOS = 5;

export const ENABLE_PAYMENT_HOW_IT_WORKS = false;

export const PRINT_SECTION_ID = 'printSection';

import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { t } from 'common/utils/intl';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../Layout/assets/logo.svg';
import { NodesIcon } from 'common/components/Icons/NodesIcon';
import { MenuNodesIcon } from 'common/components/Icons/MenuNodesIcon';
import { BillingIcon } from 'common/components/Icons/BillingIcon';
import { NODES_PATH } from 'common/nav/navUtils';
import { useNavBarStyles } from './NavBarStyles';
import {
  FEATURE_BILLING_ENABLED,
  FEATURE_TICKETS_ENABLED,
} from '../../../../const';
import { useIsSMDown, useIsXSDown } from 'common/utils/hooks/themeHooks';
import { useNavBarSMStyles } from './NavBarSMStyles';
import { useNavBarXSStyles } from './NavBarXSStyles';

import { TICKETS_PATH } from '../../../TicketsUI/TicketsConsts';
import { MenuApisIcon } from 'common/components/Icons/MenuApisIcon';
import { MenuTicketsIcon } from 'common/components/Icons/MenuTicketsIcon';
import { useEnterprise } from 'common/modules/teams/hooks/useEnterprise';
import { MenuTeamIcon } from 'common/components/Icons/MenuTeamIcon';
import { TEAM_PATH } from 'common/modules/teams/const';
import { BILLING_PATH } from '../../../FinanceUI/const';

import { ZendeskAPI } from 'components/ZendeskMounter/zendesk';
import { ZendeskHelpCircleIcon } from 'common/components/Icons/ZendeskHelpCircleIcon';
import { API_MARKET_LIST_PATH } from 'modules/APIMarketUI/APIMarketConsts';

const MY_NODE_PATH = '/apps';
const APP_DETAILS_PATH = '/apps/details';

interface INavBarProps extends RouteComponentProps {
  className?: string;
}

const NavBarComponent = ({
  location: { pathname },
  className,
}: INavBarProps) => {
  const isMatch = (path: string) => {
    switch (path) {
      case NODES_PATH: {
        const isAppDetails = pathname.indexOf(APP_DETAILS_PATH) === 0;
        if (isAppDetails) {
          return true;
        }
        return pathname.indexOf(path) === 0;
      }
      case MY_NODE_PATH: {
        const isAppDetails = pathname.indexOf(APP_DETAILS_PATH) === 0;
        if (isAppDetails) {
          return false;
        }
        return pathname.indexOf(path) === 0;
      }
      default:
        return pathname.indexOf(path) === 0;
    }
  };
  const isSMDown = useIsSMDown();
  const isXSDown = useIsXSDown();

  const { isEnterpriseAvailable, isEnterprise } = useEnterprise();

  const LINKS = useMemo(
    () => [
      !isEnterprise
        ? {
            key: 'apiMarket',
            icon: <MenuApisIcon />,
            path: API_MARKET_LIST_PATH,
          }
        : null,
      {
        key: 'nodes',
        icon: <NodesIcon />,
        path: NODES_PATH,
      },
      {
        key: 'apps',
        icon: <MenuNodesIcon />,
        path: MY_NODE_PATH,
      },
      FEATURE_TICKETS_ENABLED && isEnterpriseAvailable && isEnterprise
        ? {
            key: 'tickets',
            icon: <MenuTicketsIcon />,
            path: TICKETS_PATH,
          }
        : null,
      FEATURE_TICKETS_ENABLED && isEnterpriseAvailable && isEnterprise
        ? {
            key: 'team',
            icon: <MenuTeamIcon />,
            path: TEAM_PATH,
          }
        : null,
      FEATURE_BILLING_ENABLED && !isEnterprise
        ? {
            key: 'billing',
            icon: <BillingIcon />,
            path: BILLING_PATH,
          }
        : null,
    ],

    [isEnterpriseAvailable, isEnterprise],
  );

  const xsStyles = useNavBarXSStyles({
    column: LINKS.filter(Boolean).length + 1,
  });
  const smStyles = useNavBarSMStyles();
  const styles = useNavBarStyles();

  const classes = useMemo(() => {
    switch (true) {
      case isXSDown:
        return xsStyles;
      case isSMDown:
        return smStyles;
      default:
        return styles;
    }
  }, [isSMDown, isXSDown, smStyles, styles, xsStyles]);

  return (
    <div className={classNames(className, classes.root)}>
      {!isSMDown && (
        <RouterLink to={NODES_PATH} className={classes.logo}>
          <Logo />
          {isEnterprise && (
            <div className={classes.enterprise}>{t('nav-bar.enterprise')}</div>
          )}
        </RouterLink>
      )}
      <div className={classes.nav}>
        {LINKS.map(
          item =>
            item && (
              <RouterLink
                key={item.key}
                className={classNames(
                  classes.item,
                  isMatch(item.path) && classes.current,
                )}
                to={item.path}
              >
                {item['icon']}
                <span className={classes.text}>{t(`nav.${item.key}`)}</span>
              </RouterLink>
            ),
        )}
        {isXSDown && (
          <button
            className={classes.intercom}
            type="button"
            onClick={() => {
              ZendeskAPI('webWidget', 'open');
            }}
          >
            <ZendeskHelpCircleIcon size={32} />
          </button>
        )}
      </div>
    </div>
  );
};

export const NavBar = withRouter(NavBarComponent);

export const PAPER_LAYOUT = {
  paperLayout: true,
};

export const PAPER_NO_NAVBAR_LAYOUT = {
  paperNoNavBarLayout: true,
};

export const TRANSPARENT_LAYOUT = {
  transparentLayout: true,
};

export const TRANSPARENT_LAYOUT_SIMPLE = {
  transparentLayoutSimple: true,
};

export const TRANSPARENT_LAYOUT_NO_OVERFLOW = {
  transparentLayoutNoOverflow: true,
};

export const TRANSPARENT_LAYOUT_WITH_OVERFLOW = {
  transparentLayoutWithOverflow: true,
};

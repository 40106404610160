import * as Sentry from '@sentry/browser';
import { put, take } from 'redux-saga/effects';

import { NamespacesActionTypes } from '../actions/NamespacesActions';
import { SnackbarsActions } from 'common/store/actions/SnackbarsActions';
import { extractRequestError } from 'common/utils/requestStatus';

export function* notifyDeleteNotificationErrorSaga() {
  while (true) {
    try {
      const action = yield take(NamespacesActionTypes.NAMESPACE_DELETE_ERROR);

      yield put(
        SnackbarsActions.showSnackbar(extractRequestError(action), {
          key: 'NAMESPACE_CREATE_ERROR',
          variant: 'error',
        }),
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export function* updateNamespacesSaga() {
  while (true) {
    try {
      yield take(NamespacesActionTypes.NAMESPACE_CREATE_SUCCESS);
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export enum PaymentWay {
  Daily,
  Deposit,
}

export interface IApiAppPaymentData {
  id: string;
  name: string;
  creator: string;
  team_id: string;
  created_time: string;
  updated_time: string;
  payWay: PaymentWay;
}

export interface IAppPaymentData {
  id: string;
  name: string;
  creator: string;
  teamId: string;
  createdTime: Date;
  updatedTime: Date;
  payWay: PaymentWay;
}

export function mapAppPaymentData(data: IApiAppPaymentData): IAppPaymentData {
  return {
    id: data.id,
    name: data.name,
    creator: data.creator,
    teamId: data.team_id,
    createdTime: new Date(data.created_time),
    updatedTime: new Date(data.updated_time),
    payWay: data.payWay,
  };
}

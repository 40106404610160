import {
  CereValidatorHooks,
  cereValidatorNodeName,
} from './CereValidatorConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/CereValidator.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/CereValidatorManage').then(
      module => module.CereValidatorManage,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/CereValidatorCustomValues').then(
      module => module.CereValidatorCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      currentBlock: Number(data.currentBlock ?? 0) ?? 0,
      lastBlock: Number(data.lastBlock ?? 0) ?? 0,
      isSyncing: data?.isSyncing === 'true',
    },
  };
}
export const cereValidatorConfig: INodeConfig = {
  chartName: cereValidatorNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'cere-node': {
      [CereValidatorHooks.getNodeStatus]: parseStatus,
      [CereValidatorHooks.getSessionKey]: defaultParse,
      [CereValidatorHooks.getValidatorName]: defaultParse,
    },
  },
};

/* eslint-disable @typescript-eslint/camelcase */
import {
  INamespaceFormValues,
  INamespace,
  IApiNamespace,
  IApiNamespaceFetchResponse,
} from '../types';
import * as Sentry from '@sentry/browser';
import { CPU_DIVIDER } from 'common/core/const';

const normalizeNamespaceItem = (namespace: IApiNamespace): INamespace => {
  return {
    id: namespace.id,
    name: namespace.name,
    clusterId: namespace.cluster_id,
    cpuLimit: namespace.cpu_limit / CPU_DIVIDER,
    memLimit: namespace.mem_limit,
    storageLimit: namespace.storage_limit,
    cpuUsed: namespace.cpu_used ? namespace.cpu_used / CPU_DIVIDER : 0,
    memUsed: namespace.mem_used,
    storageUsed: namespace.storage_used,
    createdTime: new Date(namespace.created_time),
    updatedTime: new Date(namespace.updated_time),
    status: namespace.status,
  };
};

const normalizeNamespaces = (
  data: IApiNamespaceFetchResponse,
): INamespace[] => {
  try {
    if (!data.namespaces) {
      return [];
    }
    return data.namespaces
      .map(normalizeNamespaceItem)
      .sort(
        (prev, next) => next.createdTime.getTime() - prev.createdTime.getTime(),
      );
  } catch (error) {
    Sentry.captureException(error);
  }

  return [];
};

// TODO: To remove?
const mapNamespaceFormToApi = (values: INamespaceFormValues) => {
  return {
    id: values.id,
    name: values.name,
    cluster_id: values.clusterId,
    // cluster_name: values.clusterName,
    cpu_limit: values.cpuLimit,
    mem_limit: values.memLimit,
    storage_limit: values.storageLimit,
    team_id: values.teamId,
  };
};

export { normalizeNamespaceItem, normalizeNamespaces, mapNamespaceFormToApi };

export enum SyncStatusVariant {
  progress = 'progress',
  progressBlue = 'progressBlue',
  done = 'done',
  error = 'error',
  blocked = 'blocked',
  paused = 'paused',
}

export const PCHAIN_CHILD_CHAIN = 'ankrCustomValues___childChain';
export const PCHAIN_PASSWORD = 'ankrCustomValues___password';
export const PCHAIN_KEYSTORE = 'ankrCustomValues___keystore';

export const SMARTCHAIN_VALIDATOR_NAME = 'ankrCustomValues___name';
export const SMARTCHAIN_VALIDATOR_PRIVATE_KEY = 'ankrCustomValues___privateKey';
export const SMARTCHAIN_VALIDATOR_NETWORK = 'ankrCustomValues___network';
export const SMARTCHAIN_VALIDATOR_MNEMONIC = 'ankrCustomValues___mnemonic';
export const SMARTCHAIN_VALIDATOR_FEE_ADDRESS = 'ankrCustomValues___feeAddress';

export const ETHERIUM_MEDALLA_PASSWORD = 'ankrCustomValues___password';
export const ETHERIUM_MEDALLA_KEYSTORE = 'ankrCustomValues___keystore';
export const ETHERIUM_MEDALLA_DEPOSIT_DATA = 'ankrCustomValues___depositdata';

export const SMARTCHAIN_FULL_NODE_SYNC_MODE = 'ankrCustomValues___syncMode';

export const AUTO_REFRESH_DELAY_TIME = 30_000;
export const NODE_STATUS_POLLING = 300_000;

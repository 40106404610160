import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStep1Styles = makeStyles<Theme>(theme => ({
  component: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateAreas: '"title title" "ios android"',
    gridRowGap: theme.spacing(6.75),
    gridColumnGap: theme.spacing(4),

    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  title: {
    gridArea: 'title',
    textAlign: 'center',
  },

  link: {
    justifyContent: 'flex-start',

    minWidth: 205,
    minHeight: 77,
    '&&': {
      padding: theme.spacing(2, 3),
    },
  },

  ios: {
    gridArea: 'ios',

    [theme.breakpoints.down('xs')]: {
      marginTop: '4.5vh',
    },
  },

  android: {
    gridArea: 'android',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2.5),
    },
  },
}));

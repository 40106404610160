export const UtilityActionTypes = {
  REDIRECT: 'REDIRECT',
};

export const UtilityActions = {
  redirect: (to: string) => ({
    type: UtilityActionTypes.REDIRECT,
    payload: {
      to,
    },
  }),
};

import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useCheckFormStyles } from './CheckFormStyles';
import { PasswordInput } from 'common/components/Form/PasswordInput';
import { MAX_PASSWORD_LENGTH } from 'common/utils/validation';
import classNames from 'classnames';
import { CodeForm } from './CodeForm';

export interface ICheckFormProps {
  className?: string;
  codeKey?: any;
  updateDataRef: React.MutableRefObject<() => void>;
  error?: string;

  onSubmit({ code, password }: Record<'code' | 'password', string>): void;
}

export const CheckForm = ({
  className,
  codeKey,
  onSubmit,
  updateDataRef,
  error,
}: ICheckFormProps) => {
  const classes = useCheckFormStyles();

  const [password, setPassword] = useState('');
  const [code, setCode] = useState<string>('');
  const [localError, setLocalError] = useState<string>('');

  const setPasswordValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value),
    [],
  );

  updateDataRef.current = () => {
    if (code && password) {
      onSubmit({ code, password });
      setLocalError('');
    } else {
      setLocalError(t('two-factor-auth.fields-required'));
    }
  };

  useEffect(
    () => {
      if (code && password) {
        onSubmit({ code, password });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code, onSubmit], // do not react on password
  );

  const suppressSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (code && password) {
        onSubmit({ code, password });
      }
    },
    [code, onSubmit, password],
  );

  return (
    <form
      className={classNames(className, classes.component)}
      onSubmit={suppressSubmit}
    >
      <div className={classes.fieldWrapper}>
        <Typography component="label" variant="h6" className={classes.label}>
          {t('two-factor-auth.fields.password')}
        </Typography>
        <PasswordInput
          className={classes.field}
          variant="outlined"
          name="password"
          type="password"
          margin="none"
          inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
          required
          value={password}
          onChange={setPasswordValue}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <CodeForm codeKey={codeKey} handleCode={setCode} />
      </div>
      {(localError || error) && (
        <span className={classes.error} role="alert">
          {localError || error}
        </span>
      )}
    </form>
  );
};

import * as Sentry from '@sentry/browser';

import {
  IAPIZendeskOrganization,
  IZendeskOrganization,
} from '../types/organizationTypes';

export const normalizeZendeskOrganization = (
  organization: IAPIZendeskOrganization,
): IZendeskOrganization => {
  return {
    id: organization.id,
    teamId: organization.external_id,
    url: organization.url,
    name: organization.name,
    createdAt: new Date(organization.created_at),
    updatedAt: new Date(organization.updated_at),
    domainNames: organization.domain_names,
    details: organization.details,
    notes: organization.notes,
    groupId: organization.group_id,
    sharedTickets: organization.shared_tickets,
    sharedComments: organization.shared_comments,
    tags: organization.tags,
  };
};

const normalizeZendeskOrganizations = (data: {
  organizations: IAPIZendeskOrganization[];
}): IZendeskOrganization[] => {
  try {
    return data?.organizations?.length > 0
      ? data.organizations.map(normalizeZendeskOrganization)
      : [];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};
export const normalizeSingleZendeskOrganization = (data: {
  organizations: IAPIZendeskOrganization[];
}): IZendeskOrganization | undefined => {
  try {
    const response = normalizeZendeskOrganizations(data);

    const firstResponse = response?.[0];
    return firstResponse;
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

import { Route, Switch } from 'react-router';
import React from 'react';
import { withErrorHandler } from 'common/HOC/withErrorHandler';
import loadable, { LoadableComponent } from '@loadable/component';
import { withPaper } from '../LayoutUI/components/Layout';
import { NODE_INFO_PATH } from './const';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import { t } from 'common/utils/intl';
import { LoadableScreen } from 'common/components/LoadableScreen';
import { PrivateRoute } from 'common/nav/PrivateRoute';

const LoadableInfoContainer = withPaper(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/NodeInfoUI/screens/Info').then(
          module => module.InfoPage,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
  {
    paperWhite: true,
    withBottomOffsetOnXS: true,
    withBottomOffsetOnSM: true,
    hideNavBarXS: true,
    whiteXSBg: true,
  },
);

export const NodeInfoRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <PrivateRoute
        path={NODE_INFO_PATH}
        component={LoadableInfoContainer}
        isSignedIn={isSignedIn}
      />
      <Route
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};

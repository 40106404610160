import React, { useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import { ICustomValue } from 'common/modules/apps/types';
import { FieldType } from '../../const';
import { FormControlLabel } from 'components/FormControlLabel';
import { useStyles } from './DefaultCustomValueStyles';

interface IDefaultCustomValueProps {
  customValue: ICustomValue;
  placeholder?: string;
  customValidation?: (value: string, allValues: object) => string | undefined;
  Component: React.ComponentType<any>;
  attributes?: object & {
    type?: string;
    outputType?: string;
    placeholder?: string;
  };
  overrideLabel?: string;
}

export const DefaultCustomValue = ({
  customValue,
  placeholder,
  customValidation,
  Component,
  attributes,
  overrideLabel,
}: IDefaultCustomValueProps) => {
  const classes = useStyles();
  const form = useForm();

  const { key, value, label, description, title, overrideValue } = customValue;
  const showLabel =
    (typeof label === 'string' && label?.trim() !== '') ||
    (typeof overrideLabel === 'string' && overrideLabel?.trim() !== '');
  const name = `customValues.${key}`;

  useEffect(() => {
    if (overrideValue) {
      form?.change(name, overrideValue);
    }
  }, [name, form, overrideValue]);

  return (
    <div className={classes.root}>
      {showLabel && (
        <FormControlLabel
          title={title}
          description={description}
          classes={{ root: classes.label }}
        >
          {overrideLabel ?? (label || key.replace('ankrCustomValues___', ''))}
        </FormControlLabel>
      )}
      <Field name={name} validate={customValidation} type={attributes?.type}>
        {fieldProps => (
          <Component
            multiline={value === FieldType.textArea ? true : undefined}
            placeholder={placeholder}
            {...fieldProps}
            {...attributes}
          />
        )}
      </Field>
    </div>
  );
};

import React from 'react';
import { ZcashStatus } from './ZcashStatus';
import { ZcashCredentials } from './ZcashCredentials';

const ZcashConfig = () => {
  return (
    <div>
      <ZcashStatus />
      <ZcashCredentials />
    </div>
  );
};

export { ZcashConfig };

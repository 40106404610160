import {
  HathorHooks,
  hathorNodeName,
  HATHOR_IMPORT_IDENTITY,
  HATHOR_IDENTITY_FILE,
} from './HathorConst';
import {
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/hathor.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/HathorManage').then(module => module.HathorManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableCustomValues = loadable(
  async () =>
    import('./deploy/HathorCustomValues').then(
      module => module.HathorCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

const hathorIsBackedUpParse = (response: IHookResponse) => {
  const responseJson = response.data.result
    ? safeParseJSON(response.data.result)
    : {};
  return {
    ...response,
    data: {
      isBackedUp: responseJson['is_backed_up'],
    },
  };
};

const hathorBackupParse = (response: IHookResponse) => {
  return {
    ...response,
    data: response.data.result,
  };
};

export const hathorConfig: INodeConfig = {
  chartName: hathorNodeName,
  deploy: {
    component: LoadableCustomValues,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: () => ({
    customValues: {
      [HATHOR_IMPORT_IDENTITY]: 'false',
      [HATHOR_IDENTITY_FILE]: '',
    },
  }),
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => ({
    [HATHOR_IMPORT_IDENTITY]:
      customValues && (customValues[HATHOR_IMPORT_IDENTITY] ?? 'false'),
    [HATHOR_IDENTITY_FILE]:
      customValues && (customValues[HATHOR_IDENTITY_FILE] ?? ''),
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'hathor-node': {
      [HathorHooks.getNodeStatus]: defaultParse,
      [HathorHooks.getPeerId]: defaultParse,
      [HathorHooks.getIsBackedUp]: hathorIsBackedUpParse,
      [HathorHooks.getIdentityBackup]: hathorBackupParse,
    },
  },
};

import { Theme } from '@material-ui/core/styles';

import { MAIN_LAYOUT_SIDEBAR_WIDTH } from 'common/core/const';
import { makeStyles } from '@material-ui/styles';

export const useNavBarStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'fixed',
    width: MAIN_LAYOUT_SIDEBAR_WIDTH,
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.white,
  },
  logo: {
    width: 48,
    height: 48,
    display: 'block',
    margin: '10px 0 2px 16px',
    textDecoration: 'none',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 75,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  item: {
    width: MAIN_LAYOUT_SIDEBAR_WIDTH,
    height: 62,
    backgroundColor: theme.palette.common.white,
    borderLeft: '2px solid transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0',
    marginBottom: theme.spacing(1.75),
    '&:hover svg, &:hover $text': {
      color: theme.palette.primary.main,
    },
  },
  current: {
    backgroundColor: '#f4f8fe',
    borderColor: theme.palette.primary.main,
    '& svg, & $text': {
      color: theme.palette.primary.main,
    },
  },
  text: {
    color: theme.palette.grey['700'],
    textSizeAdjust: 'none',
    fontWeight: 'bold',
    fontSize: 10,
    lineHeight: '10px',
  },
  enterprise: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginTop: -10,
    textAlign: 'center',
  },
}));

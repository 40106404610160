import { StyleRules, Theme } from '@material-ui/core/styles';

const QRCodeStyles = (theme: Theme): StyleRules => ({
  root: {
    width: 150,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export { QRCodeStyles };

/* eslint-disable @typescript-eslint/camelcase */
import * as Sentry from '@sentry/browser';
import {
  IApiTeam,
  IApiTeamMember,
  IApiTeamResponse,
  IApiTeamRole,
  IApiTeamRolesResponse,
  ITeam,
  ITeamMember,
  ITeamRole,
} from '../teamTypes';
import { t } from 'common/utils/intl';

type CustomerCode = 'B' | 'C';

function isEnterprise(customerCode: CustomerCode) {
  return customerCode === 'B';
}

export function mapToAPIEnterpriseLevel(
  level?: string,
  isEnterprise?: boolean,
) {
  if (!level || !isEnterprise) {
    return '';
  }

  if (isEnterprise) {
    return 'B' + level;
  }

  return 'C' + level;
}

export const mapTeam = (team: IApiTeam): ITeam => {
  const [, customerCode, level] = team.level.match(/^(\w)(\d+)$/) ?? [];

  return {
    id: team.id,
    name: team.team_name,
    isDefault: team.default,
    createTime: new Date(team.create_time),
    updateTime: new Date(team.update_time),
    isEnterprise: isEnterprise(customerCode as CustomerCode),
    level: level,
  };
};

const mapMember = (member: IApiTeamMember): ITeamMember => {
  return {
    id: member.id,
    name: member.member_name,
    email: member.email,
    status: member.status,
    role: member.role_ids[0],
    roleIds: member.role_ids,
    createTime: new Date(member.create_time),
    updateTime: new Date(member.update_time),
  };
};

const OWNER_ROLE = 'system:owner';
const filterOwnerRole = (role: IApiTeamRole): boolean => {
  return role.id !== OWNER_ROLE;
};

const mapTeamRole = (role: IApiTeamRole): ITeamRole => {
  return {
    id: role.id,
    name: role.role_name,
    isSystem: role.system,
  };
};

export const mapTeamMembers = (members: IApiTeamMember[]): ITeamMember[] => {
  try {
    return members
      .map(member => mapMember(member))
      .sort((a, b) => b.createTime.getTime() - a.createTime.getTime());
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapTeams = (response: IApiTeamResponse): ITeam[] => {
  try {
    return [
      ...(response.default_team
        ? [{ ...response.default_team, team_name: t('team.default-team') }]
        : []),
      ...response.teams,
    ].map(team => mapTeam(team));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapTeamRoles = (response: IApiTeamRolesResponse): ITeamRole[] => {
  try {
    return response.system_roles
      .filter(filterOwnerRole)
      .map(role => mapTeamRole(role));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

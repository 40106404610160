import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { SettingsActionTypes } from './SettingActions';
import { requestInactive, RequestStatus } from 'common/utils/requestStatus';
import {
  ApiIds,
  ApiIdsType,
  IApiNotificationSettingsItem,
  IApiNotificationSettingsListResponse,
} from './SettingsTypes';
import { IExtendedAxiosResponse } from 'common/types';
import { mapNotificationSettingsList } from './SettingsMappings';

export interface ISettingsState {
  fetchNotificationSettingsStatus: RequestStatus;
  notificationSettingsList: IApiNotificationSettingsItem[];
  apiId: ApiIdsType | string;
  updateNotificationSettingsStatus: RequestStatus;
}

const initialState: ISettingsState = {
  fetchNotificationSettingsStatus: requestInactive(),
  notificationSettingsList: [],
  apiId: ApiIds.ALL,
  updateNotificationSettingsStatus: requestInactive(),
};

export const SettingsReducer = createReducer(initialState, {
  ...createAPIReducer<
    ISettingsState,
    IExtendedAxiosResponse<IApiNotificationSettingsListResponse>
  >(
    SettingsActionTypes.FETCH_NOTIFICATION_SETTINGS,
    'fetchNotificationSettingsStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          notificationSettingsList: action.response.data
            ? mapNotificationSettingsList(action.response.data)
            : [],
        };
      },
    },
  ),
  ...createAPIReducer<
    ISettingsState,
    IExtendedAxiosResponse<IApiNotificationSettingsListResponse>
  >(
    SettingsActionTypes.UPDATE_NOTIFICATION_SETTINGS,
    'updateNotificationSettingsStatus',
  ),

  [SettingsActionTypes.SET_NOTIFICATION_SETTINGS_APP_ID]: (
    state: ISettingsState,
    action: { payload: string },
  ): ISettingsState => ({
    ...state,
    apiId: action.payload,
  }),
});

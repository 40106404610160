import { AppStatus, IApiApp, IApp } from '../types';
import uniqBy from 'lodash.uniqby';
import { PaymentWay } from '../../billing/apiMapping/appPaymentData';
import { kashe } from 'kashe';

export const isDailyRunningApiApp = (item: IApiApp) =>
  (item.status === 'APP_RUNNING' || item.status === 'APP_DEPLOYING') &&
  item.payWay === PaymentWay.Daily;

const isDailyRunningApp = (item: IApp) =>
  (item.status === AppStatus.APP_RUNNING ||
    item.status === AppStatus.APP_DEPLOYING) &&
  item.payWay === PaymentWay.Daily;

export const getAppsIcons = kashe(function(apps: IApp[]) {
  return uniqBy(
    apps.filter(isDailyRunningApp).map(item => ({
      icon: item.chart.iconUrl,
      nodeName: item.chart.name,
    })),
    'icon',
  );
});

export const hasDaily = kashe(function(apps: IApp[]) {
  return apps.some(
    item =>
      item.payWay === PaymentWay.Daily &&
      (item.status === AppStatus.APP_DEPLOYING ||
        item.status === AppStatus.APP_RUNNING),
  );
});

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useToolbarNotificationStyles = makeStyles<Theme>(theme => ({
  component: {
    display: 'flex',
    alignItems: 'center',
  },

  toggle: {
    position: 'relative',

    padding: 0,

    color: theme.palette.grey[700],

    cursor: 'pointer',
  },

  toggleAlert: {
    '&::after': {
      position: 'absolute',
      top: 1,
      right: 2.5,
      content: '""',

      display: 'block',
      width: 8,
      height: 8,

      boxSizing: 'border-box',

      borderRadius: '50%',
      border: `2px solid ${theme.palette.common.white}`,

      backgroundColor: theme.palette.primary.main,
    },
  },

  popper: {
    width: '100%',
    maxWidth: 370,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 316,
    },

    [theme.breakpoints.down('xs')]: {
      width: `calc(100vw - 10px)`,
      maxWidth: 'none',
    },
  },

  paper: {
    width: '100%',
    height: '100%',
    marginTop: 22,

    borderRadius: 4,

    backgroundColor: theme.palette.common.white,

    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',

    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      marginTop: 17,
    },
  },

  dropdown: {
    width: '100%',
    height: '100%',
  },

  nodeActivity: {
    width: '100%',
    height: '100%',
    '& > div:first-child': {
      margin: 0,
      padding: '16px 20px',
      height: '100%',
      borderBottom: `1px solid ${theme.palette.grey['200']}`,
    },
  },
}));

import React from 'react';
import { t } from 'common/utils/intl';
import { Typography, Box } from '@material-ui/core';

import { ReactComponent as EmptyNotifyIcon } from '../assets/EmptyNotify.svg';
import { useStyles } from './EmptyActivitiesStyles';

export const EmptyActivities = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        mb={6}
        className={classes.svg}
      >
        <EmptyNotifyIcon />
      </Box>
      <Typography
        variant="h3"
        className={classes.message}
        color="textSecondary"
        align="center"
      >
        {t('dashboard.node-activity.no-data')}
      </Typography>
    </div>
  );
};

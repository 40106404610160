import { PlasmTestnetHooks, plasmTestnetNodeName } from './PlasmTestnetConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/PlasmTestnet.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/PlasmTestnetManage').then(
      module => module.PlasmTestnetManage,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/PlasmTestnetCustomValues').then(
      module => module.PlasmTestnetCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      current_height: Number(data.currentBlock ?? 0) ?? 0,
      latest_height: Number(data.lastBlock ?? 0) ?? 0,
      isSyncing: data.isSyncing === 'true',
    },
  };
}

export const plasmTestnetConfig: INodeConfig = {
  chartName: plasmTestnetNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'plasm-node': {
      [PlasmTestnetHooks.getNodeStatus]: parseStatus,
      [PlasmTestnetHooks.getSessionKey]: defaultParse,
      [PlasmTestnetHooks.getValidatorName]: defaultParse,
    },
  },
};

import { IApp } from 'common/modules/apps/types';
import { NODE_NAME_MAX_LENGTH } from '../const';

const NODE_NUM_REGEXP = /-[0-9]+$/;

const findMaxInstance = (appList: IApp[], chartName: string) => {
  const filteredApps = appList.filter(app => app.chart.name === chartName);
  let max = 0;

  if (filteredApps) {
    max = filteredApps.length;
    filteredApps.forEach(app => {
      const res = app.name.match(NODE_NUM_REGEXP);
      if (!res) return;
      const cur = +res[0].replace('-', '');
      max = cur > max ? cur : max;
    });
  }
  return max + 1;
};

export const getInstanceDefaultName = (
  appList: IApp[],
  chartName: string,
  userName: string,
) => {
  try {
    const newInstanceNum = findMaxInstance(appList, chartName).toFixed(0);

    return `${userName}-${chartName}`
      .replace(' ', '-')
      .substring(0, NODE_NAME_MAX_LENGTH - newInstanceNum.length - 1)
      .concat(`-${newInstanceNum}`);
  } catch {
    return `${userName}-${chartName}-1`;
  }
};

export const getPrefixedInstanceDefaultName = (
  appList: IApp[],
  chartName: string,
  userName: string,
) => {
  try {
    const newInstanceNum = findMaxInstance(appList, chartName).toFixed(0);

    return `Ankr-${userName}`
      .replace(' ', '-')
      .substring(0, NODE_NAME_MAX_LENGTH - newInstanceNum.length - 1)
      .concat(`-${newInstanceNum}`);
  } catch {
    return `Ankr-${userName}-1`.replace(' ', '-');
  }
};

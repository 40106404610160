import React, { useEffect, useRef } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { NODES_PATH } from './navUtils';
import { parse } from 'query-string';
import { EXTERNAL_TOKEN } from '../core/const';

interface IPublicOnlyRouteProps extends RouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  isSignedIn: boolean;
}

const PublicOnlyRoute = ({
  component: Component,
  render,
  isSignedIn,
  location,
  ...rest
}: IPublicOnlyRouteProps) => {
  const inited = useRef<boolean>(false);

  useEffect(() => {
    inited.current = true;
  }, []);

  const renderComponentOrRedirect = (routeProps: RouteComponentProps) => {
    const currentSearch = parse(routeProps.location.search);

    if (!isSignedIn || inited.current || currentSearch?.[EXTERNAL_TOKEN]) {
      return <>{Component && <Component {...routeProps} />}</>;
    }

    return (
      <Redirect
        to={{
          pathname: NODES_PATH,
        }}
      />
    );
  };

  return <Route {...rest} render={renderComponentOrRedirect} />;
};

export { PublicOnlyRoute };

/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { put, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { OfflineActions } from 'common/store/actions/OfflineActions';

function connectionEventChannel() {
  return eventChannel(emitter => {
    const onOnline = () => {
      emitter(false);
    };
    const onOffline = () => {
      emitter(true);
    };
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  });
}

export function* offlineDetectSaga() {
  const offlineDetectionChannel = connectionEventChannel();
  try {
    while (true) {
      const offline = yield take(offlineDetectionChannel);
      yield put(OfflineActions.setOffline(offline));
    }
  } catch (e) {
    Sentry.captureException(e);
  } finally {
    // tslint:disable-next-line:no-console
    console.warn('Oops, offline detect saga failed.');
  }
}

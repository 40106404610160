import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { lighten } from '@material-ui/core/styles';
import classNames from 'classnames';

type ModeVariant = 'gradient' | 'pure' | 'shadow';

interface IDotProps {
  color: string;
  title?: string;
  size?: number;
  mode?: ModeVariant;
}

const useStyles = makeStyles(() => ({
  root: ({ size = 8, color }: IDotProps) => ({
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundColor: color,
  }),
  background: ({ color }: IDotProps) => ({
    backgroundImage: `radial-gradient(circle at 41% 40%, ${lighten(
      color,
      0.9,
    )}, ${lighten(color, 0.6)} 23%, ${lighten(color, 0.3)} 45%, ${color})`,
  }),
  shadow: ({ color }: IDotProps) => ({
    boxShadow: `0px 2px 8px ${color}`,
  }),
}));

export const Dot = (props: IDotProps) => {
  const { mode } = props;
  const isPure = mode === 'pure';
  const isShadow = mode === 'shadow';
  const { root, background, shadow } = useStyles(props);

  return (
    <Box
      width={8}
      height={8}
      className={classNames(
        root,
        !isPure && !isShadow && background,
        isShadow && shadow,
      )}
      title={props.title}
    />
  );
};

import { AvalancheOHooks, avalancheONodeName } from './AvalancheOConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/avalancheO.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';

const LoadableManage = loadable(
  async () =>
    import('./manage/AvalancheOManage').then(module => module.AvalancheOManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

export const avalancheOConfig: INodeConfig = {
  chartName: avalancheONodeName,

  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    avalanche: {
      [AvalancheOHooks.getBootstrapStatus]: defaultParse,
    },
  },
};

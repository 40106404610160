export const ENECUUM_NODE_NAME = 'enecuum';

export const ENECUUM_DOCUMENTATION_LINK = 'https://guides.enecuum.com/';

export const ENECUUM_POS_ID = 'ankrCustomValues___posId';
export const ENECUUM_NETWORK = 'ankrCustomValues___network';

export const ENECUUM_MAX_UPTIME_VALUE = 1;
export const ENECUUM_MAX_UPTIME_RED_PERCENT = 0.5;
export const ENECUUM_MAX_UPTIME_YELLOW_PERCENT = 0.8;

export const ENECUUM_NETWORK_OPTION_TESTNET = 'testnet';
export const ENECUUM_NETWORK_OPTION_MAINNET = 'mainnet';

export enum ENECUUM_HOOKS {
  getNodeStatus = 'apphook1=node-status',
  getValidatorStatus = 'apphook2=validator-status',
  getAddress = 'apphook3=get-address',
  getNetwork = 'apphook4=get-network',
}

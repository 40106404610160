import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { startOfDay, endOfDay } from 'date-fns';

import { IStoreState } from 'store/reducers';
import { INodeLog } from 'common/modules/nodeLog/nodeLogTypes';
import { RequestStatus } from 'common/utils/requestStatus';
import { useInitEffect } from 'common/utils/hooks';
import { NodeLogActions } from 'common/modules/nodeLog/actions/NodeLogAction';
import { NodeActivityView } from './NodeActivityView';

interface INodeActivityStoreProps {
  nodeLogs: INodeLog[];
  fetchNodeLogStatus: RequestStatus;
  className?: string;
  queryLog?: (startTime: number, endTime: number) => void;
}

interface INodeActivityProps extends INodeActivityStoreProps {
  teamId?: string;
  fetchNodeLog: typeof NodeLogActions.fetchLogs;
}

const NodeActivityComponent = ({
  fetchNodeLog,
  teamId,
  ...props
}: INodeActivityProps) => {
  const queryLog = useCallback(
    (startTime: number, endTime: number) => {
      if (teamId) {
        fetchNodeLog({
          teamId: teamId,
          startTime: startTime.toString(),
          endTime: endTime.toString(),
        });
      }
    },
    [teamId, fetchNodeLog],
  );

  useInitEffect(() => {
    const today = new Date();
    const startTime = startOfDay(today).getTime();
    const endTime = endOfDay(today).getTime();

    queryLog(
      Number.parseInt((startTime / 1000).toString()),
      Number.parseInt((endTime / 1000).toString()),
    );
  });

  return <NodeActivityView queryLog={queryLog} {...props} />;
};

export const NodeActivity = connect(
  (state: IStoreState) => {
    return {
      teamId: state.team.currentTeamId,
      nodeLogs: state.nodeLogs.nodeLogs,
      fetchNodeLogStatus: state.nodeLogs.fetchNodeLogStatus,
    };
  },
  {
    fetchNodeLog: NodeLogActions.fetchLogs,
  },
)(NodeActivityComponent);

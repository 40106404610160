import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import { StafiHooks, stafiNodeName } from './stafiConst';
import { getInstanceDefaultName } from '../../../AppDeployUI/utils/getInstanceDefaultName';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/stafi.en-US.json';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableConfig = loadable(
  async () => import('./manage/StafiConfig').then(module => module.StafiConfig),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/StafiCustomValue').then(module => module.StafiCustomValue),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const stafiConfig: INodeConfig = {
  chartName: stafiNodeName,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.matic-deploy.title',
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      ankrCustomValues___nodeName: getInstanceDefaultName(
        appList,
        chartName,
        userName,
      ),
      ankrCustomValues___archiveMode: 'false',
    },
  }),
  info: LoadableConfig,
  manage: false as false,
  details: false as false,
  interceptors: {
    'stafi-node': {
      [StafiHooks.archiveMode]: defaultParse,
      [StafiHooks.nodeName]: defaultParse,
      [StafiHooks.nodeStatus]: defaultParse,
      [StafiHooks.queryChainProps]: defaultParse,
      [StafiHooks.syncStatus]: defaultParse,
    },
  },
};

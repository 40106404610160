import { IExtendedRequestAction } from 'common/types';
import { API_MARKET_ADMIN } from '../APIMarketConsts';
import {
  APIAuthenticationType,
  IAPIPayment,
  IAPIRequestsHistoryListServerParams,
  SubscribeStatus,
} from './APIMarketTypes';
import { createAction } from 'common/utils/reduxUtils';
import { MissingOrderEmptyAPIList } from './effects/interceptors';

export const APIMarketActionTypes = {
  FETCH_API_MARKET_MARKET_LIST: 'FETCH_API_MARKET_MARKET_LIST',
  FETCH_API_MARKET_API_LIST: 'FETCH_API_MARKET_API_LIST',
  FETCH_API_LIST: 'FETCH_API_LIST',
  FETCH_API_LIST_ITEM: 'FETCH_API_LIST_ITEM',
  CREATE_API_PROJECT: 'CREATE_API_PROJECT',
  CREATE_API_PROJECT_RESET: 'CREATE_API_PROJECT_RESET',
  UPGRADE_API_MARKET_PLAN: 'UPGRADE_API_MARKET_PLAN',
  UPGRADE_API_MARKET_PLAN_RESET: 'UPGRADE_API_MARKET_PLAN_RESET',
  DELETE_API_MARKET_API: 'DELETE_API_MARKET_API',
  DELETE_API_MARKET_API_RESET: 'DELETE_API_MARKET_API_RESET',
  FETCH_API_MARKET_API_STATS: 'FETCH_API_MARKET_API_STATS',
  FETCH_API_MARKET_CREATE_DATA: 'FETCH_API_MARKET_CREATE_DATA',
  FETCH_API_MARKET_CREATE_DATA_RESET: 'FETCH_API_MARKET_CREATE_DATA',
  FETCH_API_MARKET_PLANS_LIST: 'FETCH_API_MARKET_PLANS_LIST',
  GET_API_MARKET_ORDER: 'GET_API_MARKET_ORDER',
  GET_API_MARKET_BLOCK_INFO: 'GET_API_MARKET_BLOCK_INFO',
  GET_API_MARKET_UPDATE_PLAN_AMOUNT: 'GET_API_MARKET_UPDATE_PLAN_AMOUNT',
  GET_API_MARKET_METHOD: 'GET_API_MARKET_METHOD',
  GET_API_MARKET_METHOD_RESET: 'GET_API_MARKET_METHOD_RESET',
  GET_API_MARKET_PROJECT_LOG: 'GET_API_MARKET_PROJECT_LOG',
  GET_API_MARKET_PROJECT_ALLOWED_DOMAIN:
    'GET_API_MARKET_PROJECT_ALLOWED_DOMAIN',
  CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN:
    'CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN',
  CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN_RESET:
    'CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN_RESET',
  DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN:
    'DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN',
  DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN_RESET:
    'DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN_RESET',
  GET_API_MARKET_PROJECT_WHITE_IP: 'GET_API_MARKET_PROJECT_WHITE_IP',
  CREATE_API_MARKET_PROJECT_WHITE_IP: 'CREATE_API_MARKET_PROJECT_WHITE_IP',
  CREATE_API_MARKET_PROJECT_WHITE_IP_RESET:
    'CREATE_API_MARKET_PROJECT_WHITE_IP_RESET',
  DELETE_API_MARKET_PROJECT_WHITE_IP: 'DELETE_API_MARKET_PROJECT_WHITE_IP',
  DELETE_API_MARKET_PROJECT_WHITE_IP_RESET:
    'DELETE_API_MARKET_PROJECT_WHITE_IP_RESET',
  DELETE_API: 'DELETE_API',
  DELETE_API_RESET: 'DELETE_API_RESET',
  SUBSCRIBE_API: 'SUBSCRIBE_API',
  SUBSCRIBE_API_RESET: 'SUBSCRIBE_API_RESET',
  GET_API_DAILY_QUOTA: 'GET_API_DAILY_QUOTA',
  GET_API_DAILY_QUOTA_RESET: 'GET_API_DAILY_QUOTA_RESET',
  GET_ARCHIVE_MODE_PAYMENT_STATUS: 'GET_ARCHIVE_MODE_PAYMENT_STATUS',
  GET_ARCHIVE_MODE_PAYMENT_STATUS_RESET:
    'GET_ARCHIVE_MODE_PAYMENT_STATUS_RESET',
  CREATE_ARCHIVE_ORDER: 'CREATE_ARCHIVE_ORDER',
  CREATE_ARCHIVE_ORDER_RESET: 'CREATE_ARCHIVE_ORDER_RESET',
  FETCH_REQUESTS_HISTORY_LIST: 'FETCH_REQUESTS_HISTORY_LIST',

  FETCH_API_CLUSTER_LIST: 'FETCH_API_CLUSTER_LIST',
};

export interface ICreateAPIPayload {
  serviceId: string;
  projectName: string;
  nodeCode: string;
  teamId: string;
  subscribeStatus: SubscribeStatus;
  authType: APIAuthenticationType;
  authPassword: string;
  authUsername: string;
  authToken: string;

  paymentMethods: string;
  creditCardId: string;
  clusterId: string;
}

export interface IAPIProjectAllowedAddressPayload {
  projectId: string;
  address: string;
}

export interface IUpgradePlanPayload {
  payment: IAPIPayment;
  planId: string;
  orderId: string;
}

export interface ISubscribePayload {
  orderId: string;
  subscribeStatus?: SubscribeStatus;
}

export interface IArchiveModePaymentStatusPayload {
  teamId: string;
  nodeCode: string;
  planId: string;
}

export interface IArchiveOrderPayload {
  teamId: string;
  nodeCode: string;
  payment: IAPIPayment;
}

export const APIMarketActions = {
  fetchInfuraMarketList: (meta = {}): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_API_MARKET_MARKET_LIST,
    request: {
      url: `/apis/apiservice/node/v1alpha/list`,
      method: 'GET',
    },
    meta: {
      ...meta,
    },
  }),
  fetchInfuraAPIList: (teamId: string, meta = {}): IExtendedRequestAction => ({
    type: 'EMPTY', // should be retrieved together with market list, see FETCH_API_LIST and runFetchAPIListSaga
    request: {
      url: `/apis/apiorder/v2alpha/api/project/list/${teamId}`,
      method: 'GET',
      // @ts-ignore
      handleError: [MissingOrderEmptyAPIList],
    },
    meta: {
      // TODO only one request needed
      takeLatest: false,
      ...meta,
    },
  }),
  fetchInfuraAPIListItem: (
    apiId: string,
    meta = {},
  ): IExtendedRequestAction => ({
    type: 'EMPTY',
    request: {
      url: `/apis/apiorder/v2alpha/api/project/${apiId}`,
      method: 'GET',
    },
    meta: {
      ...meta,
    },
  }),
  fetchAPIList: (teamId: string) =>
    createAction(APIMarketActionTypes.FETCH_API_LIST, teamId),
  fetchAPIListItem: (apiId: string) =>
    createAction(APIMarketActionTypes.FETCH_API_LIST_ITEM, apiId),
  fetchAPIPlansList: (): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_API_MARKET_PLANS_LIST,
    request: {
      url: `/apis/apiservice/plan/v1alpha/system`,
      method: 'GET',
    },
  }),
  getAPIBlockInfo: (code: string): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_BLOCK_INFO,
    request: {
      url: `/apis/apiservice/block/v1alpha/${code}`,
      method: 'GET',
    },
  }),
  getAPIUpdatePlanAmount: (
    planId: string,
    orderId: string,
  ): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_UPDATE_PLAN_AMOUNT,
    request: {
      url: `/apis/apiorder/v2alpha/api/order/GetUpgradeOrderPayableAmount`,
      method: 'POST',
      data: {
        plan_id: planId,
        order_id: orderId,
      },
    },
  }),
  getAPIMethod: (
    url: string,
    data: any,
    authorization?: string,
  ): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_METHOD,
    request: {
      url,
      method: 'POST',
      data,
      headers: authorization
        ? {
            Authorization: authorization,
          }
        : {},
    },
    meta: {
      noAuth: true,
    },
  }),
  getAPIMethodReset: () =>
    createAction(APIMarketActionTypes.GET_API_MARKET_METHOD_RESET),
  getAPIProjectLog: (
    projectId: string,
    timeType: string,
  ): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_PROJECT_LOG,
    request: {
      url: `/apis/apiorder/v2alpha/api/project/log/total_HM/${projectId}/${timeType}`,
      method: 'GET',
    },
  }),
  getAPIProjectAllowedDomain: (projectId: string): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_PROJECT_ALLOWED_DOMAIN,
    request: {
      url: `/apis/apiorder/v2alpha/api/project/security/cross/${projectId}`,
      method: 'GET',
    },
  }),
  createAPIProjectAllowedDomain: (payload: IAPIProjectAllowedAddressPayload) =>
    createAction(
      APIMarketActionTypes.CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN,
      payload,
    ),
  createAPIProjectAllowedDomainReset: () =>
    createAction(
      APIMarketActionTypes.CREATE_API_MARKET_PROJECT_ALLOWED_DOMAIN_RESET,
    ),
  deleteAPIProjectAllowedDomain: (payload: IAPIProjectAllowedAddressPayload) =>
    createAction(
      APIMarketActionTypes.DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN,
      payload,
    ),
  deleteAPIProjectAllowedDomainReset: () =>
    createAction(
      APIMarketActionTypes.DELETE_API_MARKET_PROJECT_ALLOWED_DOMAIN_RESET,
    ),
  getAPIProjectWhiteIP: (projectId: string): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_PROJECT_WHITE_IP,
    request: {
      url: `/apis/apiorder/v2alpha/api/project/security/ip_white_list/${projectId}`,
      method: 'GET',
    },
  }),
  createAPIProjectWhiteIP: (payload: IAPIProjectAllowedAddressPayload) =>
    createAction(
      APIMarketActionTypes.CREATE_API_MARKET_PROJECT_WHITE_IP,
      payload,
    ),
  createAPIProjectWhiteIPReset: () =>
    createAction(APIMarketActionTypes.CREATE_API_MARKET_PROJECT_WHITE_IP_RESET),
  deleteAPIProjectWhiteIP: (payload: IAPIProjectAllowedAddressPayload) =>
    createAction(
      APIMarketActionTypes.DELETE_API_MARKET_PROJECT_WHITE_IP,
      payload,
    ),
  deleteAPIProjectWhiteIPReset: () =>
    createAction(APIMarketActionTypes.DELETE_API_MARKET_PROJECT_WHITE_IP_RESET),

  createAPI: (payload: ICreateAPIPayload) =>
    createAction(APIMarketActionTypes.CREATE_API_PROJECT, payload),
  createInfuraAPIReset: () =>
    createAction(APIMarketActionTypes.CREATE_API_PROJECT_RESET),

  upgradePlan: (payload: IUpgradePlanPayload) =>
    createAction(APIMarketActionTypes.UPGRADE_API_MARKET_PLAN, payload),
  upgradePlanReset: () =>
    createAction(APIMarketActionTypes.UPGRADE_API_MARKET_PLAN_RESET),

  deleteAPI: (id: string) => createAction(APIMarketActionTypes.DELETE_API, id),
  deleteAPIReset: () => createAction(APIMarketActionTypes.DELETE_API_RESET),

  subscribeAPI: (payload: ISubscribePayload) =>
    createAction(APIMarketActionTypes.SUBSCRIBE_API, payload),

  subscribeAPIReset: () =>
    createAction(APIMarketActionTypes.SUBSCRIBE_API_RESET),

  deleteInfuraAPI: (key: string): IExtendedRequestAction => ({
    type: APIMarketActionTypes.DELETE_API_MARKET_API,
    request: {
      url: `${API_MARKET_ADMIN}/api/delete`,
      method: 'POST',
      data: { key },
    },
    meta: {
      noAuth: true,
      driver: 'mock',
    },
  }),
  deleteInfuraAPIReset: () =>
    createAction(APIMarketActionTypes.DELETE_API_MARKET_API_RESET),
  createArchiveOrder: ({
    order,
    api,
  }: {
    order: IArchiveOrderPayload;
    api: ICreateAPIPayload;
  }) =>
    createAction(APIMarketActionTypes.CREATE_ARCHIVE_ORDER, {
      order,
      api,
    }),
  createArchiveOrderReset: () =>
    createAction(APIMarketActionTypes.CREATE_ARCHIVE_ORDER_RESET),

  getAPIRequestsHistoryList: (
    projectId: string,
    params: IAPIRequestsHistoryListServerParams,
  ): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_REQUESTS_HISTORY_LIST,
    request: {
      url: `/apis/apiorder/v2alpha/api/project/log/list`,
      method: 'POST',
      data: {
        project_id: projectId,
        api_log_list_request_filter: params,
      },
    },
  }),
  fetchAPIClusterList: (): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_API_CLUSTER_LIST,
    request: {
      url: `/apimgr/v1/clusters`,
      method: 'GET',
    },
    meta: {
      takeLatest: false,
    },
  }),
};

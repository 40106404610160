import { withTransparentLayout } from '../LayoutUI/components/Layout';
import { withErrorHandler } from 'common/HOC/withErrorHandler';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from 'common/nav/PrivateRoute';
import { NODES_PATH } from 'common/nav/navUtils';
import { t } from 'common/utils/intl';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import loadable, { LoadableComponent } from '@loadable/component';

const LoadableDashboardContainer = withTransparentLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/DashboardUI/screens/Dashboard').then(
          module => module.Dashboard,
        ),
      {
        fallback: <LoadingIndicator />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const DashboardRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={NODES_PATH}
        component={LoadableDashboardContainer}
      />
      <Route
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};

import { ChartName, HOOKS } from 'common/modules/apps/const';
import {
  convertResponseToError,
  IHookResponse,
  Interceptor,
} from 'common/utils/convertResponseToError';
import {
  parseAvailable,
  parseOasisBalance,
  parseOasisInitialState,
} from './OasisInterceptors';
import {
  parseTomochainAddress,
  parseTomochainName,
  parseTomochainStatus,
} from './TomochainInterceptors';
import { parseSyncStatus } from './common';
import {
  parseCeloBalances,
  parseCeloDAppBalance,
  parseCeloDAppElectionList,
  parseCeloDAppLockedGold,
  parseCeloDAppVoteHistory,
} from './CeloInterceptors';
import { safeParseJSON } from '../../../utils/string';
import {
  parseHarmonyBalance,
  parseHarmonyCollectReward,
  parseHarmonySetValidatorActive,
  parseHarmonyStatus,
} from './HarmonyInterceptors';
import { parseKusamaKeystore, parseKusamaQueryAll } from './KusamaInterceptors';
import {
  parseCardanoBalance,
  parseCardanoKeys,
  parseCardanoNodeId,
  parseCardanoStatus,
} from './CardanoInterceptors';
import {
  parseElrondGetStatus,
  parseElrondGetVersion,
  parseElrondPeerRating,
  parseElrondUpgradeNode,
} from './ElrondInterceptors';
import {
  parsePchainCandidateStatus,
  pchainBalanceParse,
} from './PchainInterceptors';
import { parseCompound } from './CompoundInterceptors';
import { parseTomoXGetStatus } from './TomoXInterceptors';
import {
  parseQtumIndexingStatus,
  parseQtumRpcCredentials,
} from './QtumInterceptors';
import {
  parseSmartchainValidatorBalance,
  parseSmartchainValidatorGetStaked,
} from './SmartchainValidatorInterceptors';

import { parseBitcoinNodeStatus } from './BitcoinInterceptors';
import { parseNulStatus } from './NulInterceptors';
import { parseEthereum2NodeStatus } from './Ethereum2Interceptors';
import { getInterceptors } from '../../../utils/nodes/registerNode';

const RPC_ERROR_EXPR = /.*rpc error:.+/i;
const BAD_REQUEST_EXPR = /400 Bad Request/i;
const GENERAL_ERROR = /.*error.+/i;

export function hasHTTPError(result: string | object) {
  try {
    if (typeof result === 'string') {
      const jsonResult = safeParseJSON(result ?? '{}');
      const type = typeof jsonResult;

      if (type === 'object') {
        return false;
      }

      return (
        RPC_ERROR_EXPR.test(result) ||
        GENERAL_ERROR.test(result.toLocaleLowerCase()) ||
        BAD_REQUEST_EXPR.test(result.toLocaleLowerCase())
      );
    }
    return false;
  } catch {
    return true;
  }
}

export function defaultParse(response: IHookResponse): IHookResponse {
  return {
    ...response,
    data: safeParseJSON(response.data.result ?? '{}'),
  };
}

export function handleError(defaultValue: any) {
  return (response: IHookResponse): IHookResponse => {
    return {
      ...response,
      data: defaultValue ?? null,
    };
  };
}

function defaultBooleanParse(response: IHookResponse): IHookResponse {
  const parsed = safeParseJSON(response.data.result ?? '{}');

  if (typeof parsed === 'boolean') {
    return {
      ...response,
      data: { booleanResult: parsed },
    };
  } else {
    return {
      ...response,
      data: { booleanResult: false },
    };
  }
}

function defaultProgressParse(response: IHookResponse): IHookResponse {
  const parsed = safeParseJSON(response.data.result ?? '{}');

  try {
    return {
      ...response,
      data: { progress: parsed?.progress },
    };
  } catch {
    return {
      ...response,
      data: { progress: 0 },
    };
  }
}

const HOOK_BEFORE_ERROR_HANDLING_PARSERS: {
  [key: string]: { [key: string]: Interceptor };
} = {
  'tomox-node': {
    [HOOKS.TOMOX_GET_STATUS]: parseTomoXGetStatus,
  },
};

const HOOK_PARSERS: {
  [key: string]: { [key: string]: Interceptor };
} = {
  [ChartName.harmonyStaking]: {
    [HOOKS.HARMONY_STAKING_APPLY_TOKENS]: defaultParse,
    [HOOKS.HARMONY_STAKING_GET_BALANCE]: parseHarmonyBalance,
    [HOOKS.HARMONY_STAKING_CREATE_VALIDATOR]: defaultParse,
    [HOOKS.HARMONY_STAKING_CHECK_STATUS]: parseHarmonyStatus,
    [HOOKS.HARMONY_STAKING_SET_VALIDATOR_ACTIVE]: parseHarmonySetValidatorActive,
    [HOOKS.HARMONY_STAKING_COLLECT_REWARD]: parseHarmonyCollectReward,
    [HOOKS.HARMONY_STAKING_SELF_STAKING]: parseHarmonyCollectReward,
  },
  'dash-insight-node': {
    [HOOKS.DASH_INSIGHT_STATUS]: defaultParse,
  },
  // Kusama, Bifrost, Polkadot, and Darwinia are Polkadot-based nodes
  [ChartName.kusamaNode]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.bifrost]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.bifrostNode]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.bifrostValidator]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.polkadotNode]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.darwiniaNode]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.chainxNode]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.acalaNode]: {
    [HOOKS.KUSAMA_VALIDATOR_QUERY]: parseKusamaQueryAll,
    [HOOKS.KUSAMA_VALIDATOR_KEYSTORE_QUERY]: parseKusamaKeystore,
    [HOOKS.KUSAMA_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_KEYS]: defaultParse,
    [HOOKS.KUSAMA_VALIDATOR_QUERY_NODE_STATUS]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_CHAIN_PROPERTIES]: defaultParse,
    [HOOKS.KUSAMA_FULL_QUERY_NODE_STATUS]: defaultParse,
  },
  [ChartName.arweave]: {
    [HOOKS.ARWEAVE_NODE_STATUS]: defaultParse,
  },
  'arweave-download-snapshot': {
    [HOOKS.ARWEAVE_GET_PROGRESS]: defaultParse,
  },
  [ChartName.tomochainNode]: {
    [HOOKS.TOMOCHAIN_GET_NAME]: parseTomochainName,
    [HOOKS.TOMOCHAIN_GET_SYNC_STATUS]: parseSyncStatus,
    [HOOKS.TOMOCHAIN_GET_NODE_TYPE]: parseTomochainStatus,
    [HOOKS.TOMOCHAIN_GET_ADDRESS]: parseTomochainAddress,
  },
  'tomox-node': {
    [HOOKS.TOMOX_GET_INFO]: defaultParse,
    [HOOKS.TOMOX_GET_UPDATE_AVAILABILITY]: defaultParse,
  },
  [ChartName.oasisValidatorNode]: {
    [HOOKS.OASIS_REGISTERED]: defaultParse,
    [HOOKS.OASIS_BALANCE]: parseOasisBalance,
    [HOOKS.OASIS_AVAILABLE_WITHDRAWAL]: parseAvailable,
    [HOOKS.OASIS_AVAILABLE_RECLAIM]: parseAvailable,
    [HOOKS.OASIS_GET_INITIAL_STATE]: parseOasisInitialState,
    [HOOKS.OASIS_SYNC]: defaultParse,
  },
  [ChartName.cardanoStake]: {
    [HOOKS.CARDANO_STAKE_GET_BALANCE]: parseCardanoBalance,
    [HOOKS.CARDANO_STAKE_NODE_STATUS]: parseCardanoStatus,
    [HOOKS.CARDANO_STAKE_RETRIEVE_KEYS]: parseCardanoKeys,
    [HOOKS.CARDANO_STAKE_NODE_ID]: parseCardanoNodeId,
  },
  [ChartName.pgs]: {
    [HOOKS.CARDANO_NODE_STATUS]: defaultParse,
  },
  [ChartName.elrondNode]: {
    [HOOKS.ELROND_GET_STATUS]: parseElrondGetStatus,
    [HOOKS.ELROND_OBSERVER_GET_STATUS]: parseElrondGetStatus,

    [HOOKS.ELROND_GET_VERSION]: parseElrondGetVersion,
    [HOOKS.ELROND_OBSERVER_GET_VERSION]: parseElrondGetVersion,

    [HOOKS.ELROND_PEER_RATING]: parseElrondPeerRating,
    [HOOKS.ELROND_OBSERVER_PEER_RATING]: parseElrondPeerRating,

    [HOOKS.ELROND_UPGRADE_NODE]: parseElrondUpgradeNode,
    [HOOKS.ELROND_OBSERVER_UPGRADE_NODE]: parseElrondUpgradeNode,

    [HOOKS.ELROND_AUTO_UPDATE_STATUS]: defaultParse,
  },

  [ChartName.iostNode]: {
    [HOOKS.IOST_GET_STATUS]: defaultParse,
    [HOOKS.IOST_GET_PUBLIC_KEY]: defaultParse,
    [HOOKS.IOST_GET_ACCOUNT]: defaultParse,
    [HOOKS.IOST_GET_NETWORK_ID]: defaultParse,
    [HOOKS.IOST_ADD_ACCOUNT]: defaultParse,
    [HOOKS.IOST_GET_BALANCE]: defaultParse,
    [HOOKS.IOST_GET_VOTES]: defaultParse,
    [HOOKS.IOST_REGISTER]: defaultParse,
    [HOOKS.IOST_NODE_NAME]: defaultParse,
    [HOOKS.IOST_IS_REGISTERED]: defaultParse,
    [HOOKS.IOST_PLEDGE_GAS]: defaultParse,
    [HOOKS.IOST_BUY_RAM]: defaultParse,
  },
  [ChartName.iostInit]: {
    [HOOKS.IOST_GET_PROGRESS]: defaultProgressParse,
  },
  [ChartName.celoValidator]: {
    [HOOKS.CELO_VALIDATOR_NODE_STATUS]: defaultParse,
    [HOOKS.CELO_VALIDATOR_QUERY_BALANCE]: parseCeloBalances,
    [HOOKS.CELO_VALIDATOR_QUERY_ADDRESS]: defaultParse,
    [HOOKS.CELO_VALIDATOR_GIST_URL]: defaultParse,
    [HOOKS.CELO_VALIDATOR_ACTION_INITIAL]: defaultParse,
    [HOOKS.CELO_VALIDATOR_ACTION_STOP_VALIDATING]: defaultParse,
    [HOOKS.CELO_VALIDATOR_ACTION_START_VALIDATING]: defaultParse,
    [HOOKS.CELO_VALIDATOR_LOCK_UNLOCK]: defaultParse,
    [HOOKS.CELO_VALIDATOR_ACTION_WITHDRAW]: defaultParse,
    [HOOKS.CELO_VALIDATOR_QUERY_ATTESTATION]: defaultParse,
  },
  [ChartName.celo]: {
    [HOOKS.CELO_RETRIEVE_KEYSTORE]: defaultParse,
    [HOOKS.CELO_QUERY_REWARD]: defaultParse,
    [HOOKS.CELO_WITHDRAW_REWARD]: defaultParse,
    [HOOKS.CELO_NODE_ACCOUNT]: defaultParse,
    [HOOKS.CELO_STATUS]: defaultParse,
  },
  dapp: {
    // Celo DApp
    [HOOKS.CELO_DAPP_QUERY_ADDRESS]: defaultParse,
    [HOOKS.CELO_DAPP_QUERY_BALANCE]: parseCeloDAppBalance,
    [HOOKS.CELO_DAPP_QUERY_ELECTION_CURRENT]: defaultParse,
    [HOOKS.CELO_DAPP_QUERY_ELECTION_LIST]: parseCeloDAppElectionList,
    [HOOKS.CELO_DAPP_QUERY_ELECTION_SHOW]: defaultParse,
    [HOOKS.CELO_DAPP_QUERY_STATUS]: defaultParse,
    [HOOKS.CELO_DAPP_QUERY_VOTE_HISTORY]: parseCeloDAppVoteHistory,
    [HOOKS.CELO_DAPP_LOCKEDGOLD_LOCK]: defaultParse,
    [HOOKS.CELO_DAPP_LOCKEDGOLD_UNLOCK]: defaultParse,
    [HOOKS.CELO_DAPP_LOCKEDGOLD_SHOW]: parseCeloDAppLockedGold,
    [HOOKS.CELO_DAPP_LOCKEDGOLD_WITHDRAW]: defaultParse,
    [HOOKS.CELO_DAPP_QUERY_NEXT_ELECTION]: defaultParse,
    [HOOKS.CELO_DAPP_QUERY_GROUP_LIST]: defaultParse,
  },
  [ChartName.horizen]: {
    [HOOKS.HORIZEN_BECOME_PROD]: defaultParse,
    [HOOKS.HORIZEN_READY_TO_PROD]: defaultBooleanParse,
    [HOOKS.HORIZEN_ACTION_REQUIRED]: defaultParse,

    [HOOKS.HORIZEN_GET_ADDRESS]: defaultParse,
    [HOOKS.HORIZEN_GET_BALANCE]: defaultParse,
    [HOOKS.HORIZEN_GET_HEIGHT]: defaultParse,
  },
  [ChartName.horizenNode]: {
    [HOOKS.HORIZEN_BECOME_PROD]: defaultParse,
    [HOOKS.HORIZEN_READY_TO_PROD]: defaultBooleanParse,
    [HOOKS.HORIZEN_ACTION_REQUIRED]: defaultParse,

    [HOOKS.HORIZEN_GET_ADDRESS]: defaultParse,
    [HOOKS.HORIZEN_GET_BALANCE]: defaultParse,
    [HOOKS.HORIZEN_GET_HEIGHT]: defaultParse,
  },
  [ChartName.horizenInit]: {
    [HOOKS.HORIZEN_GET_PROGRESS]: defaultProgressParse,
  },

  'pchain-main': {
    [HOOKS.PCHAIN_GET_NODE_STATUS]: defaultParse,
    [HOOKS.PCHAIN_GET_EPOCH]: defaultParse,
    [HOOKS.PCHAIN_GET_KEYSTORE]: defaultParse,
    [HOOKS.PCHAIN_GET_BALANCE]: pchainBalanceParse,
    [HOOKS.PCHAIN_VALIDATE_KEYSTORE]: defaultParse,
    [HOOKS.PCHAIN_GET_CANDIDATE_STATUS]: parsePchainCandidateStatus,
    [HOOKS.PCHAIN_IS_UPDATABLE]: defaultParse,
    [HOOKS.PCHAIN_GET_VALIDATOR_STATUS]: defaultParse,
  },

  [ChartName.compoundRestful]: {
    [HOOKS.COMPOUND_GET_METADATA]: parseCompound,
    [HOOKS.COMPOUND_GET_USAGE]: parseCompound,
  },
  [ChartName.qtumNode]: {
    [HOOKS.QTUM_STATUS]: defaultParse,
    [HOOKS.QTUM_FULL_NODE_STATUS]: defaultParse,
    [HOOKS.QTUM_FULL_RPC_CREDENTIALS]: parseQtumRpcCredentials,

    [HOOKS.QTUM_INFO_NODE_STATUS]: defaultParse,
    [HOOKS.QTUM_INFO_RPC_CREDENTIALS]: defaultParse,
    [HOOKS.QTUM_INFO_INDEXING_STATUS]: parseQtumIndexingStatus,
  },
  [ChartName.qtumStakingNode]: {
    [HOOKS.QTUM_STAKING_GET_ADDRESS]: defaultParse,
    [HOOKS.QTUM_STAKING_GET_BALANCE]: defaultParse,
    [HOOKS.QTUM_STAKING_GET_NODE_STATUS]: defaultParse,
    [HOOKS.QTUM_STAKING_GET_STAKING_INFO]: defaultParse,
  },
  [ChartName.qtumDb]: {
    [HOOKS.QTUM_INFO_INDEXING_STATUS]: parseQtumIndexingStatus,
  },
  [ChartName.viteFull]: {
    [HOOKS.VITE_FULL_GET_SYNC_INFO]: defaultParse,
  },
  [ChartName.viteSupernode]: {
    [HOOKS.VITE_FULL_GET_SYNC_INFO]: defaultParse,
  },
  [ChartName.chainlink]: {
    [HOOKS.CHAINLINK_GET_ADDRESS]: defaultParse,
    [HOOKS.CHAINLINK_GET_EMAIL]: defaultParse,
    [HOOKS.CHAINLINK_GET_KEYS]: defaultParse,
    [HOOKS.CHAINLINK_GET_STATUS]: defaultParse,
  },

  [ChartName.maticNode]: {
    [HOOKS.MATIC_GET_STATUS]: defaultBooleanParse,
    [HOOKS.MATIC_GET_VALIDATOR_INFO]: defaultBooleanParse,

    [HOOKS.MATIC_FULL_GET_STATUS]: defaultParse,
    [HOOKS.MATIC_FULL_GET_BOR_STATUS]: defaultParse,
    [HOOKS.MATIC_FULL_GET_NETWORK]: defaultParse,
  },
  [ChartName.tronNode]: {
    [HOOKS.TRON_GET_VOTES]: defaultParse,
    [HOOKS.TRON_WALLET_ADDRESS]: defaultParse,
    [HOOKS.TRON_STATUS]: defaultParse,
  },
  [ChartName.tronInit]: {
    [HOOKS.TRON_GET_PROGRESS]: defaultProgressParse,
  },
  [ChartName.ethereumNode]: {
    [HOOKS.ETHEREUM_GENESIS_BLOCK_HASH]: defaultParse,
    [HOOKS.ETHEREUM_GET_ACCOUNTS]: defaultParse,
    [HOOKS.ETHEREUM_NODE_STATUS]: defaultParse,
    [HOOKS.ETHEREUM_LATEST_BLOCK_HEIGHT]: defaultParse,
    [HOOKS.EDGEWARE_GET_NODE_NAME]: defaultParse,
    [HOOKS.ETHEREUM_NETWORK_ID]: defaultParse,
    [HOOKS.ETHEREUM_ARCHIVE_MODE]: defaultParse,
  },
  [ChartName.ethereumClassicNode]: {
    [HOOKS.ETHEREUM_CLASSIC_NODE_STATUS]: defaultParse,
    [HOOKS.ETHEREUM_CLASSIC_ARCHIVE_MODE]: defaultParse,
  },
  [ChartName.eth2Beacon]: {
    [HOOKS.ETHEREUM2_NODE_STATUS]: parseEthereum2NodeStatus,
    [HOOKS.ETHEREUM2_PEERS_COUNT]: defaultParse,
    [HOOKS.ETHEREUM2_GET_GENESIS]: defaultParse,
    [HOOKS.ETHEREUM2_GET_ACTIVATION_EPOCH]: defaultParse,
    [HOOKS.ETHEREUM2_GET_ETH1_DEPOSIT_BLOCK_NUMBER]: defaultParse,
    [HOOKS.ETHEREUM2_GET_EFFECTIVE_BALANCE]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_INDEX]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_SLASHING_STATUS]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_STATUS]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_WITHDRAWAL_CREDENTIALS]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_PUBLIC_KEY]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_KEYSTORE]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_ASSIGNMENTS]: defaultParse,
    [HOOKS.ETHEREUM2_GET_KEY_BACKUP_STATUS]: defaultParse,
    [HOOKS.ETHEREUM2_SET_KEY_BACKUP_STATUS]: defaultParse,
    [HOOKS.ETHEREUM2_GET_DEPOSIT_DATA]: defaultParse,
    [HOOKS.ETHEREUM2_UPLOAD_NEW_KEYSTORE]: defaultParse,
    [HOOKS.ETHEREUM2_UPLOAD_NEW_DEPOSIT_DATA]: defaultParse,
    [HOOKS.ETHEREUM2_GET_KEYSTORE_VALIDATION_STATUS]: defaultParse,
    [HOOKS.ETHEREUM2_GET_VALIDATOR_ATTESTATION]: defaultParse,
  },
  [ChartName.smartchainValidator]: {
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_STATUS]: defaultParse,
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_NAME]: defaultParse,
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_BALANCE]: parseSmartchainValidatorBalance,
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_STAKED]: parseSmartchainValidatorGetStaked,
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_TYPE]: defaultParse,
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_NODE_ADDRESS]: defaultParse,
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_BINANCE_ADDRESS]: defaultParse,
    [HOOKS.SMARTCHAIN_VALIDATOR_GET_VALIDATOR_STATUS]: defaultParse,
  },
  [ChartName.dashNode]: {
    [HOOKS.DASH_NODE_STATUS]: defaultParse,
    [HOOKS.DASH_RPC_CREDENTIALS]: defaultParse,
  },
  [ChartName.reddcoinNode]: {
    [HOOKS.REDDCOIN_NODE_STATUS]: defaultParse,
    [HOOKS.REDDCOIN_RPC_CREDENTIALS]: defaultParse,
    [HOOKS.REDDCOIN_INSIGHT_STATUS]: defaultParse,
  },
  [ChartName.bitcoinNode]: {
    [HOOKS.BITCOIN_NODE_STATUS]: parseBitcoinNodeStatus,
    [HOOKS.BITCOIN_RPC_CREDENTIALS]: defaultParse,
  },
  [ChartName.decredNode]: {
    [HOOKS.DECRED_NODE_STATUS]: defaultParse,
    [HOOKS.DECRED_RPC_CREDENTIALS]: defaultParse,
  },
  [ChartName.neoNode]: {
    [HOOKS.NEO_GET_STATUS]: defaultParse,
  },
  [ChartName.blockstackFull]: {
    [HOOKS.BLOCKSTACK_NODE_STATUS]: defaultParse,
  },
  [ChartName.eosNode]: {
    [HOOKS.EOS_GET_NODE_STATUS]: parseNulStatus,
  },
};

function onFulfilled(response: IHookResponse) {
  const node = response?.config?.meta?.container || '';
  const label = response?.config?.meta?.label || '';

  if (HOOK_BEFORE_ERROR_HANDLING_PARSERS[node]?.[label]) {
    return HOOK_BEFORE_ERROR_HANDLING_PARSERS[node][label](response);
  }

  if (response?.data?.result && hasHTTPError(response?.data?.result)) {
    return Promise.reject(convertResponseToError(response));
  }

  if (HOOK_PARSERS[node]?.[label]) {
    return HOOK_PARSERS[node][label](response);
  }

  const interceptors = getInterceptors();

  if (interceptors?.[node]?.[label]) {
    return interceptors[node]?.[label](response);
  }

  return response;
}

function isAuthError(response: IHookResponse) {
  return (response as any).response?.status === 401;
}

function onRejected(response: IHookResponse) {
  if (isAuthError(response)) {
    return Promise.reject(response);
  }
  const handleConfiguration = response?.config?.handleError;

  if (!handleConfiguration) return Promise.reject(response);

  for (const config of handleConfiguration) {
    if (config.criteria(response)) {
      return Promise.resolve(config.provideValue);
    }
  }
}

export const hooksInterceptor = { onFulfilled, onRejected };

export const avalancheVNodeName = 'avalanche-v';

export enum AvalancheVHooks {
  isBootstrapped = 'apphook1=is-bootstrapped',
  isValidatorActive = 'apphook2=is-validator-active',
  getValidatorName = 'apphook3=get-validator-name',
  getNetworkName = 'apphook4=get-network-name',
  getStakerBackup = 'apphook5=get-staker-backup',
  isBackedUp = 'apphook6=is-backed-up',
}

export const AVALANCHE_V_NETWORK_ID = 'ankrCustomValues___network_id';
export const AVALANCHE_V_STAKER_CRT = 'ankrCustomValues___staker_crt';
export const AVALANCHE_V_STAKER_KEY = 'ankrCustomValues___staker_key';
export const AVALANCHE_V_IMPORT_STAKER = 'ankrCustomValues___import_staker';

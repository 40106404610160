import createMuiTheme, {
  ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';

export const TOOLBAR_HEIGHT = 60;
export const MOBILE_TOOLBAR_HEIGHT = 48;

export function createCustomTheme(options: ThemeOptions) {
  return createMuiTheme({
    extra: {
      toolbarHeight: TOOLBAR_HEIGHT,
      palette: {
        warning: {
          main: '#F2B040',
        },
        success: {
          main: '#4FCA80',
        },
      },
    },
    ...options,
  });
}

import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import {
  getRequestsError,
  isRequestFailed,
  isRequestInactive,
  isRequestInProgress,
  isRequestSuccessful,
  RequestStatus,
} from 'common/utils/requestStatus';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { Error } from 'common/components/Error';
import { NothingFound } from 'common/components/NothingFound';
import withStyles from '@material-ui/core/styles/withStyles';
import { requestStatusDisplayStyles } from './RequestStatusDisplayStyles';

export type RequestStatusDisplayErrorRender = (
  statusArray: RequestStatus[],
  errorText?: string,
) => React.ReactNode;

export interface IRequestStatusProps {
  status: RequestStatus | RequestStatus[];
}

interface IRequestErrorDisplayProps {
  renderError?: RequestStatusDisplayErrorRender;
  errorText?: string; // Alt error text
}

export interface IRequestStatusDisplayProps
  extends StyledComponentProps,
    IRequestStatusProps,
    IRequestErrorDisplayProps {
  children?:
    | (() => React.ReactNode)
    | React.ReactElement<any>
    | React.ReactElement[]
    | false
    | ReactNode;
  size?: number;
  showError?: boolean;
  data?: any | any[];
  noDataText?: string; // Alt no data text
  noDataIcon?: string;
  validateData?: boolean;
  showLoadingWhenInactive?: boolean;
  hideWhenLoading?: boolean;
  hideUpdateStatus?: boolean;
  hideProgressStatus?: boolean;

  disabled?: boolean; // Just display child components
}

export const isLoadingStatus = (status: RequestStatus | RequestStatus[]) =>
  (status instanceof Array ? status : [status]).some(isRequestInProgress);

const defaultError = (statusArray: RequestStatus[], errorText?: string) => {
  return (
    <Error center={true}>{errorText || getRequestsError(...statusArray)}</Error>
  );
};

export const RequestStatusDisplayComponent = ({
  children = null,
  data,
  size,
  noDataText,
  noDataIcon,
  status,
  errorText,
  showError = true,
  validateData = false,
  classes = {},
  showLoadingWhenInactive = true,
  hideWhenLoading = false,
  hideUpdateStatus = false,
  hideProgressStatus = false,
  disabled = false,
  renderError,
}: IRequestStatusDisplayProps) => {
  const statusArray = status instanceof Array ? status : [status];

  const [isSuccessful, setSuccessful] = useState(false);

  const renderChildren = () => {
    if (typeof children === 'function') {
      return children();
    }

    return children;
  };

  useEffect(() => {
    if (statusArray.every(isRequestSuccessful)) {
      setSuccessful(true);
    } else if (statusArray.some(isRequestFailed)) {
      setSuccessful(false);
    }
  }, [status, statusArray]);

  if (
    disabled ||
    (hideUpdateStatus &&
      isSuccessful &&
      statusArray.some(isRequestInProgress)) ||
    (hideProgressStatus && statusArray.some(isRequestInProgress))
  ) {
    return renderChildren();
  }

  if (
    statusArray.some(isRequestInProgress) ||
    (showLoadingWhenInactive && statusArray.some(isRequestInactive))
  ) {
    if (hideWhenLoading) {
      return null;
    }

    return (
      <LoadingIndicator
        size={size}
        classes={{ root: classes.loadingIndicator }}
      />
    );
  }

  if (showError && statusArray.some(isRequestFailed)) {
    return renderError && typeof renderError === 'function'
      ? renderError(statusArray, errorText)
      : defaultError(statusArray, errorText);
  }

  if (validateData && (!data || (Array.isArray(data) && data.length === 0))) {
    return (
      <NothingFound
        title={noDataText}
        icon={noDataIcon}
        classes={{ root: classes.nothingFound }}
      />
    );
  }
  return renderChildren();
};

export const RequestStatusDisplay = withStyles(requestStatusDisplayStyles)(
  RequestStatusDisplayComponent,
);

import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export function hasError<T extends unknown>(
  meta: FieldRenderProps<T, HTMLElement>['meta'],
) {
  return (
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched
  );
}

export function getErrorText<T extends unknown>(
  meta: FieldRenderProps<T, HTMLElement>['meta'],
  subField?: string,
) {
  if (!!hasError(meta)) {
    const { error, submitError } = meta;

    if (error) {
      if (Array.isArray(error)) {
        return (
          <>
            {error.map(item => (
              <div key={item}>{item}</div>
            ))}
          </>
        );
      }

      return subField ? error[subField] : error;
    }

    return submitError;
  }

  return undefined;
}

import React, { ReactNode } from 'react';
import { HookRow, IHookRowProps } from './HookRow';
import { SyncStatusVariant } from 'common/modules/nodes/const';
import {
  Box,
  Typography,
  useTheme,
  LinearProgress,
  Theme,
  StyledComponentProps,
} from '@material-ui/core';
import { Dot } from 'common/components/Dot';
import { t } from 'common/utils/intl';
import { getStatusColor } from './hookUtils';
import { withStyles } from '@material-ui/styles';
import { limitToOneHundred } from 'common/utils/get-percentage';

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      alignItems: 'flex-start',
      marginBottom: 12,
    },
  },
  barContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 16,
      justifyContent: 'flex-start',
    },
  },
  linearColorPrimary: {
    backgroundColor: theme.palette.grey['100'],
  },
  barRoot: {
    height: 4,
    width: '100%',
    borderRadius: 10,
    flex: 1,
    maxWidth: 221,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
    },
  },
  barColor: {
    backgroundColor: theme.palette.success.main,
    borderRadius: 10,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
  },
});

interface IStatusHookRowProps extends Omit<IHookRowProps, 'children'> {
  syncStatus: SyncStatusVariant;
  altTextStatus?: ReactNode;
  children?: ReactNode;
  percentage?: number;
  hideStatus?: boolean;
  percentageValue?: ReactNode;
}

const StatusHookRowComponent = ({
  syncStatus,
  altTextStatus,
  children,
  percentage,
  classes = {},
  hideStatus,
  percentageValue,
  ...rest
}: IStatusHookRowProps & StyledComponentProps) => {
  const theme = useTheme();
  const color = getStatusColor(syncStatus, theme);

  const isSynced = syncStatus === SyncStatusVariant.done;

  return (
    <HookRow {...rest}>
      <div className={classes.container}>
        {typeof percentage === 'number' && !isSynced && (
          <div className={classes.barContainer}>
            <LinearProgress
              classes={{
                root: classes.barRoot,
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.barColor,
              }}
              variant="determinate"
              value={limitToOneHundred(percentage)}
            />
            <Box ml={2.5}>
              <Typography variant="body2">
                {percentageValue
                  ? percentageValue
                  : t('nodes.manage.near.status.amount', {
                      amount: percentage.toFixed(0),
                    })}
              </Typography>
            </Box>
          </div>
        )}
        <div className={classes.status}>
          {!hideStatus && (
            <>
              <Box mr={2}>
                <Dot color={color} mode="shadow" />
              </Box>
              <Typography variant="body1" component="div">
                {altTextStatus || t(`nodes.sync-status.status.${syncStatus}`)}
              </Typography>
            </>
          )}
          {children}
        </div>
      </div>
    </HookRow>
  );
};

const StatusHookRow = withStyles(styles)(StatusHookRowComponent);

export { StatusHookRow };

const getPercentage = (value = 0, total = 0, precision = 0): number => {
  if (value === 0 || total === 0) {
    return 0;
  }

  const ratio = value / total;

  if (precision) {
    const pow = Math.pow(10, precision);
    return Math.round(ratio * 100 * pow) / pow;
  }

  if (ratio < 1 && ratio > 0.99) {
    return 99;
  }

  return Math.ceil(ratio * 100);
};

export const limitToOneHundred = (number: number): number => {
  try {
    return Math.max(0, Math.min(number, 100));
  } catch {
    return 0;
  }
};

export { getPercentage };

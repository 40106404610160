import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const MenuTicketsIcon = withSvgIcon(
  <>
    <rect width="24" height="24" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5C6.44772 5 6 5.44772 6 6V18C6 18.5523 6.44772 19 7 19H16C16.5523 19 17 18.5523 17 18V16.5C17 16.2239 17.2239 16 17.5 16H18.5C18.7761 16 19 16.2239 19 16.5V18C19 19.6569 17.6569 21 16 21H7C5.34315 21 4 19.6569 4 18V6C4 4.34315 5.34315 3 7 3H16C17.6569 3 19 4.34315 19 6V9.5C19 9.77614 18.7761 10 18.5 10H17.5C17.2239 10 17 9.77614 17 9.5V6C17 5.44772 16.5523 5 16 5H7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3536 10.0607C23.5489 10.2559 23.5489 10.5725 23.3536 10.7678L18.7072 15.4142C18.3167 15.8047 17.6835 15.8047 17.293 15.4142L14.6465 12.7678C14.4513 12.5725 14.4513 12.2559 14.6465 12.0607L15.3536 11.3536C15.5489 11.1583 15.8655 11.1583 16.0607 11.3536L18.0001 13.2929L21.9394 9.35355C22.1347 9.15829 22.4513 9.15829 22.6465 9.35355L23.3536 10.0607Z"
    />
    <rect x="8" y="7" width="7" height="2" />
    <rect x="8" y="11" width="5" height="2" />
    <rect x="8" y="15" width="5" height="2" />
  </>,
);

import { History } from 'history';
import { Reducer, combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
// Auth reducers
import { IUserState, userReducer } from 'auth/store/reducers/userReducer';
import { ITokensState, tokensReducer } from 'auth/store/reducers/tokensReducer';
import { IUIState, uiReducer } from './uiReducer';
import {
  clusterReducer,
  IClusterState,
} from 'common/store/reducers/clusterReducer';
import {
  appsReducer,
  IAppsState,
} from 'common/modules/apps/reducers/appsReducer';
import { ankrReducer, IAnkrState } from 'common/store/reducers/ankrReducer';
import {
  ISnackbarsState,
  snackbarsReducer,
} from 'common/store/reducers/snackbarsReducer';
import {
  IEthereumState,
  EthereumReducer,
} from 'common/store/reducers/EthereumMedallaReducer';
import {
  IOfflineState,
  offlineReducer,
} from 'common/store/reducers/offlineReducer';
import {
  INamespacesState,
  namespacesReducer,
} from 'common/modules/namespaces/reducers/namespacesReducer';
import {
  ITeamState,
  teamReducer,
} from 'common/modules/teams/reducers/teamReducer';
import {
  INodeLogState,
  nodeLogReducer,
} from 'common/modules/nodeLog/reducers/nodeLogReducer';
import {
  chartsReducer,
  IChartsState,
} from 'common/modules/apps/reducers/chartsReducer';
import { persistReducer } from 'redux-persist';
import {
  IRedirectState,
  redirectReducer,
} from 'common/modules/Redirect/reducers/redirectReducer';
import {
  ILocationState,
  locationReducer,
} from 'common/store/reducers/locationReducer';
import {
  IPayState,
  payReducer,
} from 'common/modules/billing/reducers/payReducer';
import {
  IInvoiceState,
  invoiceReducer,
} from 'common/modules/billing/reducers/invoiceReducer';
import {
  APIMarketReducer,
  IAPIMarketState,
} from '../../modules/APIMarketUI/store/APIMarketReducer';
import {
  ITwoFactorState,
  TwoFactorReducer,
} from '../../modules/TwoFactorAuth/store/TwoFactorReducer';
import {
  teamPersistConfig,
  tokensPersistConfig,
  userPersistConfig,
} from 'auth/store/reducers/webStorageConfigs';
import {
  IZendeskState,
  zendeskReducer,
} from 'common/modules/zendesk/reducers/zendeskReducer';
import {
  IAnkrStakingState,
  StkrReducer,
} from 'modules/Nodes/nodes/Stkr/store/StkrReducer';
import {
  ISettingsState,
  SettingsReducer,
} from 'modules/SettingsUI/store/SettingsReducer';

export interface IStoreState {
  router: RouterState;
  ui: IUIState;
  user: IUserState;
  apps: IAppsState;
  cluster: IClusterState;
  tokens: ITokensState;
  pay: IPayState;
  invoice: IInvoiceState;
  namespaces: INamespacesState;
  team: ITeamState;
  nodeLogs: INodeLogState;
  charts: IChartsState;
  redirect: IRedirectState;
  // shared code reducers
  ankr: IAnkrState;
  snackbars: ISnackbarsState;
  ethereumMedalla: IEthereumState;
  offline: IOfflineState;
  apiMarket: IAPIMarketState;
  settings: ISettingsState;
  twoFactor: ITwoFactorState;

  location: ILocationState;

  zendesk: IZendeskState;
  ankrStaking: IAnkrStakingState;
}

const createRootReducer = (history: History, requestsReducer: Reducer) =>
  combineReducers({
    router: connectRouter(history),
    ui: uiReducer,
    user: persistReducer(userPersistConfig, userReducer),
    apps: appsReducer,
    cluster: clusterReducer,
    tokens: persistReducer(tokensPersistConfig, tokensReducer),
    pay: payReducer,
    invoice: invoiceReducer,
    namespaces: namespacesReducer,
    team: persistReducer(teamPersistConfig, teamReducer),
    nodeLogs: nodeLogReducer,
    charts: chartsReducer,
    redirect: redirectReducer,
    apiMarket: APIMarketReducer,
    twoFactor: TwoFactorReducer,

    requests: requestsReducer,
    // shared code reducers
    ankr: ankrReducer,
    snackbars: snackbarsReducer,
    ethereumMedalla: EthereumReducer,
    offline: offlineReducer,

    location: locationReducer,

    zendesk: zendeskReducer,
    ankrStaking: StkrReducer,
    settings: SettingsReducer,
  });

export { createRootReducer };

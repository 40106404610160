import {
  TEZOS_VALIDATOR_TEZOS_NETWORK,
  TezosValidatorHooks,
  tezosValidatorNodeName,
} from './TezosValidatorConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/TezosValidator.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/TezosValidatorManage').then(
      module => module.TezosValidatorManage,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/TezosValidatorCustomValues').then(
      module => module.TezosValidatorCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  return {
    ...response,
    data: {
      ...data,
      currentHeight: Number(data.current ?? 0) ?? 0,
      latestHeight: Number(data.last ?? 0) ?? 0,
      isSyncing: data.isSyncing === 'true',
    },
  };
}

export const tezosValidatorConfig: INodeConfig = {
  chartName: tezosValidatorNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      [TEZOS_VALIDATOR_TEZOS_NETWORK]: 'mainnet',
    },
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'tezos-validator': {
      [TezosValidatorHooks.getNodeStatus]: parseStatus,
      [TezosValidatorHooks.getBakingBalance]: defaultParse,
      [TezosValidatorHooks.getBakingAddr]: defaultParse,
      [TezosValidatorHooks.isImported]: defaultParse,
      [TezosValidatorHooks.importPublicKey]: defaultParse,
      [TezosValidatorHooks.registerDelegate]: defaultParse,
      [TezosValidatorHooks.is_delegate]: defaultParse,
      [TezosValidatorHooks.get_auth_key]: defaultParse,
    },
  },
};

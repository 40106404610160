import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { BREAKPOINT_XXS } from 'common/core/const';

export const useEnableTwoFactorAuthStyles = makeStyles<Theme>(theme => ({
  wrapper: {
    position: 'relative',

    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',

    overflowY: 'auto',
  },

  topPanel: {
    position: 'relative',

    flexShrink: 0,

    width: '100%',
    padding: theme.spacing(2.5, 3),

    [theme.breakpoints.down('xs')]: {
      padding: `5.5vh ${theme.spacing(4.5)}px`,
    },

    [theme.breakpoints.down(BREAKPOINT_XXS)]: {
      padding: `5.5vh ${theme.spacing(2.5)}px`,
    },

    '@media print': {
      display: 'none',
    },
  },

  iconPrev: {
    position: 'absolute',

    width: 42,
    height: 42,
    padding: 0,

    color: theme.palette.grey[700],

    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[200]}`,

    cursor: 'pointer',

    transitionTimingFunction: 'linear',
    transitionDuration: '200ms',
    transitionProperty: 'color, border-color',

    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },

    '&:focus': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },

  step: {
    flexGrow: 1,
  },

  innerStep: {
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    margin: 'auto',

    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: 'none',
      maxHeight: 'calc(345px + 7.5vh)',
      minHeight: 'calc(320px + 7.5vh)',
      padding: `2.5vh ${theme.spacing(4.5)}px`,
      paddingBottom: '5vh',
    },

    [theme.breakpoints.down(BREAKPOINT_XXS)]: {
      padding: `2.5vh ${theme.spacing(2.5)}px`,
      paddingBottom: '5vh',
    },
  },

  bottomPanel: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: '"bar next"',
    gridColumnGap: theme.spacing(5.75),

    flexShrink: 0,
    alignItems: 'center',

    width: '100%',
    padding: theme.spacing(2.25, 4),

    borderTop: `1px solid ${theme.palette.grey[100]}`,

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexShrink: 0,

      padding: `3.5vh ${theme.spacing(4.5)}px`,

      borderTop: 'none',
    },

    [theme.breakpoints.down(BREAKPOINT_XXS)]: {
      padding: `3.5vh ${theme.spacing(2.5)}px`,
    },

    '@media print': {
      display: 'none',
    },
  },

  bar: {
    gridArea: 'bar',
  },

  next: {
    gridArea: 'next',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  prev: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
}));

import { safeParseJSON } from 'common/utils/string';
import { IHookResponse } from 'common/utils/convertResponseToError';
import BigNumber from 'bignumber.js';

export interface IOasisBalance {
  general?: BigNumber;
  escrow?: BigNumber;
  total?: BigNumber;
}

export function parseOasisBalance(response: IHookResponse): IHookResponse {
  const resultObj = safeParseJSON(response.data?.result ?? '{}');

  const general = resultObj?.general?.balance
    ? new BigNumber(resultObj.general.balance)
    : undefined;
  const escrow = resultObj?.escrow?.active?.balance
    ? new BigNumber(resultObj.escrow.active.balance)
    : undefined;
  const total = resultObj?.escrow?.active?.total_shares
    ? new BigNumber(resultObj.escrow.active.total_shares)
    : undefined;

  return {
    ...response,
    data: {
      general,
      escrow,
      total,
    } as IOasisBalance,
  };
}

export interface IOasisAvailable {
  value?: BigNumber;
}

export function parseAvailable(response: IHookResponse): IHookResponse {
  const value = safeParseJSON(response.data?.result ?? '{}');

  return {
    ...response,
    data: {
      value: typeof value === 'number' ? new BigNumber(value) : undefined,
    } as IOasisAvailable,
  };
}

interface IOasisInitialState {
  initial: boolean;
}

export function parseOasisInitialState(response: IHookResponse): IHookResponse {
  const isRegistered = safeParseJSON(response.data?.result ?? '{}');

  return {
    ...response,
    data: {
      initial: isRegistered !== true,
    } as IOasisInitialState,
  };
}

import { Route, Switch } from 'react-router';
import { withPlainLayout } from '../LayoutUI/components/Layout';
import { withErrorHandler } from 'common/HOC/withErrorHandler';
import { LoadableScreen } from 'common/components/LoadableScreen';
import React from 'react';
import { AUTH_PATH, EXPIRED_PATH } from 'auth/src/const';

import {
  LOGIN_PATH,
  MODE_PATH,
  PASSWORD_RECOVERY_PATH,
  PASSWORD_RECOVERY_SENT_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
  SIGN_UP_PATH,
  SIGN_UP_SUCCESS_PATH,
  USER_CONFIRM_REGISTRATION_PATH,
  USER_REGISTRATION_SUCCESS_PATH,
} from './const';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import { t } from 'common/utils/intl';
import { ThemeProvider } from '@material-ui/styles';
import { authTheme } from '../../themes/authTheme';
import { PublicOnlyRoute } from 'common/nav/PublicOnlyRoute';
import loadable, { LoadableComponent } from '@loadable/component';

const LoadableExpired = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/Expired').then(module => module.Expired),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableModeSwitcher = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/Mode').then(module => module.Mode),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableLogin = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/Login').then(module => module.Login),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableSignup = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/SignUp').then(module => module.SigUp),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadablePasswordRecovery = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/RecoverPassword').then(
          module => module.RecoverPassword,
        ),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadablePasswordRecoverySent = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/PasswordRecoverySent').then(
          module => module.PasswordRecoverySent,
        ),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableConfirmRegister = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/ConfirmRegister').then(
          module => module.ConfirmRegister,
        ),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableRegisterSuccess = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/RegisterSuccess').then(
          module => module.RegisterSuccess,
        ),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableResetPassword = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/ResetPassword').then(
          module => module.ResetPasswordContainer,
        ),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableSignUpSuccess = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/CreateAccountSuccess').then(
          module => module.CreateAccountSuccess,
        ),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadablePasswordResetSuccess = withPlainLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/AuthUI/screens/PasswordResetSuccess').then(
          module => module.PasswordResetSuccess,
        ),
      {
        fallback: <LoadableScreen fullPage />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const AuthRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <ThemeProvider theme={authTheme}>
      <Switch>
        <Route
          exact={true}
          path={EXPIRED_PATH}
          component={LoadableExpired}
          isSignedIn={isSignedIn}
        />

        <PublicOnlyRoute
          exact={true}
          path={LOGIN_PATH}
          component={LoadableLogin}
          isSignedIn={isSignedIn}
        />

        <PublicOnlyRoute
          exact={true}
          path={[MODE_PATH]}
          component={LoadableModeSwitcher}
          isSignedIn={isSignedIn}
        />

        <PublicOnlyRoute
          exact={true}
          path={['/', `${AUTH_PATH}/`, SIGN_UP_PATH]}
          component={LoadableSignup}
          isSignedIn={isSignedIn}
        />

        <PublicOnlyRoute
          exact={true}
          path={PASSWORD_RECOVERY_PATH}
          component={LoadablePasswordRecovery}
          isSignedIn={isSignedIn}
        />
        <PublicOnlyRoute
          exact={true}
          path={PASSWORD_RECOVERY_SENT_PATH}
          component={LoadablePasswordRecoverySent}
          isSignedIn={isSignedIn}
        />
        <PublicOnlyRoute
          exact={true}
          path={USER_CONFIRM_REGISTRATION_PATH}
          component={LoadableConfirmRegister}
          isSignedIn={isSignedIn}
        />
        <PublicOnlyRoute
          exact={true}
          path={RESET_PASSWORD_PATH}
          component={LoadableResetPassword}
          isSignedIn={isSignedIn}
        />
        <PublicOnlyRoute
          exact={true}
          path={USER_REGISTRATION_SUCCESS_PATH}
          component={LoadableRegisterSuccess}
          isSignedIn={isSignedIn}
        />
        <PublicOnlyRoute
          exact={true}
          path={RESET_PASSWORD_SUCCESS_PATH}
          component={LoadablePasswordResetSuccess}
          isSignedIn={isSignedIn}
        />
        <PublicOnlyRoute
          exact={true}
          path={SIGN_UP_SUCCESS_PATH}
          component={LoadableSignUpSuccess}
          isSignedIn={isSignedIn}
        />
        <Route
          isSignedIn={isSignedIn}
          component={LoadableNotFoundPaper}
          breadcrumbs={[t('navigation.404')]}
        />
      </Switch>
    </ThemeProvider>
  );
};

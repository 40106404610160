import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useXSStep2Styles = makeStyles<Theme>(theme => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    width: '110%',

    textAlign: 'center',
    marginBottom: theme.spacing(5.5),
  },

  content: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,

    width: '100%',
    paddingBottom: 20,
  },

  text: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    textAlign: 'center',
    lineHeight: 1.3,
  },

  code: {
    width: '100%',
  },

  keep: {
    marginTop: 60,
    marginRight: 0,
  },

  error: {
    position: 'absolute',
    bottom: 0,

    color: theme.palette.error.main,
    whiteSpace: 'nowrap',
  },

  looseText: {
    marginTop: theme.spacing(2.5),
    color: theme.palette.grey[500],
  },
}));

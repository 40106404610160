import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const UNEXPECTED_ERROR_MESSAGE = 'Unexpected Error';

export interface IHookResponse extends Omit<AxiosResponse, 'config'> {
  config: {
    meta?: {
      label?: string;
      container?: string;
    };
    handleError?: ErrorHandleConfiguration[];
  };
}

export type Interceptor = (response: IHookResponse) => IHookResponse;

export type ErrorHandleConfiguration = {
  criteria: (data: any) => boolean;
  provideValue: any;
};

export function convertResponseToError(response: IHookResponse): AxiosError {
  return {
    name: 'Error',
    message: response?.data?.result || UNEXPECTED_ERROR_MESSAGE,
    config: response.config as AxiosRequestConfig,
    code: '500',
    request: response.request,

    response: {
      ...(response as AxiosResponse),
      data: { error: response?.data?.result || UNEXPECTED_ERROR_MESSAGE },
    },
  };
}

import { put, putResolve, all } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import * as Sentry from '@sentry/browser';
import BigNumber from 'bignumber.js';

import { AppsActions } from 'common/modules/apps/actions/AppsActions';
import { IApiFetchAppsResponse } from 'common/modules/apps/types';
import { SendRequestResponse } from 'common/types';
import { IClusterFetchResponse } from 'common/store/apiMappings/clusterMappings';
import { IOverview } from 'common/modules/billing/reducers/invoiceReducerTypes';

import { ZERO } from 'common/core/const';
import { isDailyRunningApiApp } from '../../apps/utils/appsSelectors';
import {
  getCalculatePricesRequests,
  getUpdatedApps,
} from './fetchOverviewSagaUtils';

export function* fetchOverviewSaga(action: Action<{ teamId: string }>): any {
  try {
    const { teamId } = action.payload;

    const fetchAppsResponse: SendRequestResponse<IApiFetchAppsResponse> = yield putResolve(
      AppsActions.fetchApps({ teamId }, { silent: true }),
    );

    if ('error' in fetchAppsResponse) {
      return yield put({ type: action.type + '_ERROR' });
    }

    const filteredApps = fetchAppsResponse.data.apps.filter(
      isDailyRunningApiApp,
    );

    const calculatePriceResponse: SendRequestResponse<
      IClusterFetchResponse
    >[] = yield all(
      getCalculatePricesRequests(filteredApps).map(req => putResolve(req)),
    );

    const prices = calculatePriceResponse?.map((response, index) => {
      const app = fetchAppsResponse.data.apps[index];

      if ('error' in response) {
        throw new Error(response.error.message);
      }

      return new BigNumber(
        response?.data?.clusters?.find(() => app.namespace.cluster_id)
          ?.daily_amount || 0,
      );
    });

    const apps = getUpdatedApps(filteredApps, prices);

    const total = apps.reduce((acc, item) => acc.plus(item.dailyPrice), ZERO);

    yield put({
      type: action.type + '_SUCCESS',
      data: {
        apps,
        total,
      } as IOverview,
    });
  } catch (error) {
    Sentry.captureException(error);

    return yield put({
      type: action.type + '_ERROR',
      error: { message: error.toString() },
    });
  }
}

import BigNumber from 'bignumber.js';

import {
  IApiApp,
  IApiCreateAppResponse,
  IApiFetchAppsResponse,
  IApiLaunchAppResponse,
  IApiResetAppResponse,
  IApiRealtimeOverview,
  IApp,
  IAppAddressInfo,
  IAppsOverview,
  IClusterPrice,
} from '../types';
import {
  IApiCheckPrice,
  IApiErrorAction,
  ICluster,
  IExtendedAxiosResponse,
  IRealtimeOverview,
  IAlert,
  IAPIAlertResponse,
  IAPIUptimeResponse,
  IUptimeResponse,
  IWalletInfo,
} from 'common/types';
import {
  extractRequestError,
  requestFailed,
  requestInactive,
  requestInProgress,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import {
  IClusterFetchResponse,
  mappingClusters,
} from 'common/store/apiMappings/clusterMappings';
import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { AppsActionTypes, getAppDetailsURL } from '../actions/AppsActions';
import {
  mapMetricsOverview,
  normalizeApp,
  normalizeApps,
  normalizePrice,
  normalizeRpcData,
} from '../apiMappings/appMappings';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { AxiosResponse } from 'axios';
import { normalizeHook } from '../apiMappings/hookMappings';
import { IHooksData } from '../types/hookTypes';
import { TeamActionTypes } from '../../teams/actions/TeamActions';
import { PaymentMethod } from '../../billing/const';

export function getAppDetails(
  entries: IAppEntries,
  appId: string,
  teamId?: string,
): IAppEntry | undefined {
  return entries[getAppDetailsURL(appId, teamId)];
}

// TODO
const createHookDataId = (hook: string) => {
  return hook;
};

export const getHookData = <R = string>(entries: IHooksData, hook: string) => {
  return (entries[createHookDataId(hook)] as unknown) as IHookDataItem<R>;
};

interface IAppEntry {
  data?: IApp;
  status: RequestStatus;
}

export interface IAppEntries {
  [key: string]: IAppEntry;
}

interface ITeamApp {
  list: IApp[];
  totalCount: number;
}

interface ITeamApps {
  [key: string]: ITeamApp;
}

export interface IHookDataItem<T = string> {
  status: RequestStatus;
  data?: T;
  meta?: any;
}

export interface IAppDepositInfo {
  appAddress?: string;
  amount?: BigNumber;
  alreadyDeposited?: BigNumber;
  paymentMethod?: PaymentMethod;
  renewalInfo?: IAppDepositInfo;
}

export interface IAppsState {
  list: IApp[];
  price?: IClusterPrice[];
  pricingCluster: ICluster[];
  realtimeData?: IRealtimeOverview;
  enterpriseHookId: string;
  alertsList: IAlert[];
  rpcUptime: IUptimeResponse;
  totalCount: number;
  appHooksData: IHooksData;
  updateAppStatus: RequestStatus;
  fetchAppsStatus: RequestStatus;
  launchAppId: string;
  launchAppResponse: string;
  launchAppStatus: RequestStatus;
  resetAppId: string;
  resetAppResponse: string;
  resetAppStatus: RequestStatus;
  createAppStatus: RequestStatus;
  deleteAppStatus: RequestStatus;
  metricsRealtimeStatus: RequestStatus;
  calculatePriceStatus: RequestStatus;
  checkPriceParam?: IApiCheckPrice;
  appHookStatus: RequestStatus;
  appAddressInfoStatus: RequestStatus;
  appCancelExtendStatus: RequestStatus;
  appEnterpriseHookStatus: RequestStatus;
  details: IAppEntries;
  appsOverviewRequestStatus: RequestStatus;
  appsOverview: IAppsOverview | null;
  teamsApps: ITeamApps;
  fetchRecnetAlertsStatus: RequestStatus;
  fetchRPCUptimeStatus: RequestStatus;
  changeAppNameStatus: RequestStatus;
  deposits: { [appId: string]: IAppDepositInfo };
  walletInfo: IWalletInfo | null;
}

const initialState: IAppsState = {
  list: [],
  price: [],
  pricingCluster: [],
  totalCount: 0,
  enterpriseHookId: '',
  appHooksData: {},
  rpcUptime: { startTime: '', successRate: 0, uptimeBlocks: [] },
  alertsList: [],
  updateAppStatus: requestInactive(),
  fetchAppsStatus: requestInactive(),
  launchAppId: '',
  launchAppResponse: '',
  launchAppStatus: requestInactive(),
  resetAppId: '',
  resetAppResponse: '',
  resetAppStatus: requestInactive(),
  createAppStatus: requestInactive(),
  deleteAppStatus: requestInactive(),
  appAddressInfoStatus: requestInactive(),
  appCancelExtendStatus: requestInactive(),
  appEnterpriseHookStatus: requestInactive(),
  metricsRealtimeStatus: requestInactive(),
  calculatePriceStatus: requestInactive(),
  changeAppNameStatus: requestInactive(),
  checkPriceParam: undefined,
  appHookStatus: requestInactive(),
  details: {},
  teamsApps: {},
  appsOverviewRequestStatus: requestInactive(),
  appsOverview: null,
  fetchRecnetAlertsStatus: requestInactive(),
  fetchRPCUptimeStatus: requestInactive(),
  deposits: {},
  walletInfo: null,
};

export const appsReducer = createReducer(initialState, {
  ...createAPIReducer<IAppsState, AxiosResponse<IApiCreateAppResponse>>(
    AppsActionTypes.APP_CREATE,
    'createAppStatus',
  ),
  ...createAPIReducer<
    IAppsState,
    IExtendedAxiosResponse<IClusterFetchResponse>
  >(AppsActionTypes.CALCULATE_APP_PRICE, 'calculatePriceStatus', {
    onSuccess: (state, action) => {
      const clusters = action.response.data?.clusters
        ? mappingClusters(action.response.data.clusters)
        : undefined;
      const request = action.meta.requestAction.request;

      return {
        ...state,
        price: clusters ? normalizePrice(clusters) : [],
        pricingCluster: clusters ? clusters : [],
        checkPriceParam: {
          cpu: request.params['resource.cpu'],
          memory: request.params['resource.memory'],
          storage: request.params['resource.storage'],
          chart_name: request.params.node_kind,
          // @ts-ignore
          cluster_id: request.clusterId,
        },
      } as any;
    },
  }),
  ...createAPIReducer<
    IAppsState,
    IExtendedAxiosResponse<IApiFetchAppsResponse>
  >(AppsActionTypes.APPS_FETCH, 'fetchAppsStatus', {
    onSuccess: (state: IAppsState, action) => {
      return {
        ...state,
        list: normalizeApps(action.response.data),
        totalCount: action.response.data.total_count,
      };
    },
    onError: (state: IAppsState) => {
      return { ...state, list: [] };
    },
  }),
  ...createAPIReducer<
    IAppsState,
    IExtendedAxiosResponse<IApiLaunchAppResponse>
  >(AppsActionTypes.APP_LAUNCH, 'launchAppStatus', {
    onReset: (state: IAppsState) => {
      return {
        ...state,
        launchAppId: '',
        launchAppResponse: '',
      };
    },
    onSuccess: (state: IAppsState, action) => {
      return {
        ...state,
        launchAppId: action.response.data.app_id,
        launchAppResponse: action.response.data.status,
      };
    },
  }),
  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<IApiResetAppResponse>>(
    AppsActionTypes.APP_RESET,
    'resetAppStatus',
    {
      onReset: (state: IAppsState) => {
        return {
          ...state,
          resetAppId: '',
          resetAppResponse: '',
        };
      },
      onSuccess: (state: IAppsState, action) => {
        return {
          ...state,
          resetAppId: action?.response?.data?.app_id,
          resetAppResponse: action?.response?.data?.status,
        };
      },
    },
  ),
  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<any>>(
    AppsActionTypes.APP_DELETE,
    'deleteAppStatus',
  ),
  // TODO
  ...createAPIReducer<
    IAppsState,
    IExtendedAxiosResponse<IApiFetchAppsResponse>
  >(AppsActionTypes.APP_UPDATE, 'updateAppStatus', {
    onSuccess: (state: IAppsState, action) => {
      return {
        ...state,
        list: normalizeApps(action.response.data),
      };
    },
  }),

  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<IApiRealtimeOverview>>(
    AppsActionTypes.APP_REALTIME,
    'metricsRealtimeStatus',
    {
      onSuccess: (state: IAppsState, action) => {
        return {
          ...state,
          realtimeData: mapMetricsOverview(action.response.data),
        };
      },
    },
  ),

  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<string>>(
    AppsActionTypes.APP_HOOK,
    'appHookStatus',
    {
      onRequest: (state: IAppsState, action): IAppsState => {
        const label = action.meta?.label;

        const oldData = state.appHooksData?.[createHookDataId(label)] ?? {};

        return {
          ...state,
          appHooksData: {
            ...state.appHooksData,
            [createHookDataId(label)]: {
              ...oldData,
              status: requestInProgress(),
            },
          },
        };
      },
      onSuccess: (
        state: IAppsState,
        action: IExtendedAxiosResponse<any>,
      ): IAppsState => {
        const requestData = (action.meta.requestAction.request as any).data;

        const label = action.meta?.label;

        const hookId = createHookDataId(label);

        return {
          ...state,
          appHooksData: {
            ...state.appHooksData,
            [hookId]: normalizeHook({
              data: action.response.data,
              hookId: `${(requestData as any).container}.${hookId}`,
              container: (requestData as any).container,
            }),
          },
        };
      },
      onError: (state: IAppsState, action): IAppsState => {
        const label = action.meta?.label;

        return {
          ...state,
          appHooksData: {
            ...state.appHooksData,
            [createHookDataId(label)]: {
              status: requestFailed(),
            },
          },
        };
      },
      onReset: (state: IAppsState, action): IAppsState => {
        const appHooksData = { ...state.appHooksData };
        delete appHooksData[createHookDataId(action.payload)];
        return {
          ...state,
          appHooksData,
        };
      },
    },
  ),

  [AppsActionTypes.APP_DETAILS]: (
    state: IAppsState,
    action: IExtendedAxiosResponse<any>,
  ): IAppsState => {
    // @ts-ignore
    const url = action.request.url ?? '';
    return {
      ...state,
      details: {
        ...state.details,
        [url]: {
          ...state.details[url],
          status: requestInProgress(),
        },
      },
    };
  },

  [AppsActionTypes.APP_DETAILS_SUCCESS]: (
    state: IAppsState,
    action: IExtendedAxiosResponse<{ app: IApiApp }>,
  ): IAppsState => {
    return {
      ...state,
      details: {
        ...state.details,
        [action?.meta?.requestAction?.request?.url ?? '']: {
          data: normalizeApp(action.response.data.app),
          status: requestSuccessful(),
        },
      },
    };
  },
  [AppsActionTypes.APP_DETAILS_ERROR]: (
    state: IAppsState,
    action: IApiErrorAction,
  ): IAppsState => ({
    ...state,
    details: {
      ...state.details,
      [action?.meta?.requestAction?.request?.url ?? '']: {
        status: requestFailed(extractRequestError(action)),
      },
    },
  }),

  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<IAppAddressInfo>>(
    AppsActionTypes.APP_ADDRESS_INFO,
    'appAddressInfoStatus',
    {
      onSuccess: (state: IAppsState, action) => {
        const { id: appId } = action.meta;

        return {
          ...state,
          deposits: {
            [appId as string]: {
              appAddress: action.response.data.app_addr,
              alreadyDeposited: new BigNumber(
                action.response.data.already_deposit,
              ),
              ...(action.response.data.renewal_info?.code > 0 && {
                renewalInfo: {
                  amount: new BigNumber(
                    action.response.data.renewal_info.amount || '',
                  ),
                  alreadyDeposited: new BigNumber(
                    action.response.data.renewal_info.renewal_already_deposit ||
                      '',
                  ),
                  paymentMethod: action.response.data.renewal_info
                    ?.pay_method as PaymentMethod,
                },
              }),
            },
          },
        };
      },
      onReset: state => {
        return {
          ...state,
          appAddressInfoStatus: requestInactive(),
        };
      },
    },
  ),

  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<IAppAddressInfo>>(
    AppsActionTypes.APP_CHANGE_NAME,
    'changeAppNameStatus',
  ),

  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<IAppAddressInfo>>(
    AppsActionTypes.APP_CANCEL_EXTEND,
    'appCancelExtendStatus',
    {
      onSuccess: (state: IAppsState) => {
        return {
          ...state,
        };
      },
    },
  ),

  ...createAPIReducer<IAppsState, AxiosResponse<string>>(
    AppsActionTypes.APP_ENTERPRISE_HOOK,
    'appEnterpriseHookStatus',
    {
      onSuccess: (state: IAppsState, action) => {
        return {
          ...state,
          enterpriseHookId: action?.data,
        };
      },
    },
  ),

  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<IAPIAlertResponse>>(
    AppsActionTypes.APP_FETCH_RENCENT_ALERTS,
    'fetchRecnetAlertsStatus',
    {
      onSuccess: (state: IAppsState, action) => {
        return {
          ...state,
          alertsList: action.response.data.data,
        };
      },
    },
  ),

  ...createAPIReducer<IAppsState, IExtendedAxiosResponse<IAPIUptimeResponse>>(
    AppsActionTypes.APP_FETCH_RPC_UPTIME,
    'fetchRPCUptimeStatus',
    {
      onSuccess: (state: IAppsState, action) => {
        return {
          ...state,
          rpcUptime: normalizeRpcData(action.response.data),
        };
      },
    },
  ),

  [AppsActionTypes.APP_HOOKS_RESET]: (
    state: IAppsState,
    action: IApiErrorAction,
  ): IAppsState => ({
    ...state,
    appHooksData: {},
  }),
  [TeamActionTypes.SET_CURRENT_TEAM]: (state: IAppsState): IAppsState => ({
    ...state,
    list: [],
    teamsApps: {},
  }),
  // Eliminate all data from current reducer
  [UserActionTypes.SIGNIN]: (): IAppsState => ({
    ...initialState,
  }),
  [UserActionTypes.SIGNOUT]: (): IAppsState => ({
    ...initialState,
  }),
  [AppsActionTypes.WALLET_INFO]: (
    state: IAppsState,
    action: { payload: IWalletInfo },
  ): IAppsState => ({
    ...state,
    walletInfo: action.payload,
  }),
  [AppsActionTypes.WALLET_INFO_RESET]: (state: IAppsState): IAppsState => ({
    ...state,
    walletInfo: null,
  }),
});

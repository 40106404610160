import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Input } from './Input';
import { getErrorText, hasError } from 'common/utils/form';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

interface IInputFieldProps
  extends FieldRenderProps<string, HTMLInputElement | HTMLTextAreaElement> {
  showErrorText?: boolean;
  showErrorOnSubmitOnly?: boolean;
  InputProps?: Partial<OutlinedInputProps>;
}

export const InputField = ({
  input: { name, onChange, value, onBlur, onFocus },
  meta,
  showErrorText = true,
  showErrorOnSubmitOnly = false,
  ...rest
}: IInputFieldProps) => {
  const showError = showErrorOnSubmitOnly
    ? meta.submitFailed && hasError(meta)
    : hasError(meta);

  const customOnChange = useCallback(
    (event: any) => {
      onChange(event);
    },
    [onChange],
  );

  return (
    <Input
      {...rest}
      variant="outlined"
      name={name}
      helperText={showErrorText && getErrorText(meta)}
      error={!!showError}
      onChange={customOnChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
    />
  );
};

export const REGEX_BETWEEN_BRACKETS = /\[(.*?)\]/;

const REGEX_URL = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;

export const parseLink = (str: string) => {
  try {
    const regexMatch = str.match(REGEX_BETWEEN_BRACKETS);
    return regexMatch ? regexMatch[1] && regexMatch[1].split(' ')[0] : '';
  } catch {
    return '';
  }
};

export const findURLInString = (str: string) => {
  try {
    const regexMatch = str.match(REGEX_URL);

    return regexMatch ? regexMatch[0] : '';
  } catch {
    return '';
  }
};

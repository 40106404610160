import { startOfDay, endOfDay, addDays, addMonths } from 'date-fns';

import { t } from 'common/utils/intl';
import { useLocaleMemo } from 'common/utils/hooks';
import { FilterValue } from './NodeActivityViewTypes';

export const useFilterSelect = () => {
  return useLocaleMemo(
    () => [
      {
        value: FilterValue.TODAY,
        label: t('dashboard.node-activity.select-dropdown.today'),
      },
      {
        value: FilterValue.LAST7DAY,
        label: t('dashboard.node-activity.select-dropdown.last-7-days'),
      },
      {
        value: FilterValue.LAST30DAY,
        label: t('dashboard.node-activity.select-dropdown.last-30-days'),
      },
    ],
    [],
  );
};

export const getStartTimeAndEndTime = (value: FilterValue) => {
  const today = new Date();

  let startTime = 0;
  let endTime = 0;

  switch (value) {
    case FilterValue.TODAY:
      startTime = startOfDay(today).getTime();
      endTime = endOfDay(today).getTime();
      break;
    case FilterValue.LAST7DAY:
      const last7day = addDays(today, -7);
      startTime = startOfDay(last7day).getTime();
      endTime = endOfDay(today).getTime();
      break;
    case FilterValue.LAST30DAY:
      const last30day = addMonths(today, -1);
      startTime = startOfDay(last30day).getTime();
      endTime = endOfDay(today).getTime();
      break;
  }

  return { startTime, endTime };
};

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStep3Styles = makeStyles<Theme>(theme => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    boxSizing: 'border-box',

    [theme.breakpoints.up('sm')]: {
      minHeight: 300,
    },
  },

  title: {
    textAlign: 'center',
  },

  form: {
    marginTop: theme.spacing(3.5),

    [theme.breakpoints.down('xs')]: {
      marginTop: '4.5vh',
    },
  },
}));

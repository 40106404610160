import * as Sentry from '@sentry/browser';
import intl from 'react-intl-universal';

import {
  IApiChart,
  IApiChartDetails,
  IApiChartsFetchResponse,
  IApiResources,
  IChart,
  IChartDetails,
  ChartCategory,
  ChartCategoryType,
} from '../types';
import { IChartsMap } from '../reducers/chartsReducer';
import { formatNodeName } from 'common/utils/formatNodeName';
import { IExtendedAxiosResponse } from '../../../types';

const normalizeResources = (data: IApiResources) => {
  return {
    recommendedCPU: Number.parseInt(data.cpu_recommend, 10),
    recommendedMemory: Number.parseInt(data.mem_recommend, 10),
    recommendedStorage: Number.parseInt(data.storage_recommend, 10),
    cpuMin: Number.parseInt(data.cpu_min, 10),
    memMin: Number.parseInt(data.mem_min, 10),
    storageMin: Number.parseInt(data.storage_min, 10),
    cpuMax: Number.parseInt(data.cpu_max, 10),
    memMax: Number.parseInt(data.mem_max, 10),
    storageMax: Number.parseInt(data.storage_max, 10),
  };
};

export const getCustomizeVariableValue = (
  items: { key: string; value: string }[] | undefined,
  key: string,
) => {
  if (!items) {
    return items;
  }

  return items.find(
    item => cleanCustomVariableName(item.key) === cleanCustomVariableName(key),
  )?.value;
};

const CUSTOM_VALUES_KEY_API_DELIMITER = '.';
const CUSTOM_VALUES_KEY_CLIENT_DELIMITER = '___';
const CUSTOM_VALUES_KEY_API_DELIMITER_REGEXP = new RegExp(
  `\\${CUSTOM_VALUES_KEY_API_DELIMITER}`,
  'g',
);
const CUSTOM_VALUE_KEY_CLIENT_DELIMITER_REGEXP = new RegExp(
  CUSTOM_VALUES_KEY_CLIENT_DELIMITER,
  'g',
);
const CUSTOM_VALUES_PREFIX = 'ankrCustomValues';
const CUSTOM_VARIABLE_PREFIX_REGEXP = new RegExp(
  `${CUSTOM_VALUES_PREFIX}\\${CUSTOM_VALUES_KEY_API_DELIMITER}`,
  'g',
);
const CUSTOM_VARIABLE_CLIENT_PREFIX_REGEXP = new RegExp(
  `${CUSTOM_VALUES_PREFIX}${CUSTOM_VALUES_KEY_CLIENT_DELIMITER}`,
  'g',
);

const cleanCustomVariableName = (key: string) => {
  return key
    .replace(CUSTOM_VARIABLE_PREFIX_REGEXP, '')
    .replace(CUSTOM_VARIABLE_CLIENT_PREFIX_REGEXP, '');
};

export const mapCustomVariableKey = (key: string) => {
  return key.replace(
    CUSTOM_VALUES_KEY_API_DELIMITER_REGEXP,
    CUSTOM_VALUES_KEY_CLIENT_DELIMITER,
  );
};

const getDescription = (appName: string, key: string) => {
  const text = intl.getHTML(
    `app-deploy.default-custom-variables.overrides.${appName}.description.${key}`,
  );
  if (text) {
    return text;
  }
  return undefined;
};

const getLabel = (appName: string, key: string) => {
  return (
    intl.get(
      `app-deploy.default-custom-variables.overrides.${appName}.label.${key}`,
    ) ?? undefined
  );
};

const getPlaceholder = (appName: string, key: string) => {
  const text = intl.get(
    `app-deploy.default-custom-variables.overrides.${appName}.placeholder.${key}`,
  );
  if (text) {
    return text;
  }
  return undefined;
};

const getTitle = (appName: string, key: string) => {
  const text = intl.get(
    `app-deploy.default-custom-variables.overrides.${appName}.title.${key}`,
  );
  if (text) {
    return text;
  }
  return undefined;
};

const normalizeCustomVariableLabel = (key: string) => {
  return key
    .replace(CUSTOM_VARIABLE_PREFIX_REGEXP, '')
    .split('_')
    .map(word => formatNodeName(word))
    .join(' ');
};

export const revertCustomVariableKeyMapping = (key: string) => {
  return key.replace(
    CUSTOM_VALUE_KEY_CLIENT_DELIMITER_REGEXP,
    CUSTOM_VALUES_KEY_API_DELIMITER,
  );
};

const normalizeChartDetails = (data: IApiChartDetails): IChartDetails => {
  return {
    name: data.name,
    repository: data.repository,
    versions: [
      {
        chart: data.version,
        app: data.app_version,
      },
    ],
    readme: data.readme_md,
    values: data.values_yaml,
    customValues: (data.custom_values || []).map(({ key, value }) => ({
      key: mapCustomVariableKey(key),
      value,
      label: getLabel(data.name, key) ?? normalizeCustomVariableLabel(key),
      title: getTitle(data.name, key),
      description: getDescription(data.name, key),
      placeholder: getPlaceholder(data.name, key),
    })),
    valuesYaml: data.values_yaml,
    ...normalizeResources(data),
  };
};

const normalizeChart = (chart: IApiChart): IChart => {
  try {
    if (!chart) {
      // @ts-ignore Spamming with warnings
      return {};
    }
    const metaCategoryKey = chart?.ankr_meta_data
      ?.category as ChartCategoryType;
    const metaCategory = ChartCategory[metaCategoryKey];

    return {
      id: chart.id,
      name: chart.name,
      nodeFamily: chart.ankr_meta_data?.nodeFamily,
      repository: chart.repository,
      description: chart.description,
      iconUrl: chart.icon_url,
      iconOnlyUrl: chart.icononly_url,
      version: chart.version,
      appVersion: chart.app_version,
      valuesYaml: chart.values_yaml,
      pricing: chart.pricing,
      metaCategory: metaCategory ?? ChartCategory.Other,
      metaDescription: chart.ankr_meta_data && chart.ankr_meta_data.description,
      metaStatus: chart.ankr_meta_data && chart.ankr_meta_data.status,
      faq: chart.node_info && chart.node_info.faq,
      overview: chart.node_info && {
        description: chart.node_info?.overview.description,
        benefits: chart.node_info?.overview.benefits,
        specifications: chart.node_info?.overview.deploy_desc,
        requirement: chart.node_info?.overview.requirement,
        cpu: chart.node_info.overview.cpu,
        mem: chart.node_info.overview.mem,
        storage: chart.node_info.overview.storage,
      },
      links: chart.node_info?.overview.links,
      ...normalizeResources(chart),
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      id: 'error',
      name: 'error',
      repository: 'stable',
      description: '',
      iconUrl: '',
      iconOnlyUrl: '',
      version: '',
      appVersion: '',
      valuesYaml: '',
      pricing: 0,
      recommendedCPU: 0,
      recommendedMemory: 0,
      recommendedStorage: 0,
      cpuMin: 0,
      memMin: 0,
      storageMin: 0,
      cpuMax: 0,
      memMax: 0,
      storageMax: 0,
      metaCategory: ChartCategory.Other,
      metaDescription: '',
      metaStatus: undefined,
      faq: {},
      overview: {
        description: '',
        benefits: '',
        specifications: '',
        requirement: '',
        cpu: 0,
        mem: 0,
        storage: 0,
      },
      links: {} as any,
    };
  }
};

const normalizeNumberFormat = (val: string, len: number): number => {
  try {
    const numFormat =
      typeof val === 'undefined' ? 0 : Number.parseFloat(val) / 1000;
    const result =
      Math.round(numFormat * Math.pow(10, len)) / Math.pow(10, len);
    return numFormat > 0 && result === 0
      ? Math.round(Math.pow(0.1, len) * Math.pow(10, len)) / Math.pow(10, len)
      : result;
  } catch {
    return 0;
  }
};

const sortChartsFunc = (a: IChart, b: IChart): number => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};
const normalizeCharts = (data: IApiChart[]): IChart[] => {
  try {
    const dataArray = data && data.length > 0 ? data.map(normalizeChart) : [];
    return dataArray.sort(sortChartsFunc);
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

const normalizeChartsResponse = ({
  response,
  meta,
}: IExtendedAxiosResponse<IApiChartsFetchResponse, any>): IChartsMap | null => {
  const repository = meta.requestAction.meta.repository;

  if (!response?.data?.charts) return {};

  return {
    [repository]: normalizeCharts(response.data.charts),
  };
};

export {
  normalizeChart,
  normalizeNumberFormat,
  normalizeChartsResponse,
  normalizeChartDetails,
};

import { GraphHooks, graphNodeName } from './GraphConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/graph.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import {
  ICustomInfoComponent,
  ICustomValueComponentProps,
} from 'common/modules/apps/types';
import React from 'react';

const LoadableManage = loadable(
  async () => import('./manage/GraphManage').then(module => module.GraphManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomInfoComponent>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/GraphCustomValues').then(
      module => module.GraphCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const graphConfig: INodeConfig = {
  chartName: graphNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'indexer-agent': {
      [GraphHooks.deploySubgraph]: defaultParse,
      [GraphHooks.removeSubgraph]: defaultParse,
      [GraphHooks.deployedSubgraph]: defaultParse,
    },
  },
};

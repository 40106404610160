import {
  select,
  take,
  delay,
  race,
  fork,
  cancel,
  put,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { getSignedInStatus } from 'auth/utils';
import { ITokensState } from 'auth/store/reducers/tokensReducer';
import {
  AnkrActions,
  AnkrActionTypes,
  IFetchSymbolPrice,
} from '../actions/AnkrActions';

import { UserActionTypes } from 'auth/store/actions/UserActions';
import { Milliseconds } from '../../types';

const OFFLINE_POLL_FREQUENCY: Milliseconds = 10 * 1000; // 10s for offline retry
const POLL_FREQUENCY: Milliseconds = 60 * 1000; // 1 minute in msec
const POLL_START_TRY_DELAY: Milliseconds = 1000;

function* fetchAnkrPriceTask() {
  yield put(AnkrActions.fetchSymbolPrice(IFetchSymbolPrice.ANKR));

  const { success, error } = yield race({
    success: take(AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE_SUCCESS),
    error: take(AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE_ERROR),
  });

  if (success) {
    yield delay(POLL_FREQUENCY);
  } else if (error) {
    yield delay(OFFLINE_POLL_FREQUENCY);
    // Sentry.withScope(scope => {
    //   scope.setExtra('network error', error);
    //   Sentry.captureMessage('Network failure happened while fetching price');
    // });
  }
}

function* ankrPricePollerRunner(): any {
  let task;
  while (true) {
    try {
      while (true) {
        const logged = yield select((state: { tokens: ITokensState }) => {
          return getSignedInStatus(state);
        });
        if (logged) {
          break;
        }
        yield delay(POLL_START_TRY_DELAY);
      }

      task = yield fork(fetchAnkrPriceTask);
      // Just restart to get fresh rate after
      yield take([UserActionTypes.SIGNIN]);
      yield cancel(task);
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export { ankrPricePollerRunner };

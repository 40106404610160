import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const ArrowLeftIcon = withSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15.7076 4.29152C15.3185 3.90248 14.6878 3.90248 14.2987 4.29152L7.29697 11.2933C7.22486 11.3654 7.16611 11.4458 7.12073 11.5315C6.91508 11.9091 6.97216 12.3915 7.29197 12.7109L14.2983 19.7084C14.6876 20.0972 15.3187 20.0972 15.708 19.7084C16.0973 19.3196 16.0973 18.6892 15.708 18.3004L9.4038 12.0042L15.7076 5.70037C16.0966 5.31133 16.0966 4.68056 15.7076 4.29152Z"
  />,
);

import { StyledComponentProps, SvgIcon } from '@material-ui/core';
import React from 'react';

type INewsProps = StyledComponentProps;

const NewsIcon = (props: INewsProps) => {
  return (
    <SvgIcon {...props}>
      <g fillRule="nonzero" stroke="none" strokeWidth="1" fill="none">
        <path
          d="M17.5368 9.64774V13.0232L18.4596 13.9645C18.7177 14.2278 18.8934 14.5632 18.9646 14.9283C19.0357 15.2935 18.9992 15.6719 18.8595 16.0159C18.7199 16.3598 18.4834 16.6538 18.1799 16.8607C17.8765 17.0675 17.5198 17.178 17.1548 17.1781H15.5749C15.3697 17.9859 14.9072 18.7014 14.2602 19.2121C13.6132 19.7229 12.8182 20 12 20C11.1818 20 10.3868 19.7229 9.73981 19.2121C9.09277 18.7014 8.63032 17.9859 8.42506 17.1781H6.84522C6.48025 17.178 6.12349 17.0675 5.82006 16.8607C5.51662 16.6538 5.28012 16.3598 5.14047 16.0159C5.00081 15.6719 4.96426 15.2935 5.03544 14.9283C5.10662 14.5632 5.28234 14.2278 5.54037 13.9645L6.46317 13.0232V9.64774C6.46317 8.14987 7.04652 6.71334 8.08487 5.65419C9.12323 4.59503 10.5315 4 12 4C13.4685 4 14.8768 4.59503 15.9151 5.65419C16.9535 6.71334 17.5368 8.14987 17.5368 9.64774ZM15.6912 9.64774C15.6912 9.15329 15.5957 8.66369 15.4102 8.20688C15.2247 7.75007 14.9528 7.335 14.6101 6.98537C14.2673 6.63574 13.8604 6.3584 13.4126 6.16919C12.9647 5.97997 12.4847 5.88258 12 5.88258C11.5153 5.88258 11.0353 5.97997 10.5874 6.16919C10.1396 6.3584 9.73268 6.63574 9.38992 6.98537C9.04715 7.335 8.77526 7.75007 8.58976 8.20688C8.40426 8.66369 8.30878 9.15329 8.30878 9.64774V13.8026L6.84522 15.2955H17.1548L15.6912 13.8026V9.64774ZM13.5983 17.1781H10.4017C10.5637 17.4642 10.7967 17.7019 11.0772 17.8671C11.3578 18.0323 11.676 18.1193 12 18.1193C12.324 18.1193 12.6422 18.0323 12.9228 17.8671C13.2033 17.7019 13.4363 17.4642 13.5983 17.1781Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};

NewsIcon.muiName = 'SvgIcon';

export { NewsIcon };

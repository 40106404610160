import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import icon from './assets/icon.svg';

export const useSuccessStyles = makeStyles<Theme>(theme => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    padding: theme.spacing(5, 10),
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(3.5, 4.5),
    },
  },

  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
    },

    '&::before': {
      position: 'relative',
      content: '""',

      display: 'block',
      width: 121,
      height: 132,
      marginBottom: theme.spacing(2),

      backgroundImage: `url("${icon}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
  },

  close: {
    minWidth: 202,
    marginTop: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
      minWidth: '100%',
    },
  },
}));

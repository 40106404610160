import { mixpanelTrack } from 'common/utils/analytics/mixpanelTrack';
import { MIXPANEL_TOKEN } from 'common/core/const';

let pathnameLast = '';

export const sendMixpanelStats = (userId: string, pathname: string) => {
  if (MIXPANEL_TOKEN === 'NO_TOKEN') return;

  if (userId && pathname !== pathnameLast) {
    pathnameLast = pathname;
    mixpanelTrack({
      event: 'Page View',
      properties: { userId, pathname },
    });
  }
};

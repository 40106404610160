import {
  withTransparentLayout,
  withTransparentLayoutNoOverflow,
} from '../LayoutUI/components/Layout';
import { withErrorHandler } from 'common/HOC/withErrorHandler';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from 'common/nav/PrivateRoute';
import { SETTINGS_PREFIX } from 'common/nav/navUtils';
import { t } from 'common/utils/intl';
import { CHANGE_EMAIL_PATH } from '../AuthUI/const';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import loadable, { LoadableComponent } from '@loadable/component';

const LoadableSettingsContainer = withTransparentLayoutNoOverflow(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/SettingsUI/screens/Settings').then(
          module => module.Settings,
        ),
      {
        fallback: <LoadingIndicator />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableChangeEmailContainer = withTransparentLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/SettingsUI/screens/ChangeEmail').then(
          module => module.ChangeEmail,
        ),
      {
        fallback: <LoadingIndicator />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const SettingsRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={SETTINGS_PREFIX}
        component={LoadableSettingsContainer}
        breadcrumbs={[t('navigation.profile')]}
      />
      <Route
        exact={true}
        path={CHANGE_EMAIL_PATH}
        component={LoadableChangeEmailContainer}
        breadcrumbs={[t('navigation.profile')]}
      />
      <Route
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};

import BigNumber from 'bignumber.js';
import { AppsActions } from 'common/modules/apps/actions/AppsActions';
import { AppStatus, IApiApp } from 'common/modules/apps/types';
import { IExtendedRequestAction } from '../../../types';
import { mapAppStatus } from 'common/modules/apps/apiMappings/appMappings';
import { ZERO } from 'common/core/const';
import { IOverviewItem } from 'common/modules/billing/reducers/invoiceReducerTypes';

export const getCalculatePricesRequests = (
  apps: IApiApp[],
): IExtendedRequestAction[] => {
  return apps.map((app: IApiApp) => {
    const replicas = app.replicas_number ?? 1;

    const request = AppsActions.calculatePrice(
      app.namespace.cluster_id,
      {
        'resource.cpu': (app.namespace.cpu_limit * replicas).toString(),
        'resource.memory': (app.namespace.mem_limit * replicas).toString(),
        'resource.storage': (app.namespace.storage_limit * replicas).toString(),
        node_kind: app.chart?.name || '',
      },
      false,
      {
        silent: true,
        takeLatest: false,
      },
    );

    return request;
  });
};

const isActive = (status: AppStatus | string) =>
  !(
    status === AppStatus.APP_FAILED ||
    status === AppStatus.APP_DELETED ||
    status === AppStatus.APP_UNKNOWN
  );

export const getUpdatedApps = (
  apps: IApiApp[],
  dailyPrices: BigNumber[],
): IOverviewItem[] => {
  return apps
    .map((app, index) => {
      const active = isActive(mapAppStatus(app.status));

      return {
        nodeName: app.chart?.name || '',
        name: app.name,
        dailyPrice: active ? dailyPrices[index] : ZERO,
        icon: app.chart?.icon_url,
        isActive: active,
        version: app.chart?.version,
        appVersion: app.chart?.app_version,
        createDate: new Date(app.created_time),
      } as IOverviewItem;
    })
    .sort((a, b) => b.dailyPrice.minus(a.dailyPrice).toNumber());
};

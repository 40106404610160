import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from '../../../utils/string';
import { isBase64 } from '../../apps/apiMappings/hookMappings';

const getResult = (result: string) => {
  return isBase64(result) ? window.atob(result) : result;
};

export const parseHarmonyStatus = (response: IHookResponse): IHookResponse => {
  const result = getResult(response?.data?.result);
  const parsed = safeParseJSON(result ?? '{}');

  return {
    ...response,
    data: {
      status: parsed.status,
      timestamp: new Date(parsed.timestamp),
      blockNumber: parsed?.blockNumber?.toLocaleString() ?? 'N/A',
      blockHash: parsed.blockHash,
      nodeName: parsed.node_name,
      address: parsed.address,
      isStaker: parsed.isStaker,
      isActive: parsed.isActive,
      eposStatus: parsed.staker_details?.['epos-status'] ?? '',
      isReset: parsed.isReset,
      shardId: parsed?.shardID?.toLocaleString() ?? 'N/A',

      totalStaked: parsed.staking_status?.total_staked ?? 0,
      selfStaked: parsed.staking_status?.self_staked ?? 0,
      delegated: parsed.staking_status?.delegated ?? 0,
      reward: parsed.staking_status?.reward ?? 0,
      esResult: result,
    },
  };
};

export const parseHarmonyBalance = (response: IHookResponse): IHookResponse => {
  const result = safeParseJSON(response.data.result ?? '{}');

  return {
    ...response,
    data: result,
  };
};
export const parseHarmonySetValidatorActive = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const success = response.data.result.indexOf('"success": true') > -1;

    return {
      ...response,
      data: {
        success,
      },
    };
  } catch {
    return {
      ...response,
      data: {
        success: false,
      },
    };
  }
};
export const parseHarmonyCollectReward = (
  response: IHookResponse,
): IHookResponse => {
  try {
    const result = safeParseJSON(response.data.result ?? '{}');

    return {
      ...response,
      data: result,
    };
  } catch {
    return {
      ...response,
      data: {
        success: false,
      },
    };
  }
};

import { AnyAction } from 'redux';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import {
  AppsActions,
  AppsActionTypes,
} from 'common/modules/apps/actions/AppsActions';
import { clearRequestsCache } from 'redux-saga-requests';
import { delay, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { delayedRedirect } from 'common/store/effects/utilitySagas';
import { push, replace } from 'connected-react-router';
import { UtilityActionTypes } from 'common/store/actions/UtilityActions';
import { IStoreState } from 'store/reducers';
import { NODES_PATH, WELCOME_PATH } from 'common/nav/navUtils';
import { NODES_DETAILS_PATH } from '../../modules/NodesUI/const';
import {
  getCurrentTeam,
  isEnterpriseModeAvailable,
  isPersonalModeAvailable,
} from 'common/modules/teams/reducers/teamReducer';
import { TeamActionTypes } from 'common/modules/teams/actions/TeamActions';
import * as Sentry from '@sentry/browser';
import { historyInstance } from 'common/utils/historyInstance';
import { parse } from 'query-string';
import { setPathEffect } from 'common/modules/Redirect/effects/setPathEffect';
import { RedirectActions } from 'common/modules/Redirect/actions/RedirectActions';
import {
  LOGIN_PATH,
  SIGN_UP_PATH,
  SIGN_UP_SUCCESS_PATH,
} from '../../modules/AuthUI/const';
import { EXPIRED_PATH } from 'auth/src/const';
import { IExtendedAxiosResponse } from 'common/types';
import { IApiLoginResponse } from 'auth/store/mappings/userMappings';
import { API_MARKET_LIST_PATH } from 'modules/APIMarketUI/APIMarketConsts';
import { APIMarketActionTypes } from 'modules/APIMarketUI/store/APIMarketActions';
import { createAction } from 'common/utils/reduxUtils';
import { MY_APPS_URI } from 'common/modules/apps/const';

const TIMEOUT = 500;

function getStartUrl(apiCount: number, nodeCount: number): string {
  if (apiCount === 0 && nodeCount === 0) return WELCOME_PATH;
  if (apiCount > 0 && nodeCount === 0) return API_MARKET_LIST_PATH;
  if (apiCount === 0 && nodeCount > 0) return NODES_PATH;
  if (apiCount > 0 && nodeCount > 0) return API_MARKET_LIST_PATH;

  return NODES_PATH;
}

function* onSignInSaga({
  response,
}: IExtendedAxiosResponse<IApiLoginResponse>) {
  // 2FA: Request for code:
  if (response.status === 202) {
    return;
  }
  // not the best place, but will left it here.
  yield put(clearRequestsCache());

  const params = parse(historyInstance.location.search);
  if (typeof params.resource === 'string' && params.resource) {
    const isResourcePath = [LOGIN_PATH, SIGN_UP_PATH, MY_APPS_URI].includes(
      params.resource,
    );

    if (!isResourcePath) {
      yield delayedRedirect(params.resource);
      return;
    }
  }

  const { resource } = yield select((state: IStoreState) => {
    return { resource: state.redirect.resource };
  });

  if (resource) {
    yield delayedRedirect(resource);
    yield put(RedirectActions.resetRedirect());
    return;
  }

  yield race([
    take([
      TeamActionTypes.FETCH_TEAMS_SUCCESS,
      TeamActionTypes.FETCH_TEAMS_ERROR,
    ]),
  ]);

  const {
    teamId,
  }: {
    teamId: string;
    isEnterpriseAvailable: boolean;
    isPersonalAvailable: boolean;
  } = yield select((state: IStoreState) => {
    return {
      teamId: getCurrentTeam(state.team)?.id,
      isEnterpriseAvailable: isEnterpriseModeAvailable(state.team),
      isPersonalAvailable: isPersonalModeAvailable(state.team),
    };
  });

  let apiCountG = 0;
  let nodeCountG = 0;

  if (teamId) {
    yield put(AppsActions.fetchApps({ teamId }));

    yield put(createAction(APIMarketActionTypes.FETCH_API_LIST, teamId));
    yield take(APIMarketActionTypes.FETCH_API_LIST + '_SUCCESS');

    yield race([
      take([
        AppsActionTypes.APPS_FETCH_ERROR,
        AppsActionTypes.APPS_FETCH_SUCCESS,
      ]),
      delay(TIMEOUT),
    ]);
    try {
      const {
        apiCount,
        nodeCount,
      }: {
        apiCount: number;
        nodeCount: number;
      } = yield select((state: IStoreState) => {
        return {
          apiCount: state.apiMarket.list.length,
          nodeCount: state.apps.totalCount,
        };
      });

      apiCountG = apiCount;
      nodeCountG = nodeCount;

      yield delayedRedirect(getStartUrl(apiCountG, nodeCountG));
      return;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  yield delayedRedirect(getStartUrl(apiCountG, nodeCountG));
}

function* onSignUpSaga() {
  yield delayedRedirect(SIGN_UP_SUCCESS_PATH);
}

function* onLogoutSaga({ payload }: AnyAction) {
  const { tokenFailed, resource } = payload || {};

  const path = resource ? `?resource=${resource}` : '';

  if (tokenFailed) {
    yield put(replace(`${EXPIRED_PATH}${path}`));
  } else {
    yield put(replace(`${LOGIN_PATH}${path}`));
  }
}

function* doRedirect(action: AnyAction) {
  yield put(replace(action.payload.to));
}

function* redirectToApp(action: AnyAction) {
  const appId = action.response.data.id;
  yield put(push(`${NODES_DETAILS_PATH}/${appId}`));
}

function* resetWallet() {
  yield put(AppsActions.resetWalletInfo());
}

/**
 * We need these redirects to run async after some of the actions
 */
function* setRedirects() {
  yield takeEvery(UserActionTypes.SIGNIN_SUCCESS, onSignInSaga);
  yield takeEvery(AppsActionTypes.APP_CREATE, resetWallet);
  yield takeEvery(AppsActionTypes.APP_CREATE_SUCCESS, redirectToApp);
  yield takeEvery(UserActionTypes.SIGNUP_SUCCESS, setPathEffect);
  yield takeEvery(UserActionTypes.SIGNUP_SUCCESS, onSignUpSaga);
  yield takeEvery(UserActionTypes.USER_REDIRECT_TO_LOGIN, onLogoutSaga);
  yield takeEvery(UtilityActionTypes.REDIRECT, doRedirect);
}

export { setRedirects };

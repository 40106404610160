import { Action } from 'redux-actions';

import { createReducer } from 'common/utils/reduxUtils';
import { NamespacesActionTypes } from '../actions/NamespacesActions';
import {
  extractRequestError,
  requestFailed,
  requestInactive,
  requestInProgress,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import {
  IApiErrorAction,
  IApiNamespaceFetchResponse,
  IExtendedAxiosResponse,
  IExtendedRequestAction,
  INamespace,
  IPaging,
  NamespaceStatus,
} from 'common/types';
import { ROWS_PER_PAGE } from 'common/core/const';
import { normalizeNamespaces } from '../apiMappings/namespaceMappings';
import { INamespaceFilters } from '../actions/NamespacesActionsTypes';

export interface INamespacesState {
  list: INamespace[];
  fetchStatus: RequestStatus;
  namespacesCreateStatus: RequestStatus;
  namespacesDeleteStatus: RequestStatus;
  namespacesPaging: IPaging;
  isDeleting: boolean;
  filters: INamespaceFilters;
}

const initialState: INamespacesState = {
  list: [],
  fetchStatus: requestInactive(),
  namespacesCreateStatus: requestInactive(),
  namespacesDeleteStatus: requestInactive(),
  namespacesPaging: { pageNumber: 0, rowsPerPage: ROWS_PER_PAGE },
  filters: {},
  isDeleting: false,
};

const namespacesReducer = createReducer(initialState, {
  [NamespacesActionTypes.NAMESPACES_FETCH]: (
    state: INamespacesState,
  ): INamespacesState => ({
    ...state,
    fetchStatus: requestInProgress(),
  }),
  [NamespacesActionTypes.NAMESPACES_FETCH_SUCCESS]: (
    state: INamespacesState,
    action: IExtendedAxiosResponse<IApiNamespaceFetchResponse>,
  ): INamespacesState => {
    return {
      ...state,
      list: normalizeNamespaces(action.response.data),
      namespacesPaging: { pageNumber: 0, rowsPerPage: ROWS_PER_PAGE },
      filters: {},
      fetchStatus: requestSuccessful(),
    };
  },
  [NamespacesActionTypes.NAMESPACES_FETCH_ERROR]: (
    state: INamespacesState,
    action: IApiErrorAction,
  ): INamespacesState => ({
    ...state,
    fetchStatus: requestFailed(extractRequestError(action)),
  }),

  [NamespacesActionTypes.NAMESPACE_CREATE]: (
    state: INamespacesState,
  ): INamespacesState => ({
    ...state,
    namespacesCreateStatus: requestInProgress(),
  }),
  [NamespacesActionTypes.NAMESPACE_CREATE_SUCCESS]: (
    state: INamespacesState,
  ): INamespacesState => {
    return {
      ...state,
      namespacesCreateStatus: requestSuccessful(),
    };
  },
  [NamespacesActionTypes.NAMESPACE_CREATE_ERROR]: (
    state: INamespacesState,
    action: IApiErrorAction,
  ): INamespacesState => ({
    ...state,
    namespacesCreateStatus: requestFailed(extractRequestError(action)),
  }),
  [NamespacesActionTypes.NAMESPACE_RESET_CREATE_STATUS]: (
    state: INamespacesState,
  ): INamespacesState => ({
    ...state,
    namespacesCreateStatus: requestInactive(),
  }),

  [NamespacesActionTypes.NAMESPACE_DELETE]: (
    state: INamespacesState,
    action: IExtendedRequestAction,
  ): INamespacesState => ({
    ...state,
    namespacesDeleteStatus: requestInProgress(),
    list: state.list.map(namespace => {
      if (action.meta && namespace.id === action.meta.id) {
        namespace.prevStatus = namespace.status;
        namespace.status = NamespaceStatus.Deleted;
      }

      return namespace;
    }),
  }),
  [NamespacesActionTypes.NAMESPACE_DELETE_SUCCESS]: (
    state: INamespacesState,
    action: IExtendedRequestAction,
  ): INamespacesState => ({
    ...state,
    namespacesDeleteStatus: requestSuccessful(),
    list: state.list.filter(
      namespace => !(action.meta && namespace.id === action.meta.id),
    ),
  }),
  [NamespacesActionTypes.NAMESPACE_DELETE_ERROR]: (
    state: INamespacesState,
    action: IApiErrorAction,
  ): INamespacesState => ({
    ...state,
    namespacesDeleteStatus: requestFailed(extractRequestError(action)),
    list: state.list.map(namespace => {
      if (action.meta && namespace.id === action.meta.id) {
        if (namespace.prevStatus) {
          namespace.status = namespace.prevStatus;
          namespace.prevStatus = undefined;
        }
      }

      return namespace;
    }),
  }),

  [NamespacesActionTypes.NAMESPACE_APPLY_FILTERS]: (
    state: INamespacesState,
    action: Action<INamespaceFilters>,
  ): INamespacesState => ({
    ...state,
    filters: action.payload || {},
    namespacesPaging: { pageNumber: 0, rowsPerPage: ROWS_PER_PAGE },
  }),

  [NamespacesActionTypes.NAMESPACES_SET_PAGE]: (
    state: INamespacesState,
    action: Action<{ page: number; rowsPerPage?: number }>,
  ): INamespacesState => ({
    ...state,
    namespacesPaging: {
      pageNumber: (action.payload && action.payload.page) || 0,
      rowsPerPage:
        (action.payload && action.payload.rowsPerPage) ||
        state.namespacesPaging.rowsPerPage,
    },
  }),
});

export { namespacesReducer };

export const ANKR_TEST_CHART_NAME = 'ankr-test';
export const ANKR_TEST_CONTAINER = 'sidecar';

export const ANKR_TEST_NAME = 'ankrCustomValues___name';
export const ANKR_TEST_ADDRESS = 'ankrCustomValues___address';
export const ANKR_TEST_NETWORK = 'ankrCustomValues___network';

export enum ANKR_TEST_HOOKS {
  getNodeStatus = 'apphook1=node-status',
}

/*
 * SELECTORS
 */

import { ITokensState } from 'auth/store/reducers/tokensReducer';

/**
 * Get access token
 * @param state
 */
export const accessTokenSelector = (state: { tokens: ITokensState }) => {
  return state.tokens?.accessToken;
};

/**
 * Get refresh token
 * @param state
 */
export const refreshTokenSelector = (state: { tokens: ITokensState }) => {
  return state.tokens?.refreshToken;
};

import { VitaeHooks, vitaeNodeName } from './VitaeConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/Vitae.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableManage = loadable(
  async () => import('./manage/VitaeManage').then(module => module.VitaeManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/VitaeCustomValues').then(
      module => module.VitaeCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const vitaeConfig: INodeConfig = {
  chartName: vitaeNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'vitae-node': {
      [VitaeHooks.nodeStatus]: defaultParse,
    },
  },
};

import { withTransparentLayout } from '../LayoutUI/components/Layout';
import { withErrorHandler } from 'common/HOC/withErrorHandler';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from 'common/nav/PrivateRoute';
import { t } from 'common/utils/intl';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import loadable, { LoadableComponent } from '@loadable/component';
import { TICKETS_PATH, TICKETS_PATH_TICKET } from './TicketsConsts';

const LoadableTicketListContainer = withTransparentLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/TicketsUI/screens/TicketList/TicketList').then(
          module => module.TicketList,
        ),
      {
        fallback: <LoadingIndicator />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const TicketsRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={TICKETS_PATH}
        component={LoadableTicketListContainer}
      />
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={TICKETS_PATH_TICKET}
        component={LoadableTicketListContainer}
      />

      <Route
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};

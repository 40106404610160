import { safeParseJSON } from 'common/utils/string';
import { IHookResponse } from 'common/utils/convertResponseToError';

export interface ITomochainGetNameResult {
  name: string;
}

export function parseTomochainName(response: IHookResponse): IHookResponse {
  const name = safeParseJSON(response.data?.result ?? '{}');

  return {
    ...response,
    data: {
      name: typeof name === 'string' ? name : '',
    } as ITomochainGetNameResult,
  };
}

export interface ITomochainStatusResult {
  status: 'MASTERNODE' | 'SLASHED' | 'PROPOSED' | '';
}

export function parseTomochainStatus(response: IHookResponse): IHookResponse {
  return {
    ...response,
    data: {
      status: safeParseJSON(response.data?.result ?? '{}')?.status || '',
    } as ITomochainStatusResult,
  };
}

export interface ITomochainGetAddressResult {
  address: string;
}

export function parseTomochainAddress(response: IHookResponse): IHookResponse {
  const address = safeParseJSON(response.data?.result ?? '{}');

  return {
    ...response,
    data: {
      address: typeof address === 'string' ? address : '',
    } as ITomochainGetAddressResult,
  };
}

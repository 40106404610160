import { TokenActions } from 'auth/store/actions/TokensActions';
import { refreshTokenSelector } from '../selectors/tokenSelectors';
import { IExtendedRequestAction } from '../../types';
import {
  isAuthError,
  shouldSkipAuth,
  sendRequestWithToken,
  makeRedirectWithoutRefreshToken,
  signoutToExpiredPath,
} from './utils';

interface IFailedPromiseRequest {
  resolve: (value: string) => void;
}

let isRefreshing = false;
let failedQueue: IFailedPromiseRequest[] = [];

export const onError = (
  error: Error,
  requestAction: IExtendedRequestAction,
  store: any,
): Promise<IExtendedRequestAction<any> | unknown> => {
  const isAuthRequestError =
    !shouldSkipAuth(requestAction) && isAuthError(error);

  if (!isAuthRequestError) throw error;

  if (isRefreshing) {
    return new Promise((resolve: IFailedPromiseRequest['resolve']) =>
      failedQueue.push({
        resolve,
      }),
    )
      .then((token: string) =>
        sendRequestWithToken(requestAction, token, store),
      )
      .catch(err => Promise.reject(err));
  }

  const refreshToken = refreshTokenSelector(store.getState());

  if (!refreshToken) {
    makeRedirectWithoutRefreshToken(store);

    throw error;
  }

  isRefreshing = true;

  return new Promise(async (resolve, reject) => {
    try {
      const { data, error: refreshError } = await store.dispatch(
        TokenActions.refreshToken(refreshToken),
      );

      if (!data || refreshError) {
        signoutToExpiredPath(store);
        reject(error);
      } else {
        const accessToken = data.access_token;

        failedQueue.forEach(promise => promise.resolve(accessToken));

        resolve(sendRequestWithToken(requestAction, accessToken, store));
      }
    } catch (error) {
      reject(error);
    } finally {
      isRefreshing = false;
      failedQueue = [];
    }
  });
};

import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const EyeIcon = withSvgIcon(
  <path
    fill="#B2BDCF"
    d="M12.1467 5.72412C8.00904 5.72412 4.26647 7.69484 1.56704 10.8751C1.01499 11.5255 1.01497 12.4745 1.567 13.125C4.26644 16.3054 8.00902 18.2759 12.1467 18.2759C16.2842 18.2759 20.027 16.3054 22.7265 13.1249C23.2785 12.4745 23.2785 11.5255 22.7264 10.8751C20.027 7.69484 16.2842 5.72412 12.1467 5.72412ZM12.1467 15.5287C10.1951 15.5287 8.61266 13.9491 8.61266 12C8.61266 10.051 10.1951 8.47131 12.1467 8.47131C14.0987 8.47131 15.6808 10.051 15.6808 12C15.6808 13.9491 14.0987 15.5287 12.1467 15.5287Z"
  />,
);

export const EyeHideIcon = withSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0282 6.68008C15.499 6.06068 13.8566 5.72412 12.1467 5.72412C8.00903 5.72412 4.26647 7.69484 1.56704 10.8751C1.01498 11.5255 1.01496 12.4745 1.567 13.125C2.16533 13.8299 2.81491 14.4754 3.50868 15.0532L8.6143 11.8911C8.67195 9.99248 10.2315 8.47131 12.1467 8.47131C12.6723 8.47131 13.1711 8.58584 13.6194 8.79127L17.0282 6.68008ZM10.6317 15.1889C11.0908 15.4068 11.6045 15.5287 12.1467 15.5287C14.078 15.5287 15.6471 13.9825 15.6803 12.0621L20.7529 8.92051C21.4586 9.50501 22.119 10.1594 22.7264 10.8751C23.2785 11.5255 23.2785 12.4745 22.7265 13.1249C20.027 16.3054 16.2842 18.2759 12.1467 18.2759C10.4201 18.2759 8.76218 17.9328 7.22021 17.3018L10.6317 15.1889Z"
      fill="#B2BDCF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.691735 19.3217C0.463174 18.9562 0.571352 18.47 0.933386 18.2356L22.2383 4.44092C22.6005 4.2064 23.0796 4.31269 23.3083 4.6783C23.5368 5.04374 23.4287 5.52992 23.0666 5.76433L1.76168 19.559C1.39948 19.7936 0.920397 19.6873 0.691735 19.3217Z"
      fill="#B2BDCF"
    />
  </>,
);

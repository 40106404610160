import {
  StkrHooks,
  stkrNodeName,
  STKR_ACCESS_TOKEN,
  STKR_NETWORK,
  STKR_SIDECAR_ID,
  STKR_NAME,
  NETWORK_OPTION_MAINNET,
} from './StkrConst';
import {
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/stkr.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import {
  ICustomValueComponentProps,
  ILaunchNodeProps,
} from 'common/modules/apps/types';
import React from 'react';

const LoadableManage = loadable(
  async () => import('./manage/StkrManage').then(module => module.StkrManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableCustomValues = loadable(
  async () =>
    import('./deploy/StkrCustomValues').then(module => module.StkrCustomValues),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

const LoadableRelaunch = loadable(
  async () => import('./launch/StkrLaunch').then(module => module.StkrLaunch),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ILaunchNodeProps>;

export const stkrConfig: INodeConfig = {
  chartName: stkrNodeName,
  deploy: {
    component: LoadableCustomValues,
  },
  launch: {
    component: LoadableRelaunch,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    networkOption: NETWORK_OPTION_MAINNET,
    customValues: {
      [STKR_ACCESS_TOKEN]: '',
      [STKR_NETWORK]: '',
      [STKR_SIDECAR_ID]: 0,
      [STKR_NAME]: '',
    },
  }),
  getResetValues: () => ({
    customValues: {
      [STKR_ACCESS_TOKEN]: '',
    },
  }),
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => ({
    [STKR_ACCESS_TOKEN]:
      customValues && (customValues[STKR_ACCESS_TOKEN] ?? ''),
    [STKR_NETWORK]: customValues && (customValues[STKR_NETWORK] ?? ''),
    [STKR_SIDECAR_ID]: customValues && (customValues[STKR_SIDECAR_ID] ?? ''),
    [STKR_NAME]: customValues && (customValues[STKR_NAME] ?? ''),
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    sidecar: {
      [StkrHooks.getNodeStatus]: defaultParse,
      [StkrHooks.getPeerCount]: defaultParse,
      [StkrHooks.getSidecarStatus]: defaultParse,
      [StkrHooks.getSidecarId]: defaultParse,
    },
  },
};

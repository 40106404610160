import { createAction } from 'common/utils/reduxUtils';

const OfflineActionTypes = {
  SET_OFFLINE: 'SET_OFFLINE',
};

const OfflineActions = {
  setOffline: (offline = true) =>
    createAction(OfflineActionTypes.SET_OFFLINE, offline),
};

export { OfflineActionTypes, OfflineActions };

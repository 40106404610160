import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import {
  ISnackbarOptions,
  SnackbarMessage,
} from '../../store/reducers/snackbarsReducer';
import { SnackbarsActions } from '../../store/actions/SnackbarsActions';

import { IHookSnackbar } from '../../../app/src/modules/NodesUI/hooks/useSnackbar';

export const useSnackbar = <P = object>(): IHookSnackbar<P> => {
  const dispatch = useDispatch();

  const showSnackbar = useMemo(
    () => (message: SnackbarMessage, options: ISnackbarOptions = {}) => {
      dispatch(SnackbarsActions.showSnackbar(message, options));
    },
    [dispatch],
  );

  return {
    showSnackbar,
  };
};

import { put, takeEvery, putResolve } from 'redux-saga/effects';
import { PayActionTypes } from '../actions/PayActions';
import { SendRequestResponse } from '../../../types';
import { Action } from 'redux-actions';
import {
  AppsActions,
  AppsActionTypes,
  ICreateAppPayload,
} from '../../apps/actions/AppsActions';

function* createAppByDailyAnkrNative(action: Action<ICreateAppPayload>) {
  yield put({
    type: AppsActionTypes.APP_CREATE,
  });

  const response: SendRequestResponse<{}> = yield putResolve(
    AppsActions.createAppWithAnkrDailyPayment(action.payload),
  );

  return response;
}

export function* ankrNativePaySaga() {
  yield takeEvery(
    PayActionTypes.CREATE_APP_BY_DAILY_ANKR_NATIVE,
    createAppByDailyAnkrNative,
  );
}

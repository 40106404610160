import { IHookResponse } from '../../../utils/convertResponseToError';
import { hasHTTPError } from './hooksInterceptor';
import { safeParseJSON } from '../../../utils/string';
import { SyncStatusVariant } from '../const';

export interface ITomoXGetUpdateStatus {
  isReady: boolean;
}

export interface ITomoxGetInfoResult {
  name: string;
  address: string;
}

export interface ITomoXGetStatusResult {
  status: SyncStatusVariant;
  currentBlock: number;
  highestBlock: number;
  percentage: number;
}

export function parseTomoXGetStatus(response: IHookResponse): IHookResponse {
  if (hasHTTPError(response?.data?.result)) {
    return {
      ...response,
      data: {
        status: SyncStatusVariant.blocked,
        currentBlock: 0,
        highestBlock: 0,
        percentage: 0,
      } as ITomoXGetStatusResult,
    };
  }

  const resultObj = safeParseJSON(response.data?.result ?? '{}');

  const status = (() => {
    if (typeof resultObj.message === 'boolean') {
      if (resultObj.message) {
        return SyncStatusVariant.progress;
      }

      return SyncStatusVariant.done;
    }

    if (resultObj.currentBlock === 0) {
      return SyncStatusVariant.blocked;
    }

    return SyncStatusVariant.error;
  })();

  return {
    ...response,
    data: {
      ...resultObj,
      status,
    } as ITomoXGetStatusResult,
  };
}

import React from 'react';
import {
  PAPER_LAYOUT,
  PAPER_NO_NAVBAR_LAYOUT,
  TRANSPARENT_LAYOUT,
  TRANSPARENT_LAYOUT_NO_OVERFLOW,
  TRANSPARENT_LAYOUT_SIMPLE,
  TRANSPARENT_LAYOUT_WITH_OVERFLOW,
} from './const';
import { withPaper } from './PaperLayout';

export { withPaper } from './PaperLayout';
export { withPlainLayout } from './EmptyLayout';

type LayoutCreator = <T extends object>(
  Component: React.ComponentType<T>,
) => // eslint-disable-next-line @typescript-eslint/ban-types
React.FC<T>;

export const withPaperLayout: LayoutCreator = component =>
  withPaper(component, PAPER_LAYOUT);

export const withPaperNoNavBarLayout: LayoutCreator = component =>
  withPaper(component, { ...PAPER_NO_NAVBAR_LAYOUT, showNavBar: false });

export const withTransparentLayout: LayoutCreator = component =>
  withPaper(component, TRANSPARENT_LAYOUT);

export const withTransparentLayoutSimple: LayoutCreator = component =>
  withPaper(component, TRANSPARENT_LAYOUT_SIMPLE);

export const withTransparentLayoutNoOverflow: LayoutCreator = component =>
  withPaper(component, TRANSPARENT_LAYOUT_NO_OVERFLOW);

export const withNoToolbarSMLayout: LayoutCreator = component =>
  withPaper(component, {
    ...TRANSPARENT_LAYOUT_WITH_OVERFLOW,
    hideNavBarSM: true,
  });

import {
  all,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
  putResolve,
} from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import { Action } from 'redux-actions';
import * as Sentry from '@sentry/browser';

import { TeamActions, TeamActionTypes } from '../actions/TeamActions';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { ITeamState } from '../reducers/teamReducer';
import {
  isUserAuthenticated,
  ITokensState,
} from 'auth/store/reducers/tokensReducer';
import { isRequestSuccessful } from 'common/utils/requestStatus';
import { ICreateTeamWithMemberPayload } from '../teamTypes';
import { onInvitationConfirm } from './confirmUserEffect';
import { PayActions } from '../../billing';

function* createTeamWithMembers(
  action: Action<ICreateTeamWithMemberPayload & { redirectOnSuccess?: string }>,
) {
  try {
    const { createTeamStatus, inviteTeamMemberStatus } = yield select(
      (state: { team: ITeamState }) => {
        return {
          createTeamStatus: state.team.createTeamStatus,
          inviteTeamMemberStatus: state.team.inviteTeamMemberStatus,
        };
      },
    );

    if (!isRequestSuccessful(createTeamStatus)) {
      const { error } = yield putResolve(
        TeamActions.createTeam({ teamName: action.payload.teamName }),
      );

      if (error) {
        return;
      }
    }

    const emails = action.payload.members.map(member => member.email);
    const roles = action.payload.members.map(member => member.role);

    const { currentTeamId } = yield select((state: { team: ITeamState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
      };
    });

    if (!isRequestSuccessful(inviteTeamMemberStatus)) {
      const { error } = yield putResolve(
        TeamActions.inviteTeamMember(emails, currentTeamId, roles),
      );

      if (error) {
        return;
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* onFetchTeamSuccess() {
  const teamId: string = yield select((state: { team: ITeamState }) => {
    return state.team.currentTeamId;
  });
  if (teamId) {
    yield put(PayActions.fetchPaymentMethodList(teamId));
    yield put(PayActions.fetchCards(teamId));
  }
}

export function* onTeamSelectSaga(action: Action<{ teamId: string }>): any {
  return yield all([
    put(TeamActions.fetchTeamRoles(action.payload.teamId)),
    put(TeamActions.fetchTeamDetail(action.payload.teamId)),
  ]);
}

function* onTeamsFetchSuccess() {
  yield delay(0);
  const teamId: string = yield select((state: { team: ITeamState }) => {
    return state.team.currentTeamId;
  });
  yield put(TeamActions.fetchTeamRoles(teamId));
  yield put(TeamActions.fetchTeamDetail(teamId));
}

function* fetchTeamsSaga() {
  const isAuthenticated: string = yield select(
    (state: { tokens: ITokensState }) => isUserAuthenticated(state.tokens),
  );

  if (isAuthenticated) {
    yield put(TeamActions.fetchTeams());
  }
}

function* teamSaga() {
  yield fetchTeamsSaga();
  yield takeEvery(
    TeamActionTypes.CONFIRM_TEAM_MEMBER_SUCCESS,
    onInvitationConfirm,
  );
  yield takeEvery(TeamActionTypes.FETCH_TEAMS_SUCCESS, onFetchTeamSuccess);
  yield takeEvery(TeamActionTypes.SET_CURRENT_TEAM, onTeamSelectSaga);
  yield takeEvery(TeamActionTypes.FETCH_TEAMS_SUCCESS, onTeamsFetchSuccess);
  yield takeEvery(
    TeamActionTypes.CREATE_TEAM_WITH_MEMBERS,
    createTeamWithMembers,
  );
  yield takeLatest([REHYDRATE, UserActionTypes.SIGNIN_SUCCESS], fetchTeamsSaga);
}

export { teamSaga };

import React, { useCallback } from 'react';
import { useCodeFieldStyles } from './CodeFieldStyles';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import { copyToClipboard } from '../../../utils';
import { CopyIcon } from 'common/components/Icons/CopyIcon';
import { t } from 'common/utils/intl';
import { useSnackbar } from 'common/utils/hooks/useSnackbar';

interface ICodeFieldProps {
  className?: string;
  code: string;
}

export const CodeField = ({ className, code }: ICodeFieldProps) => {
  const classes = useCodeFieldStyles();
  const { showSnackbar } = useSnackbar();

  const handleClick = useCallback(() => {
    showSnackbar(t('two-factor-auth.copied'), {
      key: 'two-factor-auth.copied',
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
    copyToClipboard(code);
  }, [code, showSnackbar]);

  return (
    <div className={classNames(className, classes.component)}>
      <span className={classes.code}>{code}</span>
      <IconButton className={classes.copy} size="small" onClick={handleClick}>
        <CopyIcon />
      </IconButton>
    </div>
  );
};

import React, { ReactNode } from 'react';
import { Theme } from '@material-ui/core';
import classNames from 'classnames';
import { Layout } from './Layout';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { IStoreState } from '../../../../store/reducers';
import { isUserAuthenticated } from 'auth/store/reducers/tokensReducer';
import { ILayoutStoreProps } from '../LayoutTypes';
import { TwoFactorActions } from '../../../TwoFactorAuth/store/TwoFactorActions';
import { PAGE_MAX_WIDTH } from '../../../AppDeployUI/const';

const usePaperStyles = makeStyles<Theme>(theme => ({
  paper: {
    width: `calc(100% - ${theme.spacing(17)}px)`,
    margin: theme.spacing(4.5, 8.5),
    maxWidth: PAGE_MAX_WIDTH,

    [theme.breakpoints.down('lg')]: {
      width: `calc(100% - ${theme.spacing(13)}px)`,
      margin: theme.spacing(4.5, 6.5),
    },

    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(9)}px)`,
      margin: theme.spacing(4.5, 4.5),
    },

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      margin: theme.spacing(3, 2),
    },
  },

  paperLayout: {
    background: theme.palette.common.white,
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      margin: theme.spacing(3, 2, 10),
    },
  },

  paperNoNavBarLayout: {
    background: theme.palette.common.white,
    marginTop: 36,

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      paddingBottom: 0,
      marginBottom: theme.spacing(9),
    },
  },

  transparentLayout: {
    marginTop: 2,

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      margin: theme.spacing(3, 2),
      paddingBottom: theme.spacing(8),
    },
  },

  transparentLayoutSimple: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      margin: theme.spacing(3, 2),
      paddingBottom: theme.spacing(8),
    },
  },

  transparentLayoutNoOverflow: {
    marginTop: 2,
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      margin: theme.spacing(3, 2),
      paddingBottom: theme.spacing(8),
    },
  },

  transparentLayoutWithOverflow: {
    marginTop: 2,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(3, 2),
      paddingBottom: theme.spacing(8),
    },
  },

  paperWhite: {
    background: theme.palette.common.white,
  },

  withBottomOffsetOnXS: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(10),
      paddingBottom: 0,
    },
  },

  withBottomOffsetOnSM: {
    [theme.breakpoints.only('sm')]: {
      marginBottom: theme.spacing(10),
      paddingBottom: 0,
    },
  },
}));

interface IOptionsProps {
  paperLayout?: boolean;
  paperNoNavBarLayout?: boolean;
  transparentLayout?: boolean;
  transparentLayoutSimple?: boolean;
  transparentLayoutNoOverflow?: boolean;
  transparentLayoutWithOverflow?: boolean;
  paperWhite?: boolean;
  withBottomOffsetOnXS?: boolean;
  withBottomOffsetOnSM?: boolean;
  showNavBar?: boolean;
  hideNavBarSM?: boolean;
  hideNavBarXS?: boolean;
  whiteXSBg?: boolean;
}

const PaperLayout = ({
  paperLayout,
  paperNoNavBarLayout,
  transparentLayout,
  transparentLayoutSimple,
  transparentLayoutNoOverflow,
  transparentLayoutWithOverflow,
  paperWhite,
  withBottomOffsetOnXS,
  withBottomOffsetOnSM,
  ...props
}: ILayoutStoreProps & IOptionsProps & { children: ReactNode }) => {
  const classes = usePaperStyles();
  return (
    <Layout
      className={classNames(
        classes.paper,
        paperLayout && classes.paperLayout,
        paperNoNavBarLayout && classes.paperNoNavBarLayout,
        transparentLayout && classes.transparentLayout,
        transparentLayoutSimple && classes.transparentLayoutSimple,
        transparentLayoutNoOverflow && classes.transparentLayoutNoOverflow,
        transparentLayoutWithOverflow && classes.transparentLayoutWithOverflow,
        paperWhite && classes.paperWhite,
        withBottomOffsetOnXS && classes.withBottomOffsetOnXS,
        withBottomOffsetOnSM && classes.withBottomOffsetOnSM,
      )}
      {...props}
    />
  );
};

const LayoutContainer = connect(
  (state: IStoreState) => {
    return {
      isUserAuthenticated: isUserAuthenticated(state.tokens),
      showTwoFAReminder: state.twoFactor.showReminder,
    };
  },
  {
    resetTwoFAReminder: TwoFactorActions.getReminderReset,
  },
)(PaperLayout);

export const withPaper = <T extends object>(
  Component: React.ComponentType<T>,
  options: IOptionsProps = {},
) => (props: T) => (
  <LayoutContainer {...options}>
    <Component {...props} />
  </LayoutContainer>
);

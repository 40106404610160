import {
  takeEvery,
  takeLatest,
  select,
  put,
  putResolve,
} from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';

import { UserActionTypes } from 'auth/store/actions/UserActions';
import {
  isUserAuthenticated,
  ITokensState,
} from 'auth/store/reducers/tokensReducer';
import { TeamActionTypes } from 'common/modules/teams/actions/TeamActions';
import { ZendeskActions, ZendeskActionTypes } from '../actions/ZendeskActions';
import { IUserState } from 'auth/store/reducers/userReducer';
import { IZendeskState } from '../reducers/zendeskReducer';
import { ITeamState, getCurrentTeam } from '../../teams/reducers/teamReducer';
import { IAPIZendeskMembership } from '../types/membershipTypes';
import { TokenActionTypes } from 'auth/store/actions/TokensActions';

function* fetchZendeskMemebershipSaga(): any {
  const isEnterprise: boolean = yield select(
    (state: { team: ITeamState }) =>
      getCurrentTeam(state.team)?.isEnterprise ?? false,
  );

  if (isEnterprise) {
    const teamId = yield select((state: { team: ITeamState }) => {
      return state.team.currentTeamId;
    });
    const zendeskUserId = yield select((state: { zendesk: IZendeskState }) => {
      return state.zendesk.user?.id;
    });
    const zendeskOrganizationId = yield select(
      (state: { zendesk: IZendeskState }) => {
        return state.zendesk.organizations?.[teamId]?.id;
      },
    );

    if (zendeskOrganizationId && zendeskUserId) {
      const zendeskMembership = yield select(
        (state: { zendesk: IZendeskState }) => {
          return state.zendesk.memberships[zendeskOrganizationId];
        },
      );
      if (!Boolean(zendeskMembership)) {
        const { data } = yield putResolve(
          ZendeskActions.fetchZendeskUserMemberships({ zendeskUserId }),
        );

        // prefetch users of this organization
        yield put(ZendeskActions.fetchZendeskUsers({ zendeskOrganizationId }));

        const memberships = data?.organization_memberships ?? [];

        const getMembership = memberships.find((x: IAPIZendeskMembership) => {
          return (
            x.user_id === zendeskUserId &&
            x.organization_id === zendeskOrganizationId
          );
        });

        const hasMembership = Boolean(getMembership);

        // If user has no membership to this organization, assign to current organization
        if (!hasMembership) {
          yield put(
            ZendeskActions.createOrUpdateZendeskUserMembership({
              zendeskUserId,
              zendeskOrganizationId,
            }),
          );
        }
      }
    }
  }
}
function* fetchZendeskOrganizationSaga(): any {
  const isEnterprise: boolean = yield select(
    (state: { team: ITeamState }) =>
      getCurrentTeam(state.team)?.isEnterprise ?? false,
  );

  if (isEnterprise) {
    const teamId = yield select((state: { team: ITeamState }) => {
      return state.team.currentTeamId;
    });

    if (teamId) {
      const zendeskOrganization = yield select(
        (state: { zendesk: IZendeskState }) => {
          return state.zendesk.organizations[teamId];
        },
      );

      if (!Boolean(zendeskOrganization)) {
        const { data } = yield putResolve(
          ZendeskActions.fetchZendeskOrganization({ teamId }),
        );

        const organizations = data?.organizations ?? [];

        // If organization does not exist, create organization
        if (organizations.length < 1) {
          yield put(ZendeskActions.createZendeskOrganization({ teamId }));
        }
      }
    }
  }
}

function* fetchZendeskUserSaga() {
  const isEnterprise: boolean = yield select(
    (state: { team: ITeamState }) =>
      getCurrentTeam(state.team)?.isEnterprise ?? false,
  );

  if (isEnterprise) {
    const isAuthenticated: boolean = yield select(
      (state: { tokens: ITokensState }) => {
        return isUserAuthenticated(state.tokens);
      },
    );

    if (isAuthenticated) {
      const userId: string = yield select((state: { user: IUserState }) => {
        return state.user.id;
      });
      const zendeskUser: IZendeskState['user'] = yield select(
        (state: { zendesk: IZendeskState }) => state.zendesk.user,
      );

      const loggedInAndZendeskNotLoaded = userId && !Boolean(zendeskUser);

      if (loggedInAndZendeskNotLoaded) {
        const { data } = yield putResolve(
          ZendeskActions.fetchZendeskUser({ userId }),
        );

        const users = data?.users ?? [];

        const user = users[0];

        const zendeskName = user?.name;
        const zendeskEmail = user?.email;
        const { email, name } = yield select((state: { user: IUserState }) => {
          return state.user;
        });

        if (zendeskEmail !== email || zendeskName !== name) {
          // If user does not exist, create user
          // if user email or name has changed, update data
          yield put(
            ZendeskActions.createOrUpdateZendeskUser({ userId, email, name }),
          );
        }
      }
    }
  }
}

/* TODO this saga should only run if user is inside an Enterprise Team / Account */
function* zendeskSaga() {
  yield takeEvery(
    [TeamActionTypes.FETCH_TEAMS_SUCCESS, TeamActionTypes.SET_CURRENT_TEAM],
    fetchZendeskOrganizationSaga,
  );

  yield takeLatest(
    [
      REHYDRATE,
      UserActionTypes.USER_REFRESH_SUCCESS,
      TokenActionTypes.REFRESH_TOKEN_SUCCESS,
      TeamActionTypes.FETCH_TEAMS_SUCCESS,
      TeamActionTypes.SET_CURRENT_TEAM,
    ],
    fetchZendeskUserSaga,
  );
  yield takeLatest(
    [
      REHYDRATE,
      ZendeskActionTypes.FETCH_ZENDESK_ORGANIZATION_SUCCESS,
      ZendeskActionTypes.FETCH_ZENDESK_USER_SUCCESS,
      ZendeskActionTypes.CREATE_ZENDESK_ORGANIZATION_SUCCESS,
      ZendeskActionTypes.CREATE_ZENDESK_USER_SUCCESS,
      TokenActionTypes.REFRESH_TOKEN_SUCCESS,
      TeamActionTypes.SET_CURRENT_TEAM,
    ],
    fetchZendeskMemebershipSaga,
  );
}

export { zendeskSaga };

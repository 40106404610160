import React from 'react';

import { StyledComponentProps, Theme, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { TerraStatus } from './TerraStatus';
import { TerraListRPCCalls } from './TerraListRPCCalls';
import { ICustomInfoComponent } from 'common/modules/apps/types';

const styles = (_: Theme): StyleRules => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
  },
});

interface ITerraInformationTabProps extends ICustomInfoComponent {}

const TerraInformationTabComponent = ({
  classes = {},
}: ITerraInformationTabProps & StyledComponentProps) => {
  return (
    <div className={classes.root}>
      <TerraStatus />
      <TerraListRPCCalls />
    </div>
  );
};

const TerraInformationTab = withStyles(styles)(TerraInformationTabComponent);

export { TerraInformationTab };

export const ltoNetworkNodeName = 'lto-network';

export enum LtoNetworkHooks {
  getAddress = 'apphook1=get-address',
  nodeStatus = 'apphook2=node-status',
  getBalance = 'apphook3=get-balance',
  stakeStatus = 'apphook4=stake-status',
}

export const LTO_NETWORK_NODE_NAME = 'ankrCustomValues___node_name';
export const LTO_NETWORK_WALLET_SEED = 'ankrCustomValues___wallet_seed';

import { put, putResolve, delay } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { IExtendAppPayload } from '../actions/PayActions';
import { SendRequestResponse } from '../../../types';
import { PaymentMethod } from '../const';
import { throwIfContainsError } from 'common/utils/throwIfContainsError';

const WAIT_SERVER_DELAY = 300;

export function* onExtendAppSaga(action: Action<IExtendAppPayload>): any {
  try {
    const { paymentMethod, appId, period, cardId, teamId } = action.payload;

    const prepareExtendOrderResponse: SendRequestResponse<{
      amount: string;
    }> = throwIfContainsError(
      yield putResolve({
        type: 'EMPTY',
        request: {
          url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/create_extend_order`,
          method: 'POST',
          data: {
            pay_method:
              paymentMethod === PaymentMethod.DEPOSIT_CREDIT_CARD
                ? PaymentMethod.CREDIT_CARD
                : paymentMethod,
            app_id: appId,
            period,
          },
        },
        meta: { id: appId },
      }),
    );

    throwIfContainsError(
      yield put({
        type: 'EMPTY',
        request: {
          url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/after_create_extend_order/do_extend_app`,
          method: 'POST',
          data: {
            pay_method:
              paymentMethod === PaymentMethod.DEPOSIT_CREDIT_CARD
                ? PaymentMethod.CREDIT_CARD
                : paymentMethod,
            app_id: appId,
            period,
            card_id: cardId,
          },
        },
        meta: { id: appId },
      }),
    );

    yield delay(WAIT_SERVER_DELAY);

    return yield put({
      type: action.type + '_SUCCESS',
      data: {
        amount: prepareExtendOrderResponse.data.amount,
        paymentMethod,
      },
      meta: { id: appId, teamId },
    });
  } catch (error) {
    return yield put({
      type: action.type + '_ERROR',
      error,
    });
  }
}

import React from 'react';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import {
  NEAR_FULL_SNAPSHOT_MODE,
  NearFull,
  NearFullHooks,
  NearFullNode,
} from './nearFullConst';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import localeUS from './locales/nearFull.en-US.json';

const LoadableConfig = loadable(
  async () =>
    import('./manage/NearFullConfig').then(module => module.NearFullConfig),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/NearFullCustomValues').then(
      module => module.NearFullCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const nearFullConfig: INodeConfig = {
  chartName: NearFull,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localeUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      [NEAR_FULL_SNAPSHOT_MODE]: true,
    },
  }),
  hideCustomVariableDivider: true,
  info: LoadableConfig,
  manage: false as false,
  details: false as false,
  interceptors: {
    [NearFullNode]: {
      [NearFullHooks.getBlocksSyncStatus]: defaultParse,
      [NearFullHooks.getHeadersSyncStatus]: defaultParse,
    },
  },
};

import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useStep2Styles } from './Step2Styles';
import classNames from 'classnames';
import { CodeField } from '../CodeField';
import { connect, useSelector } from 'react-redux';
import {
  getTwoFactorCode,
  getTwoFactorIsEnabled,
} from '../../../store/TwoFactorSelectors';
import { IStepProps } from '../types';
import { defineFlowStep } from '../../../components/Flow/definition';
import { QRCode } from 'common/components/QRCode';
import { TwoFactorActions } from '../../../store/TwoFactorActions';
import { IStoreState } from '../../../../../store/reducers';
import { RequestStatusDisplay } from 'common/components/RequestStatusDisplay';

interface IStep2Props {
  className?: string;
  code: string;
  qrCode: string;
}

export const Step2Component = ({ className, code, qrCode }: IStep2Props) => {
  const classes = useStep2Styles();

  return (
    <div className={classNames(className, classes.component)}>
      <Typography className={classes.title} variant="h2" component="p">
        {t('two-factor-auth.captions.enable.third-screen')}
      </Typography>
      <div className={classes.content}>
        <CodeField className={classes.code} code={code} />
        <Typography className={classes.text} variant="subtitle2" component="p">
          {t('two-factor-auth.captions.enable.note')}
        </Typography>
        <QRCode className={classes.qr} value={qrCode} />
      </div>
    </div>
  );
};

const Step2Wrapper = ({
  innerClassName,
  getCode,
  regenerateCode,
}: IStepProps & {
  getCode: typeof TwoFactorActions.getCode;
  regenerateCode: typeof TwoFactorActions.regenerateCode;
}) => {
  const code = useSelector(getTwoFactorCode).secret;
  const urlCode = useSelector(getTwoFactorCode).url;
  const userName = useSelector((state: IStoreState) => state.user.name);
  const twoFactorAuthEnabled = useSelector((state: IStoreState) =>
    getTwoFactorIsEnabled(state),
  );
  const status = useSelector(
    (state: IStoreState) => state.twoFactor.fetchCodeStatus,
  );

  useEffect(() => {
    if (twoFactorAuthEnabled) {
      regenerateCode(userName);
    } else {
      getCode(userName);
    }
  }, [getCode, regenerateCode, twoFactorAuthEnabled, userName]);

  return (
    <RequestStatusDisplay
      status={status}
      showLoadingWhenInactive={true}
      showError={true}
    >
      <Step2Component className={innerClassName} code={code} qrCode={urlCode} />
    </RequestStatusDisplay>
  );
};

const ConnectedStep2 = connect(null, {
  getCode: TwoFactorActions.getCode,
  regenerateCode: TwoFactorActions.regenerateCode,
})(Step2Wrapper);

export const Step2 = defineFlowStep<{}, {}, IStepProps>({
  Body: ConnectedStep2,
});

import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useSuccessStyles } from './SuccessStyles';
import { useIsXXSDown } from 'common/utils/hooks/themeHooks';
import classNames from 'classnames';
import { IStepProps } from '../types';
import { defineFlowStep } from '../../../components/Flow/definition';
import { useFlowControl } from '../../../components/Flow/hooks';

interface ISuccessProps {
  className?: string;

  onClose(): void;
}

export const SuccessComponent = ({ className, onClose }: ISuccessProps) => {
  const classes = useSuccessStyles();

  const isXXSDown = useIsXXSDown();

  return (
    <div className={classNames(className, classes.component)}>
      <Typography
        className={classes.title}
        variant={isXXSDown ? 'h3' : 'h2'}
        component="p"
      >
        {t('two-factor-auth.captions.enable.congratulations')}
        <br />
        {t('two-factor-auth.captions.enable.last-screen')}
      </Typography>
      <Button className={classes.close} onClick={onClose}>
        {t('two-factor-auth.nav.close')}
      </Button>
    </div>
  );
};

const SuccessWrapper = ({ className }: IStepProps) => {
  const { moveForward } = useFlowControl();
  return <SuccessComponent className={className} onClose={moveForward} />;
};

export const Success = defineFlowStep({
  Body: SuccessWrapper,
});

import React from 'react';
import RectCopyToClipboard from 'react-copy-to-clipboard';

interface ICopyToClipboardProps {
  text: string;
  onCopy?: () => void;
  children: React.ReactNode;
}

const CopyToClipboard = ({ children, text, onCopy }: ICopyToClipboardProps) => (
  <RectCopyToClipboard text={text} onCopy={onCopy}>
    {children}
  </RectCopyToClipboard>
);

export { CopyToClipboard };

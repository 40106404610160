export const NERVOS_NODE_NAME = 'nervos-l2';

export const NERVOS_CKB_URL = 'ankrCustomValues___ckb_url';
export const NERVOS_INDEXER_URL = 'ankrCustomValues___indexer_url';

export const NERVOS_DOCUMENTATION_LINK = 'https://docs.nervos.org/';

export enum NERVOS_HOOKS {
  getStatus = 'apphook1=get-status',
  getCkbUrl = 'apphook2=get-ckb',
  getIndexerUrl = 'apphook3=get-indexer',
}

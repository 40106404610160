/* eslint-disable @typescript-eslint/camelcase */
import { createAction } from 'common/utils/reduxUtils';
import { mapNamespaceFormToApi } from '../apiMappings/namespaceMappings';
import { INamespaceFilters } from './NamespacesActionsTypes';
import { NAMESPACES_CACHE_TIME } from 'common/core/const';
import { IExtendedRequestAction } from 'common/types/requestTypes';
import { DataIndexes } from 'common/types';
import { INamespaceFormValues } from '../types';
import { NAMESPACESAPI } from '../const';

const NamespacesActionTypes = {
  NAMESPACES_FETCH: 'NAMESPACES_FETCH',
  NAMESPACES_FETCH_SUCCESS: 'NAMESPACES_FETCH_SUCCESS',
  NAMESPACES_FETCH_ERROR: 'NAMESPACES_FETCH_ERROR',

  NAMESPACE_CREATE: 'NAMESPACE_CREATE',
  NAMESPACE_CREATE_SUCCESS: 'NAMESPACE_CREATE_SUCCESS',
  NAMESPACE_CREATE_ERROR: 'NAMESPACE_CREATE_ERROR',
  NAMESPACE_RESET_CREATE_STATUS: 'NAMESPACE_RESET_CREATE_STATUS',

  NAMESPACE_DELETE: 'NAMESPACE_DELETE',
  NAMESPACE_DELETE_SUCCESS: 'NAMESPACE_DELETE_SUCCESS',
  NAMESPACE_DELETE_ERROR: 'NAMESPACE_DELETE_ERROR',
  NAMESPACES_DELETE: 'NAMESPACES_DELETE',

  NAMESPACE_APPLY_FILTERS: 'NAMESPACE_APPLY_FILTERS',
  NAMESPACES_SET_PAGE: 'NAMESPACES_SET_PAGE',
};

const NamespacesActions = {
  fetchNamespaces: (
    teamId?: string | undefined,
    cacheTime = NAMESPACES_CACHE_TIME,
  ): IExtendedRequestAction => ({
    type: NamespacesActionTypes.NAMESPACES_FETCH,
    request: {
      url: NAMESPACESAPI,
      method: 'GET',
      params: {
        ...(teamId ? { team_id: teamId } : {}),
      },
    },
    meta: {
      ...(teamId ? { cacheKey: teamId } : {}),
      cache: cacheTime,
      cacheSize: 10,
    },
  }),

  createNamespace: (
    values: INamespaceFormValues,
    teamId?: string,
  ): IExtendedRequestAction => ({
    type: NamespacesActionTypes.NAMESPACE_CREATE,
    request: {
      url: NAMESPACESAPI,
      method: 'POST',
      data: mapNamespaceFormToApi(values),
      params: {
        ...(teamId ? { team_id: teamId } : {}),
      },
    },
  }),

  resetCreateStatus: () =>
    createAction(NamespacesActionTypes.NAMESPACE_RESET_CREATE_STATUS),

  deleteNamespace: (id: string, teamId?: string): IExtendedRequestAction => ({
    type: NamespacesActionTypes.NAMESPACE_DELETE,
    request: {
      url: `${NAMESPACESAPI}/${id}`,
      params: {
        ...(teamId ? { team_id: teamId } : {}),
      },
      method: 'DELETE',
    },
    meta: {
      id,
    },
  }),

  applyFilters: (filters: INamespaceFilters) =>
    createAction(NamespacesActionTypes.NAMESPACE_APPLY_FILTERS, filters),

  // UI

  setPage: (page: number, rowsPerPage?: number) =>
    createAction(NamespacesActionTypes.NAMESPACES_SET_PAGE, {
      page,
      rowsPerPage,
    }),
  deleteNamespaces: (ids: DataIndexes) =>
    createAction(NamespacesActionTypes.NAMESPACES_DELETE, {
      ids,
    }),
};

export { NamespacesActionTypes, NamespacesActions };

import React from 'react';
import {
  Props as ChipInputProps,
  default as MuiChipInput,
} from 'material-ui-chip-input';
import { useChipsInputStyles } from './ChipsInputStyles';

export const ChipInput = ({
  className,
  ...props
}: ChipInputProps & { className?: string }) => {
  const classes = useChipsInputStyles();

  return (
    <MuiChipInput
      className={className}
      classes={{
        root: classes.component,
        chipContainer: classes.wrapper,
        inputRoot: classes.container,
        chip: classes.chip,
        input: classes.input,
      }}
      {...props}
    />
  );
};

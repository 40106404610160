import {
  MOONBEAM_NODE_TYPE,
  MoonbeamHooks,
  moonbeamNodeName,
} from './MoonbeamConst';
import {
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/Moonbeam.en-US.json';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';
import { IHookResponse } from 'common/utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';

const LoadableManage = loadable(
  async () =>
    import('./manage/MoonbeamManage').then(module => module.MoonbeamManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/MoonbeamCustomValues').then(
      module => module.MoonbeamCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

function parseStatus(response: IHookResponse): IHookResponse {
  const data = safeParseJSON(response.data.result ?? '{}');
  const currentBlock = Number(data.currentBlock ?? 0) ?? 0;
  const lastBlock = Number(data.lastBlock ?? 0) ?? 0;
  return {
    ...response,
    data: {
      ...data,
      isSyncing: data.isSyncing === 'true',
      currentBlock,
      lastBlock,
    },
  };
}

export const moonbeamConfig: INodeConfig = {
  chartName: moonbeamNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      [MOONBEAM_NODE_TYPE]: 'false',
    },
  }),
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => {
    return {
      [MOONBEAM_NODE_TYPE]: customValues?.[MOONBEAM_NODE_TYPE]
        ? 'true'
        : 'false',
    };
  },
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'moonbeam-node': {
      [MoonbeamHooks.nodeStatus]: parseStatus,
    },
  },
};

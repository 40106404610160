import * as Sentry from '@sentry/browser';
import {
  IAPIZendeskTicketComment,
  IZendeskTicketComment,
} from '../types/ticketCommentTypes';
import { compareAsc } from 'date-fns';

const normalizeZendeskTicketComment = (
  comment: IAPIZendeskTicketComment,
): IZendeskTicketComment => {
  return {
    id: comment.id,
    type: comment.type,
    body: comment.body,
    public: comment.public,
    createdAt: new Date(comment.created_at),
    authorId: comment.author_id,
    attachments: comment.attachments,
  };
};

export const normalizeZendeskTicketComments = (data: {
  comments: IAPIZendeskTicketComment[];
}) => {
  try {
    const comments =
      data?.comments?.length > 0
        ? data.comments
            .map(normalizeZendeskTicketComment)
            .sort((a, b) => compareAsc(a.createdAt, b.createdAt))
        : [];

    comments.splice(0, 1);

    return comments;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

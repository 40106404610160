import { StyleRules, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    text: {
      fontWeight: 'bold',
      color: theme.palette.grey[900],
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.grey[700],
      fontWeight: 'bold',
    },
    backButton: {
      position: 'relative',
      left: 0,
    },
    icon: {
      paddingLeft: 0,
    },
  }),
);

import { MIXPANEL_TOKEN, GA_ID } from 'common/core/const';

import mixpanel from 'mixpanel-browser';

import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

export const initializeAnalytics = () => {
  try {
    if (MIXPANEL_TOKEN !== 'NO_TOKEN') {
      mixpanel.init(MIXPANEL_TOKEN);
    }
    ReactGA.initialize(GA_ID);
  } catch (error) {
    Sentry.captureException(error);
  }
};

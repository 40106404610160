import React from 'react';
import { useInitEffect } from 'common/utils/hooks';
import { useInterval } from 'common/utils/useInterval';
import { Milliseconds } from 'common/types';

import { ProgressHookRow } from '../../../../NodesUI/screens/NodeDetails/Hooks/ProgressHookRow';
import { useZCashStatus } from '../ZcashHooks';

const POLLING_INTERVAL: Milliseconds = 10000;
export const ZcashStatus = () => {
  const {
    call: queryCall,
    result: queryResult,
    status: queryStatus,
  } = useZCashStatus();

  const currentHeight = Number(queryResult?.blocks) ?? 0;
  const toHeight = queryResult?.headers ? Number(queryResult?.headers) ?? 0 : 0;

  const isSyncing = currentHeight < toHeight;

  useInitEffect(() => {
    queryCall();
  });

  useInterval(() => {
    if (isSyncing) {
      queryCall();
    }
  }, POLLING_INTERVAL);

  return (
    <>
      <ProgressHookRow
        status={queryStatus}
        height={toHeight}
        currentHeight={currentHeight}
        isSyncing={isSyncing}
      />
    </>
  );
};

import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const ArrowDropDownIcon = withSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.58398 10.376C7.78189 10.6728 8.21811 10.6728 8.41603 10.376L11.4818 5.77735C11.7033 5.44507 11.4651 5 11.0657 5H4.93426C4.53491 5 4.29672 5.44507 4.51823 5.77735L7.58398 10.376Z"
  />,
  { viewBox: '0 0 16 16' },
);

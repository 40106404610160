import { delay, put, select, takeEvery, putResolve } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import * as Sentry from '@sentry/browser';
import { RequestAction } from '@redux-requests/core';

import {
  IExtendAppByNewCardPayload,
  PayActions,
  PayActionTypes,
} from '../actions/PayActions';
import { ITeamState } from '../../teams/reducers/teamReducer';
import { getPaymentMethod, IPayState } from '../reducers/payReducer';
import {
  IApiCheckSelectableParams,
  IPaymentMethod,
  PayStatus,
} from '../../apps/types/payTypes';
import { IAppsState } from '../../apps/reducers/appsReducer';
import { AppsActionTypes } from 'common/modules/apps/actions/AppsActions';
import { PaymentMethod, PaymentStep } from '../const';

const FETCH_PAYMENT_REPEAT_DELAY = 2000;

function* onFetchPaymentMethodSuccess(action: RequestAction): any {
  try {
    const paymentName = action.meta?.requestAction.request.meta.paymentName;

    const {
      currentTeamId = '',
      paymentMethod,
      checkPriceParam,
      paymentMethods,
    }: {
      currentTeamId?: string;
      paymentMethod?: IPaymentMethod;
      paymentMethods: IPaymentMethod[];
      checkPriceParam: IApiCheckSelectableParams;
    } = yield select(
      (state: { team: ITeamState; pay: IPayState; apps: IAppsState }) => {
        return {
          currentTeamId: state.team.currentTeamId,
          paymentMethod: getPaymentMethod(
            state.pay.paymentMethods,
            paymentName,
          ),
          paymentMethods: state.pay.paymentMethods,
          checkPriceParam: state.apps?.checkPriceParam,
        };
      },
    );

    if (paymentMethod?.status === PayStatus.PENDING) {
      yield delay(FETCH_PAYMENT_REPEAT_DELAY);
      yield put(PayActions.fetchPaymentMethod(currentTeamId, paymentName));
    } else if (paymentMethod?.status === PayStatus.READY) {
      const { data } = yield putResolve(
        PayActions.checkPaymentMethod(currentTeamId, paymentName, [
          checkPriceParam,
        ]),
      );

      if (
        data.selectable ||
        (paymentMethods.length === 1 &&
          paymentMethods.find(item => item.name === paymentName))
      ) {
        yield put(PayActions.selectPaymentMethod(currentTeamId, paymentName));
      }

      const { paymentStep } = yield select(
        (state: { team: ITeamState; pay: IPayState }) => {
          return {
            paymentStep: state.pay.paymentStep,
          };
        },
      );

      if (paymentStep === PaymentStep.CREATING_ESCROW) {
        yield put(PayActions.setCurrentStep(PaymentStep.DEPOSIT));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* createAppByNewCard(action: Action<IExtendAppByNewCardPayload>) {
  const { currentTeamId, justCreatedCardId } = yield select(
    (state: { team: ITeamState; pay: IPayState }) => {
      return {
        justCreatedCardId: state.pay.justCreatedCardId,
        currentTeamId: state.team.currentTeamId ?? '',
      };
    },
  );

  yield put(
    PayActions.extendApp({
      teamId: currentTeamId,
      paymentMethod: PaymentMethod.CREDIT_CARD,
      appId: action.payload.appId,
      cardId: justCreatedCardId,
      period: action.payload.period,
    }),
  );
}

function* onSetupPayMethodSuccess(action: RequestAction) {
  try {
    const paymentName = action.meta?.requestAction.request.meta.paymentName;
    const { currentTeamId } = yield select(
      (state: { team: ITeamState; pay: IPayState }) => {
        return {
          currentTeamId: state.team.currentTeamId,
        };
      },
    );
    yield put(PayActions.fetchPaymentMethod(currentTeamId, paymentName));
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* onSelectPayMethodSuccess(action: RequestAction) {
  yield put(PayActions.checkPriceManual());
}

function* onCheckPrice() {
  try {
    const { teamId, checkPriceParam } = yield select(
      (state: { team: ITeamState; apps: IAppsState }) => {
        return {
          teamId: state.team.currentTeamId,
          checkPriceParam: state.apps?.checkPriceParam,
        };
      },
    );

    if (checkPriceParam) {
      yield put(
        PayActions.checkPrice({
          team_id: teamId,
          cpu: checkPriceParam.cpu,
          memory: checkPriceParam.memory,
          storage: checkPriceParam.storage,
          chart_name: checkPriceParam.chart_name,
          cluster_id: checkPriceParam.cluster_id,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* onCheckPaymentSuccess(action: RequestAction) {
  const request = action.meta?.requestAction.request;
  const { teamId, paymentMethods } = yield select(
    (state: { team: ITeamState; pay: IPayState }) => {
      return {
        teamId: state.team.currentTeamId,
        paymentMethods: state.pay.paymentMethods,
      };
    },
  );
  const payName = request.payName;
  const currentPayMethod: IPaymentMethod | undefined = paymentMethods.find(
    (item: IPaymentMethod) => item.name === payName,
  );

  if (request.needSelect && currentPayMethod && !currentPayMethod.selected) {
    yield put(PayActions.selectPaymentMethod(teamId, payName));
  }
}

function* onCalculatePriceSuccess(action: RequestAction) {
  try {
    const request = action.meta?.requestAction.request;
    const { teamId, cpu, memory, storage, chartName, clusterId } = yield select(
      (state: { team: ITeamState; pay: IPayState }) => {
        return {
          teamId: state.team.currentTeamId,
          paymentMethods: state.pay.paymentMethods,
          currentPaymentMethod: state.pay.currentPaymentMethod,
          cpu: request.params['resource.cpu'],
          memory: request.params['resource.memory'],
          storage: request.params['resource.storage'],
          chartName: request.params.node_kind,
          clusterId: request?.clusterId,
        };
      },
    );
    if (clusterId) {
      yield put(
        PayActions.checkPrice({
          team_id: teamId,
          cpu: cpu,
          memory: memory,
          storage: storage,
          chart_name: chartName,
          cluster_id: clusterId,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* paySaga() {
  yield takeEvery(
    // find a way how to fix
    // @ts-ignore
    PayActionTypes.FETCH_PAYMENT_METHOD_SUCCESS,
    onFetchPaymentMethodSuccess,
  );
  yield takeEvery(
    // find a way how to fix
    // @ts-ignore
    PayActionTypes.SETUP_PAYMENT_METHOD_SUCCESS,
    onSetupPayMethodSuccess,
  );
  yield takeEvery(
    // find a way how to fix
    // @ts-ignore
    PayActionTypes.SELECT_PAYMENT_METHOD_SUCCESS,
    onSelectPayMethodSuccess,
  );
  yield takeEvery(PayActionTypes.CHECK_PRICE_MANUAL, onCheckPrice);
  yield takeEvery(
    // find a way how to fix
    // @ts-ignore
    PayActionTypes.CHECK_PAYMENT_METHOD_SUCCESS,
    onCheckPaymentSuccess,
  );
  yield takeEvery(
    // find a way how to fix
    // @ts-ignore
    AppsActionTypes.CALCULATE_APP_PRICE_SUCCESS,
    onCalculatePriceSuccess,
  );
  yield takeEvery(PayActionTypes.EXTEND_APP_BY_NEW_CARD, createAppByNewCard);
}

export { paySaga };

import React from 'react';
import { TezosHooks, tezosNodeName } from './TezosConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/Tezos.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';

const LoadableManage = loadable(
  async () => import('./manage/TezosManage').then(module => module.TezosManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

export const tezosConfig: INodeConfig = {
  chartName: tezosNodeName,

  locales: {
    'en-US': localesUS,
  },
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'tezos-node': {
      [TezosHooks.getNodeStatus]: defaultParse,
    },
  },
};

export const moonbeamNodeName = 'moonbeam';

export enum MoonbeamHooks {
  nodeStatus = 'apphook1=node-status',
}

export const MOONBEAM_MONIKER = 'ankrCustomValues___moniker';
export const MOONBEAM_NODE_TYPE = 'ankrCustomValues___node_type';

export const MOONBEAM_DOCS_HREF = 'https://docs.moonbeam.network/';

export function safeParseJSON(s: string) {
  try {
    return JSON.parse(s);
  } catch (e) {
    try {
      return JSON.parse(s.replace(/\n/gi, '').replace(/'/g, '"'));
    } catch (e) {
      return s;
    }
  }
}

export const add0xPrefix = (string: string) => {
  try {
    return string.startsWith('0x') ? string : `0x${string}`;
  } catch {
    return '';
  }
};

export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const checkString = (text: object | string): string => {
  if (typeof text === 'string') {
    return text;
  }
  return '';
};

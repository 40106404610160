export enum TextColor {
  DEFAULT = '#666666',
  LIGHT = '#999999',
  DARK = '#333333',
  RATE_POSITIVE = '#65C8A0',
  BALANCE = '#4a90e2',
  PANEL_TITLE = '#333333',
  WARNING = '#F2B040',
  ERROR = '#e04a43',
  EMAIL_TITLE = '#4650A7',
}

export enum AppColor {
  OFFICIAL = '#333333',
  SECONDARY = '#666666',
  WEAKENING = '#999999',
  INDICATIVE = '#E3E3E3',
  TRANSFER = '#39A3CF',
  COMPLEMENTARY_FIFTH = '#EFF8FC',
  STATUS_IMPORTANT = '#ed2024',
  STATUS_TEMPERATE = '#fea34f',
  STATUS_OK = '#39cf8b',
  BLACK = '#000000',
  STATS_CPU = '#99C5F9',
  STATS_MEMORY = '#99C5F9',
  STATS_STORAGE = '#99C5F9',
  STATS_NETWORK = '#99C5F9',
  PROGRESS_BACKGROUND = '#F5F5F5',
  BEP2 = '#fcba4a',
  MAINNET = '#2C83BD',
  ERC20 = '#718EA1',
  IMPORTANT_BG = '#F7FAFB',

  TOOLBAR_BACKGROUND = '#FFFFFF',
  LOGO_BACKGROUND = '#31373D',
  DRAWER_BACKGROUND = '#252A2F',
  DRAWER_ITEM_COLOR = '#FBFBFB',
  DRAWER_SELECTED_ITEM_COLOR = '#252A2F',
  DRAWER_SELECTED_ITEM_BACKGROUND = '#EEF0F4',
}

import { HandleRequestConfig } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';

import {
  createApiTransportInstance,
  ITransportConfig,
} from 'common/store/effects/apiSagaUtils';
import { onError, onRequest, onSuccess } from 'common/store/interceptors';
import { hooksInterceptor } from 'common/modules/nodes/interceptors/hooksInterceptor';
import { deployInterceptor } from 'common/modules/nodes/interceptors/deployInterceptor';

export const API_CONFIG: ITransportConfig = {
  baseURL: process.env.REACT_APP_API_BASE || '',
  responseInterceptors: [hooksInterceptor],
  requestInterceptors: [deployInterceptor],
};

const axiosInstance = createApiTransportInstance(API_CONFIG);

export const HANDLE_REQUEST_CONFIG: HandleRequestConfig = {
  driver: createDriver(axiosInstance),
  onRequest,
  onSuccess,
  onError,
};

export enum TrafficIntensity {
  UNAVAILABLE,
  LIGHT,
  MEDIUM,
  HEAVY,
}

export interface IApiNetworkInfoResponse {
  user_count: number;
  host_count: number;
  ns_count: number;
  container_count: number;
  traffic: TrafficIntensity;
  cpu_total: string;
  cpu_used: string;
  memory_total: string;
  memory_used: string;
  storage_total: string;
  storage_used: string;
  network_count: number;
}

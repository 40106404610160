import {
  RippleFullNodeHooks,
  rippleFullNodeNodeName,
} from './RippleFullNodeConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/RippleFullNode.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import React from 'react';

const LoadableManage = loadable(
  async () =>
    import('./manage/RippleFullNodeManage').then(
      module => module.RippleFullNodeManage,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

export const rippleFullNodeConfig: INodeConfig = {
  chartName: rippleFullNodeNodeName,
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {},
  }),
  hideCustomVariableDivider: true,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    'ripple-full-node': {
      [RippleFullNodeHooks.nodeStatus]: defaultParse,
      // [RippleFullNodeHooks.current_ledger]: defaultParse,
      [RippleFullNodeHooks.ledgers]: defaultParse,
    },
  },
};

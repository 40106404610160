import { put, putResolve } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import * as Sentry from '@sentry/browser';

import { SendRequestResponse } from 'common/types';
import { normalizeApisInvoiceList } from 'common/modules/apps/apiMappings/invoiceMapping';
import { IApiApisInvoiceList } from 'common/modules/apps/types/invoiceType';

function getUrl(teamId: string): string {
  return `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project/invoice/list/${teamId}`;
}

export function* fetchApisInvoiceListSaga(action: Action<{ teamId: string }>) {
  try {
    const { teamId } = action.payload;

    const fetchInvoicesResponse: SendRequestResponse<IApiApisInvoiceList> = yield putResolve(
      {
        type: '',
        request: {
          url: getUrl(teamId),
        },
        meta: {
          takeLatest: false,
        },
      },
    );

    if ('error' in fetchInvoicesResponse) {
      yield put({ type: action.type + '_ERROR' });
    } else {
      const apisInvoiceList = normalizeApisInvoiceList(
        fetchInvoicesResponse.data.invoice_list,
      ).sort((a, b) => b.startTime.getTime() - a.startTime.getTime());

      yield put({
        type: action.type + '_SUCCESS',
        data: { apisInvoiceList },
      });
    }
  } catch (error) {
    yield put({
      type: action.type + '_ERROR',
      error: { message: error.toString() },
    });

    Sentry.captureException(error);
  }
}

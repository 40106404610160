import { fade, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const ProfileButtonStyles = (theme: Theme): StyleRules => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  button: {
    minWidth: 'auto',
  },
  name: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    color: theme.palette.grey['800'],
  },
  popper: {
    zIndex: 1,
    width: 218,
  },
  paper: {
    borderRadius: 4,
    overflow: 'hidden',
    background: theme.palette.common.white,
    boxShadow: '0px 8px 14px rgba(54, 68, 87, 0.26)',
    marginTop: 8,
    marginRight: 22,
    [theme.breakpoints.down('xs')]: {
      marginTop: 3,
      marginRight: -5,
    },
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: '18px 0 18px 12px',
    color: theme.palette.grey['700'],
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.grey['200'], 0.5),
    },
  },
  text: {
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  modeSwitcher: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  rightButton: {
    marginLeft: theme.spacing(1),
  },
});

export { ProfileButtonStyles };

import React, { ReactNode } from 'react';
import { Box, Button } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { HookRow, IHookRowProps } from './HookRow';

interface ILinkHookRowProps extends Omit<IHookRowProps, 'children'> {
  onClick?: () => void;
  buttonText?: string;
  disabled?: boolean;
  buttonWidth?: number | string;
  buttonProps?: any;
  children?: React.ReactElement<any> | React.ReactElement[] | false | ReactNode;
}

export const ButtonHookRow = ({
  onClick,
  buttonText,
  disabled,
  buttonWidth,
  buttonProps = {},
  children,
  ...rest
}: ILinkHookRowProps) => {
  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <HookRow {...rest} wrapTitle={true}>
      {children}
      <Box display="flex" alignItems="center" width={buttonWidth}>
        <Button
          fullWidth={true}
          onClick={handleClick}
          size="small"
          disabled={disabled}
          {...buttonProps}
        >
          {buttonText || t('nodes.link.view')}
        </Button>
      </Box>
    </HookRow>
  );
};

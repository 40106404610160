import { IExtendedRequestAction } from 'common/types';

const SIDECAR_URLS = {
  goerli: 'https://api.goerli.stkr.io/v1alpha/sidecar',
  mainnet: 'https://api.stkr.io/v1alpha/sidecar',
};
const ETH_URLS = {
  goerli: 'https://eth-goerli-01.dccn.ankr.com',
  mainnet: 'https://eth-02.dccn.ankr.com',
};

export const StkrActionTypes = {
  CREATE_SIDECAR: 'CREATE_SIDECAR',
  GET_CERTS: 'GET_CERTS',
  GET_REWARDS: 'GET_REWARDS',
};

const apiRoot = {
  goerli: `https://api.goerli.stkr.io/v1alpha`,
  mainnet: `https://api.stkr.io/v1alpha`,
};

const certUrl = (network: string, sidecarId: string) =>
  `${apiRoot[network]}/sidecar/${sidecarId}/download/json-file`;

const rewardsUrl = (network: string, sidecarId: string) =>
  `${apiRoot[network]}/reward/sidecar/${sidecarId}`;

export const StkrActions = {
  createSidecar: ({
    appName,
    accessToken,
    network,
  }: {
    appName: string;
    accessToken: string;
    network: string;
  }): IExtendedRequestAction => ({
    type: StkrActionTypes.CREATE_SIDECAR,
    request: {
      url: SIDECAR_URLS[network],
      method: 'POST',
      data: {
        name: appName,
        eth1Url: ETH_URLS[network],
        withCerts: true,
      },
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    },
    meta: {
      noAuth: true,
      silent: true,
    },
  }),
  getCerts: ({
    accessToken,
    sidecarId,
    network,
  }: {
    accessToken: string;
    sidecarId: string;
    network: string;
  }): IExtendedRequestAction => ({
    type: StkrActionTypes.GET_CERTS,
    request: {
      url: certUrl(network, sidecarId),
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    },
    meta: {
      noAuth: true,
    },
  }),
  getRewards: ({
    accessToken,
    sidecarId,
    network,
  }: {
    accessToken: string;
    sidecarId: string;
    network: string;
  }): IExtendedRequestAction => ({
    type: StkrActionTypes.GET_REWARDS,
    request: {
      url: rewardsUrl(network, sidecarId),
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    },
    meta: {
      noAuth: true,
      silent: true,
    },
  }),
};

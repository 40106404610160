import React from 'react';
import localesUS from './locales/fantomV.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import {
  FANTOM_V_PASSWORD,
  FANTOM_V_WALLET_KEYSTORE,
  FANTOM_V_VALIDATOR_KEYSTORE,
  FANTOM_V_HOOKS,
  FANTOM_V_NODE_NAME,
} from './fantomVConst';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';

const LoadableTabs = loadable(
  async () => import('./manage/FantomVTabs').then(module => module.FantomVTabs),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/FantomVCustomValue').then(
      module => module.FantomVCustomValue,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const fantomVConfig: INodeConfig = {
  chartName: FANTOM_V_NODE_NAME,
  deploy: {
    component: LoadableDeploy,
    title: 'app-deploy.deploy.advanced-configuration',
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: () => ({
    customValues: {
      [FANTOM_V_PASSWORD]: '',
      [FANTOM_V_WALLET_KEYSTORE]: '',
      [FANTOM_V_VALIDATOR_KEYSTORE]: '',
    },
  }),

  tabsComponent: LoadableTabs,
  manage: false as false,
  details: false as false,
  info: false as false,
  interceptors: {
    'fantom-node': {
      [FANTOM_V_HOOKS.addValidator]: defaultParse,
      [FANTOM_V_HOOKS.getValidatorKeystore]: defaultParse,
      [FANTOM_V_HOOKS.getWalletKeystore]: defaultParse,
      [FANTOM_V_HOOKS.getAddresses]: defaultParse,
      [FANTOM_V_HOOKS.getNodeStatus]: defaultParse,
      [FANTOM_V_HOOKS.getBalance]: defaultParse,
      [FANTOM_V_HOOKS.getKeystoreStatus]: defaultParse,
      [FANTOM_V_HOOKS.deleteKeystore]: defaultParse,
      [FANTOM_V_HOOKS.checkValidator]: defaultParse,
    },
  },
};

import { createAction } from 'common/utils/reduxUtils';

export const InvoiceActionTypes = {
  FETCH_OVERVIEW: 'FETCH_OVERVIEW',

  GROUP_LIST_BY_MONTH: 'GROUP_LIST_BY_MONTH',

  FETCH_APIS_INVOICE_LIST: 'FETCH_APIS_INVOICE_LIST',
};

export const InvoiceActions = {
  fetchOverview: ({ teamId }: { teamId: string }) => {
    return {
      type: InvoiceActionTypes.FETCH_OVERVIEW,
      payload: { teamId },
    };
  },
  fetchApisInvoiceList: ({ teamId }: { teamId: string }) =>
    createAction(InvoiceActionTypes.FETCH_APIS_INVOICE_LIST, { teamId }),
};

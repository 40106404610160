import React from 'react';
import { ZcashDocs } from './ZcashDocs';
import { HooksGroup } from '../../../../NodesUI/screens/NodeDetails/Hooks/HooksGroup';
import { t, tHTML } from 'common/utils/intl';
import {
  Message,
  MessageType,
  MessageVariant,
} from 'common/components/Message';
import { useZcashCredentials } from '../ZcashHooks';
import { useInitEffect } from 'common/utils/hooks';
import { Box } from '@material-ui/core';
import { ValueHookRow } from '../../../../NodesUI/screens/NodeDetails/Hooks/ValueHookRow';

export const ZcashCredentials = () => {
  const { result, status, call } = useZcashCredentials();
  useInitEffect(() => {
    call();
  });
  return (
    <HooksGroup title={t('nodes.zcash.manage.credentials')} status={status}>
      <Box mt={2} mb={1}>
        <Message
          message={tHTML(`nodes.zcash.manage.memo`)}
          type={MessageType.info}
          variant={MessageVariant.quote}
        />
      </Box>
      {result && (
        <>
          <ValueHookRow
            title={t('nodes.zcash.manage.username')}
            value={result.rpcuser}
            showBoldLabel={false}
            showBorder={false}
          />
          <ValueHookRow
            title={t('nodes.zcash.manage.password')}
            value={result.rpcpassword}
            showBoldLabel={false}
            showBorder={false}
          />
        </>
      )}
      <ZcashDocs />
    </HooksGroup>
  );
};

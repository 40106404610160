import { t } from 'common/utils/intl';
import { isNodeNameValid } from 'common/utils/validation';

export const validateTerraNodeName = (value?: string) => {
  if (typeof value !== 'string') {
    return t('validation.field-required-notice');
  }

  if (!isNodeNameValid(value)) {
    return t('app-deploy.terra-custom-value.validation.name-format');
  }

  return undefined;
};

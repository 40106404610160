import { IHookResponse } from '../../../utils/convertResponseToError';
import { safeParseJSON } from 'common/utils/string';
import { isBase64 } from '../../apps/apiMappings/hookMappings';

export function parseNulStatus(response: IHookResponse): IHookResponse {
  try {
    let result = response.data?.result;

    result = isBase64(result) ? window.atob(result) : result;

    const resultArray = result.split('}{');

    const resultLocal = safeParseJSON(`${resultArray[0]}}`);

    const resultNetwork = safeParseJSON(`{${resultArray[1]}`);

    return {
      ...response,
      data: {
        networkHeight: resultNetwork?.result?.networkHeight ?? 1,
        localHeight: resultLocal?.result?.localHeight ?? 0,
        esResult: result,
      },
    };
  } catch (err) {
    return {
      ...response,
    };
  }
}

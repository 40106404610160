import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const HintIcon = withSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.75 8C11.75 10.0711 10.0711 11.75 8 11.75C5.92893 11.75 4.25 10.0711 4.25 8C4.25 5.92893 5.92893 4.25 8 4.25C10.0711 4.25 11.75 5.92893 11.75 8ZM13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM8.57932 9.58203V10.6367C8.57932 10.688 8.56413 10.7301 8.53376 10.7631C8.5034 10.796 8.46459 10.8125 8.41735 10.8125H7.44559C7.39835 10.8125 7.35955 10.796 7.32918 10.7631C7.29881 10.7301 7.28363 10.688 7.28363 10.6367V9.58203C7.28363 9.53076 7.29881 9.48865 7.32918 9.45569C7.35955 9.42273 7.39835 9.40625 7.44559 9.40625H8.41735C8.46459 9.40625 8.5034 9.42273 8.53376 9.45569C8.56413 9.48865 8.57932 9.53076 8.57932 9.58203ZM9.5941 6.04993C9.78137 6.32458 9.875 6.62304 9.875 6.94531C9.875 7.10644 9.85391 7.25476 9.81173 7.39026C9.76956 7.52576 9.72232 7.63653 9.67002 7.7226C9.61772 7.80866 9.54264 7.89655 9.44479 7.98627C9.34694 8.07599 9.26933 8.14008 9.21197 8.17853C9.15461 8.21698 9.07194 8.26917 8.96397 8.33508C8.85262 8.40466 8.76068 8.4953 8.68813 8.60699C8.61559 8.71869 8.57932 8.80932 8.57932 8.87891C8.57932 8.93017 8.56413 8.97229 8.53376 9.00525C8.5034 9.03821 8.46459 9.05469 8.41735 9.05469H7.44559C7.39835 9.05469 7.35955 9.03821 7.32918 9.00525C7.29881 8.97229 7.28363 8.93017 7.28363 8.87891V8.68115C7.28363 8.43579 7.3722 8.20599 7.54935 7.99176C7.72649 7.77753 7.92135 7.61914 8.13392 7.5166C8.29588 7.43603 8.41061 7.35364 8.47809 7.26941C8.54557 7.18518 8.57932 7.07348 8.57932 6.93433C8.57932 6.81348 8.51605 6.70544 8.38952 6.61023C8.26299 6.51502 8.11874 6.46741 7.95678 6.46741C7.78132 6.46741 7.63623 6.51135 7.52151 6.59924C7.41016 6.68713 7.26507 6.85559 7.08624 7.10461C7.05587 7.14856 7.0137 7.17053 6.95971 7.17053C6.91922 7.17053 6.88717 7.15955 6.86355 7.13757L6.19546 6.58826C6.11785 6.52234 6.10435 6.44543 6.15497 6.35754C6.58686 5.57751 7.21277 5.1875 8.0327 5.1875C8.32625 5.1875 8.61306 5.26258 8.89311 5.41272C9.17317 5.56286 9.40683 5.77527 9.5941 6.04993Z"
    fill="#6B82A2"
  />,
  { viewBox: '0 0 16 16' },
);

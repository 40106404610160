import React from 'react';

export const CheckboxInactive = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="21" height="21" rx="3.5" stroke="#6B82A2" />
  </svg>
);

import { useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppHook } from 'common/modules/apps/types';
import {
  requestInactive,
  RequestStatus,
  getRequestError,
} from 'common/utils/requestStatus';
import { getAppDetails } from 'common/modules/apps/reducers/appsReducer';
import { IStoreState } from 'store/reducers';
import { AppsActions } from 'common/modules/apps/actions/AppsActions';
import { useAppParams } from './appHooks';

export interface IHookHookResult<T = any, P = any> {
  call: (params?: P) => void;
  reset: () => void;
  abort: () => void;
  result?: T;
  cachedResult?: T;
  status: RequestStatus;
  statusError?: string;
  hook?: IAppHook<P>;
}

interface IHookHookParams {
  callOnce?: boolean;
}

export const useAppHook = <T = string, P = object>(
  hookName?: string,
  hookParams?: IHookHookParams,
): IHookHookResult<T, P> => {
  const { appId } = useAppParams();
  const { result, status, hook } = useSelector((state: IStoreState) => {
    const appEntry = getAppDetails(
      state.apps.details,
      appId,
      state.team.currentTeamId,
    );
    const hooks = appEntry?.data?.appHooks || [];
    const hook = hooks.find((x: IAppHook) => x.label === hookName) as
      | IAppHook<P>
      | undefined;
    // Getting Hook Data:
    const hookData = hookName ? state.apps.appHooksData[hookName] : undefined;
    const result = hookData?.data as T | undefined;

    const status = hookData?.status || requestInactive();

    return {
      hook,
      result,
      status,
      teamId: state.team.currentTeamId,
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  const called = useRef(false);

  const call = useMemo(
    () => (args?: P) => {
      if (hookParams?.callOnce) {
        if (called.current) return;
      }
      called.current = true;
      hookName &&
        dispatch(AppsActions.fetchAppHookData({ name: hookName, appId, args }));
    },
    [hookParams, hookName, dispatch, appId],
  );

  const abort = useMemo(
    () => (args?: P) => {
      hookName &&
        called &&
        dispatch(AppsActions.fetchAppHookData({ name: hookName, appId, args }));
    },
    [hookName, dispatch, appId],
  );

  const reset = useMemo(
    () => () => {
      hookName && dispatch(AppsActions.resetAppHook(hookName));
    },
    [hookName, dispatch],
  );

  const statusError = getRequestError(status);

  return {
    result,
    status,
    statusError,
    call,
    hook,
    reset,
    abort,
  };
};

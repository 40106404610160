import {
  all,
  delay,
  fork,
  join,
  put,
  race,
  select,
  take,
  takeEvery,
  putResolve,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { Action } from 'redux-actions';
import {
  IDeleteBankCardPayload,
  IEnableNewCardPayload,
  ISelectCardPaymentMethod,
  PayActions,
  PayActionTypes,
} from '../actions/PayActions';
import { ITeamState } from '../../teams/reducers/teamReducer';
import { PaymentMethod } from '../const';
import {
  IApiCheckPayMethodSelectableResponse,
  IApiCreateCardResponse,
  IApiStripeClientSecret,
  ICard,
  IPaymentMethod,
} from '../../apps/types/payTypes';
import { SendRequestResponse } from '../../../types';
import {
  getCurrentCard,
  IEnableNewBankCardData,
  IPayState,
} from '../reducers/payReducer';
import {
  AppsActions,
  AppsActionTypes,
  ICreateAppPayload,
} from '../../apps/actions/AppsActions';
import { CPU_DIVIDER, MEM_DIVIDER, STORAGE_DIVIDER } from '../../../core/const';
import { throwIfContainsError } from '../../../utils/throwIfContainsError';

const SELECTABLE_STATUS_LIMIT = 100;
const CREATED_CARD_ENABLE_TIMES_LIMIT = 10;
const CHECK_STATUS_DELAY = 5000;

interface IStripeSuccessResponse {
  setupIntent: {
    id: string;
    object: 'setup_intent';
    cancellation_reason: string | null;
    client_secret: string;
    created: number;
    description: null;
    last_setup_error: null;
    livemode: boolean;
    next_action: null;
    payment_method: string;
    payment_method_types: ['card'];
    status: 'succeeded';
    usage: 'off_session';
  };
}

function* handleEnableNewBankCardError(event: any) {
  Sentry.captureException(event);
  yield put({
    type: PayActionTypes.CREATE_NEW_BANK_CARD_ERROR,
    error: event?.error || event,
  });
  return event;
}

interface IStripeErrorResponse {
  error: string;
}

type IStripeResponse = IStripeSuccessResponse | IStripeErrorResponse;

function* onEnableNewBankCard({
  payload: { cardElement, address, stripe, enableCardOnCreate },
}: Action<IEnableNewCardPayload>): any {
  try {
    if (!stripe) {
      return yield handleEnableNewBankCardError(
        new Error("Stripe.js hasn't loaded yet."),
      );
    }
    const {
      currentTeamId = '',
      isCardPayMethodExist,
      isCurrentPaymentMethod,
    }: {
      currentTeamId?: string;
      isCardPayMethodExist: boolean;
      isCurrentPaymentMethod: boolean;
      paymentMethods: IPaymentMethod[];
    } = yield select((state: { team: ITeamState; pay: IPayState }) => {
      const cardPaymentMethod = state.pay.paymentMethods.find(
        method => method.name === PaymentMethod.CREDIT_CARD,
      );

      return {
        currentTeamId: state.team.currentTeamId,
        isCardPayMethodExist: !!cardPaymentMethod,
        isCurrentPaymentMethod: !!cardPaymentMethod?.selected,
        paymentMethods: state.pay.paymentMethods,
      };
    });

    if (!isCardPayMethodExist) {
      const setupResponse: SendRequestResponse = yield putResolve({
        type: 'EMPTY',
        request: {
          url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${currentTeamId}/payMethods/${PaymentMethod.CREDIT_CARD}:setup`,
          method: 'POST',
          meta: {
            paymentName: PaymentMethod.CREDIT_CARD,
            silent: true,
          },
        },
      });

      if ('error' in setupResponse) {
        Sentry.captureException(setupResponse);

        return yield handleEnableNewBankCardError(setupResponse);
      }
    }

    const getClientSecretResponse: SendRequestResponse<IApiStripeClientSecret> = yield putResolve(
      PayActions.fetchStripeClientSecret(currentTeamId),
    );

    if ('error' in getClientSecretResponse) {
      return yield handleEnableNewBankCardError(getClientSecretResponse);
    }

    const confirmSetupResponse: IStripeResponse = yield stripe.confirmCardSetup(
      getClientSecretResponse.data.stripe_client_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: address.name,
            address: {
              city: address.city,
              country: address.country,
              line1: address.line1,
              postal_code: address.postalCode,
              state: address.state,
            },
          },
        },
      },
    );

    if ('error' in confirmSetupResponse) {
      return yield handleEnableNewBankCardError(confirmSetupResponse);
    }

    const createResponse: SendRequestResponse<IApiCreateCardResponse> = yield putResolve(
      PayActions.createBankCard(
        currentTeamId,
        confirmSetupResponse.setupIntent.payment_method,
      ),
    );

    if ('error' in createResponse) {
      return yield handleEnableNewBankCardError(createResponse);
    }

    if (!isCurrentPaymentMethod) {
      const waitForSelectableStatusTask = yield fork(
        waitForSelectableStatus,
        currentTeamId,
      );

      const [isEmittedAgain] = yield race([
        take([PayActionTypes.CREATE_NEW_BANK_CARD]),
        join(waitForSelectableStatusTask),
      ]);

      if (isEmittedAgain) {
        waitForSelectableStatusTask.cancel();
        return;
      }

      const selectMethodResponse: SendRequestResponse<IApiStripeClientSecret> = yield putResolve(
        PayActions.selectPaymentMethod(
          currentTeamId,
          PaymentMethod.CREDIT_CARD,
          { silent: true },
        ),
      );

      if ('error' in selectMethodResponse) {
        return yield handleEnableNewBankCardError(selectMethodResponse);
      }

      if (enableCardOnCreate) {
        yield put(
          PayActions.enableBankCard(
            currentTeamId,
            confirmSetupResponse.setupIntent.payment_method,
          ),
        );
      }
    } else if (enableCardOnCreate) {
      let counter = 0;
      let checking = true;

      while (checking) {
        const enableCardResponse: SendRequestResponse<any> = yield putResolve(
          PayActions.enableBankCard(
            currentTeamId,
            confirmSetupResponse.setupIntent.payment_method,
          ),
        );

        counter += 1;

        if ('error' in enableCardResponse) {
          if (counter >= CREATED_CARD_ENABLE_TIMES_LIMIT) {
            return yield handleSelectCardPaymentMethodError(enableCardResponse);
          }
        } else {
          checking = false;
        }

        yield delay(CHECK_STATUS_DELAY);
      }
    }

    yield all([
      put(PayActions.fetchCards(currentTeamId)),
      put(PayActions.fetchPaymentMethodList(currentTeamId)),
    ]);

    yield put({
      type: PayActionTypes.CREATE_NEW_BANK_CARD_SUCCESS,
      data: {
        cardId: confirmSetupResponse.setupIntent.payment_method,
      } as IEnableNewBankCardData,
    });
  } catch (error) {
    return yield handleEnableNewBankCardError(error);
  }
}

function* waitForSelectableStatus(currentTeamId: string): any {
  let tries = 0;

  try {
    while (true) {
      const checkResponse: SendRequestResponse<IApiCheckPayMethodSelectableResponse> = yield putResolve(
        PayActions.checkPaymentMethod(
          currentTeamId,
          PaymentMethod.CREDIT_CARD,
          undefined,
          undefined,
          { silent: true },
        ),
      );

      tries += 1;

      // @ts-ignore
      if (checkResponse?.data?.selectable) {
        return checkResponse;
      }

      if (tries > SELECTABLE_STATUS_LIMIT) {
        return yield handleEnableNewBankCardError(
          new Error('Selectable status limit exceeded'),
        );
      }

      yield delay(CHECK_STATUS_DELAY);
    }
  } catch (error) {
    return yield handleEnableNewBankCardError(error);
  }
}

function* handleSelectCardPaymentMethodError(event: any) {
  Sentry.captureException(event);

  yield put({
    type: PayActionTypes.SELECT_CARD_PAYMENT_METHOD_ERROR,
    error: event?.error || event,
  });

  return event;
}

function* selectCardPaymentMethod(
  action: Action<ISelectCardPaymentMethod>,
): any {
  try {
    const {
      currentTeamId = '',
      isCardPaymentMethodEnabled,
      isCardEnabled,
    }: {
      currentTeamId?: string;
      isCardPaymentMethodEnabled: boolean;
      isCardEnabled: boolean;
      paymentMethods: IPaymentMethod[];
    } = yield select((state: { team: ITeamState; pay: IPayState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
        isCardPaymentMethodEnabled:
          state.pay.currentPaymentMethod === PaymentMethod.CREDIT_CARD,
        isCardEnabled:
          getCurrentCard(state.pay.cards)?.id === action.payload.card.id,
        paymentMethods: state.pay.paymentMethods,
      };
    });

    if (!isCardPaymentMethodEnabled) {
      const selectMethodResponse: SendRequestResponse<IApiStripeClientSecret> = yield putResolve(
        PayActions.selectPaymentMethod(
          currentTeamId,
          PaymentMethod.CREDIT_CARD,
        ),
      );

      if ('error' in selectMethodResponse) {
        return yield handleSelectCardPaymentMethodError(selectMethodResponse);
      }
    }

    if (!isCardEnabled) {
      const enableCardResponse: SendRequestResponse<any> = yield putResolve(
        PayActions.enableBankCard(currentTeamId, action.payload.card.id),
      );

      if ('error' in enableCardResponse) {
        return yield handleSelectCardPaymentMethodError(enableCardResponse);
      }
    }

    yield all([
      put(PayActions.fetchCards(currentTeamId)),
      put(PayActions.fetchPaymentMethodList(currentTeamId)),
    ]);

    yield put({
      type: PayActionTypes.SELECT_CARD_PAYMENT_METHOD_SUCCESS,
    });
  } catch (error) {
    return yield handleSelectCardPaymentMethodError(error);
  }
}

function* createAppByDepositCard(action: Action<ICreateAppPayload>) {
  try {
    yield put({
      type: AppsActionTypes.APP_CREATE,
    });

    const {
      currentTeamId,
      justCreatedCardId,
    }: {
      currentTeamId: string;
      justCreatedCardId?: string;
    } = yield select((state: { team: ITeamState; pay: IPayState }) => ({
      currentTeamId: state.team.currentTeamId ?? '',
      justCreatedCardId: state.pay.justCreatedCardId,
    }));

    const cardId = action.payload.depositPaymentMethod.id || justCreatedCardId;

    const createDepositCardResponse: SendRequestResponse<{
      code: 0;
      message: 'success';
      pre_pay_id: string;
    }> = throwIfContainsError(
      yield putResolve({
        type: 'EMPTY',
        request: {
          url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${currentTeamId}/cards/${cardId}`,
          method: 'POST',
          data: {
            team_id: currentTeamId,
            card_id: action.payload.depositPaymentMethod.id,
            period: action.payload.period,
            auto_renewal: 0,
            cluster_id: action.payload.cluster.id,
            chart_name: action.payload.chartName,
            cpu_limit: action.payload.cpu * CPU_DIVIDER,
            mem_limit: action.payload.memory * MEM_DIVIDER,
            storage_limit: action.payload.storage * STORAGE_DIVIDER,
            icon_url: action.payload.chartIconUrl,
            project_name: action.payload.name,
            replicas_number: action.payload.replicas,
          },
        },
        meta: { silent: true },
      }),
    );

    const payload = {
      ...action.payload,
      depositPaymentMethod: {
        id: createDepositCardResponse.data.pre_pay_id,
        paymentMethod: PaymentMethod.DEPOSIT_CREDIT_CARD,
      },
    };

    const response: SendRequestResponse<{
      cards: ICard[];
    }> = throwIfContainsError(
      yield putResolve(AppsActions.createApp(payload, '')),
    );

    return response;
  } catch (error) {
    yield put({
      type: AppsActionTypes.APP_CREATE_ERROR,
      data: { error },
    });
  }
}

function* createAppByDailyCard(action: Action<ICreateAppPayload>) {
  yield put({
    type: AppsActionTypes.APP_CREATE,
  });

  const cardId = action.payload.depositPaymentMethod.id;

  const {
    card,
  }: {
    card: ICard;
  } = yield select((state: { team: ITeamState; pay: IPayState }) => ({
    card: cardId
      ? state.pay.cards.find(item => item.id === cardId)
      : state.pay.cards.find(item => item.enabled),
  }));

  if (cardId) {
    yield selectCardPaymentMethod({ type: 'EMPTY', payload: { card } });
  }

  const response: SendRequestResponse<{}> = yield putResolve(
    AppsActions.createApp(action.payload),
  );

  return response;
}

function* handleDeleteBankCardError(event: any) {
  Sentry.captureException(event);
  yield put({
    type: PayActionTypes.DELETE_BANK_CARD_ERROR,
    error: event?.error || event,
  });
  return event;
}

function* deleteBankCard(action: Action<IDeleteBankCardPayload>): any {
  try {
    const {
      currentTeamId = '',
    }: {
      currentTeamId?: string;
    } = yield select((state: { team: ITeamState; pay: IPayState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
      };
    });

    const deleteCardResponse: SendRequestResponse<{
      cards: ICard[];
    }> = yield putResolve({
      type: PayActionTypes.DELETE_BANK_CARD,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${currentTeamId}/cards/${action.payload.cardId}`,
        method: 'DELETE',
      },
      meta: { silent: true },
    });

    if ('error' in deleteCardResponse) {
      return yield handleDeleteBankCardError(deleteCardResponse);
    }

    yield put({
      type: PayActionTypes.DELETE_BANK_CARD_SUCCESS,
      data: deleteCardResponse.data,
    });
  } catch (error) {
    return yield handleDeleteBankCardError(error);
  }
}

export function* cardPaySaga() {
  yield takeEvery(PayActionTypes.CREATE_NEW_BANK_CARD, onEnableNewBankCard);
  yield takeEvery(
    PayActionTypes.SELECT_CARD_PAYMENT_METHOD,
    selectCardPaymentMethod,
  );
  yield takeEvery(PayActionTypes.DELETE_BANK_CARD, deleteBankCard);
  yield takeEvery(
    PayActionTypes.CREATE_APP_BY_DAILY_CARD,
    createAppByDailyCard,
  );
  yield takeEvery(
    PayActionTypes.CREATE_APP_BY_DEPOSIT_CARD,
    createAppByDepositCard,
  );
}

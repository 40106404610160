export const vitaeNodeName = 'vitae';

export enum VitaeHooks {
  nodeStatus = 'apphook1=node-status',
}

export const VITAE_USERNAME = 'ankrCustomValues___username';
export const VITAE_PASSWORD = 'ankrCustomValues___password';

export const VITAE_DOCS_LINK =
  'https://docs.ankr.com/enteprise-solutions/vitae';

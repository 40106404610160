import scrypt from 'scrypt-js';
import { IEncryptedPrivateKey } from 'common/types/walletTypes';

export function getKDFKey(
  encryptedPrivateKey: IEncryptedPrivateKey,
  auth: string,
): Promise<readonly number[]> {
  const authArray = Buffer.from(auth, 'utf8');
  const salt = Buffer.from(encryptedPrivateKey.kdfparams.salt, 'hex');
  const dkLen = encryptedPrivateKey.kdfparams.dklen;

  const n = encryptedPrivateKey.kdfparams.n;
  const r = encryptedPrivateKey.kdfparams.r;
  const p = encryptedPrivateKey.kdfparams.p;

  return new Promise((resolve, reject) => {
    scrypt
      .scrypt(authArray, salt, n, r, p, dkLen)
      .then(function(key) {
        if (key) {
          resolve(Array.from(key));
        }
      })
      .catch(err => {
        if (err) {
          reject(err);
        }
      });
  });
}

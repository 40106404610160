import {
  AVALANCHE_API_ADMIN_ENABLED,
  AVALANCHE_API_IPCS_ENABLED,
  AVALANCHE_API_KEYSTORE_ENABLED,
  AVALANCHE_API_METRICS_ENABLED,
  AVALANCHE_SNOW_QUORUM_SIZE,
  AVALANCHE_SNOW_SAMPLE_SIZE,
  AvalancheHooks,
  avalancheNodeName,
} from './AvalancheConst';
import {
  IGetLatestCustomValuesParams,
  INodeConfig,
} from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/avalanche.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableManage = loadable(
  async () =>
    import('./manage/AvalancheManage').then(module => module.AvalancheManage),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/AvalancheCustomValues').then(
      module => module.AvalancheCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const avalancheConfig: INodeConfig = {
  chartName: avalancheNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      [AVALANCHE_SNOW_QUORUM_SIZE]: '14',
      [AVALANCHE_SNOW_SAMPLE_SIZE]: '20',
      [AVALANCHE_API_KEYSTORE_ENABLED]: true,
      [AVALANCHE_API_ADMIN_ENABLED]: true,
      [AVALANCHE_API_METRICS_ENABLED]: true,
      [AVALANCHE_API_IPCS_ENABLED]: true,
    },
  }),
  getNodeLatestCustomValues: ({
    customValues,
  }: IGetLatestCustomValuesParams) => ({
    [AVALANCHE_API_IPCS_ENABLED]: Boolean(
      customValues && (customValues[AVALANCHE_API_IPCS_ENABLED] ?? true),
    ).toString(),
    [AVALANCHE_API_KEYSTORE_ENABLED]: Boolean(
      customValues && (customValues[AVALANCHE_API_KEYSTORE_ENABLED] ?? true),
    ).toString(),
    [AVALANCHE_API_ADMIN_ENABLED]: Boolean(
      customValues && (customValues[AVALANCHE_API_ADMIN_ENABLED] ?? true),
    ).toString(),
    [AVALANCHE_API_METRICS_ENABLED]: Boolean(
      customValues && (customValues[AVALANCHE_API_METRICS_ENABLED] ?? true),
    ).toString(),
  }),
  hideCustomVariableDivider: true,
  // customAppName: NodeConfiguration,
  info: LoadableManage,
  manage: false as false,
  details: false as false,
  interceptors: {
    avalanche: {
      [AvalancheHooks.getApiStatus]: defaultParse,
      [AvalancheHooks.getBootstrapStatus]: defaultParse,
    },
  },
};

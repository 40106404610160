import { TOKENS_PERSIST_KEY } from 'common/core/const';
import storage from 'redux-persist/lib/storage';
import { userReducerStorageBlacklist } from './userReducer';
import { teamReducerStorageBlacklist } from 'common/modules/teams/reducers/teamReducer';

export const tokensPersistConfig = {
  key: TOKENS_PERSIST_KEY,
  storage,
};

export const userPersistConfig = {
  key: 'user',
  storage: storage,
  blacklist: userReducerStorageBlacklist,
};

export const teamPersistConfig = {
  key: 'team',
  storage: storage,
  blacklist: teamReducerStorageBlacklist,
};

export const cosmosNodeName = 'cosmos';

export enum CosmosHooks {
  nodeStatus = 'apphook1=node-status',
}

export const COSMOS_ENABLE_SNAPSHOT = 'ankrCustomValues___enable_snapshot';
export const COSMOS_MONIKER = 'ankrCustomValues___moniker';

export const COSMOS_DOCS_HREF =
  'https://docs.ankr.com/enteprise-solutions/cosmos';

import { TerraHooks } from '../terraConst';
import { useAppHook } from '../../../../NodesUI/hooks/useAppHook';

interface ITerraStatusHook {
  current_height: string;
  latest_height: string;
  status: string;
}

export const useTerraStatus = () => {
  return useAppHook<ITerraStatusHook>(TerraHooks.TERRA_GET_STATUS);
};

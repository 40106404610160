import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import { NodeConfiguration } from '../../../AppDeployUI/components/DeployAppName/Custom/NodeConfiguration';
import { NearHooks, nearNode } from './nearConst';
import { parseNearLink } from 'common/modules/nodes/interceptors/NearInterceptors';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableTabs = loadable(
  async () => import('./manage/NearTabs').then(module => module.NearTabs),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/NearCustomValue').then(module => module.NearCustomValues),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const nearConfig: INodeConfig = {
  chartName: nearNode,
  deploy: {
    component: LoadableDeploy,
  },
  getDefaultCustomValues: ({ appList, chartName, userName }) => ({
    customValues: {
      ankrCustomValues___mnemonic: '',
      ankrCustomValues___password: '',
      ankrCustomValues___moniker: '',
    },
  }),
  customAppName: NodeConfiguration,
  tabsComponent: LoadableTabs,
  manage: false as false,
  details: false as false,
  info: false as false,
  interceptors: {
    [nearNode]: {
      [NearHooks.NEAR_GET_NODE_PUBLIC_KEY]: defaultParse,
      [NearHooks.NEAR_GET_NODE_VALIDATOR_KEY]: defaultParse,
      [NearHooks.NEAR_GET_LOGIN_LINK]: parseNearLink,
    },
  },
};

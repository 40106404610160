import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const NodesIcon = withSvgIcon(
  <path
    d="M11.5097 4.42312C11.8142 4.25189 12.1858 4.25189 12.4903 4.42312L12.9805 3.55154L12.4903 4.42312L18.4903 7.79812C18.8051 7.97524 19 8.30842 19 8.66969V15.3303C19 15.6916 18.8051 16.0248 18.4903 16.2019L12.4903 19.5769L12.9805 20.4485L12.4903 19.5769C12.1858 19.7481 11.8142 19.7481 11.5097 19.5769L11.0195 20.4485L11.5097 19.5769L5.50974 16.2019C5.19486 16.0248 5 15.6916 5 15.3303V8.66969C5 8.30842 5.19486 7.97524 5.50974 7.79812L11.5097 4.42312Z"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
  />,
);

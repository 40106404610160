export const sifchainNodeName = 'sifchain';

export enum SifchainHooks {
  nodeStatus = 'apphook1=node-status',
}

export const SIFCHAIN_MONIKER = 'ankrCustomValues___moniker';

export const SIFCHAIN_DOCS_HREF =
  'https://docs.ankr.com/enteprise-solutions/sifchain';

import React from 'react';
import {
  Box,
  StyledComponentProps,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import { ReactNode } from 'react';
import { TooltipHint } from 'common/components/TooltipHint';

const styles = (theme: Theme): StyleRules => ({
  root: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    minHeight: 24,
  },
  label: {
    fontWeight: 'bold',
  },
});

interface IFormControlLabelProps extends StyledComponentProps {
  children: ReactNode;
  description?: string;
  title?: string;
}

const FormControlLabelComponent = ({
  classes = {},
  children,
  description,
  title,
}: IFormControlLabelProps) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{children}</Typography>
      {description && (
        <Box display="flex" alignItems="center">
          <TooltipHint title={title} description={description} />
        </Box>
      )}
    </div>
  );
};

export const FormControlLabel = withStyles(styles)(FormControlLabelComponent);

import { IExtendedAxiosResponse } from 'common/types';
import { INodeLog, IApiNodeLogs } from '../nodeLogTypes';
import { createReducer, createAPIReducer } from '../../../utils/reduxUtils';
import { NodeLogActionType } from '../actions/NodeLogAction';
import { RequestStatus, requestInactive } from '../../../utils/requestStatus';
import { mapNodeLog } from '../apiMappings/nodeLogMappings';

export interface INodeLogState {
  nodeLogs: INodeLog[];
  fetchNodeLogStatus: RequestStatus;
}

const initState: INodeLogState = {
  nodeLogs: [],
  fetchNodeLogStatus: requestInactive(),
};

export const nodeLogReducer = createReducer(initState, {
  ...createAPIReducer<INodeLogState, IExtendedAxiosResponse<IApiNodeLogs>>(
    NodeLogActionType.FETCH_LOGS,
    'fetchNodeLogStatus',
    {
      onSuccess: (state: INodeLogState, action) => {
        return {
          ...state,
          nodeLogs: mapNodeLog(action.response.data),
        };
      },
    },
  ),
});

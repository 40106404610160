import React from 'react';
import { DocsHookRow } from '../../../../NodesUI/screens/NodeDetails/Hooks/DocsHookRow';
import { t } from 'common/utils/intl';

const DOCS_HREF = 'https://swagger.terra.money/#/Tendermint_RPC/get_node_info';

export const TerraListRPCCalls = () => {
  return (
    <DocsHookRow title={t('nodes.reddcoin.list-rpc-calls')} href={DOCS_HREF} />
  );
};

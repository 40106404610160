import React from 'react';
import { t } from 'common/utils/intl';
import { Box, Typography } from '@material-ui/core';

import { StyleRules } from '@material-ui/core/styles';

import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';

const styles = (): StyleRules => ({
  bold: {
    fontWeight: 'bold',
  },
});

interface INodeConfigurationProps extends StyledComponentProps {}

const NodeConfigurationComponent = ({
  classes = {},
}: INodeConfigurationProps) => {
  return (
    <>
      <Box mb={3.5} display="flex">
        <Typography variant="h3">
          {t('app-deploy.node-configuration-title')}
        </Typography>
      </Box>

      <Typography variant="body1" className={classes.bold}>
        {t('app-deploy.deploy.application')}
      </Typography>
    </>
  );
};

const NodeConfiguration = withStyles(styles)(NodeConfigurationComponent);

export { NodeConfiguration };

import React from 'react';
import {
  createCustomTheme,
  TOOLBAR_HEIGHT,
  MOBILE_TOOLBAR_HEIGHT,
} from './createCustomTheme';
import { createMuiTheme } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { darken } from '@material-ui/core/styles';
import { CheckboxActive } from './assets/CheckboxActive';
import { CheckboxInactive } from './assets/CheckboxInactive';
import { ArrowDropDownIcon } from 'common/components/Icons/ArrowDropDownIcon';
import { ISvgIconProps } from 'common/components/Icons/withSvgIcon';
import { ThumbIcon } from 'common/components/Icons/ThumbIcon';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const defaultTheme = createMuiTheme();

export const DEFAULT_FONT = '"Lab Grotesque", sans-serif';

export const PALETTE = {
  type: 'light',
  primary: {
    light: '#569AEF',
    main: '#2075E8',
    dark: '#181F49',
    contrastText: '#EEF0F4',
  },
  text: {
    primary: '#1A2439',
    secondary: '#364457',
  },
  error: {
    main: '#F1534C',
  },
  warning: {
    main: '#FFB63C',
  },
  success: {
    main: '#36C98E',
  },
  grey: {
    900: '#1A2439',
    800: '#364457',
    700: '#6B82A2',
    300: '#F5F8FD',
    200: '#E1E4EB',
    100: '#EEF0F4',
  },
  divider: '#E1E4EB',
};

const mainTheme = createCustomTheme({
  typography: {
    fontFamily: DEFAULT_FONT,
    body2: {
      fontFamily: DEFAULT_FONT,
    },
  },
  props: {
    MuiUseMediaQuery: {
      noSsr: true,
    },
    MuiLink: {
      underline: 'none',
    },
    MuiMenu: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    },
    MuiListItem: {
      disableRipple: true,
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiButton: {
      color: 'primary',
      disableRipple: true,
      variant: 'contained',
      size: 'large',
    },
    MuiButtonGroup: {
      color: 'primary',
      disableRipple: true,
      size: 'large',
    },
    MuiCheckbox: {
      color: 'primary',
      checkedIcon: <CheckboxActive />,
      icon: <CheckboxInactive />,
    },
    MuiSelect: {
      IconComponent: ({ ref, ...rest }: ISvgIconProps) => {
        return <ArrowDropDownIcon size="xs" {...rest} />;
      },
    },
    MuiSlider: {
      ThumbComponent: ThumbIcon,
    },
    MuiIconButton: {
      disableRipple: true,
    },
  },
  palette: PALETTE as PaletteOptions,
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: PALETTE.primary.main,
      },
      root: {
        minHeight: 44,
        minWidth: 50,
        [defaultTheme.breakpoints.down('sm')]: {
          minHeight: 25,
        },
      },
    },
    MuiRadio: {
      root: {
        color: PALETTE.grey[700],
      },
      colorPrimary: {
        '&$checked': {
          color: PALETTE.primary.main,
        },
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: 700,
      },
      paperWidthSm: {
        maxWidth: 600,
        [defaultTheme.breakpoints.down('sm')]: {
          maxWidth: 368,
        },
      },
      paperWidthXs: {
        maxWidth: 300,
      },
      paperFullWidth: {
        [defaultTheme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      paper: {
        [defaultTheme.breakpoints.down('sm')]: {
          margin: 16,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 20,
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        [defaultTheme.breakpoints.down('xs')]: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        gap: '20px',
        padding: '18px 52px',
        [defaultTheme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },
    },
    MuiTab: {
      root: {
        '&&': {
          lineHeight: 1.4,
          minHeight: 44,
          minWidth: 50,
          fontWeight: 'bold',
          fontSize: 18,
          textTransform: 'none',
          padding: '8px 0 5px 0',
          marginRight: 32,
          paddingTop: 2,
          paddingBottom: 8,
          color: PALETTE.text.secondary,

          transitionDuration: '200ms',
          transitionTimingFunction: 'linear',
          transitionProperty: 'color',

          '&:hover': {
            color: PALETTE.primary.main,
            opacity: 1,
          },

          '&:focus': {
            color: PALETTE.primary.main,
            opacity: 1,
          },
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: 0,
        boxShadow: `0px 4px 14px rgba(12, 43, 84, 0.2)`,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold',
        width: '100%',
        '@media print': {
          display: 'none',
        },
      },
      action: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 8,
      },
      message: {
        padding: '16px 0',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        backgroundColor: defaultTheme.palette.common.white,
      },
    },
    MuiToolbar: {
      root: {
        '&&': {
          minHeight: TOOLBAR_HEIGHT,
          [defaultTheme.breakpoints.down('xs')]: {
            minHeight: MOBILE_TOOLBAR_HEIGHT,
          },
        },
      },
    },
    MuiButton: {
      root: {
        padding: '6px 16px',

        [defaultTheme.breakpoints.down('sm')]: {
          padding: '4px 12px',
        },
        '&&': {
          textTransform: 'none',
          fontSize: 14,
          fontWeight: 'bold',
        },
        '&&, &&:active': {
          boxShadow: 'none',
        },
        '&$sizeSmall': {
          fontSize: 12,
          lineHeight: '20px',
        },
        '&$sizeLarge': {
          padding: '10px 16px',
        },
        '&$textPrimary:hover': {
          backgroundColor: 'transparent',
        },
      },
      contained: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        backgroundColor: '#F1534C',
        '&:hover': {
          backgroundColor: '#DE4C45',
        },
      },
      outlined: {
        padding: '5px 16px',
        '&$sizeSmall': {
          paddingTop: 3,
          paddingBottom: 3,
        },
        '&$sizeLarge': {
          padding: '9px 16px',
        },
      },
      containedPrimary: {
        '&, a&': {
          color: '#fff',
        },
        '&$disabled': {
          backgroundColor: '#C7D0DD',
          color: '#fff',
        },
        '&:hover': {
          backgroundColor: PALETTE.primary.light,
        },
        '&:active': {
          backgroundColor: darken(PALETTE.primary.main, 0.1),
        },
        '& $label': {
          textShadow: '0px 1px 2px rgba(24, 101, 205, 0.4)',
        },
        '&$focusVisible': {
          boxShadow: 'none',
          backgroundColor: darken(PALETTE.primary.main, 0.1),
        },
      },
      outlinedPrimary: {
        borderColor: '#E1E4EB',
        '&, a&': {
          color: PALETTE.grey['700'],
        },
        '&$disabled': {
          color: '#C7D0DD',
          borderColor: '#E1E4EB',
        },
        '&:hover': {
          color: PALETTE.primary.main,
          backgroundColor: '#fff',
        },
        '&:active': {
          backgroundColor: PALETTE.grey['#EEF0F4'],
          color: PALETTE.primary.main,
        },
      },
      textSecondary: {
        '&&': {
          padding: 0,
        },
        color: PALETTE.grey['700'],
        '&$disabled': {
          color: '#C7D0DD',
          backgroundColor: 'transparent',
        },
        '&:hover': {
          color: PALETTE.primary.main,
          backgroundColor: 'transparent',
        },
        '&:active': {
          color: PALETTE.primary.main,
          backgroundColor: 'transparent',
        },
      },
      sizeLarge: {
        minWidth: 120,
      },
    },
    MuiButtonGroup: {
      root: {
        '&&': {
          display: 'flex',
          boxShadow: 'none',
        },
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 30,
        fontWeight: 'bold',
        lineHeight: '38px',
        '&$paragraph': {
          marginBottom: 8,
        },
      },
      h2: {
        fontSize: 26,
        fontWeight: 'bold',
        lineHeight: '120%',
      },
      h3: {
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '120%',
      },
      h4: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '120%',
      },
      h5: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '120%',
      },
      h6: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '120%',
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 'normal',
        lineHeight: '130%',
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '120%',
      },
      body1: {
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '130%',
        '&$paragraph': {
          marginBottom: 8,
        },
      },
      body2: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '130%',
        '&$paragraph': {
          marginBottom: 8,
        },
      },
      caption: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '130%',
        '&$paragraph': {
          marginBottom: 8,
        },
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 10,
      },
      inputAdornedStart: {
        paddingLeft: 8,
      },
      multiline: {
        padding: '12px 14px',
        color: PALETTE.grey['800'],
        fontWeight: 500,
        lineHeight: '130%',
      },
      input: {
        padding: '22px 16px',
        fontSize: 14,
        fontWeight: 500,
        boxSizing: 'border-box',
        background: '#fff',
        '&[disabled]': {
          color: PALETTE.grey['800'],
          backgroundColor: PALETTE.grey['100'],
        },
      },
      root: {
        '&$focused $notchedOutline, &:hover $notchedOutline': {
          borderColor: PALETTE.primary.light,
          borderWidth: 1,
        },
        '&$disabled': {
          backgroundColor: PALETTE.grey['100'],
          borderColor: PALETTE.grey['200'],
        },
        '&$disabled:hover $notchedOutline, &$disabled $notchedOutline': {
          borderColor: PALETTE.grey['200'],
        },
      },
      notchedOutline: {
        borderColor: PALETTE.grey['200'],
      },
      marginDense: {
        paddingTop: 7.5,
        paddingBottom: 7.5,
      },
    },

    MuiInputBase: {
      root: {
        background: '#fff',
      },
      input: {
        '&[type=number]': {
          padding: '13px 16px 14px',
          height: '100%',
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            margin: 0,
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            margin: 0,
          },
        },
        '&:-webkit-autofill': {
          // Chrome autocomplete input background overwriting
          transitionDelay: '99999s',
          transitionProperty: 'background-color, color',
        },
        fontWeight: 500,
        borderRadius: 4,
        '&$disabled': {
          background: PALETTE.grey[200],
        },
      },
    },

    MuiPaper: {
      root: {
        '&$elevation1': {
          boxShadow: 'none',
        },
        '&$elevation3': {
          boxShadow: 'none',
          border: '1px solid #E1E4EB',
        },
        '&$elevation2': {
          boxShadow: '0px 8px 14px rgba(54, 68, 87, 0.26)',
        },
      },
      outlined: {
        border: `1px solid ${PALETTE.grey['200']}`,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 8,
        marginBottom: 6,
      },
    },
    MuiMenu: {
      paper: {
        marginTop: 8,
      },
    },
    MuiList: {},
    MuiMenuItem: {
      root: {
        fontSize: 14,
        color: '#333',
        fontWeight: 500,
        minHeight: 32,
        width: '100%',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          background: 'transparent',
          color: PALETTE.primary.main,
        },
      },
      button: {
        paddingTop: 12,
        paddingBottom: 12,
        color: PALETTE.grey['700'],
        fontWeight: 500,
        '&:hover': {
          color: PALETTE.primary.main,
          '& path': {
            fill: PALETTE.primary.main,
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiCheckbox: {
      root: {
        padding: '8px 14px',
        height: 38,
        width: 50,
        '&:hover rect': {
          stroke: PALETTE.primary.light,
        },
        '&.Mui-checked:hover rect': {
          fill: PALETTE.primary.light,
        },
        [defaultTheme.breakpoints.down('xs')]: {
          padding: 0,
          height: 40,
          width: 40,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -8,
      },
      labelPlacementTop: {
        '&&': {
          width: '100%',
          margin: 0,
          marginLeft: 0,
          alignItems: 'flex-start',
        },
        '& $label': {
          fontSize: 14,
          fontWeight: 'bold',
          color: PALETTE.grey['800'],
          marginBottom: 8,
        },
      },
    },
    MuiContainer: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
        '& a': {
          textDecoration: 'none',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      maxWidthXl: {
        '&&': {
          maxWidth: 1600,
        },
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiSelect: {
      select: {
        paddingLeft: 16,
        padding: '12px 10px',
        fontSize: 14,
        background: '#fff',
        height: 44,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        position: 'absolute',
        '&&': {
          right: 17,
          color: PALETTE.grey['700'],

          top: '50%',
          transform: 'translate(0, -50%)',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        color: PALETTE.text.secondary,
        maxWidth: 220,
        boxShadow: '0px 4px 14px rgba(12, 43, 84, 0.2)',
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '130%',
        '& .highlight': {
          color: PALETTE.warning.main,
        },
      },
      arrow: {
        color: 'white',
      },
    },
    MuiSvgIcon: {
      root: {
        '.Mui-focused &': {
          color: PALETTE.primary.main,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',

          '& svg': {
            color: PALETTE.primary.main,
          },
        },
      },
    },
    MuiSlider: {
      thumb: {
        width: 38,
        height: 28,
        transform: 'translateX(-15px) translateY(-9px)',
        backgroundColor: 'unset',
        borderRadius: 8,
      },
      track: {
        height: 1,
        borderRadius: 0,
      },
      rail: {
        height: 1,
        borderRadius: 0,
        backgroundColor: PALETTE.grey['100'],
        opacity: 1,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: PALETTE.grey['200'],
      },
      light: {
        backgroundColor: PALETTE.grey['100'],
      },
    },
    MuiTableContainer: {
      root: {
        padding: '0 12px',
        margin: '0px -12px',
        width: 'calc(100% + 24px)',
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: '0',
        tableLayout: 'fixed',
      },
    },
    MuiTableCell: {
      head: {
        color: PALETTE.grey['700'],
        fontSize: 12,
        borderBottom: 'none',
        padding: '0 24px 16px 24px',
        position: 'relative',
        top: 8,
      },
      body: {
        backgroundColor: defaultTheme.palette.common.white,
        padding: '24px',
        color: PALETTE.text.secondary,
        '&:first-child': { borderTopLeftRadius: 4, borderBottomLeftRadius: 4 },
        '&:last-child': { borderTopRightRadius: 4, borderBottomRightRadius: 4 },
      },
    },
    MuiTableRow: {
      hover: {
        transition: 'box-shadow 300ms',
        '&:hover': {
          boxShadow: '0px 4px 14px rgba(12, 43, 84, 0.2)',
          cursor: 'pointer',
        },
        '&:hover > td:last-child svg, &:hover > td:last-child svg path': {
          fill: PALETTE.primary.main,
          opacity: 1,
        },
      },
    },
    MuiAutocomplete: {
      root: {
        width: '100%',
      },
      input: {
        paddingTop: '13px !important',
        paddingBottom: '13px !important',
      },
      inputRoot: {
        background: defaultTheme.palette.common.white,
      },
    },
    MuiFormHelperText: {
      marginDense: {
        fontSize: 16,
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        '&$expanded': {
          transform: 'rotate(0)',
        },
      },
    },
  },
} as ThemeOptions);

export { mainTheme };

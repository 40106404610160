import { useAppHook } from 'modules/NodesUI/hooks/useAppHook';
import { ZcashHooks } from './ZcashConst';

interface IZcashStatus {
  chain: 'main';
  blocks: number;
  headers: number;
  bestblockhash: string;
  difficulty: number;
  verificationprogress: number; // 0..1
  chainwork: string;
  pruned: false;
  size_on_disk: number;
  estimatedheight: number;
  commitments: number;
  valuePools: any[];
  softforks: any[];
  upgrades: any;
  consensus: { chaintip: string; nextblock: string };
}
export const useZCashStatus = () => {
  return useAppHook<IZcashStatus>(ZcashHooks.getStatus);
};

export const useZcashCredentials = () => {
  return useAppHook<{ rpcuser: string; rpcpassword: string }>(
    ZcashHooks.getCredentials,
  );
};

import BigNumber from 'bignumber.js';

import { IApiApisInvoice, IApisInvoice } from '../types/invoiceType';
import { CardBrand } from '../types/payTypes';

export const normalizeApisInvoiceList = (
  data: IApiApisInvoice[],
): IApisInvoice[] => {
  return data.map((item: IApiApisInvoice) => {
    return {
      id: item.order_id,
      projectName: item.project_name,
      createDateStamp: new Date(parseInt(item.create_date_stamp) * 1000),
      startTime: new Date(parseInt(item.service_period_start) * 1000),
      endTime: new Date(parseInt(item.service_period_end) * 1000),
      iconUrl: item.icon_url,
      paymentMethod: item.payment_info.payment_methods,
      cardId: item.payment_info.credit_card_id,
      cardBrand: item.payment_info.credit_card_brand as CardBrand,
      totalUsd: new BigNumber(item.total),
    };
  });
};

export const rippleFullNodeNodeName = 'ripple-full-node';

export enum RippleFullNodeHooks {
  nodeStatus = 'apphook1=node-status',
  current_ledger = 'apphook2=current_ledger',
  ledgers = 'apphook3=ledgers',
}

export const RIPPLE_FULL_NODE_DOCS_HREF =
  'https://docs.ankr.com/enteprise-solutions/ripple';

export const FANTOM_V_NODE_NAME = 'fantom-val';

export const FANTOM_V_PASSWORD = 'ankrCustomValues___password';
export const FANTOM_V_WALLET_KEYSTORE = 'ankrCustomValues___wallet-keystore';
export const FANTOM_V_VALIDATOR_KEYSTORE =
  'ankrCustomValues___validator-keystore';

export enum FANTOM_V_HOOKS {
  getNodeStatus = 'apphook1=node-status',
  getAddresses = 'apphook2=get-addresses',
  getValidatorKeystore = 'apphook3=get-validator-keystore',
  getWalletKeystore = 'apphook4=get-wallet-keystore',
  addValidator = 'apphook5=add-validator',
  getBalance = 'apphook6=get-balance',
  getKeystoreStatus = 'apphook7=keystore-status',
  deleteKeystore = 'apphook8=delete-keystore',
  checkValidator = 'apphook9=check-validator',
}

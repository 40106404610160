import { Theme } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/styles';

export const useNavBarXSStyles = makeStyles<Theme, { column: number }>(
  theme => ({
    root: {
      position: 'fixed',

      width: '100vw',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.common.white,

      boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.15)',
    },

    nav: {
      display: 'grid',
      gridTemplateRows: 62,
      gridTemplateColumns: props =>
        `repeat(auto-fill, minmax(${100 / props.column}%, 1fr))`,
    },

    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,

      padding: '9px 0',

      textDecoration: 'none',

      outline: 'none',
      tapHighlightColor: 'transparent',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },

      '&:hover svg': {
        color: theme.palette.common.white,
      },

      '&:hover $text': {
        height: 12,
        marginTop: 5,
      },

      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },

      '&:focus $text': {
        height: 12,
        marginTop: 5,
      },

      '&:focus svg': {
        color: theme.palette.common.white,
      },

      '&:active': {
        backgroundColor: theme.palette.primary.main,
      },

      '&:active $text': {
        height: 12,
        marginTop: 5,
      },

      '&:active svg': {
        color: theme.palette.common.white,
      },
    },

    current: {
      backgroundColor: theme.palette.primary.main,

      '& svg': {
        color: theme.palette.common.white,
      },

      '& $text': {
        height: 12,
        marginTop: 5,
      },
    },

    text: {
      height: 0,
      marginTop: 0,

      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: 1,

      overflow: 'hidden',
    },

    intercom: {
      flexGrow: 1,
      flexShrink: 0,

      padding: 0,

      color: theme.palette.primary.main,

      border: 0,
      backgroundColor: 'transparent',

      '&:focus': {
        outline: 'none',
      },
    },
  }),
);

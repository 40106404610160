export interface IFetchNodeLogPayload {
  teamId: string;
  startTime: string;
  endTime: string;
}

type NodeLogLevel = 'INFO' | 'SUCCESS' | 'WARNING' | 'DANGER';

export enum Action {
  'upgrade_api_project' = 'API upgraded!',
  'api_project_subscribe_disable' = 'Unsubscribed from API plan.',
  'api_project_subscribe_enable' = 'Subscribed to API plan.',
  'downgrade_api_project' = 'API downgraded',
  'create_api_project' = 'New API created!',
  'delete_api_project' = 'API deleted.',
  'delete_api_project_allowed_domain' = 'Website address is deleted.',
  'create_api_project_allowed_domain' = 'Website address is added.',
  'create_api_project_ip_white_list' = 'IP address is added.',
  'delete_api_project_ip_white_list' = 'IP address is deleted.',
}

export interface IApiNodeLog {
  level: NodeLogLevel;
  user_id: string;
  team_id: string;
  action: keyof typeof Action;
  item: string;
  timestamp: string;
  user_name?: string;
}

export interface IApiNodeLogs {
  event_item: IApiNodeLog[];
}

export interface INodeLog {
  level: NodeLogLevel;
  action: Action;
  item: string;
  time: Date;
  userName?: string;
}

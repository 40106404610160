import {
  HederaHashgraphHooks,
  hederaHashgraphNodeName,
} from './HederaHashgraphConst';
import { INodeConfig } from 'common/utils/nodes/NodesTypes';
import localesUS from './locales/HederaHashgraph.en-US.json';
import { defaultParse } from 'common/modules/nodes/interceptors/hooksInterceptor';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadingIndicator } from 'common/components/LoadingIndicator';
import { ICustomValueComponentProps } from 'common/modules/apps/types';
import React from 'react';

const LoadableConfig = loadable(
  async () =>
    import('./manage/HederaHashgraphManage').then(
      module => module.HederaHashgraphManage,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<any>;

const LoadableDeploy = loadable(
  async () =>
    import('./deploy/HederaHashgraphCustomValues').then(
      module => module.HederaHashgraphCustomValues,
    ),
  {
    fallback: <LoadingIndicator />,
  },
) as LoadableComponent<ICustomValueComponentProps>;

export const hederaHashgraphConfig: INodeConfig = {
  chartName: hederaHashgraphNodeName,
  deploy: {
    component: LoadableDeploy,
  },
  locales: {
    'en-US': localesUS,
  },
  hideCustomVariableDivider: true,
  info: LoadableConfig,
  manage: false as false,
  details: false as false,
  interceptors: {
    importer: {
      [HederaHashgraphHooks.getNodeStatus]: defaultParse,
    },
  },
};
